export default class Platform {

    id;
    nonce;

    name;
    description;

    hostNames;
    features;

    constructor() {
        this.nonce = Math.random().toString(36).substring(7);

        this.hostNames = [];
        this.features = [];
    }
}