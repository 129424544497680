import React from "react";

const Marker = (props) => {
    const { color, name, id } = props;
    return (
        <div key={id}>
            <div className="pin bounce" style={{ backgroundColor: color, cursor: "pointer" }} title={name} />
            <div className="pulse" />
        </div>
    );
};

export default Marker;
