import BusinessLocation from "./BusinessLocation";

export default class Business {
    id;
    nonce;

    location;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.location = [new BusinessLocation()];
    }
}
