import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import Loader from '../../ui/components/Loader';

import Chat from '../../../models/chat/Chat';
import ChatUser from '../../../models/chat/ChatUser';

import { activateInputs } from '../../../utils/formUtils';
import { base64ToBlob } from '../../../utils/imageUtils';

import CreateMessageForm from './CreateMessageForm';
import AvatarPicker from '../../ui/components/AvatarPicker';

export default class CreateChatPage extends Component {
    state = { inputValue: '', avatarPicker: false, avatar: '/images/group.svg' };
    searchTimeout = null;

    componentDidMount() {
        const { onChange } = this.props;
        activateInputs();

        onChange('formChat', new Chat());
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit() {
        const { formChat, message, createChat } = this.props;
        formChat.users.length > 0 && createChat(formChat, message);
    }

    getChatUser = (user) => {
        const { formChat } = this.props;
        const currentChatUser = formChat.users.find((u) => u.id === user.id);

        const newChatUser = new ChatUser();
        newChatUser.user = user;
        return currentChatUser ? currentChatUser : newChatUser;
    };

    render() {
        const { t, onChange, formChat, isFetching, searchProfiles, uploadFile } = this.props;
        const { avatar, avatarPicker } = this.state;
        const { Option } = components;

        if (isFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        const SelectOption = (props) => (
            <Option {...props} className="select-option">
                <img src={props.data.avatar} alt={props.data.label} />
                <span>{props.data.label}</span>
            </Option>
        );
        const SelectValue = (props) => (
            <div className="select-value">
                <img src={props.data.avatar} alt={props.data.label} />
                {props.data.label}
            </div>
        );

        if (formChat) {
            return (
                <div className="chat-main">
                    <AvatarPicker
                        {...this.props}
                        title={t('form.uploadLogo')}
                        active={avatarPicker}
                        handleSubmit={(croppedImage) => {
                            this.setState({
                                avatar: croppedImage,
                                avatarPicker: !avatarPicker,
                            });
                            uploadFile(base64ToBlob(croppedImage), 'chat').then((uploadedAvatar) => {
                                const newChat = { ...formChat };
                                newChat.title = uploadedAvatar.file.id;
                                onChange('formChat', newChat);
                            });
                        }}
                        onClick={() => this.setState({ avatarPicker: !avatarPicker })}
                        onClose={() => this.setState({ avatarPicker: !avatarPicker })}
                    />
                    <div className="create">
                        <h1>{t('chat.new')}</h1>
                        <form className="no-padding flex-container">
                            {formChat.users.length > 1 && (
                                <div
                                    className="center"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            avatarPicker: !avatarPicker,
                                        });
                                    }}
                                >
                                    <div className="input-group">
                                        <img className="profile-image center small" src={avatar} alt="Chat avatar" />
                                        <div>{t('form.label.choosePicture')}</div>
                                    </div>
                                </div>
                            )}
                            <div className="one">
                                {formChat.users.length > 1 && (
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            value={formChat.title}
                                            onChange={(event) => {
                                                const newChat = { ...formChat };
                                                newChat.title = event.target.value;
                                                onChange('formChat', newChat);
                                            }}
                                        />
                                        <label>{t('form.label.groupName')}</label>
                                    </div>
                                )}
                                <div className="input-group">
                                    <AsyncSelect
                                        isMulti={true}
                                        components={{
                                            Option: SelectOption,
                                            SingleValue: SelectValue,
                                        }}
                                        noOptionsMessage={() => t('profiles.notFound')}
                                        placeholder={t('form.label.addPeople')}
                                        loadingMessage={() => t('loading')}
                                        loadOptions={(inputValue, callback) => {
                                            if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                            this.searchTimeout = setTimeout(() => {
                                                searchProfiles({ query: inputValue }, 0, 20).then((response) => {
                                                    callback(
                                                        response.profiles
                                                            .filter(
                                                                (profile) =>
                                                                    !formChat.users
                                                                        .map((u) => u.user.id)
                                                                        .includes(profile.user.id)
                                                            )
                                                            .map((profile) => {
                                                                return {
                                                                    value: profile.user,
                                                                    label: profile.name,
                                                                    avatar: profile.avatar
                                                                        ? profile.avatar.url
                                                                        : '/images/user.svg',
                                                                };
                                                            })
                                                    );
                                                });
                                            }, 800);
                                        }}
                                        onChange={(e) => {
                                            const newChat = { ...formChat };
                                            if (e && e.length > 0) {
                                                const formChatUsers = e.map((el) => {
                                                    const formChatUser = new ChatUser();
                                                    formChatUser.user = el.value;
                                                    return formChatUser;
                                                });
                                                newChat.users = formChatUsers;
                                            } else {
                                                newChat.users = [];
                                            }
                                            onChange('formChat', newChat);
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <CreateMessageForm
                        createMessage={() => this.handleSubmit()}
                        {...this.props}
                        hasValidCreationFields={() => {
                            if (formChat.users.length === 1) return true;
                            if (formChat.users.length > 1 && formChat.title !== '') return true;
                            return false;
                        }}
                    />
                </div>
            );
        }
        return null;
    }
}
