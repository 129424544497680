import React, { Component } from 'react';

export default class FilePreview extends Component {
    render() {
        const { file } = this.props;
        var src = file.url;
        switch (file.type) {
            case 'pdf':
                src = '/files/pdf.jpg';
                break;
            case 'word':
                src = '/files/word.jpg';
                break;
            default:
                src = file.url;
        }

        return <img src={src} alt={file.originalName || ''} />;
    }
}
