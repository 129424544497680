import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as platformActions from "../../../state/actions/platformActions";

import ContactForm from "../../../components/pages/crud/ContactForm";

class ContactFormContainer extends Component {
    render() {
        const { ...props } = this.props;

        return <ContactForm {...props} />;
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
        isFetchingContact: state.platform.isFetchingContact,
        contactMessage: state.platform.contactMessage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        contact: (email, subject, message, captcha) =>
            dispatch(platformActions.contact(email, subject, message, captcha)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(ContactFormContainer));
