import React, { Component } from 'react';

import Loader from '../../ui/components/Loader';
import AsideView from '../../ui/components/AsideView';

import User from '../../../models/user/User';
import UserForm from './UserForm';

export default class CreateUserPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange('formUser', new User());
    }

    render() {
        const { t, formUser, isFetchingUser, createUser, inviteUser } = this.props;

        if (!formUser || isFetchingUser) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t('user.new')}</h1>
                <UserForm
                    {...this.props}
                    key={formUser.id}
                    onSubmit={(user) => createUser(user)}
                    onInvite={(user) => inviteUser(user)}
                />
            </AsideView>
        );
    }
}
