import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class About extends Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <section className="home" id="home">
                    <div className="container">
                        <div className="statement">
                            <div className="flex-container halves">
                                <div className="half illustration">
                                    <img
                                        className="home_top_img"
                                        src="/images/vynworktop.png"
                                        alt="Vynwork illustration"
                                    />
                                </div>
                                <div className="half self-center">
                                    <h2 className="top">{t('about.top.header.top')}</h2>
                                    <h1>{t('about.top.header')}</h1>
                                    <h4>{t('about.top.body')}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="document">
                        <h2 className="top">{t('about.team.header')}</h2>
                        <h3>{t('about.team.body')}</h3>
                        <div className="team">
                            <div className="team__member">
                                <img src="https://transportial.com/app/uploads/2021/05/Thomas-1.jpg" alt="Thomas Kolmans"/>
                                <div className="team__member__name">Thomas Kolmans</div>
                                <div className="team__member__role">Founder</div>
                            </div>
                            <div className="team__member">
                                <img src="https://transportial.com/app/uploads/2021/05/Don-De-Jon-2.jpg" alt="Don de Jong" />
                                <div className="team__member__name">Don de Jong</div>
                                <div className="team__member__role">Co-founder</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default withTranslation('translation')(About);
