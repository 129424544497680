import React, { Component } from 'react';
import ReactSVG from 'react-svg';

export default class OptionsMenu extends Component {
    state = {
        optionsMenuOpen: false,
    };

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    closeOptionsMenusOnClick = (e) => {
        const target = e.target;
        const { optionsMenuOpen } = this.state;
        if (optionsMenuOpen) {
            if (!this.hasParentNode(target, 'options-menu')) {
                this.setState({
                    optionsMenuOpen: false,
                });
            }
        }
    };

    componentDidMount() {
        document.body.addEventListener('click', this.closeOptionsMenusOnClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.closeOptionsMenusOnClick);
    }

    render() {
        const { items, className } = this.props;
        const { optionsMenuOpen } = this.state;
        return (
            <div className={`options-menu${optionsMenuOpen ? ' options-menu--open' : ''} ${className}`}>
                <ReactSVG
                    src="/icons/dots.svg"
                    className="options-menu__dots"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({ optionsMenuOpen: !optionsMenuOpen });
                    }}
                />
                <div className="options-menu__options">
                    {items && items.length > 0
                        ? items.map((item) => (
                              <div
                                  className="options-menu__options__item"
                                  onClick={item.onClick}
                                  key={`options-menu__option-${item.name}`}
                              >
                                  {item.name}
                              </div>
                          ))
                        : null}
                </div>
            </div>
        );
    }
}
