import api from "../api";
import moment from "moment";

export const get = (id = "") => {
    return api.get(`/task/${id}`);
};

export const create = (task) => {
    return api.post(`/task`, task);
};

export const update = (task) => {
    return api.put(`/task`, task);
};

export const personalLatest = (from = 0, amount = 20) => {
    return api.get(`/tasks/personal/${from}/${amount}`);
};

export const personalLatestByDate = (fromDate, from = 0, amount = 20) => {
    return api.get(`/tasks/personal/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${from}/${amount}`);
};

export const latest = (from = 0, amount = 20) => {
    return api.get(`/tasks/${from}/${amount}`);
};

// Task Application CRUD
export const createApplication = (application) => {
    return api.post(`/task/application`, application);
};

export const updateApplication = (application) => {
    return api.put(`/task/application`, application);
};

export const cancelApplication = (application) => {
    return api.post(`/task/application/cancel`, application);
};

export const acceptApplication = (application) => {
    return api.post(`/task/application/accept`, application);
};

export const denyApplication = (application) => {
    return api.post(`/task/application/deny`, application);
};

/**
 * Shifts
 */
export const getShift = (id = "") => {
    return api.get(`/task/shift/${id}`);
};
export const createShift = (task, shift) => {
    return api.post(`/task/shift`, { task, shift });
};

export const updateShift = (shift) => {
    return api.put(`/task/shift`, shift);
};
export const latestShifts = (from = 0, amount = 20) => {
    return api.get(`/tasks/shifts/${from}/${amount}`);
};

export const getPersonalShifts = (from = 0, amount = 20) => {
    return api.get(`/tasks/shifts/personal/${from}/${amount}`);
};

export const getPersonalShiftsByDate = (fromDate, toDate, from = 0, amount = 20) => {
    return api.get(
        `/tasks/shifts/personal/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${
            toDate ? toDate.format("YYYY-MM-DD") : null
        }/${from}/${amount}`
    );
};
export const getPersonalShiftsByDateAndStatus = (fromDate, toDate, status, from = 0, amount = 20) => {
    return api.get(
        `/tasks/shifts/personal/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${
            toDate ? toDate.format("YYYY-MM-DD") : null
        }/${status}/${from}/${amount}`
    );
};

export const getShiftTasksByDate = (fromDate = moment(), toDate = moment(), from = 0, amount = 20) => {
    return api.get(
        `/tasks/shifts/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${
            toDate ? toDate.format("YYYY-MM-DD") : null
        }/${from}/${amount}`
    );
};

export const search = (searchParameters = {}, from = 0, amount = 20) => {
    return api.post(`/tasks/search/${from}/${amount}`, searchParameters);
};

export const searchShifts = (searchParameters = {}, fromDate, from = 0, amount = 20) => {
    return api.post(
        `/tasks/shifts/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${from}/${amount}`,
        searchParameters
    );
};

export const checkIn = (check) => {
    return api.post(`/task/checkIn`, check);
};

export const checkOut = (check) => {
    return api.post(`/task/checkOut`, check);
};

export const approveCheck = (check) => {
    return api.post(`/task/check/approve`, check);
};

export const createCheckDispute = (check) => {
    return api.post(`/task/check/dispute`, check);
};

export const checksLatest = (from = 0, amount = 20) => {
    return api.get(`/task/checks/${from}/${amount}`);
};

export const checksLatestByDate = (fromDate, toDate, from = 0, amount = 20) => {
    return api.get(
        `/task/checks/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${
            toDate ? toDate.format("YYYY-MM-DD") : null
        }/${from}/${amount}`
    );
};

export const shiftChecksLatest = (shift, from = 0, amount = 20) => {
    return api.get(`/task/shift/${shift.id}/checks/${from}/${amount}`);
};

export const shiftChecksLatestByDate = (shift, fromDate, toDate, from = 0, amount = 20) => {
    return api.get(
        `/task/shift/${shift.id}/checks/${fromDate ? fromDate.format("YYYY-MM-DD") : null}/${
            toDate ? toDate.format("YYYY-MM-DD") : null
        }/${from}/${amount}`
    );
};

export const checkInLatest = (shift, from = 0, amount = 20) => {
    return api.get(`/task/shift/${shift.id}/checkIns/${from}/${amount}`);
};

export const checkOutLatest = (shift, from = 0, amount = 20) => {
    return api.get(`/task/shift/${shift.id}/checkOuts/${from}/${amount}`);
};
