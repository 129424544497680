import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

class Home extends Component {
    state = {
        activeTab: 'employee',
    };

    componentDidMount() {
        const top = document.documentElement.scrollTop || document.body.scrollTop;
        const nav = document.querySelector('nav');

        document.addEventListener('scroll', (e) => {
            const top = document.documentElement.scrollTop || document.body.scrollTop;
            top > 0 ? nav.classList.add('top') : nav.classList.remove('top');
        });

        top > 0 ? nav.classList.add('top') : nav.classList.remove('top');
    }
    down() {
        const topOffset = document.getElementById('home').offsetHeight - 65;
        window.scrollTo({ top: topOffset, left: 0, behavior: 'smooth' });
    }

    render() {
        const { t, platform } = this.props;
        let beta = parseFloat(process.env.REACT_APP_VERSION) >= 1 || process.env.MODE === 'development' ? '' : 'beta';

        return (
            <div>
                <nav className={beta + ' welcome'}>
                    <div className="container">
                        <Link to="/">
                            <div className="logo">
                                <img src="images/Vynwork_letters.png" alt="Vynwork logo" />
                            </div>
                            <div className="logo small">
                                <img src="images/Vynwork.svg" alt="Vynwork logo" />
                            </div>
                        </Link>
                        <div className="listbutton" id="listbutton"></div>
                        {platform.canRegister ? (
                            <Link to="/register">
                                <button>{t('header.register')}</button>
                            </Link>
                        ) : (
                            <Link to="/contact">
                                <button>{t('header.contact')}</button>
                            </Link>
                        )}

                        <Link to="/login" className="login">
                            {t('header.login')}
                        </Link>
                    </div>
                </nav>
                <section className="home" id="home">
                    <div className="container">
                        <div className="statement">
                            <div className="flex-container halves">
                                <div className="half illustration">
                                    <img
                                        className="home_top_img"
                                        src="/images/vynworktop.png"
                                        alt="Vynwork illustration"
                                    />
                                </div>
                                <div className="half self-center">
                                    <h2 className="top">{t('home.header.hello')}</h2>
                                    <h1>{t('home.header.header')}</h1>
                                    <h4>{t('home.header.body')}</h4>
                                    {platform.canRegister ? (
                                        <Link to="/register">
                                            <button>{t('home.startNow')}</button>
                                        </Link>
                                    ) : (
                                        <Link to="/contact">
                                            <button>{t('home.getInTouch')}</button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="down" id="down" onClick={() => this.down()}></div>
                    </div>
                </section>
                <section className="whatservices">
                    <div className="container">
                        <div className="flex-container">
                            <div className="two">
                                <h2 className="top">{t('home.what.top')}</h2>
                                <h1>{t('home.what.header')}</h1>
                                <h3>{t('home.what.body')}</h3>
                            </div>
                            <div className="one"></div>
                        </div>
                        <div className="part hidden-small"></div>
                        <div className="flex-container">
                            <div className="half">
                                <ReactSVG className="icon" src="/images/home/tasks.svg" />
                                <h2>{t('home.what.task.header')}</h2>
                                <p>{t('home.what.task.body')}</p>
                            </div>
                            <div className="half">
                                <ReactSVG className="icon" src="/images/home/connected.svg" />
                                <h2>{t('home.what.network.header')}</h2>
                                <p>{t('home.what.network.body')}</p>
                            </div>
                            <div className="half">
                                <ReactSVG className="icon" src="/images/home/chat.svg" />
                                <h2>{t('home.what.communication.header')}</h2>
                                <p>{t('home.what.communication.body')}</p>
                            </div>
                            <div className="half">
                                <ReactSVG className="icon" src="/images/home/payments.svg" />
                                <h2>{t('home.what.payment.header')}</h2>
                                <p>{t('home.what.payment.body')}</p>
                            </div>
                        </div>
                        <div className="ready">
                            <h1>{t('home.what.start.header')}</h1>
                            <h3>{t('home.what.start.body')}</h3>
                            {platform.canRegister ? (
                                <Link to="/register">
                                    <button>{t('home.startNow')}</button>
                                </Link>
                            ) : (
                                <Link to="/contact">
                                    <button>{t('home.getInTouch')}</button>
                                </Link>
                            )}
                        </div>
                    </div>
                    <img className="background-image" src="/images/world.svg" alt="vynwork working for each other" />
                </section>
                <section className="why">
                    <div className="container">
                        <h2 className="top">{t('home.why.top')}</h2>
                        <div className="flex-container">
                            <div className="one">
                                <div className="why__item">
                                    <h2>{t('home.why.flexible.header')}</h2>
                                    <p>{t('home.why.flexible.body')}</p>
                                </div>
                                <div className="why__item">
                                    <h2>{t('home.why.transparent.header')}</h2>
                                    <p>{t('home.why.transparent.body')}</p>
                                </div>
                                <div className="why__item">
                                    <h2>{t('home.why.certainty.header')}</h2>
                                    <p>{t('home.why.certainty.body')}</p>
                                </div>
                                <div className="why__item">
                                    <h2>{t('home.why.cheaper.header')}</h2>
                                    <p>{t('home.why.cheaper.body')}</p>
                                </div>
                            </div>
                            <div className="one">
                                <img src="/images/platform-screenshot.png" alt="platform-example" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="how">
                    <div className="container">
                        <h2 className="top">{t('home.how.top')}</h2>
                        <div className="flex-container">
                            <div className="half two no-top-padding">
                                <h1>{t('home.how.steps.header')}</h1>
                                <h3>{t('home.how.steps.body')}</h3>
                            </div>
                            <div className="half no-top-padding small-hidden foot-steps">
                                <ReactSVG
                                    className="full hidden-small"
                                    alt="Vynwork footprints"
                                    src="/images/home/footprints.svg"
                                />
                            </div>
                        </div>
                        <div className="tabs white">
                            <ul>
                                <li
                                    className={this.state.activeTab === 'employee' ? 'active' : ''}
                                    onClick={(e) => {
                                        this.setState({
                                            activeTab: 'employee',
                                        });
                                    }}
                                >
                                    {t('home.how.tabs.employee')}
                                </li>
                                <li
                                    className={this.state.activeTab === 'employer' ? 'active' : ''}
                                    onClick={(e) => {
                                        this.setState({
                                            activeTab: 'employer',
                                        });
                                    }}
                                >
                                    {t('home.how.tabs.employer')}
                                </li>
                            </ul>
                        </div>
                        <section className={this.state.activeTab === 'employer' ? 'activity active' : 'activity'}>
                            <div className="steps">
                                <div className="step">
                                    <div className="counter">1</div>
                                    <h1>{t('home.how.employer.step1.header')}</h1>
                                    <div className="text">{t('home.how.employer.step1.body')}</div>
                                </div>
                                <div className="step">
                                    <div className="counter">2</div>
                                    <h1>{t('home.how.employer.step2.header')}</h1>
                                    <div className="text">{t('home.how.employer.step2.body')}</div>
                                </div>
                                <div className="step">
                                    <div className="counter">3</div>
                                    <h1>{t('home.how.employer.step3.header')}</h1>
                                    <div className="text">{t('home.how.employer.step3.body')}</div>
                                </div>
                            </div>
                        </section>
                        <section className={this.state.activeTab === 'employee' ? 'activity active' : 'activity'}>
                            <div className="steps">
                                <div className="step">
                                    <div className="counter">1</div>
                                    <h1>{t('home.how.employee.step1.header')}</h1>
                                    <div className="text">{t('home.how.employee.step1.body')}</div>
                                </div>
                                <div className="step">
                                    <div className="counter">2</div>
                                    <h1>{t('home.how.employee.step2.header')}</h1>
                                    <div className="text">{t('home.how.employee.step2.body')}</div>
                                </div>
                                <div className="step">
                                    <div className="counter">3</div>
                                    <h1>{t('home.how.employee.step3.header')}</h1>
                                    <div className="text">{t('home.how.employee.step3.body')}</div>
                                </div>
                            </div>
                        </section>

                        <div className="align-right">
                            <div className="flex-container">
                                <div className="half small-hidden"></div>
                                <div className="half two">
                                    <h1>{t('home.how.question.header')}</h1>
                                    <h3>
                                        <Trans
                                            t={t}
                                            i18nKey={'home.how.question.body'}
                                            components={[<Link key={0} to="/contact">contact</Link>]}
                                        />
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="ready">
                            <h1>{t('home.how.ready.header')}</h1>
                            <h3>{t('home.how.ready.body')}</h3>
                            {platform.canRegister ? (
                                <Link to="/register">
                                    <button>{t('home.startNow')}</button>
                                </Link>
                            ) : (
                                <Link to="/contact">
                                    <button>{t('home.getInTouch')}</button>
                                </Link>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation('translation')(Home);
