import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as socketActions from './state/actions/socketActions';
import { socketConstants } from './state/constants';
import { messaging } from './utils/init-fcm';
import * as userApi from './api/userApi';

class WebSocketWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasInitiatedConnection: false,
        };

        this.webSocket = null;

        const { newChatMessage, registerSocket } = this.props;

        try {
            this.webSocket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
            this.webSocket.onmessage = (message) => {
                console.log('new message: ', message);
                const json = JSON.parse(message.data);
                console.log(json);
                switch (json.type) {
                    case socketConstants.REGISTERED_SOCKET:
                        registerSocket(json);
                        break;
                    case socketConstants.NEW_CHAT_MESSAGE:
                        newChatMessage(json);
                        break;
                    default:
                        break;
                }
            };

            this.webSocket.onopen = () => this.initiateSession();
            this.webSocket.onclose = () => {};
            this.webSocket.onerror = (error) => {};
        } catch (e) {}

        setInterval(() => {
            this.keepAlive();
        }, 10000);
    }

    initiateSession = () => {
        const { bearer, user } = this.props;
        const { hasInitiatedConnection } = this.state;

        if (user && this.webSocket && !hasInitiatedConnection) {
            if (this.webSocket.readyState !== this.webSocket.OPEN) return;
            this.webSocket.send(
                JSON.stringify({
                    action: 'initiateConnection',
                    authorization: bearer,
                    user: user.id,
                })
            );
            this.setState({ hasInitiatedConnection: true });
        }
    };

    keepAlive = () => {
        const { bearer, socketId } = this.props;
        if (this.webSocket) {
            if (this.webSocket.readyState !== this.webSocket.OPEN) return;
            const data = JSON.stringify({
                action: 'keepAlive',
                socketId: socketId,
                authorization: bearer,
                location: window.location,
                focused: window.document.hasFocus(),
            });

            this.webSocket.send(data);
        }
    };

    closeSession = () => {
        const { bearer, socketId } = this.props;
        if (this.webSocket) {
            if (this.webSocket.readyState !== this.webSocket.OPEN) return;
            this.webSocket.send(
                JSON.stringify({
                    action: 'closeConnection',
                    authorization: bearer,
                    socketId: socketId,
                })
            );
            this.webSocket.close();
            this.setState({ hasInitiatedConnection: false });
        }
    };

    componentDidMount() {
        if (messaging) {
            messaging
                .requestPermission()
                .then(async (e) => {
                    const token = await messaging.getToken();
                    userApi.fcm(token).then((response) => console.log(response));
                })
                .catch(async (err) => {
                    console.log('Unable to get permission to notify.', err);
                });
            navigator.serviceWorker.addEventListener('message', (message) => console.log(message));
        }
    }

    componentDidUpdate() {
        const { hasInitiatedConnection } = this.state;
        if (!hasInitiatedConnection) this.initiateSession();
    }

    componentWillUnmount() {
        this.closeSession();
    }

    render() {
        const { Page } = this.props;
        return <Page {...this.props} webSocket={this.webSocket} />;
    }
}

function mapStateToProps(state) {
    return {
        bearer: state.auth.bearer,
        user: state.auth.user,
        socketId: state.auth.socketId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        newChatMessage: (message) => dispatch(socketActions.newChatMessage(message)),
        registerSocket: (message) => dispatch(socketActions.registerSocket(message)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketWrapper);
