import { categoryConstants } from "../constants";

const initialState = {
    isFetchingCategory: false,
    category: null,

    isFetchingCategories: false,
    categories: [],
    categoriesLoaded: 0,
    categoriesTotalResults: 0,
};

export default function category(state = initialState, action) {
    switch (action.type) {
        case categoryConstants.CATEGORY_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case categoryConstants.GET_CATEGORY_STARTED:
            return { ...state, isFetchingCategory: true, error: null };
        case categoryConstants.GET_CATEGORY_FAILURE:
            return { ...state, isFetchingCategory: false, error: "Could not find category" };
        case categoryConstants.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchingCategory: false,
                category: action.category,
            };

        case categoryConstants.GET_CATEGORIES_STARTED:
            return { ...state, isFetchingCategories: true };
        case categoryConstants.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetchingCategories: false,
                categories: [],
                message: action.message || "",
            };
        case categoryConstants.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                isFetchingCategories: false,
                categories: action.categories,
                categoriesLoaded: action.categories.length,
                categoriesTotalResults: action.totalResults,
            };

        case categoryConstants.CREATE_CATEGORY_STARTED:
            return { ...state, isFetchingCategory: true };
        case categoryConstants.CREATE_CATEGORY_FAILURE:
            return { ...state, isFetchingCategory: false, message: action.message || "" };
        case categoryConstants.CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchingCategory: false,
                category: action.category,
                categories: [...state.categories, action.category],
            };

        case categoryConstants.UPDATE_CATEGORY_STARTED:
            return { ...state, isFetchingCategory: true };
        case categoryConstants.UPDATE_CATEGORY_FAILURE:
            return { ...state, isFetchingCategory: false, message: action.message || "" };
        case categoryConstants.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchingCategory: false,
                category: action.category,
                categories: [...state.categories].map((category) =>
                    action.category.id === category.id ? action.category : category
                ),
            };
        case categoryConstants.DELETE_CATEGORY_STARTED:
            return { ...state, isFetchingCategory: true };
        case categoryConstants.DELETE_CATEGORY_FAILURE:
            return { ...state, isFetchingCategory: false, message: action.message || "" };
        case categoryConstants.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetchingCategory: false,
                category: action.category,
                categories: [...state.categories].filter((category) => action.category.id !== category.id),
            };

        default:
            return state;
    }
}
