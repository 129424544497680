import React, { Component } from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';

import { intToDouble } from '../../utils/moneyUtils';
import { enumerateDaysBetweenDates } from '../../utils/dateUtils';

import TransactionsContainer from '../../containers/wallet/transaction/TransactionsContainer';
import InvoicesContainer from '../../containers/wallet/invoice/InvoicesContainer';
import PayoutsContainer from '../../containers/wallet/payout/PayoutsContainer';
import CreatePayoutContainer from '../../containers/wallet/payout/crud/CreatePayoutContainer';
import AddCreditContainer from '../../containers/wallet/crud/AddCreditContainer';

import Loader from '../ui/components/Loader';

export default class WalletPage extends Component {
    constructor(props) {
        super(props);

        const startDate = moment().subtract(14, 'd'),
            endDate = moment();
        const dates = enumerateDaysBetweenDates(startDate, endDate);
        const dateLabels = dates.map((date) => date.format('DD-MM'));
        const { t, platform } = this.props;

        this.state = {
            tab: 'transactions',
            startDate: startDate,
            endDate: endDate,
            series: [
                {
                    name: t('transactions'),
                    data: dates.map((d) => 0),
                },
            ],
            options: {
                chart: {
                    height: 300,
                    type: 'area',
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    type: 'string',
                    categories: dateLabels,
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [platform.primaryColor],
                stroke: {
                    curve: 'smooth',
                    width: 3,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                    },
                },
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.85,
                        opacityTo: 0,
                    },
                },
            },
        };
    }

    componentDidMount() {
        const { t, user, getUserWallet, getTransactionsByDate } = this.props;
        const { startDate, endDate, options } = this.state;

        const { match } = this.props;
        if (match.params.tab) {
            this.setState({
                tab: match.params.tab,
            });
        }

        getUserWallet(user.id).then((response) => {
            const wallet = response.wallet;
            getTransactionsByDate(startDate, endDate).then((response) => {
                const dates = enumerateDaysBetweenDates(startDate, endDate);
                const transactionsByDate = response.transactions;
                const newOptions = { ...options };

                newOptions.yaxis = {
                    labels: {
                        formatter: (value) => {
                            return `${wallet.balance.currency.sign}${intToDouble(
                                value,
                                2,
                                user.language.decimalDelimiter,
                                user.language.thousandDelimiter
                            )}`;
                        },
                    },
                };

                this.setState({
                    series: [
                        {
                            name: t('wallet.transaction.amount'),
                            data: dates.map((date) => {
                                return transactionsByDate
                                    .filter((transaction) => moment(transaction[1]).isSameOrBefore(date, 'days'))
                                    .map((transaction) => (transaction ? transaction[0] : 0))
                                    .reduce((a, b) => a + b, 0);
                            }),
                        },
                    ],
                    options: newOptions,
                });
            });
        });
    }

    render() {
        const { t, platform, addToStack, wallet, isFetchingTransactionsByDate, isFetching, user } = this.props;
        const { tab, options, series } = this.state;

        if (!isFetching && wallet) {
            const { balance } = wallet;
            const { amount, currency } = balance;

            return (
                <div className="wrapper top">
                    <div className="container wallet-view">
                        <div className="wrapper normal top">
                            <div className="flex-container">
                                <div className="half balance">
                                    {currency.sign}
                                    {wallet !== null
                                        ? intToDouble(
                                              amount,
                                              2,
                                              user.language.decimalDelimiter,
                                              user.language.thousandDelimiter
                                          )
                                        : intToDouble(
                                              0,
                                              2,
                                              user.language.decimalDelimiter,
                                              user.language.thousandDelimiter
                                          )}
                                    <span> {currency.name}</span>
                                </div>
                                <div className="half center">
                                    <div className="button-menu">
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addToStack({
                                                    name: t('wallet.addCredit.header'),
                                                    component: <AddCreditContainer />,
                                                });
                                            }}
                                        >
                                            <button>{t('wallet.addCredit')}</button>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addToStack({
                                                    name: t('wallet.payout.header'),
                                                    component: <CreatePayoutContainer />,
                                                });
                                            }}
                                        >
                                            <button>{t('wallet.withdrawMoney')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="graph">
                                {isFetchingTransactionsByDate ? (
                                    <div className="absolute-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <Chart options={options} series={series} type="area" height="250" />
                                )}
                            </div>
                            <div className="tabs full-white">
                                <ul>
                                    <li
                                        className={'tab' + (tab === 'transactions' ? ' active' : '')}
                                        onClick={() => {
                                            this.setState({ tab: 'transactions' });
                                            window.history.replaceState({}, platform.name, '/wallet/transactions');
                                        }}
                                    >
                                        {t('wallet.transactions.header')}
                                    </li>
                                    <li
                                        className={'tab' + (tab === 'payouts' ? ' active' : '')}
                                        onClick={() => {
                                            this.setState({ tab: 'payouts' });
                                            window.history.replaceState({}, platform.name, '/wallet/payouts');
                                        }}
                                    >
                                        {t('wallet.payouts.header')}
                                    </li>
                                    <li
                                        className={'tab' + (tab === 'invoices' ? ' active' : '')}
                                        onClick={() => {
                                            this.setState({ tab: 'invoices' });
                                            window.history.replaceState({}, platform.name, '/wallet/invoices');
                                        }}
                                    >
                                        {t('wallet.invoices.header')}
                                    </li>
                                </ul>
                            </div>
                            <section className={'activity ' + (tab === 'transactions' ? 'active' : '')}>
                                {tab === 'transactions' ? <TransactionsContainer /> : null}
                            </section>
                            <section className={'activity ' + (tab === 'payouts' ? 'active' : '')}>
                                {tab === 'payouts' ? <PayoutsContainer /> : null}
                            </section>
                            <section className={'activity ' + (tab === 'invoices' ? 'active' : '')}>
                                {tab === 'invoices' ? <InvoicesContainer /> : null}
                            </section>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="absolute-center">
                <Loader />
            </div>
        );
    }
}
