import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import Business from "../../../models/business/Business";
import BusinessForm from "./BusinessForm";

export default class CreateBusinessPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange("formBusiness", new Business());
    }

    render() {
        const { t, formBusiness, businessIsFetching, createBusiness, popStack } = this.props;

        if (!formBusiness || businessIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("business.new")}</h1>
                <BusinessForm
                    {...this.props}
                    onSubmit={(business) =>
                        createBusiness(business).then(() => {
                            popStack();
                        })
                    }
                />
            </AsideView>
        );
    }
}
