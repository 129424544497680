export const userConstants = {
    USER_CHANGE_VALUE: "USER_CHANGE_VALUE",

    GET_USER_STARTED: "GET_USER_STARTED",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILURE: "GET_USER_FAILURE",

    GET_USERS_STARTED: "GET_USERS_STARTED",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "GET_USERS_FAILURE",

    SEARCH_USERS_STARTED: "SEARCH_USERS_STARTED",
    SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
    SEARCH_USERS_FAILURE: "SEARCH_USERS_FAILURE",

    CREATING_USER_STARTED: "CREATING_USER_STARTED",
    CREATING_USER_SUCCESS: "CREATING_USER_SUCCESS",
    CREATING_USER_FAILURE: "CREATING_USER_FAILURE",

    UPDATING_USER_STARTED: "UPDATING_USER_STARTED",
    UPDATING_USER_SUCCESS: "UPDATING_USER_SUCCESS",
    UPDATING_USER_FAILURE: "UPDATING_USER_FAILURE",

    INVITING_USER_STARTED: "INVITING_USER_STARTED",
    INVITING_USER_SUCCESS: "INVITING_USER_SUCCESS",
    INVITING_USER_FAILURE: "INVITING_USER_FAILURE",

    DELETE_USER_STARTED: "DELETE_USER_STARTED",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
};
