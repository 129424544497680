import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'moment/locale/nl';

import nl from './resources/languages/nl';
import en from './resources/languages/en';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: { nl, en },
        lng: 'nl',

        keySeparator: false,

        interpolation: {
            escapeValue: false,
        },
    });
i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
})
export default i18n;
