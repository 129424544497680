import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthenticationContainer from "./AuthenticationContainer";
import SimpleReactValidator from "simple-react-validator";

export default class ForgotPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.onForgot();
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        const { t, onChange, email, error } = this.props;

        return (
            <AuthenticationContainer {...this.props}>
                <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <h2 className="top">{t("forgot.header")}</h2>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={this.email}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange("email", value, event);
                                }}
                            />
                            <label>{t("form.label.email")}</label>
                        </div>
                        {this.validator.message(t("form.label.email"), email, "required|email")}
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{error}</div>
                        <input type="submit" value={t("form.request")} onClick={(e) => e.stopPropagation()}/>
                    </div>
                </form>
                <div className="or">{t("form.or")}</div>
                <div className="login">
                    <Link to="/login">
                        <button className="full">{t("forgot.backToLogin")}</button>
                    </Link>
                </div>
            </AuthenticationContainer>
        );
    }
}
