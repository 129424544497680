import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import Platform from "../../../models/platform/Platform";
import PlatformForm from "./PlatformForm";

export default class CreatePlatformPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange("formPlatform", new Platform());
    }

    render() {
        const { t, formPlatform, isFetchingFormPlatform, createPlatform } = this.props;

        if (!formPlatform || isFetchingFormPlatform) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("platform.new")}</h1>
                <PlatformForm {...this.props} onSubmit={(platform) => createPlatform(platform)} />
            </AsideView>
        );
    }
}
