
export default class TaskApplication {
    
    id;
    nonce; 

    message;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }

}