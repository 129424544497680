export default class TaskKeyword {
    
    id;
    nonce; 

    header;
    tags;

    constructor(header = '') {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.header = header;
        this.tags = [];
    }

}