import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Loader from '../../ui/components/Loader';
import NoData from '../../ui/components/NoData';

import PayoutView from './PayoutView';

import { intToDouble } from '../../../utils/moneyUtils';

class PayoutsView extends Component {
    handleScroll = () => {
        const { payoutsLoaded, payoutsTotalResults, isFetchingPayouts } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (!isFetchingPayouts && payoutsTotalResults > payoutsLoaded) {
                this.fetch(payoutsLoaded, 20);
            }
        }
    };

    fetch = (offset, amount) => {
        const { getPayouts } = this.props;
        getPayouts(offset, amount);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        window.removeEventListener('scroll', this.handleScroll);

        this.fetch(0, 20);
    }
    render() {
        const { t, isFetchingPayouts, payouts, user, addToStack } = this.props;

        if (isFetchingPayouts) {
            return (
                <div className="payouts">
                    <Loader />
                </div>
            );
        }

        if (payouts.length > 0) {
            return (
                <div className="payouts">
                    {payouts.map((payout) => (
                        <div
                            className="payout"
                            key={`payout-${payout.id}`}
                            onClick={(e) => {
                                e.preventDefault();
                                addToStack({
                                    name: payout.name,
                                    component: <PayoutView payout={payout} user={user} />,
                                });
                            }}
                        >
                            <div className="one amount">
                                {payout.amount.currency.sign || '€'}
                                {intToDouble(
                                    payout.amount.amount,
                                    2,
                                    user.language.decimalDelimiter,
                                    user.language.thousandDelimiter
                                )}
                            </div>
                            <div className="one date">{moment(payout.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                            <div className="one id">{payout.id}</div>
                        </div>
                    ))}
                </div>
            );
        } else
            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('wallet.payouts.notFound')}</NoData>
                </div>
            );
    }
}
export default withTranslation('translation')(PayoutsView);
