import React, { Component } from 'react';

import Loader from '../ui/components/Loader';
import { activateInputs } from '../../utils/formUtils';

export default class AuthenticationContainer extends Component {
    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const { isFetching, children } = this.props;

        if (isFetching) {
            return (
                <div className="container">
                    <div className="wrapper authentication">
                        <div className="container small-box" style={{ padding: '100px 0px 80px' }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="container">
                <div className="wrapper authentication">
                    <div className="container small-box">{children}</div>
                </div>
            </div>
        );
    }
}
