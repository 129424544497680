import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import Dropzone from 'react-dropzone';

// Emote selector
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import * as fileApi from '../../../api/fileApi';

import Message from '../../../models/chat/Message';
import ChatUser from '../../../models/chat/ChatUser';
import ContentEditable from '../../general/ContentEditable';

import { activateInputs, pasteIntoInput } from '../../../utils/formUtils';
import { base64ToBlob, getBase64 } from '../../../utils/imageUtils';
import Loader from '../../ui/components/Loader';
import { formatBytes } from '../../../utils/fileUtils';
import Progress from '../../ui/components/Progress';

export default class CreateMessageForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            emojiPicker: false,
        };

        this.messageRef = React.createRef();
        this.dropzoneRef = React.createRef();
    }

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    _handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            this.handleSubmit(e);
        } else {
            pasteIntoInput(e.target, '\n');
        }
    };

    handleSubmit(e) {
        e.preventDefault();
        const { createMessage, onChange, chat, message } = this.props;
        const { files } = this.state;

        const createdMessage = { ...message };
        createdMessage.files = [...files].map((f) => f.externalReference.id);

        const newMessage = new Message();
        newMessage.isOffline = true;
        newMessage.user = this.getCurrentChatUser(chat).id;

        createMessage && createMessage(chat.id, createdMessage);

        onChange('message', newMessage);
        this.setState({ files: [] });
    }

    getCurrentChatUser = (chat) => {
        const { user } = this.props;
        const currentChatUser = chat.users.find((chatUser) => chatUser.user.id === user.id);
        const newChatUser = new ChatUser();
        newChatUser.user = user;

        return currentChatUser ? currentChatUser : newChatUser;
    };

    uploadFile = (file) => {
        getBase64(file).then((base64) => {
            fileApi
                .uploadFile(base64ToBlob(base64), 'files', (e) => {
                    const files = [...this.state.files];
                    const prevFileIndex = files.findIndex((f) => f.id === file.id);
                    if (prevFileIndex >= 0) {
                        files[prevFileIndex].progress = (e.total / e.loaded) * 100;
                        files[prevFileIndex].uploading = true;
                        if (!files[prevFileIndex].base64) files[prevFileIndex].base64 = base64;
                        this.setState({
                            files,
                        });
                    }
                })
                .then((response) => {
                    const files = [...this.state.files];
                    const prevFileIndex = files.findIndex((f) => f.id === file.id);
                    if (prevFileIndex >= 0) {
                        if (response.success) {
                            files[prevFileIndex].externalReference = response.file;
                        } else {
                            files[prevFileIndex].error = response.message;
                        }
                        files[prevFileIndex].uploading = false;
                        files[prevFileIndex].uploaded = true;
                        this.setState({
                            files,
                        });
                    }
                });
        });
    };

    componentDidMount() {
        const { onChange } = this.props;
        activateInputs();

        const newMessage = new Message();
        newMessage.isOffline = true;
        newMessage.user = new ChatUser().id;

        onChange('message', newMessage);

        document.addEventListener('click', (e) => {
            const target = e.target;
            const { emojiPicker } = this.state;
            if (emojiPicker) {
                if (!this.hasParentNode(target, 'emojiPicker')) {
                    this.setState({ emojiPicker: false });
                }
            }
        });
    }

    render() {
        const { t, message, hasValidCreationFields, onChange } = this.props;
        const { files, emojiPicker } = this.state;
        if (message) {
            return (
                <div className="new-message">
                    <div className="upload-attachments">
                        {files.length > 0 && (
                            <div className="input-group uploadedFiles">
                                {files.map((file) => {
                                    return (
                                        <div className="file">
                                            <img src={file.base64 || file.url} alt={file.originalName} />
                                            <div className="info">
                                                <div className="name">
                                                    {file.error ? file.error : file.name}
                                                    <span>{formatBytes(file.size)}</span>
                                                </div>
                                                {file.progress >= 100 ? (
                                                    <div className="state">{t('attachments.uploaded')}</div>
                                                ) : (
                                                    <div className="state">
                                                        <span>{t('attachments.uploading')}</span>
                                                        <Progress progress={file.progress || 0} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="actions">
                                                {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                                                {file.uploaded && (
                                                    <i
                                                        className="fa fa-times behind"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            this.setState({
                                                                files: this.state.files.filter((f) => f.id !== file.id),
                                                            });
                                                        }}
                                                    ></i>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <form>
                        <Dropzone
                            ref={(node) => {
                                this.dropzoneRef = node;
                            }}
                            onDrop={(acceptedFiles) => {
                                const newFiles = acceptedFiles.map((file) => {
                                    file.id = Math.random().toString(36).substring(7);
                                    return file;
                                });
                                this.setState(() => ({ files: files.concat(newFiles) }));

                                newFiles.forEach((file) => {
                                    this.uploadFile(file);
                                });
                            }}
                            accept="image/*, pdf/*, doc/*"
                        >
                            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                console.log(hasValidCreationFields());
                                return (
                                    <div
                                        {...getRootProps({
                                            onClick: (event) => event.stopPropagation(),
                                        })}
                                    >
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <div
                                                className={`input-group drag-and-drop${
                                                    isDragActive ? ' dragging' : ''
                                                }${isDragReject ? ' rejected' : ''}`}
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <p>
                                                    {isDragActive
                                                        ? isDragReject
                                                            ? t('dragAndDrop.rejected')
                                                            : t('dragAndDrop.dragging')
                                                        : t('dragAndDrop.label')}
                                                </p>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="input-group no-margin-top">
                                                    <ContentEditable
                                                        ref={this.messageRef}
                                                        className="message-input scrollable"
                                                        placeholder={t('chats.message.placeholder')}
                                                        onKeyDown={(e) => this._handleKeyDown(e)}
                                                        onChange={(e) => {
                                                            const newMessage = { ...message };
                                                            newMessage.message = e;

                                                            onChange('message', newMessage);
                                                        }}
                                                        value={message.message}
                                                    />
                                                </div>
                                                <button
                                                    className="send"
                                                    disabled={
                                                        hasValidCreationFields() === false ||
                                                        !message.message ||
                                                        (message.message || '').trim() === ''
                                                    }
                                                    onClick={(e) => this.handleSubmit(e)}
                                                >
                                                    <i className="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        </Dropzone>
                    </form>
                    <div className="actions">
                        <div
                            className="action"
                            onClick={(e) => {
                                e.preventDefault();
                                this.dropzoneRef.open();
                            }}
                        >
                            <div className="icon">
                                <ReactSVG src="/icons/attach.svg" />
                            </div>
                        </div>
                        <div
                            className="action emojiPicker"
                            onClick={(e) => {
                                this.setState({
                                    emojiPicker: true,
                                });
                            }}
                        >
                            {emojiPicker && (
                                <Picker
                                    set="apple"
                                    onSelect={(e) => {
                                        const newMessage = { ...message };
                                        const text = (newMessage.message || '').replace(/&nbsp;/g, ' ');

                                        const before = text.substring(
                                            0,
                                            this.messageRef.current.state.caretPosition.start
                                        );
                                        const after = text.substring(
                                            this.messageRef.current.state.caretPosition.end,
                                            text.length
                                        );

                                        this.setState({
                                            emojiPicker: false,
                                        });

                                        newMessage.message = before + e.native + after;

                                        this.messageRef.current.newCaretPosition({
                                            carretPosition: {
                                                start: (this.messageRef.current.state.caretPosition.start + 1),
                                                end: this.messageRef.current.state.caretPosition.end,
                                            },
                                        });
                                        onChange('message', newMessage);
                                    }}
                                    title={t('form.label.pickEmoji')}
                                    emoji="point_up"
                                    style={{ position: 'absolute', bottom: '35px', left: '20px' }}
                                />
                            )}
                            <div className="icon">
                                <ReactSVG src="/icons/emoji.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <Loader />;
    }
}
