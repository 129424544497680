import { authConstants } from "../constants";
import * as authApi from "../../api/authApi";

import * as navigationActions from "./navigationActions";
import Alert from "../../models/Alert";

export const changeValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});
/**
 * Login actions
 */
export const changeLoginValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

export const startLogin = () => ({
    type: authConstants.LOGIN_FORM_STARTED,
});

export const loginSuccess = (result) => ({
    type: authConstants.LOGIN_FORM_SUCCESS,
    ...result,
});

export const loginFailure = (error) => ({
    type: authConstants.LOGIN_FORM_FAILURE,
    ...error,
});

export const login = (email, password, t) => {
    return (dispatch) => {
        dispatch(startLogin());

        const promise = authApi.login(email, password);

        promise
            .then((result) => {
                dispatch(loginSuccess(result));
            })
            .catch((error) => {
                dispatch(loginFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.login.error.title"),
                            t("notification.login.error.description"),
                            "error"
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Register actions
 */
export const changeRegisterValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

export const startRegister = () => ({
    type: authConstants.REGISTER_FORM_STARTED,
});

export const registerSuccess = (result) => ({
    type: authConstants.REGISTER_FORM_SUCCESS,
    ...result,
});

export const registerFailure = (error) => ({
    type: authConstants.REGISTER_FORM_FAILURE,
    ...error,
});

export const register = (username, email, password, repeatPassword, terms, newsletter, recaptcha) => {
    return (dispatch) => {
        dispatch(startRegister());

        const promise = authApi.register(
            username,
            email,
            password,
            repeatPassword,
            terms,
            newsletter,
            recaptcha
        );

        promise
            .then((result) => {
                dispatch(registerSuccess(result));
            })
            .catch((error) => {
                dispatch(registerFailure(error));
            });

        return promise;
    };
};

/**
 * Confirm actions
 */
export const startEmailConfirmation = () => ({
    type: authConstants.CONFIRM_EMAIL_STARTED,
});

export const emailConfirmationSuccess = (result) => ({
    type: authConstants.CONFIRM_EMAIL_SUCCESS,
    ...result,
});

export const emailConfirmationFailure = (error) => ({
    type: authConstants.CONFIRM_EMAIL_FAILURE,
    ...error,
});

export const confirmEmail = (user, token, t) => {
    return (dispatch) => {
        dispatch(startEmailConfirmation());

        const promise = authApi.confirmEmail(user, token);

        promise
            .then((result) => {
                dispatch(emailConfirmationSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.confirm.success.title"),
                            t("notification.confirm.success.description"),
                            "success"
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(emailConfirmationFailure(error));
            });

        return promise;
    };
};

/**
 * Forgot actions
 */
export const changeForgotValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

export const startForgot = () => ({
    type: authConstants.FORGOT_FORM_STARTED,
});

export const forgotSuccess = (result) => ({
    type: authConstants.FORGOT_FORM_SUCCESS,
    ...result,
});

export const forgotFailure = (error) => ({
    type: authConstants.FORGOT_FORM_FAILURE,
    ...error,
});

export const forgot = (email) => {
    return (dispatch) => {
        dispatch(startForgot());

        const promise = authApi.forgot(email);

        promise
            .then((result) => {
                dispatch(forgotSuccess(result));
            })
            .catch((error) => {
                dispatch(forgotFailure(error));
            });

        return promise;
    };
};

/**
 * Reset actions
 */

export const startReset = () => ({
    type: authConstants.RESET_FORM_STARTED,
});

export const resetSuccess = (result) => ({
    type: authConstants.RESET_FORM_SUCCESS,
    ...result,
});

export const resetFailure = (error) => ({
    type: authConstants.RESET_FORM_FAILURE,
    ...error,
});

export const reset = (user, token, password, repeatPassword) => {
    return (dispatch) => {
        dispatch(startForgot());

        const promise = authApi.reset(user, token, password, repeatPassword);

        promise
            .then((result) => {
                dispatch(forgotSuccess(result));
            })
            .catch((error) => {
                dispatch(forgotFailure(error));
            });

        return promise;
    };
};

/**
 * Update user actions
 */
export const updateValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

export const startUpdate = () => ({
    type: authConstants.UPDATE_USER_STARTED,
});

export const updateSuccess = (result) => ({
    type: authConstants.UPDATE_USER_SUCCESS,
    ...result,
});

export const updateFailure = (error) => ({
    type: authConstants.UPDATE_USER_FAILURE,
    ...error,
});

export const update = (t, user) => {
    return (dispatch) => {
        dispatch(startUpdate());

        const promise = authApi.update(user);

        promise
            .then((result) => {
                dispatch(updateSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.settings.update.success.title"),
                            t("notification.settings.update.success.description"),
                            "success"
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(updateFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.settings.update.error.title"),
                            t("notification.settings.update.error.description"),
                            "failure"
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Update password actions
 */
export const changeUpdatePassword = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

export const startUpdatePassword = () => ({
    type: authConstants.UPDATE_PASSWORD_STARTED,
});

export const updatePasswordSuccess = (result) => ({
    type: authConstants.UPDATE_PASSWORD_SUCCESS,
    ...result,
});

export const updatePasswordFailure = (error) => ({
    type: authConstants.UPDATE_PASSWORD_FAILURE,
    ...error,
});

export const updatePassword = (t, currentPassword, newPassword, repeatPassword) => {
    return (dispatch) => {
        dispatch(startUpdatePassword());

        const promise = authApi.updatePassword(currentPassword, newPassword, repeatPassword);

        promise
            .then((result) => {
                dispatch(updatePasswordSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.password.update.success.title"),
                            t("notification.password.update.success.description"),
                            "success"
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(updatePasswordFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t("notification.password.update.error.title"),
                            t("notification.password.update.error.description"),
                            "failure"
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Fetching sessions
 */
export const startGetSessions = () => ({
    type: authConstants.GET_SESSIONS_STARTED,
});

export const getSessionsSuccess = (result) => ({
    type: authConstants.GET_SESSIONS_SUCCESS,
    ...result,
});

export const getSessionsFailure = (error) => ({
    type: authConstants.GET_SESSIONS_FAILURE,
    ...error,
});

export const getSessions = (token) => {
    return (dispatch) => {
        dispatch(startGetSessions());

        const promise = authApi.getActiveSessions(token);

        promise
            .then((result) => {
                dispatch(getSessionsSuccess(result));
            })
            .catch((error) => {
                dispatch(getSessionsFailure(error));
            });

        return promise;
    };
};

/**
 * Logout actions
 */
export const startLogout = () => ({
    type: authConstants.LOGOUT_STARTED,
});

export const logoutSuccess = (result) => ({
    type: authConstants.LOGOUT_SUCCESS,
    ...result,
});

export const logoutFailure = (error) => ({
    type: authConstants.LOGOUT_FAILURE,
    ...error,
});

export const logout = (token) => {
    return (dispatch) => {
        dispatch(startLogout());

        const promise = authApi.logout(token);

        promise
            .then((result) => {
                dispatch(logoutSuccess(result));
            })
            .catch((error) => {
                dispatch(logoutFailure(error));
            });

        return promise;
    };
};

/**
 * Logout all actions
 */
export const startLogoutAllOthers = () => ({
    type: authConstants.LOGOUT_ALL_OTHERS_STARTED,
});

export const logoutAllOthersSuccess = (result) => ({
    type: authConstants.LOGOUT_ALL_OTHERS_SUCCESS,
    ...result,
});

export const logoutAllOthersFailure = (error) => ({
    type: authConstants.LOGOUT_ALL_OTHERS_FAILURE,
    ...error,
});

export const logoutAllOthers = (token) => {
    return (dispatch) => {
        dispatch(startLogoutAllOthers());

        const promise = authApi.logoutAllOthers(token);

        promise
            .then((result) => {
                dispatch(logoutAllOthersSuccess(result));
            })
            .catch((error) => {
                dispatch(logoutAllOthersFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching role
 */
export const startGetRole = () => ({
    type: authConstants.GET_ROLE_STARTED,
});

export const getRoleSuccess = (result) => ({
    type: authConstants.GET_ROLE_SUCCESS,
    ...result,
});

export const getRoleFailure = (error) => ({
    type: authConstants.GET_ROLE_FAILURE,
    ...error,
});

export const getRole = (id = '') => {
    return (dispatch) => {
        dispatch(startGetRole());

        const promise = authApi.getRole(id);

        promise
            .then((result) => {
                dispatch(getRoleSuccess(result));
            })
            .catch((error) => {
                dispatch(getRoleFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching roles
 */
export const startGetRoles = () => ({
    type: authConstants.GET_ROLES_STARTED,
});

export const getRolesSuccess = (result, from) => ({
    type: authConstants.GET_ROLES_SUCCESS,
    from,
    ...result,
});

export const getRolesFailure = (error) => ({
    type: authConstants.GET_ROLES_FAILURE,
    ...error,
});

export const getRoles = (from, amount) => {
    return (dispatch) => {
        dispatch(startGetRoles());

        const promise = authApi.allRoles(from, amount);

        promise
            .then((result) => {
                dispatch(getRolesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getRolesFailure(error));
            });

        return promise;
    };
};

export const searchRoles = (searchParameters, from, amount) => {
    return (dispatch) => {
        dispatch(startGetRoles());

        const promise = authApi.searchRoles(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getRolesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getRolesFailure(error));
            });

        return promise;
    };
};

/**
 * Creating role
 */
export const startCreateRole = () => ({
    type: authConstants.CREATE_ROLE_STARTED,
});

export const createRoleSuccess = (result) => ({
    type: authConstants.CREATE_ROLE_SUCCESS,
    ...result,
});

export const createRoleFailure = (error) => ({
    type: authConstants.CREATE_ROLE_FAILURE,
    ...error,
});

export const createRole = (role) => {
    return (dispatch) => {
        dispatch(startCreateRole());

        const promise = authApi.createRole(role);

        promise
            .then((result) => {
                dispatch(createRoleSuccess(result));
            })
            .catch((error) => {
                dispatch(createRoleFailure(error));
            });

        return promise;
    };
};

/**
 * Updating role
 */
export const startUpdateRole = () => ({
    type: authConstants.UPDATE_ROLE_STARTED,
});

export const updateRoleSuccess = (result) => ({
    type: authConstants.UPDATE_ROLE_SUCCESS,
    ...result,
});

export const updateRoleFailure = (error) => ({
    type: authConstants.UPDATE_ROLE_FAILURE,
    ...error,
});
export const updateRole = (role) => {
    return (dispatch) => {
        dispatch(startUpdateRole());

        const promise = authApi.updateRole(role);

        promise
            .then((result) => {
                dispatch(updateRoleSuccess(result));
            })
            .catch((error) => {
                dispatch(updateRoleFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting role
 */
export const startDeleteRole = () => ({
    type: authConstants.DELETE_ROLE_STARTED,
});

export const deleteRoleSuccess = (result) => ({
    type: authConstants.DELETE_ROLE_SUCCESS,
    ...result,
});

export const deleteRoleFailure = (error) => ({
    type: authConstants.DELETE_ROLE_FAILURE,
    ...error,
});
export const deleteRole = (role) => {
    return (dispatch) => {
        dispatch(startDeleteRole());

        const promise = authApi.removeRole(role);

        promise
            .then((result) => {
                dispatch(deleteRoleSuccess(result));
            })
            .catch((error) => {
                dispatch(deleteRoleFailure(error));
            });

        return promise;
    };
};