import { walletConstants, platformConstants } from '../constants';

const initialState = {
    isFetchingWallet: false,
    wallet: null,

    isFetchingTransactions: false,
    isFetchingTransactionsByDate: false,
    transactionsLoaded: 0,
    transactionsLoadedFrom: 0,
    transactionsTotalResults: 0,
    transactions: [],
    transactionsByDate: [],

    isFetchingTransaction: false,
    transaction: null,

    isFetchingInvoices: false,
    invoicesLoaded: 0,
    invoicesLoadedFrom: 0,
    invoicesTotalResults: 0,
    invoices: [],

    isFetchingInvoice: false,
    invoice: null,

    isFetchingPayouts: false,
    payoutsLoaded: 0,
    payoutsLoadedFrom: 0,
    payoutsTotalResults: 0,
    payouts: [],

    isFetchingPayout: false,
    payout: null,

    isFetchingCurrencies: false,
    currencies: [],
};

export default function wallet(state = initialState, action) {
    switch (action.type) {
        case walletConstants.WALLET_CHANGE_VALUE:
            state = {
                ...state,
                error: '',
                [action.name]: action.value,
            };
            return state;

        case walletConstants.GET_WALLET_STARTED:
            return { ...state, isFetchingWallet: true };
        case walletConstants.GET_WALLET_FAILURE:
            return { ...state, isFetchingWallet: false };
        case walletConstants.GET_WALLET_SUCCESS:
            return {
                ...state,
                isFetchingWallet: false,
                wallet: action.wallet,
            };

        case walletConstants.GET_TRANSACTIONS_STARTED:
            return { ...state, isFetchingTransactions: true };
        case walletConstants.GET_TRANSACTIONS_FAILURE:
            return { ...state, isFetchingTransactions: false };
        case walletConstants.GET_TRANSACTIONS_SUCCESS:
            state = {
                ...state,
                isFetchingTransactions: false,
                transactions: action.from > 0 ? [...state.transactions, ...action.transactions] : action.transactions,
                transactionsLoaded:
                    action.from > 0
                        ? state.transactions.length + action.transactions.length
                        : action.transactions.length,
                transactionsTotalResults: action.totalResults || 0,
            };
            return state;

        case walletConstants.GET_TRANSACTIONS_BY_DATE_STARTED:
            return { ...state, isFetchingTransactionsByDate: true };
        case walletConstants.GET_TRANSACTIONS_BY_DATE_FAILURE:
            return { ...state, isFetchingTransactionsByDate: false, message: action.message || '' };
        case walletConstants.GET_TRANSACTIONS_BY_DATE_SUCCESS:
            state = {
                ...state,
                isFetchingTransactionsByDate: false,
                transactionsByDate: action.transactions,
            };
            return state;

        case walletConstants.CREATING_TRANSACTION_STARTED:
        case walletConstants.UPDATING_TRANSACTION_STARTED:
            return { ...state, isFetching: true };
        case walletConstants.CREATING_TRANSACTION_FAILURE:
        case walletConstants.UPDATING_TRANSACTION_FAILURE:
            return { ...state, isFetching: false };
        case walletConstants.CREATING_TRANSACTION_SUCCESS:
        case walletConstants.UPDATING_TRANSACTION_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                transaction: action.transaction,
            };
            return state;

        case walletConstants.CREATING_CHARGE_STARTED:
        case walletConstants.UPDATING_CHARGE_STARTED:
            return { ...state, isFetching: true };
        case walletConstants.CREATING_CHARGE_FAILURE:
        case walletConstants.UPDATING_CHARGE_FAILURE:
            return { ...state, isFetching: false };
        case walletConstants.CREATING_CHARGE_SUCCESS:
        case walletConstants.UPDATING_CHARGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                charge: action.charge,
                source: action.source,
            };

        case walletConstants.EXECUTE_CHARGE_STARTED:
            return { ...state, isFetching: true };
        case walletConstants.EXECUTE_CHARGE_FAILURE:
            return { ...state, isFetching: false };
        case walletConstants.EXECUTE_CHARGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                charge: action.charge,
            };

        case walletConstants.GET_INVOICE_STARTED:
            return { ...state, isFetchingInvoice: true };
        case walletConstants.GET_INVOICE_FAILURE:
            return { ...state, isFetchingInvoice: false };
        case walletConstants.GET_INVOICE_SUCCESS:
            state = {
                ...state,
                isFetchingInvoice: false,
                invoice: action.invoice,
            };
            return state;

        case walletConstants.GET_INVOICES_STARTED:
            return { ...state, isFetchingInvoices: true };
        case walletConstants.GET_INVOICES_FAILURE:
            return { ...state, isFetchingInvoices: false };
        case walletConstants.GET_INVOICES_SUCCESS:
            state = {
                ...state,
                isFetchingInvoices: false,
                invoices: action.from > 0 ? [...state.invoices, ...action.invoices] : action.invoices,
                invoicesLoaded:
                    action.from > 0 ? state.invoices.length + action.invoices.length : action.invoices.length,
                invoicesTotalResults: action.totalResults || 0,
            };
            return state;

        case walletConstants.CREATING_PAYOUT_STARTED:
        case walletConstants.UPDATING_PAYOUT_STARTED:
            return { ...state, isFetchingPayout: true };
        case walletConstants.CREATING_PAYOUT_FAILURE:
        case walletConstants.UPDATING_PAYOUT_FAILURE:
            return { ...state, isFetchingPayout: false };
        case walletConstants.CREATING_PAYOUT_SUCCESS:
        case walletConstants.UPDATING_PAYOUT_SUCCESS:
            return {
                ...state,
                isFetchingPayout: false,
                payout: action.payout,
            };

        case walletConstants.GET_PAYOUT_STARTED:
            return { ...state, isFetchingPayout: true };
        case walletConstants.GET_PAYOUT_FAILURE:
            return { ...state, isFetchingPayout: false };
        case walletConstants.GET_PAYOUT_SUCCESS:
            state = {
                ...state,
                isFetchingPayout: false,
                payout: action.payout,
            };
            return state;

        case walletConstants.GET_PAYOUTS_STARTED:
            return { ...state, isFetchingPayouts: true };
        case walletConstants.GET_PAYOUTS_FAILURE:
            return { ...state, isFetchingPayouts: false };
        case walletConstants.GET_PAYOUTS_SUCCESS:
            state = {
                ...state,
                isFetchingPayouts: false,
                payouts: action.from > 0 ? [...state.payouts, ...action.payouts] : action.payouts,
                payoutsLoaded: action.from > 0 ? state.payouts.length + action.payouts.length : action.payouts.length,
                payoutsTotalResults: action.totalResults || 0,
            };
            return state;

        case walletConstants.GET_CURRENCIES_STARTED:
            return { ...state, isFetchingCurrencies: true };
        case walletConstants.GET_CURRENCIES_FAILURE:
            return { ...state, isFetchingCurrencies: false };
        case walletConstants.GET_CURRENCIES_SUCCESS:
        case platformConstants.GET_PLATFORM_SUCCESS:
            state = {
                ...state,
                isFetchingCurrencies: false,
                currencies: action.currencies || [],
            };
            return state;
        default:
            return state;
    }
}
