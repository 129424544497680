import { messageTemplateConstants } from "../constants/messageTemplate.constants";

const initialState = {
    isFetchingMessageTemplate: false,
    formMessageTemplate: null,
    messageTemplate: null,

    isFetchingMessageTemplates: false,
    messageTemplatesTotalResults: 0,
    messageTemplatesLoaded: 0,
    messageTemplates: [],
};

export default function messageTemplate(state = initialState, action) {
    switch (action.type) {
        case messageTemplateConstants.MESSAGE_TEMPLATE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case messageTemplateConstants.GET_MESSAGE_TEMPLATES_STARTED:
            return { ...state, isFetchingMessageTemplates: true };
        case messageTemplateConstants.GET_MESSAGE_TEMPLATES_FAILURE:
            return { ...state, isFetchingMessageTemplates: false, error: action.message || "" };
        case messageTemplateConstants.GET_MESSAGE_TEMPLATES_SUCCESS:
            return {
                ...state,
                isFetchingMessageTemplates: false,
                messageTemplates: action.from > 0 ? [...state.messageTemplates, ...action.messageTemplates] : action.messageTemplates,
                messageTemplatesLoaded:
                    action.from > 0 ? state.messageTemplates.length + action.messageTemplates.length : action.messageTemplates.length,
                messageTemplatesTotalResults: action.totalResults || 0,
            };

        case messageTemplateConstants.SEARCH_MESSAGE_TEMPLATES_STARTED:
            return { ...state, isFetchingMessageTemplates: true };
        case messageTemplateConstants.SEARCH_MESSAGE_TEMPLATES_FAILURE:
            return { ...state, isFetchingMessageTemplates: false, error: action.message || "" };
        case messageTemplateConstants.SEARCH_MESSAGE_TEMPLATES_SUCCESS:
            return {
                ...state,
                isFetchingMessageTemplates: false,
                messageTemplates: action.from > 0 ? [...state.messageTemplates, ...action.messageTemplates] : action.messageTemplates,
                messageTemplatesLoaded:
                    action.from > 0 ? state.messageTemplates.length + action.messageTemplates.length : action.messageTemplates.length,
                messageTemplatesTotalResults: action.totalResults || 0,
            };

        case messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_STARTED:
            return { ...state, isFetchingMessageTemplate: true };
        case messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_FAILURE:
            return { ...state, isFetchingMessageTemplate: false, error: action.message || "" };
        case messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isFetchingMessageTemplate: false,
                messageTemplate: action.messageTemplate,
                messageTemplates: [action.messageTemplate, ...state.messageTemplates],
            };

        case messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_STARTED:
            return { ...state, isFetchingMessageTemplate: true };
        case messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_FAILURE:
            return { ...state, isFetchingMessageTemplate: false, error: action.message || "" };
        case messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isFetchingMessageTemplate: false,
                messageTemplate: action.messageTemplate,
                messageTemplates: [...state.messageTemplates].map((messageTemplate) => {
                    if (action.messageTemplate.id === messageTemplate.id) {
                        return action.messageTemplate;
                    }
                    return messageTemplate;
                }),
            };

        case messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_STARTED:
            return { ...state, isFetchingMessageTemplate: true };
        case messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_FAILURE:
            return { ...state, isFetchingMessageTemplate: false, message: action.message || "" };
        case messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isFetchingMessageTemplate: false,
                messageTemplate: action.messageTemplate,
                messageTemplates: [...state.messageTemplates].filter((messageTemplate) => action.messageTemplate.id !== messageTemplate.id),
            };
        default:
            return state;
    }
}
