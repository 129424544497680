import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import WalletPage from '../../components/wallet/WalletPage';

import * as walletActions from '../../state/actions/walletActions';
import * as navigationActions from '../../state/actions/navigationActions';

class WalletContainer extends Component {

    render() {

        const {
            getUserWallet,
            getTransactions,
            getTransactionsByDate,
            ...props
        } = this.props;

        

        return (
            <WalletPage
                {...props}
                getUserWallet={(id) => getUserWallet(id)}
                getTransactions={(from, amount) => getTransactions(from, amount)}
                getTransactionsByDate={(startDate, endDate) => getTransactionsByDate(startDate, endDate)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.wallet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserWallet: (id) => dispatch(walletActions.getUserWallet(id)),
        getTransactions: (from, amount) => dispatch(walletActions.getTransactions(from, amount)),
        getTransactionsByDate: (startDate, toDate) => dispatch(walletActions.getTransactionsByDate(startDate, toDate)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
        popStack: () => dispatch(navigationActions.popStack())
    }
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(withTranslation('translation')(WalletContainer));