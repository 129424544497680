import TaskPayout from './TaskPayout';
import moment from 'moment';

export default class TaskShift {
    
    id;
    nonce;

    title;
    amount;
    startsAt;
    endsAt;

    payout;

    createdAt;
    updatedAt;

    // form props
    date;
    startTime;
    endTime;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.amount = 1;
        this.payout = new TaskPayout(); 
        
        this.date = moment().format("DD/MM/YYYY");
        this.startTime = "08:00";
        this.endTime = "17:00";
    }
}