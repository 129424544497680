import axios from 'axios';
import Promise from 'promise';
import { store } from './index';
import { handleError, handleResponse } from './utils/responseMiddleware';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
    },
};

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(
    (config) => {
        const storeState = store.getState();
        const token = storeState.auth.bearer;
        const platform = storeState.platform.platform;
        config.headers.Authorization = `Bearer ${token}`;

        if (platform && platform !== undefined && platform !== null) {
            config.headers.Platform = platform ? platform.id : '';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        if (response.data.success === true) {
            return Promise.resolve(handleResponse(response));
        } else {
            return Promise.reject(handleError(response));
        }
    },
    (error) => {
        return Promise.reject(handleError(error));
    }
);

export default instance;
