import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ForgotPage from "../../components/authentication/ForgotPage";
import * as authActions from "../../state/actions/authActions.js";

class ForgotContainer extends Component {
    render() {
        const { changeValue, forgot, ...props } = this.props;

        const { email } = props;

        return (
            <ForgotPage
                {...props}
                onChange={changeValue}
                onForgot={() => {
                    forgot(email).then((response) => {
                        this.props.history.push("/");
                    });
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        error: state.auth.error,
        email: state.auth.email,
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        forgot: (email) => dispatch(authActions.forgot(email)),
        changeValue: (name, value) => dispatch(authActions.changeForgotValue(name, value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(ForgotContainer));
