import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { intToDouble } from '../../../utils/moneyUtils';
import Charge from "../../../models/wallet/Charge";

import CreateChargeContainer from '../../../containers/wallet/crud/CreateChargeContainer';
import Loader from '../../ui/components/Loader';

import PersonInformation from './PersonInformation';
import BusinessInformation from './BusinessInformation';

class InvoiceView extends Component {
    render() {
        const { t, user, invoiceIsFetching, invoice, profile, addToStack } = this.props;

        if (invoiceIsFetching) {
            return <Loader />;
        }
        return (
            <div className="invoice-view">
                <div className="information flex-container justify-between align-items-end">
                    <div className="target">
                        {true ? (
                            <PersonInformation profile={invoice.targetProfile} />
                        ) : (
                            <BusinessInformation business={invoice.targetBusiness} />
                        )}
                    </div>
                    <div className="subject">
                        {true ? (
                            <PersonInformation profile={invoice.subjectProfile} subject={true} />
                        ) : (
                            <BusinessInformation business={invoice.subjectBusiness} subject={true} />
                        )}
                    </div>
                </div>

                <div className="flex-container justify-between name">
                    <h1 className="no-margin">{invoice.name}</h1>
                    <div className="right">
                        <div>
                            <b>{t('invoice.date')}:</b> {moment(invoice.date).format('DD-MM-YYYY')}
                        </div>
                        <div>
                            <b>{t('invoice.dueAt')}:</b> {moment(invoice.dueAt).format('DD-MM-YYYY')}
                        </div>
                    </div>
                </div>

                <div className="items">
                    {invoice.items.map((item) => {
                        const taxRate = item.value.taxRate ? item.value.taxRate.percentage : 21;
                        return (
                            <div className="item">
                                <div className="quantity">{item.quantity}</div>
                                <div className="description">{item.description}</div>
                                <div className="tax">
                                    {item.value.currency.sign || '€'}
                                    {intToDouble(
                                        (item.value.amount / (taxRate + 100)) * taxRate,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )}
                                </div>
                                <div className="amount">
                                    {item.value.currency.sign || '€'}
                                    {intToDouble(
                                        item.value.amount,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="summary">
                    <div className="summary__subTotal">
                        <label>{t('invoice.subTotal')}:</label>
                        {invoice.subTotal.currency.sign || '€'}
                        {intToDouble(
                            invoice.subTotal.amount,
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}
                    </div>
                    <div className="summary__tax">
                        <label>{t('invoice.tax')}:</label>
                        {invoice.tax.currency.sign || '€'}
                        {intToDouble(
                            invoice.tax.amount,
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}
                    </div>
                    <div className="summary__total">
                        <label>{t('invoice.total')}:</label>
                        {invoice.total.currency.sign || '€'}
                        {intToDouble(
                            invoice.total.amount,
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}
                    </div>
                </div>
                <div className="actions">
                    {invoice.targetProfile && invoice.targetProfile.id === profile.id && invoice.status === 'open' && (
                        <button
                            type="button"
                            onClick={(e) => {
                                const newCharge = new Charge();

                                newCharge.name = invoice.name;
                                newCharge.description = invoice.description;
                                if (invoice.subjectProfile) {
                                    newCharge.toUser = invoice.subjectProfile.user.id;
                                }
                                if (invoice.targetProfile) {
                                    newCharge.fromUser = invoice.targetProfile.user.id;
                                }
                                if (invoice.subjectBusiness) {
                                    newCharge.toBusiness = invoice.subjectBusiness.id;
                                }
                                if (invoice.targetBusiness) {
                                    newCharge.fromBusiness = invoice.targetBusiness.id;
                                }
                                newCharge.amount = {...invoice.total}
                                newCharge.invoices = [{...invoice}]

                                addToStack({
                                    name: invoice.name,
                                    component: <CreateChargeContainer charge={newCharge} />,
                                });
                            }}
                        >
                            {t('form.pay')}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}
export default withTranslation('translation')(InvoiceView);
