import React, { Component } from "react";

export default class Alert extends Component {
    render() {
        const { alert, className, ...props } = this.props;

        return (
            <div className={`alert ${alert.type || ''} ${className}`} {...props}>
                <div className="title">{alert.title || ""}</div>
                <div className="description">{alert.description || ""}</div>
            </div>
        );
    }
}
