import React, { Component } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

export default class CountrySelector extends Component {
    state = {
        options: countryList().native().getData(),
        value: this.props.value ? this.props.value : null,
    };

    render() {
        const { options, value } = this.state;
        const { onChange, placeholder } = this.props;
        return (
            <Select
                options={options}
                value={options.find((option) => option.value === value)}
                placeholder={placeholder}
                onChange={(value) => {
                    this.setState({ value });
                    onChange && onChange(value);
                }}
            />
        );
    }
}
