import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateExperiencePage from "../../../components/cv/experience/CreateExperiencePage";

import * as profileActions from "../../../state/actions/profileActions";
import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreateExperienceContainer extends Component {
    render() {
        const { changeValue, createExperience, searchBusinesses, ...props } = this.props;

        

        return (
            <CreateExperiencePage
                {...props}
                onChange={changeValue}
                createExperience={(experience) => createExperience(experience)}
                searchBusinesses={(searchParameters, from, amount) =>
                    searchBusinesses(searchParameters, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(profileActions.changeValue(name, value)),
        createExperience: (experience) =>
            dispatch(profileActions.createExperience(experience)),
        searchBusinesses: (searchParameters, from, amount) =>
            dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateExperienceContainer));
