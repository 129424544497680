export default class TaskTag {
    
    id;
    nonce; 

    name;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }

}