import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import Loader from '../ui/components/Loader';
import Rating from '../rating/Rating';

export default class AccountView extends Component {
    componentDidMount() {
        const { profile, profileIsFetching, getUserProfile, getPersonalBusinesses } = this.props;

        if (!profile && !profileIsFetching) getUserProfile();
        getPersonalBusinesses();
    }

    render() {
        const { t, profile, profileIsFetching, personalBusinesses } = this.props;

        if (!profileIsFetching && profile) {
            return (
                <div className="side-profile">
                    <img src={profile.banner ? profile.banner.url : '/images/banner.svg'} alt={profile.banner ? profile.banner.originalName : ''} className="banner"></img>
                    <div className="picture">
                        <img src={profile.avatar ? profile.avatar.url : '/images/user.svg'} alt={profile.avatar ? profile.avatar.originalName : ''}></img>
                    </div>
                    <div style={{ padding: '10px 10px 0px' }}>
                        <div className="name">
                            <Link to="/profile">{profile.name}</Link>
                        </div>
                        <Rating rating={profile.rating ? profile.rating.totalRating || 0.0 : 0.0} small>
                            {t('rating.reviews.total', {
                                count: profile.rating ? profile.rating.totalReviews || 0 : 0,
                            })}
                        </Rating>
                        {profile.headline && <div className="headline">{profile.headline}</div>}
                        <div className="info">
                            <div>
                                <ReactSVG src="/icons/profile.svg" /> {profile.user.username}
                            </div>
                            <div>
                                <ReactSVG src="/icons/messages.svg" />
                                {profile.user.email}
                            </div>
                        </div>
                        <div className="info">
                            {personalBusinesses.map((business) => (
                                <Link to={`/business/${business.id}`}>
                                    <img
                                        src={business.logo ? business.logo.url : '/icons/business.svg'}
                                        className={business.logo ? 'rounded' : ''}
                                        alt={`${business.name} logo`}
                                    ></img>
                                    {business.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
        return <Loader />;
    }
}
