import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

import { shadeColor } from './utils/colorUtils';
import { i18n } from './index';

import Loader from './components/ui/components/Loader';
import MetaTags from './components/ui/views/MetaTags';

import ComponentStackContainer from './containers/ComponentStackContainer';
import AlertStackContainer from './containers/AlertStackContainer';

/* Components */
import NotFound from './components/defaults/404';

import Home from './containers/pages/HomeContainer';
import About from './components/pages/About';
import Seller from './components/pages/Seller';
import Contact from './components/pages/Contact';

import Header from './containers/header/HeaderContainer';
import Footer from './containers/footer/FooterContainer';

import Login from './containers/authentication/LoginContainer';
import ConfirmEmail from './containers/authentication/ConfirmEmailContainer';
import Register from './containers/authentication/RegisterContainer';
import Welcome from './containers/authentication/WelcomeContainer';
import Forgot from './containers/authentication/ForgotContainer';
import Recover from './containers/authentication/ForgotResetContainer';

import Logout from './containers/authentication/LogoutContainer';

import Dashboard from './containers/dashboard/DashboardContainer';

import CreateProfile from './containers/profile/crud/CreateProfileContainer';
import Profile from './containers/profile/ProfileContainer';

import Task from './containers/task/TaskContainer';

import Business from './containers/business/BusinessContainer';

import Wallet from './containers/wallet/WalletContainer';
import ChargeReturn from './containers/wallet/ChargeReturnContainer';

import Chats from './containers/chat/ChatsContainer';

import Notifications from './containers/notification/NotificationsContainer';

import CreateSupportTicket from './containers/support/crud/CreateSupportTicketContainer';
import SupportTickets from './containers/support/SupportTicketsContainer';
import SupportTicket from './containers/support/SupportTicketContainer';

import PlatformsContainer from './containers/platform/PlatformsContainer';
import CategoriesContainer from './containers/category/CategoriesContainer';
import UsersContainer from './containers/user/UsersContainer';
import RolesContainer from './containers/role/RolesContainer';
import ContractsContainer from './containers/contract/ContractsContainer';

import Settings from './containers/settings/SettingsContainer';

import Cookies from './components/legal/Cookies';
import TermsOfService from './components/legal/TermsOfService';
import DataUsage from './components/legal/DataUsage';

import RedirectView from './components/ui/views/RedirectView';

import * as platformActions from './state/actions/platformActions';
import * as navigationActions from './state/actions/navigationActions';

class IndexContainer extends Component {
    componentDidMount() {
        const { getPlatform, t } = this.props;
        getPlatform(t).then((data) => {
            i18n.changeLanguage(data.language.toLowerCase());
        });
    }

    render() {
        const { isAuthenticated, platformIsFetching, platform, languagesIsFetching, error, user } = this.props;

        const GlobalStyles = createGlobalStyle`
            html {
                --primary-color: ${platform ? platform.primaryColor : 'black'};
                --primary-dark-color: ${platform ? shadeColor(platform.primaryColor, -40) : 'black'};
                --secondary-color: ${platform ? platform.secondaryColor : 'black'};
            }
        `;

        const PageShell = (Page) => {
            return (props) => (
                <div className={'page'}>
                    <AlertStackContainer />
                    <ComponentStackContainer />
                    <Header {...props} />
                    <Page {...props} />
                    <Footer {...props} />
                </div>
            );
        };

        const AlwaysAccessibleRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
        );

        const DefaultRoute = ({ component: Component, ...rest }) => (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? <Redirect to="/dashboard" /> : <Component {...props} {...rest} />
                }
            />
        );

        const AuthRoute = ({ component: Component, ...rest }) => (
            <Route
                {...rest}
                render={(props) => (isAuthenticated ? <Component {...rest} {...props} /> : <Redirect to="/login" />)}
            />
        );

        const PortalRoute = ({ component: Component, ...rest }) => (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        user.status === 'REGISTERED' ? (
                            <Component {...props} {...rest} />
                        ) : (
                            <Redirect to="/create/profile" />
                        )
                    ) : (
                        <Redirect to="/login" />
                    )
                }
            />
        );

        if (error) {
            return (
                <div className="absolute-center">
                    <GlobalStyles />
                    <h1 className="center">An error occurred</h1>
                    <p className="center">{error}</p>
                </div>
            );
        }

        if (platformIsFetching || !platform || languagesIsFetching) {
            return (
                <div className="absolute-center">
                    <GlobalStyles />
                    <Loader />
                </div>
            );
        }

        const homeRedirection = () => <RedirectView to={platform.websiteUrl} />;

        return (
            <Route
                render={({ location }) => (
                    <div className="background">
                        <GlobalStyles />
                        <MetaTags {...this.props} />
                        <Switch location={location}>
                            {/* General */}
                            {platform.hasWebsite ? (
                                platform.websiteUrl.includes('://') ? (
                                    <DefaultRoute exact path="/" component={PageShell(homeRedirection)} />
                                ) : (
                                    <DefaultRoute exact noHeader path="/" component={PageShell(Home)} />
                                )
                            ) : (
                                <DefaultRoute exact path="/" component={PageShell(Login)} noFooter />
                            )}

                            {platform.hasWebsite && (
                                <AlwaysAccessibleRoute exact redFooter path="/over" component={PageShell(About)} />
                            )}
                            {platform.hasWebsite && (
                                <AlwaysAccessibleRoute exact redFooter path="/about" component={PageShell(About)} />
                            )}
                            {platform.hasWebsite && (
                                <AlwaysAccessibleRoute
                                    exact
                                    whiteLogo
                                    redFooter
                                    path="/contact"
                                    component={PageShell(Contact)}
                                />
                            )}
                            {platform.hasWebsite && (
                                <AlwaysAccessibleRoute exact noFooter path="/pitch" component={PageShell(Seller)} />
                            )}
                            {platform.hasWebsite && (
                                <AlwaysAccessibleRoute exact noFooter path="/seller" component={PageShell(Seller)} />
                            )}

                            {/* Legal */}
                            <AlwaysAccessibleRoute exact path="/legal/cookies" component={PageShell(Cookies)} />
                            <AlwaysAccessibleRoute
                                exact
                                path="/legal/terms-of-service"
                                component={PageShell(TermsOfService)}
                            />
                            <AlwaysAccessibleRoute exact path="/legal/tos" component={PageShell(TermsOfService)} />
                            <AlwaysAccessibleRoute exact path="/legal/data-usage" component={PageShell(DataUsage)} />

                            {/* Support */}
                            <AlwaysAccessibleRoute
                                noFooter
                                exact
                                path="/support/new"
                                component={PageShell(CreateSupportTicket)}
                            />
                            <AlwaysAccessibleRoute
                                noFooter
                                exact
                                path="/support/ticket/:id/:token"
                                component={PageShell(SupportTicket)}
                            />

                            <PortalRoute exact path="/support" component={PageShell(SupportTickets)} />
                            <PortalRoute exact path="/support/ticket/:id" component={PageShell(SupportTicket)} />

                            {/* Portal login */}
                            <DefaultRoute exact path="/login" component={PageShell(Login)} noFooter />
                            <DefaultRoute exact path="/register" component={PageShell(Register)} noFooter />
                            <DefaultRoute exact path="/forgot" component={PageShell(Forgot)} noFooter />
                            <DefaultRoute
                                exact
                                path="/user/recover/:user/:token"
                                component={PageShell(Recover)}
                                noFooter
                            />
                            <DefaultRoute
                                exact
                                path="/user/:userId/verify/:token"
                                component={PageShell(ConfirmEmail)}
                                noFooter
                            />
                            <DefaultRoute exact path="/welcome/:user" component={PageShell(Welcome)} />

                            <AlwaysAccessibleRoute exact path="/logout" component={PageShell(Logout)} />

                            {/* Creating profile */}
                            <AuthRoute exact path="/create/profile" component={PageShell(CreateProfile)} />

                            {/* Portal */}
                            <PortalRoute exact path="/dashboard" component={PageShell(Dashboard)} />
                            <PortalRoute exact path="/dashboard/:tab" component={PageShell(Dashboard)} />
                            <PortalRoute exact path="/notifications" component={PageShell(Notifications)} />

                            <AlwaysAccessibleRoute exact path="/task/:id" component={PageShell(Task)} />
                            <AlwaysAccessibleRoute exact path="/task/:id/:slug" component={PageShell(Task)} />
                            <AlwaysAccessibleRoute exact path="/task/:id/:shift/:slug" component={PageShell(Task)} />

                            <PortalRoute exact path="/business/:id" component={PageShell(Business)} />
                            <PortalRoute exact path="/business/:id/:slug" component={PageShell(Business)} />

                            <PortalRoute exact path="/profile" component={PageShell(Profile)} />
                            <PortalRoute exact path="/profile/:id" component={PageShell(Profile)} />
                            <PortalRoute exact path="/profile/:id/:tab" component={PageShell(Profile)} />

                            <PortalRoute exact path="/wallet" component={PageShell(Wallet)} />
                            <PortalRoute exact path="/wallet/:tab" component={PageShell(Wallet)} />

                            <PortalRoute exact path="/wallet/charge/return/:id" component={PageShell(ChargeReturn)} />

                            <PortalRoute exact path="/messages" chatNotVisible component={PageShell(Chats)} />
                            <PortalRoute exact path="/messages/new" chatNotVisible component={PageShell(Chats)} />
                            <PortalRoute exact path="/messages/edit" chatNotVisible component={PageShell(Chats)} />
                            <PortalRoute exact path="/messages/:id" chatNotVisible component={PageShell(Chats)} />

                            <PortalRoute exact path="/platforms" component={PageShell(PlatformsContainer)} />
                            <PortalRoute exact path="/categories" component={PageShell(CategoriesContainer)} />
                            <PortalRoute exact path="/users" component={PageShell(UsersContainer)} />
                            <PortalRoute exact path="/roles" component={PageShell(RolesContainer)} />
                            <PortalRoute exact path="/contracts" component={PageShell(ContractsContainer)} />

                            <PortalRoute exact path="/settings" component={PageShell(Settings)} />
                            <PortalRoute exact path="/settings/:tab" component={PageShell(Settings)} />

                            {/* Fallbacks */}
                            <AlwaysAccessibleRoute path="*" component={PageShell(NotFound)} />
                        </Switch>
                    </div>
                )}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platformIsFetching: state.platform.platformIsFetching,
        platform: state.platform.platform,
        tags: state.platform.tags,
        languagesIsFetching: state.platform.languagesIsFetching,
        languages: state.platform.languages,
        error: state.platform.error,
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPlatform: () => dispatch(platformActions.getPlatform()),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(IndexContainer));
