import Address from '../../general/Address';

export default class Person {

    id;
    nonce; 

    firstName;
    lastName;
    phoneNumber;
    gender;
    dateOfBirth;
    identification;
    address;

    createdAt;
    updatedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
        this.address = new Address();

        this.gender = "MALE";
    }
    
}