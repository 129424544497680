import React, { Component } from 'react';

import UpdatePlatformContainer from '../../containers/platform/crud/UpdatePlatformContainer';
import CreatePlatformContainer from '../../containers/platform/crud/CreatePlatformContainer';

import TableView from '../ui/views/TableView';
import PopOver from '../ui/components/PopOver';

export default class PlatformsView extends Component {
    state = {
        selectedOptions: ['logo', 'name', 'description', 'hostNames'],
        popOvers: [],
        searchQuery: '',
    };

    componentDidMount() {
        const { getPlatforms } = this.props;
        getPlatforms(0, 20);
    }

    render() {
        const { t,  platforms, isFetchingPlatforms, platformsLoaded, platformsTotalResults, addToStack, deletePlatform, searchPlatforms, getPlatforms } = this.props;
        const { selectedOptions, popOvers, searchQuery } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        isLoading={isFetchingPlatforms}
                        values={platforms}
                        title={t('platforms.header')}
                        searchEnabled
                        searchPlaceholder={t('search.placeholder')}
                        createNewLabel={t('platform.create')}
                        noDataLabel={t('platforms.notFound')}
                        onSearchChange={(searchQuery) => {
                            this.setState({ searchQuery });
                            searchQuery ? searchPlatforms({ query: searchQuery }, 0, 20) : getPlatforms(0, 20);
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t('platform.create'),
                                component: <CreatePlatformContainer />,
                            });
                        }}
                        onScrolledToBottom={() => {
                            if (platformsTotalResults > platformsLoaded) {
                                searchQuery ? searchPlatforms({ query: searchQuery }, platformsLoaded, platformsLoaded + 20) : getPlatforms(platformsLoaded, platformsLoaded + 20);
                            }
                        }}
                        rowOnClick={(e) => {
                            // nothing
                        }}
                        optionsSelectable
                        selectedOptions={selectedOptions}
                        options={[
                            {
                                name: 'logo',
                                size: 'image-size',
                                header: '',
                                type: 'image',
                                selector: 'logo.url',
                                defaultValue: '/images/Vynwork.png',
                            },
                            {
                                name: 'name',
                                size: 'one',
                                header: t('platform.name'),
                                type: 'string',
                                selector: 'name',
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'description',
                                size: 'one',
                                header: t('platform.description'),
                                type: 'string',
                                selector: 'description',
                            },
                            {
                                name: 'hostNames',
                                size: 'three',
                                header: t('platform.hostNames'),
                                type: 'array',
                                selector: 'hostNames',
                                valueSelector: 'hostName',
                            },
                        ]}
                        actions={[
                            {
                                name: t('form.edit'),
                                enabled: true,
                                action: (e, platform) => {
                                    e.preventDefault();

                                    addToStack({
                                        name: t('platform.update'),
                                        component: <UpdatePlatformContainer targetPlatform={platform} />,
                                    });
                                },
                            },
                            {
                                name: t('form.delete'),
                                enabled: true,
                                action: (e, platform) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                key={`platform-popover`}
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t('platform.delete.header')}</div>
                                                    <div className="text">{t('platform.delete.description')}</div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deletePlatform(platform);
                                                            }}
                                                        >
                                                            {t('delete')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                            {
                                name: t('form.goTo'),
                                enabled: true,
                                action: (e, platform) => {
                                    e.preventDefault();
                                    const url = `${window.location.protocol}//${platform.hostNames[0].hostName}`;
                                    window.location.href = url;
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}
