import React, { Component } from "react";

import { activateInputs } from "../../utils/formUtils";

import Switch from "../ui/components/Switch";

const enabledSettings = ["birthdays", "chat", "profile", "tasks", "wallet"];

export default class NotificationsSettings extends Component {
    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    updateNotificationSetting = (index, newNotification) => {
        const { settings, onChange, updateSettings } = this.props;

        const notificationIndex = Object.entries(settings.notifications).filter((notification) =>
            enabledSettings.includes(notification[0])
        )[index][0];

        const newSettings = { ...settings };
        const newNotifications = { ...settings.notifications };
        newNotifications[notificationIndex] = newNotification;

        newSettings.notifications = newNotifications;

        onChange("settings", newSettings);

        updateSettings(newSettings);
    };

    render() {
        const { t, settings } = this.props;
        const notifications = Object.entries(settings.notifications).filter((notification) =>
            enabledSettings.includes(notification[0])
        );
        return (
            <div>
                {notifications.map((notificationBreakdown, index) => {
                    const notification = notificationBreakdown[1];
                    return (
                        <div className="input-group highlight-box" key={notification.id}>
                            <h2 className="no-margin-bottom">
                                {t(`settings.notifications.${notificationBreakdown[0]}.header`)}
                            </h2>
                            <h3 className="no-margin-top">
                                {t(`settings.notifications.${notificationBreakdown[0]}.description`)}
                            </h3>
                            <div className="flex-container">
                                <div className="one">
                                    <div>{t("settings.enabled")}</div>
                                    <Switch
                                        checked={notification.enabled}
                                        onChange={(e, newState) => {
                                            const newNotification = { ...notification };
                                            newNotification.enabled = newState;

                                            this.updateNotificationSetting(index, newNotification);
                                        }}
                                    />
                                </div>
                                <div className="one">
                                    <div>{t("settings.emails")}</div>
                                    <Switch
                                        disabled={!notification.enabled}
                                        checked={notification.enabled && notification.emails}
                                        onChange={(e, newState) => {
                                            const newNotification = { ...notification };
                                            newNotification.emails = newState;

                                            this.updateNotificationSetting(index, newNotification);
                                        }}
                                    />
                                </div>
                                <div className="one">
                                    <div>{t("settings.push")}</div>
                                    <Switch
                                        disabled={!notification.enabled}
                                        checked={notification.enabled && notification.pushNotification}
                                        onChange={(e, newState) => {
                                            const newNotification = { ...notification };
                                            newNotification.pushNotification = newState;

                                            this.updateNotificationSetting(index, newNotification);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
