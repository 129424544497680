import api from '../api';

// CRUD
export const get = (id = '') => {
    return api.get(`/user/${id}`);
};

export const create = (user) => {
    return api.post(`/user`, user);
};

export const update = (user) => {
    return api.put(`/user`, user);
};

export const remove = (user) => {
    return api.delete(`/user/${user.id}`);
};

// Fetch and search
export const latest = (from = 0, amount = 20) => {
    return api.get(`/users/${from}/${amount}`);
};

export const search = (searchParameters = {}, from = 0, amount = 20) => {
    return api.post(`/users/${from}/${amount}`, searchParameters);
};

// Functions

export const block = (user, reason) => {
    return api.post(`/user/block/${user.id}`, { reason });
};

export const unblock = (user, reason) => {
    return api.post(`/user/unblock/${user.id}`, { reason });
};

export const invite = (user) => {
    return api.post(`/user/invite`, user);
};

export const fcm = (token) => {
    return api.post(`/user/fcm`, { token })
}