import Money from '../Money';

export default class Payout {

    id;
    nonce; 
    
    name;
    amount;

    constructor() {
        this.nonce = Math.random().toString(36).substring(7);
        this.amount = new Money();
    }
}