import React, { Component } from "react";
import ReactSVG from "react-svg";
import moment from "moment";

import Loader from "../ui/components/Loader";

import ApplicationsContainer from "../../containers//task/ApplicationsContainer";
import ChecksContainer from "../../containers/task/ChecksContainer";
import UpdateShiftContainer from "../../containers/task/crud/UpdateShiftContainer";

export default class TaskShiftView extends Component {
    state = {
        tab: "applications",
    };

    render() {
        const { t, addToStack, shiftIsFetching, shift } = this.props;
        const { tab } = this.state;

        if (shiftIsFetching || !shift) {
            return <Loader />;
        }
        return (
            <div className="shift-view">
                <div className="flex-container justify-between">
                    <div>
                        <h1 className="">{shift.task.title}</h1>
                        <h2 className="">
                            {moment(shift.startsAt).format("DD-MM-YYYY")} |{" "}
                            <span className="time">
                                {moment(shift.startsAt).format("HH:mm")} -{" "}
                                {moment(shift.endsAt).format("HH:mm")}
                            </span>
                        </h2>
                    </div>

                    <div className="action-icons self-center">
                        {shift.applications.length < 1 && (
                            <div
                                className="action-icon"
                                onClick={(e) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: t("task.shift.update"),
                                        component: <UpdateShiftContainer targetShift={shift} />,
                                    });
                                }}
                            >
                                <ReactSVG src="/icons/pencil.svg" />
                            </div>
                        )}
                    </div>
                </div>

                {shift.task.descriptionParts.map((part) => (
                    <div className="description-part">
                        <h2>{part.header}</h2>
                        <p>{part.body}</p>
                    </div>
                ))}
                <div className="keywords">
                    {shift.task.keywords
                        .sort((a, b) => a.header - b.header)
                        .map((keyword) => (
                            <div className="keyword">
                                <h2>{keyword.header}</h2>
                                <div className="tags">
                                    {keyword.tags.map((tag) => (
                                        <div className="tag">{tag.name}</div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
                <div className="tabs">
                    <ul>
                        <li
                            className={"tab " + (tab === "applications" ? "active" : "")}
                            onClick={() => {
                                this.setState({ tab: "applications" });
                            }}
                        >
                            {t("task.shift.applications.header")}
                        </li>
                        <li
                            className={"tab " + (tab === "checkInOut" ? "active" : "")}
                            onClick={() => {
                                this.setState({ tab: "checkInOut" });
                            }}
                        >
                            {t("dashboard.checkInOut")}
                        </li>
                    </ul>
                </div>
                <section
                    className={`activity${tab === "applications" ? " active" : ""}`}
                    style={{ marginTop: 20 }}
                >
                    {tab === "applications" && (
                        <ApplicationsContainer
                            {...this.props}
                            applications={shift.applications}
                            task={shift.task}
                        />
                    )}
                </section>
                <section
                    className={`activity${tab === "checkInOut" ? " active" : ""}`}
                    style={{ marginTop: 20 }}
                >
                    {tab === "checkInOut" && <ChecksContainer {...this.props} targetShift={shift} />}
                </section>
            </div>
        );
    }
}
