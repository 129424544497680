import React, { Component } from "react";
import moment from "moment";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import Task from "../../../models/task/Task";

import TaskForm from "./TaskForm";

export default class CreateTaskPage extends Component {
    componentDidMount() {
        const { onChange, getPersonalBusinesses } = this.props;

        getPersonalBusinesses();

        const newTask = new Task();
        onChange("formTask", newTask);
    }

    render() {
        const { t, isFetchingCurrencies, formTask, taskIsFetching, createTask } = this.props;

        if (isFetchingCurrencies || !formTask || taskIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.new")}</h1>
                <TaskForm
                    {...this.props}
                    onSubmit={(formTask) => {
                        const task = { ...formTask };
                        const shifts = [...task.shifts];
                        shifts.forEach((shift, index) => {
                            const currentShift = { ...shift };
                            currentShift.startsAt = moment(
                                `${currentShift.date} ${currentShift.startTime}`,
                                "DD/MM/YYYY HH:mm"
                            );
                            currentShift.endsAt = moment(
                                `${currentShift.date} ${currentShift.endTime}`,
                                "DD/MM/YYYY HH:mm"
                            );
                            shifts[index] = currentShift;
                        });
                        task.shifts = shifts;
                        createTask(task);
                    }}
                />
            </AsideView>
        );
    }
}
