import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateUserPage from "../../../components/user/crud/CreateUserPage.js";

import * as userActions from "../../../state/actions/userActions";
import * as authActions from "../../../state/actions/authActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreateUserContainer extends Component {
    render() {
        const { changeValue, createUser, popStack, ...props } = this.props;

        return (
            <CreateUserPage
                {...props}
                onChange={changeValue}
                createUser={(user) =>
                    createUser(user).then((response) => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.auth,
        ...state.user,
};
}

function mapDispatchToProps(dispatch) {
    return {
        createUser: (user) => dispatch(userActions.createUser(user)),
        inviteUser: (user) => dispatch(userActions.inviteUser(user)),
        changeValue: (name, value) => dispatch(userActions.changeValue(name, value)),

        getRoles: (from, amount) => dispatch(authActions.getRoles(from, amount)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateUserContainer));
