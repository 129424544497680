import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'underscore';
import Skeleton from 'react-loading-skeleton';

import { sluggify } from '../../utils/stringUtils';
import { intToDouble } from '../../utils/moneyUtils';

import NoData from '../ui/components/NoData';

export default class SearchShifts extends Component {

    state = {
        hasTriedLoading: false,
        loadedSearchParams: null,
    };

    handleScroll = () => {
        const { shiftsTotalResults, shiftsLoaded, shiftsIsFetching } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (!shiftsIsFetching && shiftsTotalResults > shiftsLoaded) {
                this.fetch(shiftsLoaded, 20);
            }
        }
    };

    fetch = (offset, amount) => {
        const { searchParams, getShifts, searchShifts } = this.props;
        searchParams ? searchShifts(searchParams, searchParams.fromDate, offset, amount) : getShifts(offset, amount);
    };

    _groupByDay = (shifts) => _.groupBy(shifts, (shift) => moment(shift.startsAt).format('DD MMMM'));

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        this.fetch(0, 20);

        this.setState({
            hasTriedLoading: true,
        });
    }

    componentDidUpdate() {
        const { searchParams } = this.props;
        const { loadedSearchParams } = this.state;
        if (loadedSearchParams !== searchParams) {
            this.fetch(0, 20);
            this.setState({
                loadedSearchParams: searchParams,
            });
        }
    }

    render() {
        const { hasTriedLoading } = this.state;
        const { t, shiftsIsFetching, shifts, user } = this.props;
        const groupedShifts = Object.entries(this._groupByDay(shifts));

        const loadingView = (
            <>
                <Skeleton height={35} />
                <div className="shifts">
                    {[0, 1, 2].map((i) => (
                        <div className="empty-task" key={i}>
                            <p>
                                <Skeleton height={150} />
                            </p>
                            <Skeleton count={3} />
                        </div>
                    ))}
                </div>
                <div className="shifts">
                    {[0, 1, 2].map((i) => (
                        <div className="empty-task" key={i}>
                            <p>
                                <Skeleton height={150} />
                            </p>
                            <Skeleton count={3} />
                        </div>
                    ))}
                </div>
            </>
        );

        if (hasTriedLoading) {
            if (shifts.length > 0) {
                return (
                    <div className="shifts-overview">
                        {groupedShifts.map((group) => (
                            <div key={group[0]} className="shift-date-group">
                                <div className="group-date">{group[0]}</div>
                                <div className="shifts">
                                    {group[1].map((shift) => {
                                        const task = shift.task;
                                        const { address } = task.location;

                                        return (
                                            <Link
                                                className="list-task"
                                                to={`/task/${task.id}/${shift.id}/${sluggify(task.title)}`}
                                                key={task.id}
                                            >
                                                <img
                                                    src={
                                                        task.banners.length > 0
                                                            ? task.banners[0].url
                                                            : '/images/task-banner.svg'
                                                    }
                                                    alt="task banner"
                                                />
                                                <div className="top">
                                                    <div className=""></div>
                                                    <div className="title">{task.title}</div>
                                                    <div className="price">
                                                        {`${shift.payout.money.currency.sign}${intToDouble(
                                                            shift.payout.money.amount,
                                                            2,
                                                            user.language.decimalDelimiter,
                                                            user.language.thousandDelimiter
                                                        )} ${t('p/h')}`}
                                                    </div>
                                                    <div className="address">{`${address.street} ${
                                                        address.houseNumber || ''
                                                    }${address.houseNumberSuffix || ''}, ${address.zipCode} ${
                                                        address.city || ''
                                                    } `}</div>

                                                    <div className="time">
                                                        {moment(shift.startsAt).format('HH:mm')} -{' '}
                                                        {moment(shift.endsAt).format('HH:mm')}
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                        {shiftsIsFetching ? loadingView : null}
                    </div>
                );
            }

            if (shiftsIsFetching) return <div className="shifts-overview">{loadingView}</div>;

            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('tasks.notFound')}</NoData>
                </div>
            );
        }

        return <div className="shifts-overview">{loadingView}</div>;
    }
}
