import React, { Component } from "react";

export default class Welcome extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { match, platform } = this.props; 
        const { params } = match;
        return (
            <div>
                <section className="">
                    <div className="container">
                        <div className="wrapper top">
                            <div className="flex-container">
                                <div className="half">
                                    <h2 className="top">Welkom {params.user}!</h2>
                                    <h1>{platform.name} is blij om je erbij te hebben!</h1>
                                    <h1>Vanaf hier nemen we je mee.</h1>
                                    <p>
                                        Je hebt een email ontvangen, hierin is een link die bevestigd aan ons
                                        dat uw email ook werkelijk bestaat. Dit is erg belangrijk om bij je
                                        account te kunnen komen later. Hierna kun je in enkele stappen meteen
                                        aan de slag!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="how without-block">
                    <div className="container">
                        <div className="wrapper">
                            <h2 className="top">Volgende stappen</h2>
                            <h1>Nog enkele stappen en je kunt aan de slag.</h1>
                            <div className="steps ">
                                <div className="step">
                                    <div className="counter">1</div>
                                    <h1>Verifieer je email</h1>
                                    <div className="text">
                                        Om te zorgen dat jij altijd weer bij je account kunt komen, moeten wij
                                        zeker zijn dat je het juiste email hebt opgegeven.
                                    </div>
                                </div>
                                <div className="step">
                                    <div className="counter">2</div>
                                    <h1>Vul gegevens in</h1>
                                    <div className="text">
                                        Je zult ons wat meer informatie moeten geven om werkelijk aan de slag
                                        te kunnen. Denk aan je bankrekening nummer, je volledige naam en je
                                        adres.
                                    </div>
                                </div>
                                <div className="step">
                                    <div className="counter">3</div>
                                    <h1>Onderteken het contract</h1>
                                    <div className="text">
                                        Je hebt 2 keuzes. Teken bij ons een contract, of ga aan de slag via
                                        ons als zelfstandige.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
