import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SettingsPage from "../../components/settings/SettingsPage";
import * as authActions from "../../state/actions/authActions";
import * as settingsActions from "../../state/actions/settingsActions";
import * as platformActions from "../../state/actions/platformActions";

class SettingsContainer extends Component {
    componentDidMount() {
        const { getSettings } = this.props;

        getSettings();
    }

    render() {
        const {
            getSettings,
            updateSettings,
            updateUser,
            updatePassword,
            getSessions,
            logoutAllOthers,
            changeValue,
            ...props
        } = this.props;

        const { t } = this.props;

        return (
            <SettingsPage
                {...props}
                onChange={changeValue}
                getSettings={() => getSettings()}
                updateSettings={(settings) => updateSettings(t, settings)}
                updateUser={(user) => updateUser(t, user)}
                updatePassword={(currentPassword, newPassword, repeatPassword) =>
                    updatePassword(t, currentPassword, newPassword, repeatPassword)
                }
                getSessions={() => getSessions()}
                logoutAllOthers={() => logoutAllOthers()}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        error: state.auth.error,
        isFetchingUser: state.auth.isFetchingUser,

        platform: state.platform.platform,

        isFetchingSessions: state.auth.isFetchingSessions,
        session: state.auth.session,
        sessions: state.auth.sessions,

        languages: state.platform.languages,
        languagesIsFetching: state.platform.languagesIsFetching,
        ...state.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(settingsActions.changeValue(name, value)),
        getSettings: () => dispatch(settingsActions.getSettings()),
        updateSettings: (t, settings) =>
            dispatch(settingsActions.updateSettings(t, settings)),

        updateUser: (t, user) => dispatch(authActions.update(t, user)),
        updatePassword: (t, currentPassword, newPassword, repeatPassword) =>
            dispatch(authActions.updatePassword(t, currentPassword, newPassword, repeatPassword)),

        getSessions: (from, amount) => dispatch(authActions.getSessions(from, amount)),
        logoutAllOthers: () => dispatch(authActions.logoutAllOthers()),

        getLanguages: () => dispatch(platformActions.getLanguages()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(SettingsContainer));
