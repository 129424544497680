import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import Education from "../../../models/user/profile/cv/Education";
import EducationForm from "./EducationForm";

export default class CreateEducationPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange("formEducation", new Education());
    }

    render() {
        const { t, formEducation, isFetchingEducation, createEducation, popStack } = this.props;

        if (!formEducation || isFetchingEducation) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("cv.education.new.header")}</h1>
                <EducationForm
                    {...this.props}
                    onSubmit={(education) =>
                        createEducation(education).then(() => {
                            popStack();
                        })
                    }
                />
            </AsideView>
        );
    }
}
