import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreateRolePage from '../../../components/role/crud/CreateRolePage.js';

import * as authActions from '../../../state/actions/authActions';
import * as navigationActions from '../../../state/actions/navigationActions';
import * as platformActions from '../../../state/actions/platformActions';

class CreateRoleContainer extends Component {
    componentDidMount() {
        const { getPermissions } = this.props;
        getPermissions();
    }

    render() {
        const { changeValue, createRole, popStack, ...props } = this.props;

        return (
            <CreateRolePage
                {...props}
                onChange={changeValue}
                createRole={(role) =>
                    createRole(role).then(() => {
                        popStack && popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        permissions: state.platform.permissions,
        ...state.auth,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createRole: (role) => dispatch(authActions.createRole(role)),
        changeValue: (name, value) => dispatch(authActions.changeValue(name, value)),

        getPermissions: () => dispatch(platformActions.getPermissions()),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateRoleContainer));
