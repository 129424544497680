import { combineReducers } from "redux";

import auth from "./auth";
import article from "./article";
import user from "./user";
import notification from "./notification";
import profile from "./profile";
import chat from "./chat";
import messageTemplate from "./messageTemplate";
import task from "./task";
import wallet from "./wallet";
import contract from "./contract";
import platform from "./platform";
import category from "./category";
import support from "./support";
import business from "./business";
import rating from "./rating";
import navigation from "./navigation";
import settings from "./settings";

export default combineReducers({
    auth,
    article,
    user,
    notification,
    profile,
    chat,
    messageTemplate,
    task,
    wallet,
    contract,
    platform,
    category,
    support,
    business,
    rating,
    navigation,
    settings,
});
