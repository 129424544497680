import React, { Component } from 'react';
import styled from 'styled-components';

export default class Rating extends Component {

    render() {
        const { rating, children, small } = this.props;
        const Stars = styled.div`
            --rating: ${rating}
        `;

        return (
            <div className={`stars-rating${small ? " small": ""}`}>
                {rating > 0 ? (rating.toFixed(2) + '/5') : null}
                <Stars className="stars" />
                <span>{children}</span>
            </div>
        );
    }
}