import { settingsConstants } from "../constants";
import { authConstants } from "../constants";

const initialState = {
    generalSettingsIsFetching: false,
    settingsIsFetching: false,
    settings: null,
};

export default function settings(state = initialState, action) {
    switch (action.type) {
        case settingsConstants.GET_SETTINGS_STARTED:
            return { ...state, settingsIsFetching: true };
        case settingsConstants.GET_SETTINGS_FAILURE:
            return { ...state, settingsIsFetching: false, message: action.message || "" };
        case settingsConstants.GET_SETTINGS_SUCCESS:
            return { ...state, settingsIsFetching: false, settings: action.settings };

        case authConstants.UPDATE_USER_STARTED:
            return { ...state, generalSettingsIsFetching: true };
        case authConstants.UPDATE_USER_FAILURE:
            return { ...state, generalSettingsIsFetching: false, message: action.message || "" };
        case authConstants.UPDATE_USER_SUCCESS:
            const newSettings = { ...state.settings };
            newSettings.user = action.user;
            return { ...state, generalSettingsIsFetching: false, settings: newSettings };

        case settingsConstants.SETTINGS_ON_CHANGE:
            return {
                ...state,
                error: "",
                [action.name]: action.value,
            };
        default:
            return state;
    }
}
