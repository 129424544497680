import React, { Component } from "react";
import moment from "moment";

import Loader from "../../ui/components/Loader";
import NoData from "../../ui/components/NoData";

import IDEAL from "../source/IDEAL";

import { intToDouble } from "../../../utils/moneyUtils";

export default class TransactionView extends Component {
    componentDidMount() {
        const { match, getTransaction } = this.props;
        if (match && match.params.id) getTransaction(match.params.id);
    }

    plusOrMinusTransaction(transaction) {
        return transaction.amount.amount >= 0 ? "plus" : "minus";
    }

    render() {
        const { t, transaction, isFetchingTransaction, user } = this.props;

        if (!isFetchingTransaction) {
            if (transaction) {
                const source = () => {
                    if (transaction.source) {
                        switch (transaction.source.type) {
                            default:
                            case "ideal":
                                return <IDEAL source={transaction.source} />;
                        }
                    }
                    return null;
                };
                return (
                    <div className="transaction-view">
                        <h1 className="no-margin-top center">{t("wallet.transaction.header")}<span className={transaction.status.toLowerCase()}>{transaction.status}</span></h1>
                        <h3 className="no-margin-bottom no-margin-top center">#{transaction.id}</h3>
                        <div className="transaction">
                            <div className={`center amount ${this.plusOrMinusTransaction(transaction)}`}>
                                {transaction.amount.currency.sign}
                                {intToDouble(
                                    transaction.amount.amount,
                                    2,
                                    user.language.decimalDelimiter,
                                    user.language.thousandDelimiter
                                )}
                            </div>
                            <div className="center date">
                                {moment.utc(transaction.date).local().format("DD/MM/YY HH:mm")}
                            </div>
                            <div className="flex-container justify-center sources">{source()}</div>
                        </div>
                    </div>
                );
            }
            return <NoData>{t("transaction.notFound")}</NoData>;
        }
        return <Loader />;
    }
}
