import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import SimpleReactValidator from 'simple-react-validator';

import PlatformHostName from '../../../models/platform/PlatformHostName';

import { activateInputs } from '../../../utils/formUtils';
import { truncate } from '../../../utils/stringUtils';

import UploadButton from '../../ui/components/UploadButton';
import Switch from '../../ui/components/Switch';

export default class CreatePlatform extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {
            logo: '/images/business.svg',
            favicon: '/images/business.svg',
            notificationLogo: '/images/business.svg',
            fullLogo: '/images/business.svg',
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formPlatform } = this.props;

        onSubmit && onSubmit(formPlatform);
    }

    render() {
        const { onChange, t, formPlatform, uploadFile } = this.props;
        const { logo, favicon, notificationLogo, fullLogo } = this.state;

        return (
            <div className="step-form" key={formPlatform.id}>
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group no-margin-top flex-container align-items-start">
                            <img
                                className=""
                                style={{ maxWidth: 200, maxHeight: 80 }}
                                src={formPlatform.logo && formPlatform.logo.url ? formPlatform.logo.url : logo}
                                alt=""
                            />
                            <UploadButton
                                className="self-center"
                                loading={formPlatform.logo ? formPlatform.logo.loading : false}
                                uploaded={formPlatform.logo ? !formPlatform.logo.loading : false}
                                value={formPlatform.logo ? truncate(formPlatform.logo.originalName, 15) : null}
                                onReset={() => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.logo = null;

                                    onChange('formPlatform', newPlatform);
                                }}
                                onChange={(event) => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.logo = {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    };

                                    onChange('formPlatform', newPlatform);

                                    uploadFile(event.path[0].files[0], 'platform')
                                        .then((response) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.logo = {
                                                ...response.file,
                                                loading: false,
                                            };

                                            onChange('formPlatform', newPlatform);
                                        })
                                        .catch(() => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.logo = null;

                                            onChange('formPlatform', newPlatform);
                                        });
                                }}
                            >
                                {t('form.label.chooseLogo')}
                            </UploadButton>
                        </div>
                        <div className="input-group flex-container align-items-start">
                            <img
                                className=""
                                style={{ maxWidth: 200, maxHeight: 80 }}
                                src={
                                    formPlatform.favicon && formPlatform.favicon.url
                                        ? formPlatform.favicon.url
                                        : favicon
                                }
                                alt=""
                            />
                            <UploadButton
                                className="self-center"
                                loading={formPlatform.favicon ? formPlatform.favicon.loading : false}
                                uploaded={formPlatform.favicon ? !formPlatform.favicon.loading : false}
                                value={formPlatform.favicon ? truncate(formPlatform.favicon.originalName, 15) : null}
                                onReset={() => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.favicon = null;

                                    onChange('formPlatform', newPlatform);
                                }}
                                onChange={(event) => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.favicon = {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    };

                                    onChange('formPlatform', newPlatform);

                                    uploadFile(event.path[0].files[0], 'platform')
                                        .then((response) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.favicon = {
                                                ...response.file,
                                                loading: false,
                                            };

                                            onChange('formPlatform', newPlatform);
                                        })
                                        .catch(() => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.favicon = null;

                                            onChange('formPlatform', newPlatform);
                                        });
                                }}
                            >
                                {t('form.label.chooseFaviconLogo')}
                            </UploadButton>
                        </div>
                        <div className="input-group flex-container align-items-start">
                            <img
                                className=""
                                style={{ maxWidth: 200, maxHeight: 80 }}
                                src={
                                    formPlatform.notificationLogo && formPlatform.notificationLogo.url
                                        ? formPlatform.notificationLogo.url
                                        : notificationLogo
                                }
                                alt=""
                            />
                            <UploadButton
                                className="self-center"
                                loading={formPlatform.notificationLogo ? formPlatform.notificationLogo.loading : false}
                                uploaded={
                                    formPlatform.notificationLogo ? !formPlatform.notificationLogo.loading : false
                                }
                                value={
                                    formPlatform.notificationLogo
                                        ? truncate(formPlatform.notificationLogo.originalName, 15)
                                        : null
                                }
                                onReset={() => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.notificationLogo = null;

                                    onChange('formPlatform', newPlatform);
                                }}
                                onChange={(event) => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.notificationLogo = {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    };

                                    onChange('formPlatform', newPlatform);

                                    uploadFile(event.path[0].files[0], 'platform')
                                        .then((response) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.notificationLogo = {
                                                ...response.file,
                                                loading: false,
                                            };

                                            onChange('formPlatform', newPlatform);
                                        })
                                        .catch(() => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.notificationLogo = null;

                                            onChange('formPlatform', newPlatform);
                                        });
                                }}
                            >
                                {t('form.label.chooseNotificationLogo')}
                            </UploadButton>
                        </div>
                        <div className="input-group flex-container align-items-start">
                            <img
                                className=""
                                style={{ maxWidth: 200, maxHeight: 80 }}
                                src={
                                    formPlatform.fullLogo && formPlatform.fullLogo.url
                                        ? formPlatform.fullLogo.url
                                        : fullLogo
                                }
                                alt=""
                            />
                            <UploadButton
                                className="self-center"
                                loading={formPlatform.fullLogo ? formPlatform.fullLogo.loading : false}
                                uploaded={formPlatform.fullLogo ? !formPlatform.fullLogo.loading : false}
                                value={formPlatform.fullLogo ? truncate(formPlatform.fullLogo.originalName, 15) : null}
                                onReset={() => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.fullLogo = null;

                                    onChange('formPlatform', newPlatform);
                                }}
                                onChange={(event) => {
                                    const newPlatform = { ...formPlatform };
                                    newPlatform.fullLogo = {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    };

                                    onChange('formPlatform', newPlatform);

                                    uploadFile(event.path[0].files[0], 'platform')
                                        .then((response) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.fullLogo = {
                                                ...response.file,
                                                loading: false,
                                            };

                                            onChange('formPlatform', newPlatform);
                                        })
                                        .catch(() => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.fullLogo = null;

                                            onChange('formPlatform', newPlatform);
                                        });
                                }}
                            >
                                {t('form.label.chooseFullLogo')}
                            </UploadButton>
                        </div>
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formPlatform.name}
                                    onChange={(event) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.name = event.target.value;

                                        onChange('formPlatform', newPlatform, event);
                                    }}
                                />
                                <label>{t('form.label.name')}*</label>
                            </div>
                            {this.validator.message(t('form.label.name'), formPlatform.name, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <CreatableSelect
                                    placeholder={`${t('form.label.hostNames')}*`}
                                    isClearable
                                    isMulti={true}
                                    noOptionsMessage={() => t('noOption')}
                                    formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                    }}
                                    onChange={(e) => {
                                        const newPlatform = { ...formPlatform };
                                        if (e && e.length) {
                                            newPlatform.hostNames = e.map((e) => {
                                                const newHostName = new PlatformHostName();
                                                newHostName.id = e.value;
                                                newHostName.hostName = e.label;
                                                return newHostName;
                                            });
                                        } else {
                                            newPlatform.hostNames = [];
                                        }

                                        onChange('formPlatform', newPlatform);
                                    }}
                                    onCreateOption={(e) => {
                                        const newPlatform = { ...formPlatform };
                                        const hostNames = [...newPlatform.hostNames];

                                        const newHostName = new PlatformHostName();
                                        newHostName.hostName = e;

                                        hostNames.push(newHostName);
                                        newPlatform.hostNames = hostNames;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                    value={
                                        formPlatform.hostNames.length
                                            ? formPlatform.hostNames.map((host) => ({
                                                  value: host.id,
                                                  label: host.hostName,
                                              }))
                                            : []
                                    }
                                />
                            </div>
                            {this.validator.message(t('form.label.hostNames'), formPlatform.hostNames, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formPlatform.description}
                                    onChange={(event) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.description = event.target.value;

                                        onChange('formPlatform', newPlatform, event);
                                    }}
                                ></textarea>
                                <label>{t('form.label.description')}*</label>
                            </div>
                            {this.validator.message(t('form.label.description'), formPlatform.description, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formPlatform.contactEmail}
                                    onChange={(event) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.contactEmail = event.target.value;

                                        onChange('formPlatform', newPlatform, event);
                                    }}
                                />
                                <label>{t('form.label.contactEmail')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.contactEmail'),
                                formPlatform.contactEmail,
                                'required'
                            )}
                        </div>
                        <div className="input-group no-margin-top">
                            <div className="input-group half">
                                <div>{t('form.label.hasToHaveFunds')}</div>
                                <Switch
                                    checked={formPlatform.hasToHaveFunds}
                                    onChange={(e, newState) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.hasToHaveFunds = newState;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-group no-margin-top">
                            <div className="input-group half">
                                <div>{t('form.label.businessOnly')}</div>
                                <Switch
                                    checked={formPlatform.businessOnly}
                                    onChange={(e, newState) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.businessOnly = newState;
                                        newPlatform.createBusinessAtProfile = newState;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                />
                            </div>
                            <div className="input-group half">
                                <div>{t('form.label.createBusinessAtProfile')}</div>
                                <Switch
                                    checked={formPlatform.createBusinessAtProfile}
                                    disabled={formPlatform.businessOnly}
                                    onChange={(e, newState) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.createBusinessAtProfile = newState;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-group no-margin-top">
                            <div className="input-group half">
                                <div>{t('form.label.hasWebsite')}</div>
                                <Switch
                                    checked={formPlatform.hasWebsite}
                                    onChange={(e, newState) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.hasWebsite = newState;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                />
                            </div>
                            <div className="input-group half">
                                <div>{t('form.label.canRegister')}</div>
                                <Switch
                                    checked={formPlatform.canRegister}
                                    onChange={(e, newState) => {
                                        const newPlatform = { ...formPlatform };
                                        newPlatform.canRegister = newState;

                                        onChange('formPlatform', newPlatform);
                                    }}
                                />
                            </div>
                        </div>
                        {formPlatform.hasWebsite && (
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.websiteUrl}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.websiteUrl = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.websiteUrl')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.websiteUrl'),
                                    formPlatform.websiteUrl,
                                    'required'
                                )}
                            </div>
                        )}
                        <div className="input-group">
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="color"
                                        value={formPlatform.primaryColor}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.primaryColor = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.primaryColor')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.primaryColor'),
                                    formPlatform.primaryColor,
                                    'required'
                                )}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="color"
                                        value={formPlatform.secondaryColor}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.secondaryColor = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.secondaryColor')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.secondaryColor'),
                                    formPlatform.secondaryColor,
                                    'required'
                                )}
                            </div>
                        </div>
                        <div className="input-group more">
                            <h1>{t('keys.stripe')}</h1>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.stripePublicKey}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.stripePublicKey = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.publicKey')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.publicKey'),
                                    formPlatform.stripePublicKey,
                                    'string'
                                )}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.stripeKey}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.stripeKey = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.secretKey')}*</label>
                                </div>
                                {this.validator.message(t('form.label.secretKey'), formPlatform.stripeKey, 'string')}
                            </div>
                        </div>
                        <div className="input-group more">
                            <h1>{t('keys.aws')}</h1>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.awsKey}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.awsKey = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.publicKey')}*</label>
                                </div>
                                {this.validator.message(t('form.label.publicKey'), formPlatform.awsKey, 'string')}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.awsSecret}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.awsSecret = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.secretKey')}*</label>
                                </div>
                                {this.validator.message(t('form.label.secretKey'), formPlatform.awsSecret, 'string')}
                            </div>
                        </div>
                        <div className="input-group more">
                            <h1>{t('keys.googleApi')}</h1>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.googleApiKey}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.googleApiKey = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.publicKey')}*</label>
                                </div>
                                {this.validator.message(t('form.label.publicKey'), formPlatform.googleApiKey, 'string')}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.googleApiSecret}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.googleApiSecret = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.secretKey')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.secretKey'),
                                    formPlatform.googleApiSecret,
                                    'string'
                                )}
                            </div>
                        </div>
                        <div className="input-group more">
                            <h1>{t('keys.googleCaptcha')}</h1>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.googleCaptchaKey}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.googleCaptchaKey = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.publicKey')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.publicKey'),
                                    formPlatform.googleCaptchaKey,
                                    'string'
                                )}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formPlatform.googleCaptchaSecret}
                                        onChange={(event) => {
                                            const newPlatform = { ...formPlatform };
                                            newPlatform.googleCaptchaSecret = event.target.value;

                                            onChange('formPlatform', newPlatform, event);
                                        }}
                                    />
                                    <label>{t('form.label.secretKey')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.secretKey'),
                                    formPlatform.googleCaptchaSecret,
                                    'string'
                                )}
                            </div>
                        </div>
                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t('form.save')}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
