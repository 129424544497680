import { categoryConstants } from "../constants";
import * as categoryApi from "../../api/categoryApi";

export const changeValue = (name, value) => ({
    type: categoryConstants.CATEGORY_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching category
 */
export const startGetCategory = () => ({
    type: categoryConstants.GET_CATEGORY_STARTED,
});

export const getCategorySuccess = (result) => ({
    type: categoryConstants.GET_CATEGORY_SUCCESS,
    ...result,
});

export const getCategoryFailure = (error) => ({
    type: categoryConstants.GET_CATEGORY_FAILURE,
    ...error,
});

export const getCategory = () => {
    return (dispatch) => {
        dispatch(startGetCategory());

        const promise = categoryApi.get();

        promise
            .then((result) => {
                dispatch(getCategorySuccess(result));
            })
            .catch((error) => {
                dispatch(getCategoryFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching categories
 */
export const startGetCategories = () => ({
    type: categoryConstants.GET_CATEGORIES_STARTED,
});

export const getCategoriesSuccess = (result, from) => ({
    type: categoryConstants.GET_CATEGORIES_SUCCESS,
    ...result,
});

export const getCategoriesFailure = (error) => ({
    type: categoryConstants.GET_CATEGORIES_FAILURE,
    ...error,
});

export const getCategories = () => {
    return (dispatch) => {
        dispatch(startGetCategories());

        const promise = categoryApi.all();

        promise
            .then((result) => {
                dispatch(getCategoriesSuccess(result));
            })
            .catch((error) => {
                dispatch(getCategoriesFailure(error));
            });

        return promise;
    };
};

export const searchCategories = (searchParameters) => {
    return (dispatch) => {
        dispatch(startGetCategories());

        const promise = categoryApi.search(searchParameters);

        promise
            .then((result) => {
                dispatch(getCategoriesSuccess(result));
            })
            .catch((error) => {
                dispatch(getCategoriesFailure(error));
            });

        return promise;
    };
};

/**
 * Creating category
 */
export const startCreateCategory = () => ({
    type: categoryConstants.CREATE_CATEGORY_STARTED,
});

export const createCategorySuccess = (result) => ({
    type: categoryConstants.CREATE_CATEGORY_SUCCESS,
    ...result,
});

export const createCategoryFailure = (error) => ({
    type: categoryConstants.CREATE_CATEGORY_FAILURE,
    ...error,
});

export const createCategory = (category) => {
    return (dispatch) => {
        dispatch(startCreateCategory());

        const promise = categoryApi.create(category);

        promise
            .then((result) => {
                dispatch(createCategorySuccess(result));
            })
            .catch((error) => {
                dispatch(createCategoryFailure(error));
            });

        return promise;
    };
};

/**
 * Updating category
 */
export const startUpdateCategory = () => ({
    type: categoryConstants.UPDATE_CATEGORY_STARTED,
});

export const updateCategorySuccess = (result) => ({
    type: categoryConstants.UPDATE_CATEGORY_SUCCESS,
    ...result,
});

export const updateCategoryFailure = (error) => ({
    type: categoryConstants.UPDATE_CATEGORY_FAILURE,
    ...error,
});
export const updateCategory = (category) => {
    return (dispatch) => {
        dispatch(startUpdateCategory());

        const promise = categoryApi.update(category);

        promise
            .then((result) => {
                dispatch(updateCategorySuccess(result));
            })
            .catch((error) => {
                dispatch(updateCategoryFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting category
 */
export const startDeleteCategory = () => ({
    type: categoryConstants.DELETE_CATEGORY_STARTED,
});

export const deleteCategorySuccess = (result) => ({
    type: categoryConstants.DELETE_CATEGORY_SUCCESS,
    ...result,
});

export const deleteCategoryFailure = (error) => ({
    type: categoryConstants.DELETE_CATEGORY_FAILURE,
    ...error,
});
export const deleteCategory = (category) => {
    return (dispatch) => {
        dispatch(startDeleteCategory());

        const promise = categoryApi.remove(category);

        promise
            .then((result) => {
                dispatch(deleteCategorySuccess(result));
            })
            .catch((error) => {
                dispatch(deleteCategoryFailure(error));
            });

        return promise;
    };
};