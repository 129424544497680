import React, { Component } from "react";

import TaskDescriptionPart from "../../../models/task/TaskDescriptionPart";

export default class TaskDescriptionsForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    _addDescriptionPart() {
        const { onChange, formTask } = this.props;

        const task = { ...formTask };
        const parts = [...task.descriptionParts];
        parts.push(new TaskDescriptionPart());
        task.descriptionParts = parts;

        onChange("formTask", task);
    }

    render() {
        const { onChange, formTask } = this.props;
        const { descriptionParts } = formTask;

        return (
            <div className="input-container">
                {descriptionParts.map((part, index) => {
                    return (
                        <div className="input-group" key={part.id}>
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={part.body}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        const parts = [...newTask.descriptionParts];

                                        const currentPart = { ...parts[index] };
                                        currentPart.body = event.target.value;

                                        parts[index] = currentPart;

                                        newTask.descriptionParts = parts;

                                        onChange("formTask", newTask, event);
                                    }}
                                ></textarea>
                                <label>{part.header}</label>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
