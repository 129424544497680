import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as taskActions from '../../../state/actions/taskActions';
import * as navigationActions from '../../../state/actions/navigationActions';
import * as walletActions from '../../../state/actions/walletActions';

import CreateApplicationPage from '../../../components/task/crud/CreateApplicationPage';

class CreateApplicationContainer extends Component {

    render() {
        const { popStack, changeValue, createApplication, ...props } = this.props;

        return (
            <CreateApplicationPage
                {...props}
                onChange={changeValue}
                createApplication={(application) =>
                    createApplication(application).then(() => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        categories: state.platform.categories,
        currencies: state.wallet.currencies,
        isFetchingCurrencies: state.wallet.isFetchingCurrencies,
        businesses: state.business.personalBusinesses,
        formApplication: state.task.formApplication,
        isFetchingApplication: state.task.isFetchingApplication,
        task: state.task.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        createApplication: (application) => dispatch(taskActions.createApplication(application)),

        hasFunds: (amount) => dispatch(walletActions.hasFunds(amount)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateApplicationContainer));
