import React, { Component } from "react";

export default class FirstTime extends Component {
    _renderImage() {
        const { image } = this.props;
        return image ? <img src={image} alt="" /> : null;
    }

    render() {
        const { className, children, linearGradient } = this.props;

        return (
            <div className="aside-view">
                <div className="wrapper top">
                    <div className={`aside-view-content container ${className}`}>{children}</div>
                    <div className={linearGradient ? "aside-view-image linear-gradient" : "aside-view-image"}>
                        {this._renderImage()}
                    </div>
                </div>
            </div>
        );
    }
}
