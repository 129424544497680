import firebase from 'firebase/app';
import '@firebase/messaging';

const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyA7AEKh3f9ogRVw2xLu06-0OiW1soCz2sM',
    authDomain: 'vynwork-bv.firebaseapp.com',
    projectId: 'vynwork-bv',
    storageBucket: 'vynwork-bv.appspot.com',
    messagingSenderId: '978935929254',
    appId: '1:978935929254:web:67f684b598b9286ebd9767',
    measurementId: 'G-63JWPEHQZV',
});

const messaging = firebase.messaging.isSupported() ? firebaseApp.messaging() : null
export { messaging };

