import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import moment from 'moment';

import NoData from '../ui/components/NoData';
import Loader from '../ui/components/Loader';
import { activateInputs } from '../../utils/formUtils';

class ChatsList extends Component {
    constructor(props) {
        super(props);

        this.searchTimeout = null;
        this.searchRef = React.createRef();

        this.state = {
            searchQuery: '',
            searchIsFocused: false,
            searchCaretPosition: {
                start: 0,
                end: 0,
            },
        };
    }

    componentDidMount() {
        activateInputs();
    }

    getPreviewMessage(chat) {
        const { t } = this.props;
        const latestMessage = chat.latest ? chat.latest.message : t("chat.messages.notFound");
        return latestMessage.length < 45 ? latestMessage : `${latestMessage.substr(0, 45)}...`;
    }

    onScroll(e) {
        const { getChats, searchChats, chatsLoaded, chatsTotalResults } = this.props;
        const { searchQuery } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight - 50;
        if (bottom && chatsLoaded < chatsTotalResults) {
            searchQuery
                ? searchChats({ query: searchQuery }, chatsLoaded, chatsLoaded + 20)
                : getChats(chatsLoaded, chatsLoaded + 20);
        }
    }

    onSearchChangeHandler(e) {
        this.setState({
            searchCaretPosition: {
                start: e.currentTarget.selectionStart,
                end: e.currentTarget.selectionEnd,
            },
        });
    }

    onSearchChange(searchQuery) {
        const { searchChats, getChats } = this.props;
        searchQuery ? searchChats({ query: searchQuery }, 0, 20) : getChats(0, 20);
    }

    _renderChats() {
        const { t, i18n, chatsIsFetching, chats, activeChat, openChat } = this.props;

        if (chatsIsFetching) {
            return <Loader />;
        }

        if (!chatsIsFetching || chats.length > 0) {
            if (chats.length > 0) {
                return (
                    <div className="chats scrollable" onScroll={(e) => this.onScroll(e)}>
                        {chats.map((chat) => (
                            <div
                                key={chat.id}
                                className={`item${activeChat && activeChat === chat.id ? ' active' : ''}`}
                                onClick={(e) => openChat(chat)}
                            >
                                {chat.image && <img src={chat.image.url} alt="" />}
                                <div className="left-box">
                                    <div className="name">
                                        {`${chat.title}` || t('chat.noTitle')}
                                        {chat.messagesUnread ? <span>{chat.messagesUnread}</span> : null}
                                    </div>
                                    <div
                                        className="preview"
                                        dangerouslySetInnerHTML={{ __html: this.getPreviewMessage(chat) }}
                                    ></div>
                                    <div className="preview-date">
                                        {chat.latest &&
                                            moment(chat.latest.updatedAt).utc().local().locale(i18n.language).fromNow()}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            }
            return <NoData>{t('chats.notFound')}</NoData>;
        }
        return <Loader />;
    }

    render() {
        const { t } = this.props;
        const { searchQuery } = this.state;

        return (
            <div className="chat-left">
                <div className="flex-container chat-left-header">
                    <div className="one self-center">
                        <h3>{t('chats.header')}</h3>
                    </div>
                    <div className="one flex-container self-center justify-end actions">
                        <div className="action-icons">
                            <Link to="/messages/new" className="action-icon">
                                <ReactSVG src="/icons/edit.svg" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="chats-list">
                    <div className="input-group">
                        <input
                            ref={this.searchRef}
                            type="text"
                            value={searchQuery}
                            placeholder={t('search.placeholder')}
                            onBlur={(e) => {
                                this.setState({
                                    searchIsFocused: false,
                                });
                                this.onSearchChangeHandler(e);
                            }}
                            onChange={(e) => {
                                const searchQuery = e.target.value;
                                this.onSearchChangeHandler(e);
                                this.setState({
                                    searchQuery: searchQuery,
                                    searchIsFocused: true,
                                });
                                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                this.searchTimeout = setTimeout(() => {
                                    this.onSearchChange(searchQuery);
                                }, 400);
                            }}
                        />
                    </div>
                    {this._renderChats()}
                </div>
            </div>
        );
    }
}

export default withTranslation("translation")(ChatsList)