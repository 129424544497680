import React from "react";
import { Link } from "react-router-dom";

export default class Seller extends React.Component {
    constructor(props) {
        super(props);
        this.slideIndex = 1;
        this.slidecount = 0;
        this.state = {
            fetched: false,
        };
    }

    componentDidUpdate() {
        if (this.state.fetched) {
            let seller = this;
            this.slideIndex = 1;
            this.slidecount = document.querySelectorAll(".slide").length;

            document.getElementById("dots").innerHTML = "";
            for (let k = 0; k < this.slidecount; k++) {
                (function () {
                    let j = k + 1;
                    let span = document.createElement("span");
                    span.className = "dot";
                    span.onclick = function () {
                        seller.currentSlide(j);
                    };
                    document.getElementById("dots").append(span);
                })();
            }

            this.showSlides(this.slideIndex);
            window.onkeydown = function (e) {
                if (e.keyCode === "37") {
                    seller.plusSlides(-1);
                }
                if (e.keyCode === "39") {
                    seller.plusSlides(1);
                }
            };
        }
    }

    componentDidMount() {
        this.setState({
            fetched: true,
        });
    }

    plusSlides(n) {
        let m = (this.slideIndex += n);
        this.showSlides(m);
    }

    currentSlide(n) {
        this.slideIndex = n;
        this.showSlides(n);
    }

    showSlides(n) {
        if (this.state.fetched) {
            let i;
            let slides = document.getElementsByClassName("slide");
            let dots = document.getElementsByClassName("dot");
            let prev = document.getElementById("prev");
            let next = document.getElementById("next");

            if (n > slides.length) this.slideIndex = 1;
            if (n < 1) this.slideIndex = slides.length;

            let index = this.slideIndex - 1;
            prev.style.display = this.slideIndex <= 1 ? "none" : "block";
            next.style.display = this.slidecount <= this.slideIndex ? "none" : "block";

            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }

            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" active", "");
            }

            slides[index].style.display = "inline-block";
            dots[index].className += " active";
        }
    }

    render() {
        if (this.state.fetched) {
            return (
                <div>
                    <div className="slideshow-container">
                        <div className="slide fade">
                            <div className="absolute-center">
                                <h2 className="top center">Welkom!</h2>
                                <img
                                    src="/images/Vynwork_letters.png"
                                    className="home_page_img"
                                    alt="Vynwork logo"
                                />
                                <h1>De toekomst van werk</h1>
                            </div>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Wat?</h2>
                            <h1 className="center">
                                Een platform voor flexibel werk, goedkoper én makkelijker!
                            </h1>
                            <div className="full">
                                <div className="flex-container">
                                    <div className="half"></div>
                                    <div className="half text-center">
                                        <h2 className="top center">Voor wie</h2>
                                        <div className="flex-container">
                                            <div className="half">
                                                <div className="ball two">Flexers</div>
                                            </div>
                                            <div className="half">
                                                <div className="ball two">ZZP'ers</div>
                                            </div>
                                        </div>
                                        <div className="flex-container">
                                            <div className="half">
                                                <div className="ball two">Bedrijven</div>
                                            </div>
                                            <div className="half">
                                                <div className="ball two">Particulieren</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half">
                                        <h2 className="top center">Functies</h2>
                                        <div className="flex-container">
                                            <div className="half">
                                                <img src="/images/home/red/tasks.svg" alt="" />
                                                <h2 className="center">Matching</h2>
                                            </div>
                                            <div className="half">
                                                <img src="/images/home/red/network.svg" alt="" />
                                                <h2 className="center">Netwerking</h2>
                                            </div>
                                        </div>
                                        <div className="flex-container">
                                            <div className="half">
                                                <img src="/images/home/red/communication.svg" alt="" />
                                                <h2 className="center">Communicatie</h2>
                                            </div>
                                            <div className="half">
                                                <img src="/images/home/red/payments.svg" alt="" />
                                                <h2 className="center">Betalingen</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half"></div>
                                </div>
                            </div>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Waarom?</h2>
                            <h1 className="center">Het huidige probleem</h1>
                            <div className="flex-container">
                                <div className="half ">
                                    <img src="/images/presentation/not-equal.svg" alt="" />
                                    <h2 className="center">Slechte matching</h2>
                                    <h3 className="center">
                                        {`Er wordt bijna tot geen rekening gehouden welke werknemer bij welke
                                        werkgever past. Hierdoor daalt productiviteit & enthousiasme.`}
                                    </h3>
                                </div>
                                <div className="half ">
                                    <img src="/images/presentation/expensive.svg" alt="" />
                                    <h2 className="center">Duur</h2>
                                    <h3 className="center">
                                        Het kost veel geld om iemand flexibel in te huren
                                    </h3>
                                </div>
                                <div className="half">
                                    <img src="/images/presentation/earning.svg" alt="" />
                                    <h2 className="center">Verdiend weinig</h2>
                                    <h3 className="center">
                                        Als je voor een uitzender werkt, verdien je weinig. Het meeste gaat
                                        naar de uitzendbureaus.
                                    </h3>
                                </div>
                            </div>
                            <div className="flex-container">
                                <div className="half">
                                    <img src="/images/presentation/unclear.svg" alt="" />
                                    <h2 className="center">Onduidelijk</h2>
                                    <h3 className="center">
                                        {`Telefoontjes, e-mails, adressen vinden, communiceren met je
                                        werkgever. Allemaal onduidelijk & moeilijk.`}
                                    </h3>
                                </div>
                                <div className="half">
                                    <img src="/images/presentation/calendar.svg" alt="" />
                                    <h2 className="center">Geen controle</h2>
                                    <h3 className="center">
                                        {`Een uitzendbureau kan niet in je agenda kijken en belt maar gewoon om
                                        te kijken of dat kan. Dat zorgt voor geen controle & geen fijne
                                        werktijden`}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Hoe?</h2>
                            <h1>Vind werk</h1>
                            <h2>{`Registreren -> Profiel instellen -> solliciteren`}</h2>
                            <section className="margin-top">
                                <h1>Vind werknemers</h1>
                                <h2>{`Registreren -> Nieuwe taak aanmaken -> sollicitant accepteren`}</h2>
                            </section>
                            <section className="margin-top">
                                <h1>Resultaat</h1>
                                <div className="flex-container">
                                    <div className="half ">
                                        <img src="/images/why/flexible.svg" alt="" />
                                        <h2 className="center">Flexibel</h2>
                                        <h3 className="center">
                                            Bepaal zelf wanneer je werkt en wij kunnen wél in uw agenda kijken
                                        </h3>
                                    </div>
                                    <div className="half">
                                        <img src="/images/why/clearity.svg" alt="" />
                                        <h2 className="center">Transparant</h2>
                                        <h3 className="center">Alles op 1 plek in 1 simpel overzicht</h3>
                                    </div>
                                    <div className="half switched">
                                        <img src="/images/why/security.svg" alt="" />
                                        <h2 className="center">Veilig</h2>
                                        <h3 className="center">Geen zorgen of u betaald word</h3>
                                    </div>
                                    <div className="half">
                                        <img src="/images/why/coins.svg" alt="" />
                                        <h2 className="center">{`Goedkoop & goed verdienen`}</h2>
                                        <h3 className="center">
                                            {`Door ons business model is het goedkoper voor de werkgever &
                                            verdient de werknemer meer`}
                                        </h3>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Markt</h2>
                            <section className="margin-top">
                                <div className="flex-container">
                                    <div className="one self-center">
                                        <div className="ball one">€21 miljard</div>
                                        <h2 className="center">Flexmarkt in Nederland (2016)</h2>
                                    </div>
                                    <div className="one self-center">
                                        <div className="ball two">1.978.571</div>
                                        <h2 className="center">Werkende in de Flexmarkt (1,9 miljoen)</h2>
                                    </div>
                                    <div className="one self-center">
                                        <div className="ball three">547.000</div>
                                        <h2 className="center">Oproep-/invalkracht</h2>
                                    </div>
                                    <div className="one self-center">
                                        <div className="ball four">277.000</div>
                                        <h2 className="center">Werkend als uitzendkracht</h2>
                                    </div>
                                </div>
                            </section>
                            <section className="margin-top">
                                <h1 className="center">Flexmarkt in de groei</h1>
                                <div className="flex-container">
                                    <div className="one">
                                        <img className="growth" src="/images/presentation/growth.png" alt=""/>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Bedrijfsmodel</h2>
                            <h1 className="center">Vynwork neemt 10% van elke taak die uitgevoerd wordt</h1>
                            <section className="margin-top">
                                <h2 className="top center">Verdere verdienmodellen</h2>
                                <div className="flex-container">
                                    <div className="half">
                                        <div className="ball one">Advertenties</div>
                                        <h3 className="center">
                                            Gerichte advertenties binnen de professionele sector
                                        </h3>
                                    </div>
                                    <div className="half">
                                        <div className="ball one">Data</div>
                                        <h3 className="center">
                                            Data voor bedrijven om hun process te versnellen & te verbeteren
                                        </h3>
                                    </div>
                                    <div className="half">
                                        <div className="ball one">Leningen</div>
                                        <h3 className="center">
                                            Iemand inhuren maar kunt het niet direct betalen? Sluit een kleine
                                            lening af.
                                        </h3>
                                    </div>
                                    <div className="half">
                                        <div className="ball one">Ranking</div>
                                        <h3 className="center">
                                            Betaal om boven aan te staan als werkgever of werknemer.
                                        </h3>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Competitieve voordelen</h2>
                            <section className="margin-top middle">
                                <div className="flex-container">
                                    <div className="one">
                                        <img src="/images/presentation/target.svg" alt="" />
                                        <h2 className="center">makkelijk & snel</h2>
                                    </div>
                                    <div className="one">
                                        <img src="/images/presentation/transaction.svg" alt="" />
                                        <h2 className="center">Online transacties</h2>
                                    </div>
                                    <div className="one">
                                        <img src="/images/presentation/world.svg" alt="" />
                                        <h2 className="center">Geen limitaties & grotere markt</h2>
                                    </div>
                                </div>
                            </section>
                            <section className="margin-top middle">
                                <div className="flex-container">
                                    <div className="one">
                                        <img src="/images/why/coins.svg" alt="" />
                                        <h2 className="center">Goedkoper & meer verdienen</h2>
                                    </div>
                                    <div className="one">
                                        <img src="/images/presentation/office.svg" alt="" />
                                        <h2 className="center">Geen kantoor nodig in elke stad/dorp</h2>
                                    </div>
                                    <div className="one">
                                        <img src="/images/presentation/man.svg" alt="" />
                                        <h2 className="center">Minder eigen personeel</h2>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="slide fade">
                            <h2 className="top">Branding & Marketing</h2>
                            <section className="margin-top">
                                <h1 className="center">Kleuren</h1>
                                <div className="color-palate">
                                    <div className="color one"></div>
                                    <div className="color two"></div>
                                    <div className="color three"></div>
                                    <div className="color four"></div>
                                </div>
                            </section>
                            <section className="margin-top middle">
                                <h1 className="center">Logo</h1>
                                <div className="flex-container">
                                    <div className="one">
                                        <img src="/images/Vynwork_letters.png" alt="" />
                                    </div>
                                    <div className="one">
                                        <img src="/images/Vyn.png" alt="" />
                                    </div>
                                    <div className="one">
                                        <img src="/images/Vynwork.svg" alt="" />
                                    </div>
                                </div>
                            </section>
                            <section className="margin-top middle">
                                <h1 className="center">Domeinen</h1>
                                <div className="flex-container">
                                    <div className="one">
                                        <h2 className="center">Vynwork.com</h2>
                                    </div>
                                    <div className="one">
                                        <h2 className="center">Vynwork.nl</h2>
                                    </div>
                                    <div className="one">
                                        <h2 className="center">Vyn.work</h2>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="slide fade">
                            <div className="absolute-center">
                                <img src="/images/Vynwork_letters.png" className="home_page_img" alt="" />
                                <section className="margin-top">
                                    <div className="representative">
                                        <img src="/images/thomas.jpg" alt=""/>
                                        <h1 className="center">Thomas Kolmans</h1>
                                        <h2 className="center">Founder</h2>
                                        <h2 className="center">
                                            <a href="mailto:thomas@vynwork.com">thomas@vynwork.com</a>
                                        </h2>
                                        <h2 className="center">
                                            <a href="tel:+31649777284">+31649777284</a>
                                        </h2>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <a href="/" className="prev" id="prev" onClick={(e) => {
                            e.preventDefault();
                            this.plusSlides(-1)
                        }}>
                            &#10094;
                        </a>
                        <a href="/" className="next" id="next" onClick={(e) => {
                            e.preventDefault();
                            this.plusSlides(1)
                        }}>
                            &#10095;
                        </a>
                        <Link to="/">
                            <img className="vynwork" src="/images/Vynwork_letters.png" alt="Vynwork logo" />
                        </Link>
                    </div>
                    <div className="dots" id="dots"></div>
                </div>
            );
        }
        return (
            <div className="absolute-center">
                <img src="/images/Vynwork_letters.png" className="home_page_img" alt="" />
                <div className="spinner"></div>
            </div>
        );
    }
}
