import React, { Component } from "react";

import ShiftForm from "./ShiftForm";
import TaskShift from "../../../models/task/TaskShift";
import CloseButton from "../../ui/components/CloseButton";

export default class TaskShiftsForm extends Component {

    _addShift() {
        const { onChange, formTask } = this.props;

        const task = { ...formTask };
        const shifts = [...task.shifts];
        shifts.push(new TaskShift());
        task.shifts = shifts;

        onChange("formTask", task);
    }

    render() {
        const { t, onChange, formTask} = this.props;
        const { shifts } = formTask;

        return (
            <div className="input-container">
                {shifts.map((shift, index) => {
                    return (
                        <div className="list-sector" key={index}>
                            {index !== 0 && (
                                <div className="list-actions">
                                    <div></div>
                                    <CloseButton
                                        onClick={() => {
                                            const newTask = { ...formTask };
                                            const shifts = [...newTask.shifts];
                                            shifts.splice(index, 1)

                                            newTask.shifts = shifts;

                                            onChange("formTask", newTask);
                                        }}
                                    />
                                </div>
                            )}
                            <ShiftForm 
                                {...this.props}
                                shift={shift}
                                onChange={(newShift) => {
                                    const newTask = { ...formTask };
                                    const shifts = [...newTask.shifts];
                            
                                    shifts[index] = newShift;
                                    newTask.shifts = shifts;

                                    onChange("formTask", newTask);
                                }}
                            />
                        </div>
                    );
                })}
                <div className="input-group right">
                    <button type="button" onClick={(e) => this._addShift(e)}>
                        {t("form.label.addShift")}
                    </button>
                </div>
            </div>
        );
    }
}
