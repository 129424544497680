import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreateChargePage from '../../../components/wallet/crud/CreateChargePage';

import * as walletActions from '../../../state/actions/walletActions';

import Charge from '../../../models/wallet/Charge';

class CreateChargeContainer extends Component {
    componentWillMount() {
        const { changeValue, ownProps } = this.props;
        const { charge } = ownProps;
        changeValue('charge', charge || new Charge());
    }

    render() {
        const { changeValue, createCharge, ...props } = this.props;
        const { charge } = this.props;

        return <CreateChargePage {...props} onChange={changeValue} createCharge={() => createCharge(charge)} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        ...state.wallet,
        ownProps: { ...ownProps },
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(walletActions.changeValue(name, value)),
        createCharge: (charge) => dispatch(walletActions.createCharge(charge)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateChargeContainer));
