import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ChatPage from '../../components/chat/ChatPage';

import * as chatActions from '../../state/actions/chatActions';
import * as navigationActions from '../../state/actions/navigationActions';

class ChatContainer extends Component {
    render() {
        const { getChat, getMessages, ...props } = this.props;

        return <ChatPage {...props} getChat={(id) => getChat(id)} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        platform: state.platform.platform,
        chatIsFetching: state.chat.chatIsFetching,
        chat: state.chat.chat,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getChat: (id) => dispatch(chatActions.getChat(id)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ChatContainer));
