import React, { Component } from "react";
import moment from "moment";

import { base64ToBlob } from "../../../utils/imageUtils";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import TaskKeywordsForm from "./TaskKeywordsForm";
import TaskDescriptionsForm from "./TaskDescriptionsForm";
import TaskBannerPicker from "./TaskBannerPicker";
import { activateInputs } from "../../../utils/formUtils";

export default class UpdateTaskPage extends Component {
    constructor(props) {
        super(props);
        this.bannerRef = React.createRef();
    }
    onSubmit = (formTask) => {
        const { updateTask } = this.props;
        const task = { ...formTask };
        const shifts = [...task.shifts];
        shifts.forEach((shift, index) => {
            const currentShift = { ...shift };
            currentShift.startsAt = moment(
                `${currentShift.date} ${currentShift.startTime}`,
                "DD/MM/YYYY HH:mm"
            );
            currentShift.endsAt = moment(`${currentShift.date} ${currentShift.endTime}`, "DD/MM/YYYY HH:mm");
            shifts[index] = currentShift;
        });
        task.shifts = shifts;
        updateTask(task);
    };

    handleSubmit(e) {
        e.preventDefault();
        const { uploadFile, formTask } = this.props;

        this.bannerRef.current
            .getResult()
            .then((banner) => {
                uploadFile(base64ToBlob(banner), "task").then((response) => {
                    const task = { ...formTask };

                    const banners = [];
                    banners.push(response.file.id);
                    task.banners = banners;

                    this.onSubmit(task);
                });
            })
            .catch(() => {
                const task = { ...formTask };
                task.banners = [];
                this.onSubmit(task);
            });
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const { t, isFetchingCurrencies, formTask, taskIsFetching, onChange } = this.props;

        if (isFetchingCurrencies || !formTask || taskIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.update")}</h1>
                <div className="step-form">
                    <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formTask.title}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        newTask.title = event.target.value;
                                        onChange("formTask", newTask, event);
                                    }}
                                />
                                <label>{t("form.label.title")}</label>
                            </div>
                        </div>
                        <div className="input-group">
                            <h1>{t("form.label.banner")}</h1>
                            <TaskBannerPicker
                                {...this.props}
                                ref={this.bannerRef}
                                banner={
                                    formTask.banners && formTask.banners.length > 0
                                        ? formTask.banners[0]
                                        : null
                                }
                            />
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    value={formTask.description}
                                    className="small"
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        newTask.description = event.target.value;
                                        onChange("formTask", newTask, event);
                                    }}
                                ></textarea>
                                <label>{t("form.label.generalShortDescription")}</label>
                            </div>
                        </div>
                        <div className="input-group more">
                            <TaskDescriptionsForm {...this.props} />
                        </div>
                        <div className="input-group more">
                            <TaskKeywordsForm {...this.props} />
                        </div>
                        <div className="input-group more right">
                            <input type="submit" value={t("form.update")} onClick={(e) => e.stopPropagation()} />
                        </div>
                    </form>
                </div>
            </AsideView>
        );
    }
}
