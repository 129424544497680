import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as taskActions from "../../../state/actions/taskActions";
import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";
import * as fileApi from "../../../api/fileApi";

import CreateTaskPage from "../../../components/task/crud/CreateTaskPage";

class CreateTaskContainer extends Component {

    render() {
        const {
            history,
            onNavigateTo,
            changeValue,
            createTask,
            getPersonalBusinesses,
            ...props
        } = this.props;

        return (
            <CreateTaskPage
                {...props}
                onChange={changeValue}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
                getPersonalBusinesses={() => getPersonalBusinesses()}
                createTask={(task) =>
                    createTask(task).then((response) => {
                        onNavigateTo
                            ? onNavigateTo(`/task/${response.task.id}`)
                            : history.push(`/task/${response.task.id}`);
                    })
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        categories: state.platform.categories,
        currencies: state.wallet.currencies,
        isFetchingCurrencies: state.wallet.isFetchingCurrencies,
        businesses: state.business.personalBusinesses,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        createTask: (task) => dispatch(taskActions.createTask(task)),
        getPersonalBusinesses: () => dispatch(businessActions.getPersonalBusinesses()),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateTaskContainer));
