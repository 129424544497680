import React, { Component } from "react";
import ReactSVG from "react-svg";

import Loader from "../ui/components/Loader";
import NoData from "../ui/components/NoData";

import ExperienceCard from "./experience/ExperienceCard";
import EducationCard from "./education/EducationCard";

import UpdateAboutContainer from "../../containers/cv/about/UpdateAboutContainer";
import CreateEducationContainer from "../../containers/cv/education/CreateEducationContainer";
import CreateExperienceContainer from "../../containers/cv/experience/CreateExperienceContainer";

export default class CurriculumVitaeView extends Component {
    render() {
        const { t, addToStack, cv, user, profile, profileIsFetching } = this.props;

        if (!cv || profileIsFetching) return <Loader />;

        const IsAuthenticatedUser = profile.user.id === user.id;

        return (
            <div>
                <div className="cv-block">
                    <div className="cv-block-header flex-container justify-between">
                        <h2>{t("cv.about.header")}</h2>
                        <div className="flex-container action-icons">
                            {IsAuthenticatedUser && (
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        addToStack({
                                            name: t("cv.about.update"),
                                            component: <UpdateAboutContainer />,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/pencil.svg" />
                                </div>
                            )}
                        </div>
                    </div>

                    {cv.about ? (
                        <p style={{whiteSpace: 'pre-wrap'}}>{cv.about}</p>
                    ) : (
                        <NoData className="left">{t("cv.about.notFound", { name: profile.name })}</NoData>
                    )}
                </div>
                <div className="cv-block">
                    <div className="cv-block-header flex-container justify-between">
                        <h2>{t("cv.experience.header")}</h2>
                        <div className="flex-container action-icons">
                            {IsAuthenticatedUser && (
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        addToStack({
                                            name: t("cv.education.new"),
                                            component: <CreateExperienceContainer />,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/plus.svg" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="experiences">
                        {cv.experiences.length > 0 ? (
                            cv.experiences.map((experience) => (
                                <ExperienceCard {...this.props} experience={experience} />
                            ))
                        ) : (
                            <NoData className="left">
                                {t("cv.experiences.notFound", { name: profile.name })}
                            </NoData>
                        )}
                    </div>
                    <hr />
                    <div className="cv-block-header not-first flex-container justify-between">
                        <h2>{t("cv.education.header")}</h2>
                        <div className="flex-container action-icons">
                            {IsAuthenticatedUser && (
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        addToStack({
                                            name: t("cv.experience.new"),
                                            component: <CreateEducationContainer />,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/plus.svg" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="educations">
                        {cv.educations.length > 0 ? (
                            cv.educations.map((education) => (
                                <EducationCard {...this.props} education={education} />
                            ))
                        ) : (
                            <NoData className="left">
                                {t("cv.educations.notFound", { name: profile.name })}
                            </NoData>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
