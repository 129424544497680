import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from '../ui/components/Loader';
import Avatar from '../ui/components/Avatar';

export default class HeaderMessagesList extends Component {
    getPreviewMessage(chat) {
        const { t } = this.props;
        const latestMessage = chat.latest ? chat.latest.message : t('chat.messages.notFound');
        return latestMessage.length < 45 ? latestMessage : latestMessage.substr(0, 45) + '...';
    }

    onScroll(e) {
        const { getChats, chatsLoaded, chatsTotalResults } = this.props;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && chatsLoaded < chatsTotalResults) {
            getChats(chatsLoaded, chatsLoaded + 20);
        }
    }

    render() {
        const { t, chatsIsFetching, chats } = this.props;

        if (!chatsIsFetching) {
            if (chats.length > 0) {
                return (
                    <div>
                        <div className="item-list scrollable" onScroll={(e) => this.onScroll(e)}>
                            {chats.map((chat) => (
                                <Link to={'/messages/' + chat.id} key={chat.id}>
                                    <div className="message">
                                        <Avatar imageUrl={chat.image.url} name={chat.title} />
                                        <div className="left">
                                            <h1>
                                                <span>
                                                    {`${chat.title}` || t('chat.noTitle')}
                                                    {chat.messagesUnread ? (
                                                        <span className="unread">{chat.messagesUnread}</span>
                                                    ) : null}
                                                </span>
                                                <span className="date">
                                                    {chat.latest ? moment(chat.latest.updatedAt).fromNow() : ''}
                                                </span>
                                            </h1>
                                            <p dangerouslySetInnerHTML={{ __html: this.getPreviewMessage(chat) }}></p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="more">
                            <Link to="/messages">{t('chats.all')}</Link>
                        </div>
                    </div>
                );
            }
            return <div className="nothing">{t('chats.notFound')}</div>;
        }
        return <Loader />;
    }
}
