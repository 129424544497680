import React, { Component } from "react";
import { Link } from "react-router-dom";

import SimpleReactValidator from "simple-react-validator";
import AuthenticationContainer from "./AuthenticationContainer";

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onLogin } = this.props;

        if (this.validator.allValid()) {
            onLogin();
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        const { t, platform, onChange, email, password, error } = this.props;
        return (
            <AuthenticationContainer {...this.props}>
                <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <h2 className="top">{t("login.header")}</h2>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={email}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange("email", value, event);
                                }}
                            />
                            <label>{t("form.label.email")}</label>
                        </div>
                        {this.validator.message("email", email, "required|email")}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="password"
                                value={password}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange("password", value, event);
                                }}
                            />
                            <label>{t("form.label.password")}</label>
                        </div>
                        {this.validator.message("email", email, "required")}
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{error}</div>
                        <input type="submit" value={t("form.login")} onClick={(e) => e.stopPropagation()} />
                        <div className="input-group">
                            <Link to="/forgot">{t("login.forgot")}</Link>
                        </div>
                    </div>
                </form>
                {platform.canRegister && (
                    <>
                        <div className="or">{t("form.or")}</div>
                        <div className="login">
                            <Link to="/register">
                                <button className="full">{t("login.noAccount")}</button>
                            </Link>
                        </div>
                    </>
                )}
            </AuthenticationContainer>
        );
    }
}
