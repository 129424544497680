export const articleConstants = {
    ARTICLE_CHANGE_VALUE: "ARTICLE_CHANGE_VALUE",

    GET_ARTICLE_STARTED: "GET_ARTICLE_STARTED",
    GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
    GET_ARTICLE_FAILURE: "GET_ARTICLE_FAILURE",

    GET_ARTICLES_STARTED: "GET_ARTICLES_STARTED",
    GET_ARTICLES_SUCCESS: "GET_ARTICLES_SUCCESS",
    GET_ARTICLES_FAILURE: "GET_ARTICLES_FAILURE",

    SEARCH_ARTICLES_STARTED: "SEARCH_ARTICLES_STARTED",
    SEARCH_ARTICLES_SUCCESS: "SEARCH_ARTICLES_SUCCESS",
    SEARCH_ARTICLES_FAILURE: "SEARCH_ARTICLES_FAILURE",

    CREATING_ARTICLE_STARTED: "CREATING_ARTICLE_STARTED",
    CREATING_ARTICLE_SUCCESS: "CREATING_ARTICLE_SUCCESS",
    CREATING_ARTICLE_FAILURE: "CREATING_ARTICLE_FAILURE",

    UPDATING_ARTICLE_STARTED: "UPDATING_ARTICLE_STARTED",
    UPDATING_ARTICLE_SUCCESS: "UPDATING_ARTICLE_SUCCESS",
    UPDATING_ARTICLE_FAILURE: "UPDATING_ARTICLE_FAILURE",

    DELETE_ARTICLE_STARTED: "DELETE_ARTICLE_STARTED",
    DELETE_ARTICLE_SUCCESS: "DELETE_ARTICLE_SUCCESS",
    DELETE_ARTICLE_FAILURE: "DELETE_ARTICLE_FAILURE",
};
