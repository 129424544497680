import React, { Component } from "react";

import UpdateCategoryContainer from "../../containers/category/crud/UpdateCategoryContainer";
import CreateCategoryContainer from "../../containers/category/crud/CreateCategoryContainer";

import TableView from "../ui/views/TableView";
import PopOver from "../ui/components/PopOver";

export default class CategoriesView extends Component {
    state = {
        selectedOptions: ["name", "abbreviated"],
        popOvers: [],
        searchQuery: "",
    };

    componentDidMount() {
        const { getCategories } = this.props;
        getCategories(0, 20);
    }

    render() {
        const { t, categories, isFetchingCategories, categoriesLoaded,  categoriesTotalResults, addToStack, deleteCategory, searchCategories, getCategories } = this.props;
        const { selectedOptions, popOvers, searchQuery } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        isLoading={isFetchingCategories}
                        values={categories}
                        title={t("categories.header")}
                        searchEnabled
                        searchPlaceholder={t("search.placeholder")}
                        onSearchChange={(searchQuery) => {
                            searchQuery ? searchCategories({query: searchQuery}, 0, 20) : getCategories(0, 20)
                        
                            this.setState({
                                searchQuery: searchQuery
                            })
                        }}
                        onScrolledToBottom={() => {
                            if (categoriesTotalResults > categoriesLoaded ) {
                                searchQuery ? searchCategories({query: searchQuery}, categoriesLoaded, categoriesLoaded + 20) : getCategories(categoriesLoaded, categoriesLoaded + 20)
                            }
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t("category.create"),
                                component: <CreateCategoryContainer />,
                            });
                        }}
                        createNewLabel={t("category.create")}
                        rowOnClick={(e) => {
                            // nothing
                        }}
                        options={[
                            {
                                name: "name",
                                size: "one",
                                header: t("category.name"),
                                type: "string",
                                selector: "name",
                                defaultValue: t("noValue"),
                            },
                            {
                                name: "abbreviated",
                                size: "one",
                                header: t("category.abbreviated"),
                                type: "string",
                                selector: "abbreviated"
                            }
                        ]}
                        selectedOptions={selectedOptions}
                        actions={[
                            {
                                name: t("form.edit"),
                                enabled: true,
                                action: (e, category) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: t("category.update"),
                                        component: <UpdateCategoryContainer overrideCategory={category} />,
                                    });
                                },
                            },
                            {
                                name: t("form.delete"),
                                enabled: true,
                                action: (e, category) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t("category.delete.header")}</div>
                                                    <div className="text">
                                                        {t("category.delete.description")}
                                                    </div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t("cancel")}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deleteCategory(category)
                                                            }}
                                                        >
                                                            {t("delete")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                        ]}
                        noDataLabel={t("categories.notFound")}
                    />
                </div>
            </div>
        );
    }
}
