import api from '../api';

// CRUD
export const get = () => {
    return api.get(`/platform`);
};
export const create = (platform) => {
    return api.post(`/platform`, platform);
};

export const update = (platform) => {
    return api.put(`/platform`, platform);
};
export const remove = (platform) => {
    return api.delete(`/platform/${platform.id}`);
};

// Fetching and finding
export const all = (from = 0, amount = 20) => {
    return api.get(`/platforms/${from}/${amount}`);
};

export const search = (searchParameters, from = 0, amount = 20) => {
    return api.post(`/platforms/${from}/${amount}`, searchParameters);
};

// Rest
export const languages = () => {
    return api.get(`/languages`);
};

export const industries = () => {
    return api.get(`/industries`);
};

export const categories = () => {
    return api.get(`/categories`);
};

export const permissions = () => {
    return api.get(`/permissions`);
};

export const contact = (email, subject, message, recaptcha) => {
    return api.post(`/platform/contact`, {
        email,
        subject,
        message,
        recaptcha,
    });
};
