import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateContractPage from "../../../components/contract/crud/UpdateContractPage.js";

import * as contractActions from "../../../state/actions/contractActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdateContractContainer extends Component {
    render() {
        const { changeValue, updateContract, searchVehicles, popStack, callback, ...props } = this.props;

        return (
            <UpdateContractPage
                {...props}
                onChange={changeValue}
                updateContract={(contract) =>
                    updateContract(contract).then((response) => {
                        popStack && popStack();
                        callback && callback(response);
                    })
                }
                searchVehicles={(searchParameters, from, amount) =>
                    searchVehicles(searchParameters, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        languages: state.platform.languages,
        ...state.contract,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateContract: (contract) => dispatch(contractActions.updateContract(contract)),
        changeValue: (name, value) => dispatch(contractActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateContractContainer));
