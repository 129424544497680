import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from "../ui/components/Loader";

import Rating from "../rating/Rating";

export default class TaskUser extends Component {
    render() {
        const { t, profile } = this.props;
        if (profile) {
            return (
                <div className="side-profile highlighted">
                    <div className="picture">
                        <img
                            src={profile.avatar ? profile.avatar.url : "/images/user.svg"}
                            alt={profile.avatar ? profile.avatar.originalName : ""}
                        ></img>
                    </div>
                    <Link
                        to={`/profile/${profile.user.id}`}
                        className="name"
                    >{`${profile.person.firstName} ${profile.person.lastName}`}</Link>
                    <Rating rating={profile.rating ? profile.rating.totalRating || 0.0 : 0.0} small>
                        {t("rating.reviews.total", {
                            count: profile.rating ? profile.rating.totalReviews || 0 : 0,
                        })}
                    </Rating>
                    {profile.headline && <div className="headline">{profile.headline}</div>}
                    {profile.cv.about && <div className="about">{profile.cv.about}</div>}
                </div>
            );
        }
        return (
            <div className="side-profile highlighted">
                <Loader />
            </div>
        );
    }
}
