import api from '../api';

// CRUD
export const getTicket = (id, token = null) => {
    return api.get(`/support/ticket/${id}${token ? `?token=${token}` : ''}`);
};
export const createTicket = (ticket) => {
    return api.post(`/support/ticket`, ticket);
};
export const updateTicket = (ticket) => {
    return api.put(`/support/ticket`, ticket);
};

// Get requests and errors related to ticket
export const getTicketRequests = (supportTicket, from, amount) => {
    return api.get(`support/ticket/${supportTicket.id}/requests/${from}/${amount}`);
};

export const getTicketErrors = (supportTicket, from, amount) => {
    return api.get(`/support/ticket/${supportTicket.id}/errors/${from}/${amount}`);
};

// CRUD ticket actions
export const createTicketAction = (ticketAction, token = null) => {
    return api.post(`/support/ticket/action${token ? `?token=${token}` : ''}`, ticketAction);
};
export const updateTicketAction = (ticketAction, token = null) => {
    return api.put(`/support/ticket/action${token ? `?token=${token}` : ''}`, ticketAction);
};
export const deleteTicketAction = (ticketAction) => {
    return api.delete(`/support/ticket/action/${ticketAction.id}`);
};

// Fetching and finding
export const latest = (from = 0, amount = 20) => {
    return api.get(`/support/tickets/${from}/${amount}`);
};
export const latestWithStatus = (status, from = 0, amount = 20) => {
    return api.get(`/support/tickets/${status}/${from}/${amount}`);
};

export const search = (searchParameters = {}, from = 0, amount = 20) => {
    return api.post(`/support/tickets/${from}/${amount}`, searchParameters);
};
export const searchWithStatus = (status, searchParameters = {}, from = 0, amount = 20) => {
    return api.post(`/support/tickets/${status}/${from}/${amount}`, searchParameters);
};
