import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreateSupportTicketAction from '../../../components/support/crud/CreateSupportTicketAction.js';

import * as supportActions from '../../../state/actions/supportActions';
import * as navigationActions from '../../../state/actions/navigationActions';

class CreateSupportTicketActionContainer extends Component {
    render() {
        const { popStack, changeValue, createTicketAction, history, ...props } = this.props;

        return (
            <CreateSupportTicketAction
                {...props}
                onChange={changeValue}
                createTicketAction={(ticketAction, token) =>
                    createTicketAction(ticketAction, token).then((response) => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.support,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createTicketAction: (ticketAction, token) => dispatch(supportActions.createTicketAction(ticketAction, token)),
        changeValue: (name, value) => dispatch(supportActions.changeValue(name, value)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(CreateSupportTicketActionContainer));
