import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import * as taskActions from "../../../state/actions/taskActions";
import * as navigationActions from "../../../state/actions/navigationActions";
import * as fileApi from "../../../api/fileApi";

import UpdateTaskPage from "../../../components/task/crud/UpdateTaskPage";

class UpdateTaskContainer extends Component {
    componentDidMount() {
        const { changeValue, targetTask } = this.props;
        const newTask = { ...targetTask };
        newTask.shifts = [...newTask.shifts].map((shift) => {
            const newShift = { ...shift };

            newShift.date = moment(shift.startsAt).format("DD/MM/YYYY");
            newShift.startTime = moment(shift.startsAt).format("HH:mm");
            newShift.endTime = moment(shift.endsAt).format("HH:mm");

            return newShift;
        });
        changeValue("formTask", newTask);
    }

    render() {
        const { changeValue, updateTask, popStack, ...props } = this.props;

        return (
            <UpdateTaskPage
                {...props}
                onChange={changeValue}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
                updateTask={(task) => updateTask(task).then(() => {
                    popStack && popStack()
                })}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        categories: state.platform.categories,
        currencies: state.wallet.currencies,
        isFetchingCurrencies: state.wallet.isFetchingCurrencies,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        updateTask: (task) => dispatch(taskActions.updateTask(task)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateTaskContainer));
