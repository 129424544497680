import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateEducationPage from "../../../components/cv/education/UpdateEducationPage";

import * as profileActions from "../../../state/actions/profileActions";
import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdateEducationContainer extends Component {
    componentDidMount() {
        const { changeValue, targetEducation } = this.props;
        changeValue("formEducation", targetEducation);
    }

    render() {
        const { changeValue, updateEducation, searchBusinesses, ...props } = this.props;

        return (
            <UpdateEducationPage
                {...props}
                onChange={changeValue}
                updateEducation={(education) => updateEducation(education)}
                searchBusinesses={(searchParameters, from, amount) =>
                    searchBusinesses(searchParameters, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(profileActions.changeValue(name, value)),
        updateEducation: (education) => dispatch(profileActions.updateEducation(education)),
        searchBusinesses: (searchParameters, from, amount) =>
            dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateEducationContainer));
