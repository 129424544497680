import { ratingConstants } from "../constants";

const initialState = {
    ratingIsFetching: false,
    rating: null,

    reviewsIsFetching: false,
    reviewsLoaded: 0,
    reviewsTotalResults: 0,
    reviews: [],

    reviewIsFetching: false,
    review: null,
    formReview: null,
};

export default function rating(state = initialState, action) {
    switch (action.type) {
        case ratingConstants.RATING_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case ratingConstants.GET_REVIEWS_STARTED:
            return { ...state, isFetchingReviews: true };
        case ratingConstants.GET_REVIEWS_FAILURE:
            return { ...state, isFetchingReviews: false, error: action.message || "" };
        case ratingConstants.GET_REVIEWS_SUCCESS:
            return {
                ...state,
                isFetchingReviews: false,
                reviews: action.reviews || [],
                reviewsLoaded: action.from || 0,
                reviewsTotalResults: action.totalResults || 0,
            };

        case ratingConstants.SEARCH_REVIEWS_STARTED:
            return { ...state, isFetchingReviews: true };
        case ratingConstants.SEARCH_REVIEWS_FAILURE:
            return { ...state, isFetchingReviews: false, error: action.message || "" };
        case ratingConstants.SEARCH_REVIEWS_SUCCESS:
            return {
                ...state,
                isFetchingReviews: false,
                reviews: action.reviews || [],
                reviewsLoaded: action.from || 0,
                reviewsTotalResults: action.totalResults || 0,
            };

        case ratingConstants.CREATING_REVIEW_STARTED:
            return { ...state, isFetchingReview: true };
        case ratingConstants.CREATING_REVIEW_FAILURE:
            return { ...state, isFetchingReview: false, error: action.message || "" };
        case ratingConstants.CREATING_REVIEW_SUCCESS:
            return { ...state, isFetchingReview: false, review: action.review };

        case ratingConstants.UPDATING_REVIEW_STARTED:
            return { ...state, isFetchingReview: true };
        case ratingConstants.UPDATING_REVIEW_FAILURE:
            return { ...state, isFetchingReview: false, error: action.message || "" };
        case ratingConstants.UPDATING_REVIEW_SUCCESS:
            return { ...state, isFetchingReview: false, review: action.review };
        default:
            return state;
    }
}
