export default class Category {

    id;
    nonce;

    parentCategory;

    name;

    descriptionParts;
    keywords;

    createdAt;
    updatedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.descriptionParts = [];
        this.keywords = [];
    }
}