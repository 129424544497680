import { walletConstants } from '../constants';
import * as walletApi from '../../api/walletApi';

import * as navigationActions from './navigationActions';
import Alert from '../../models/Alert';

/**
 * Change redux value
 */
export const changeValue = (name, value) => ({
    type: walletConstants.WALLET_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching wallet
 */
export const startGetWallet = () => ({
    type: walletConstants.GET_WALLET_STARTED,
});

export const getWalletSuccess = (result) => ({
    type: walletConstants.GET_WALLET_SUCCESS,
    ...result,
});

export const getWalletFailure = (error) => ({
    type: walletConstants.GET_WALLET_FAILURE,
    ...error,
});

export const getWallet = (id) => {
    return (dispatch) => {
        dispatch(startGetWallet());

        const promise = walletApi.get(id);

        promise
            .then((result) => {
                dispatch(getWalletSuccess(result));
            })
            .catch((error) => {
                dispatch(getWalletFailure(error));
            });

        return promise;
    };
};

export const getUserWallet = (id) => {
    return (dispatch) => {
        dispatch(startGetWallet());

        const promise = walletApi.getFromUser(id);

        promise
            .then((result) => {
                dispatch(getWalletSuccess(result));
            })
            .catch((error) => {
                dispatch(getWalletFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching currencies
 */
export const startGetCurrencies = () => ({
    type: walletConstants.GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = (result) => ({
    type: walletConstants.GET_CURRENCIES_SUCCESS,
    ...result,
});

export const getCurrenciesFailure = (error) => ({
    type: walletConstants.GET_CURRENCIES_FAILURE,
    ...error,
});

export const getCurrencies = () => {
    return (dispatch) => {
        dispatch(startGetCurrencies());

        const promise = walletApi.getCurrencies();

        promise
            .then((result) => {
                dispatch(getCurrenciesSuccess(result));
            })
            .catch((error) => {
                dispatch(getCurrenciesFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching transactions
 */
export const startGetTransactions = () => ({
    type: walletConstants.GET_TRANSACTIONS_STARTED,
});

export const getTransactionsSuccess = (result, from) => ({
    type: walletConstants.GET_TRANSACTIONS_SUCCESS,
    from: from,
    ...result,
});

export const getTransactionsFailure = (error) => ({
    type: walletConstants.GET_TRANSACTIONS_FAILURE,
    ...error,
});

export const getTransactions = (from, to) => {
    return (dispatch) => {
        dispatch(startGetTransactions());

        const promise = walletApi.getTransactions(from, to);

        promise
            .then((result) => {
                dispatch(getTransactionsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getTransactionsFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching transactions by date
 */
export const startGetTransactionsByDate = () => ({
    type: walletConstants.GET_TRANSACTIONS_BY_DATE_STARTED,
});

export const getTransactionsByDateSuccess = (result) => ({
    type: walletConstants.GET_TRANSACTIONS_BY_DATE_SUCCESS,
    ...result,
});

export const getTransactionsByDateFailure = (error) => ({
    type: walletConstants.GET_TRANSACTIONS_BY_DATE_FAILURE,
    ...error,
});

export const getTransactionsByDate = (startDate, toDate) => {
    return (dispatch) => {
        dispatch(startGetTransactionsByDate());

        const promise = walletApi.getTransactionsByDate(startDate, toDate);

        promise
            .then((result) => {
                dispatch(getTransactionsByDateSuccess(result));
            })
            .catch((error) => {
                dispatch(getTransactionsByDateFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching invoices
 */
export const startGetInvoices = () => ({
    type: walletConstants.GET_INVOICES_STARTED,
});

export const getInvoicesSuccess = (result, from) => ({
    type: walletConstants.GET_INVOICES_SUCCESS,
    from: from,
    ...result,
});

export const getInvoicesFailure = (error) => ({
    type: walletConstants.GET_INVOICES_FAILURE,
    ...error,
});

export const getInvoices = (from, to) => {
    return (dispatch) => {
        dispatch(startGetInvoices());

        const promise = walletApi.getInvoices(from, to);

        promise
            .then((result) => {
                dispatch(getInvoicesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getInvoicesFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching invoice
 */
export const startGetInvoice = () => ({
    type: walletConstants.GET_INVOICE_STARTED,
});

export const getInvoiceSuccess = (result) => ({
    type: walletConstants.GET_INVOICE_SUCCESS,
    ...result,
});

export const getInvoiceFailure = (error) => ({
    type: walletConstants.GET_INVOICE_FAILURE,
    ...error,
});

export const getInvoice = (id) => {
    return (dispatch) => {
        dispatch(startGetInvoice());

        const promise = walletApi.getInvoice(id);

        promise
            .then((result) => {
                dispatch(getInvoiceSuccess(result));
            })
            .catch((error) => {
                dispatch(getInvoiceFailure(error));
            });

        return promise;
    };
};

/**
 * Creating invoice
 */

export const startCreateInvoice = () => ({
    type: walletConstants.CREATING_INVOICE_STARTED,
});

export const createInvoiceSuccess = (result) => ({
    type: walletConstants.CREATING_INVOICE_SUCCESS,
    ...result,
});

export const createInvoiceFailure = (error) => ({
    type: walletConstants.CREATING_INVOICE_FAILURE,
    ...error,
});

export const createInvoice = (invoice) => {
    return (dispatch) => {
        dispatch(startCreateInvoice());

        const promise = walletApi.createInvoice(invoice);

        promise
            .then((result) => {
                dispatch(createInvoiceSuccess(result));
            })
            .catch((error) => {
                dispatch(createInvoiceFailure(error));
            });

        return promise;
    };
};

/**
 * Updating invoice
 */
export const startUpdateInvoice = () => ({
    type: walletConstants.UPDATING_INVOICE_STARTED,
});

export const updateInvoiceSuccess = (result) => ({
    type: walletConstants.UPDATING_INVOICE_SUCCESS,
    ...result,
});

export const updateInvoiceFailure = (error) => ({
    type: walletConstants.UPDATING_INVOICE_FAILURE,
    ...error,
});

export const updateInvoice = (invoice) => {
    return (dispatch) => {
        dispatch(startUpdateInvoice());

        const promise = walletApi.updateInvoice(invoice);

        promise
            .then((result) => {
                dispatch(updateInvoiceSuccess(result));
            })
            .catch((error) => {
                dispatch(updateInvoiceFailure(error));
            });

        return promise;
    };
};

/**
 * Creating charge
 */
export const startCreateCharge = () => ({
    type: walletConstants.CREATING_CHARGE_STARTED,
});

export const createChargeSuccess = (result) => ({
    type: walletConstants.CREATING_CHARGE_SUCCESS,
    ...result,
});

export const createChargeFailure = (error) => ({
    type: walletConstants.CREATING_CHARGE_FAILURE,
    ...error,
});

export const createCharge = (charge) => {
    return (dispatch) => {
        dispatch(startCreateCharge());

        const promise = walletApi.createCharge(charge);

        promise
            .then((result) => {
                dispatch(createChargeSuccess(result));
            })
            .catch((error) => {
                dispatch(createChargeFailure(error));
            });

        return promise;
    };
};

/**
 * Updating charge
 */
export const startUpdateCharge = () => ({
    type: walletConstants.UPDATING_CHARGE_STARTED,
});

export const updateChargeSuccess = (result) => ({
    type: walletConstants.UPDATING_CHARGE_SUCCESS,
    ...result,
});

export const updateChargeFailure = (error) => ({
    type: walletConstants.UPDATING_CHARGE_FAILURE,
    ...error,
});

export const updateCharge = (charge) => {
    return (dispatch) => {
        dispatch(startUpdateCharge());

        const promise = walletApi.updateCharge(charge);

        promise
            .then((result) => {
                dispatch(updateChargeSuccess(result));
            })
            .catch((error) => {
                dispatch(updateChargeFailure(error));
            });

        return promise;
    };
};

/**
 * Charge the source
 */

export const startExecuteCharge = () => ({
    type: walletConstants.EXECUTE_CHARGE_STARTED,
});

export const updateExecuteSuccess = (result) => ({
    type: walletConstants.EXECUTE_CHARGE_SUCCESS,
    ...result,
});

export const updateExecuteFailure = (error) => ({
    type: walletConstants.EXECUTE_CHARGE_FAILURE,
    ...error,
});

export const executeCharge = (charge) => {
    return (dispatch) => {
        dispatch(startExecuteCharge());

        const promise = walletApi.executeCharge(charge);

        promise
            .then((result) => {
                dispatch(updateExecuteSuccess(result));
            })
            .catch((error) => {
                dispatch(updateExecuteFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching payouts
 */
export const startGetPayouts = () => ({
    type: walletConstants.GET_PAYOUTS_STARTED,
});

export const getPayoutsSuccess = (result, from) => ({
    type: walletConstants.GET_PAYOUTS_SUCCESS,
    from: from,
    ...result,
});

export const getPayoutsFailure = (error) => ({
    type: walletConstants.GET_PAYOUTS_FAILURE,
    ...error,
});

export const getPayouts = (from, to) => {
    return (dispatch) => {
        dispatch(startGetPayouts());

        const promise = walletApi.getPayouts(from, to);

        promise
            .then((result) => {
                dispatch(getPayoutsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getPayoutsFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching payout
 */
export const startGetPayout = () => ({
    type: walletConstants.GET_PAYOUT_STARTED,
});

export const getPayoutSuccess = (result) => ({
    type: walletConstants.GET_PAYOUT_SUCCESS,
    ...result,
});

export const getPayoutFailure = (error) => ({
    type: walletConstants.GET_PAYOUT_FAILURE,
    ...error,
});

export const getPayout = (id) => {
    return (dispatch) => {
        dispatch(startGetPayout());

        const promise = walletApi.getPayout(id);

        promise
            .then((result) => {
                dispatch(getPayoutSuccess(result));
            })
            .catch((error) => {
                dispatch(getPayoutFailure(error));
            });

        return promise;
    };
};

/**
 * Creating payout
 */

export const startCreatePayout = () => ({
    type: walletConstants.CREATING_PAYOUT_STARTED,
});

export const createPayoutSuccess = (result) => ({
    type: walletConstants.CREATING_PAYOUT_SUCCESS,
    ...result,
});

export const createPayoutFailure = (error) => ({
    type: walletConstants.CREATING_PAYOUT_FAILURE,
    ...error,
});

export const createPayout = (payout, t) => {
    return (dispatch) => {
        dispatch(startCreatePayout());

        const promise = walletApi.createPayout(payout);

        promise
            .then((result) => {
                dispatch(createPayoutSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t('notification.payout.success.title'),
                            t('notification.payout.success.description'),
                            'success'
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(createPayoutFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            t('notification.payout.error.title'),
                            t('notification.payout.error.description', { message: error.message }),
                            'error'
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Updating payout
 */
export const startUpdatePayout = () => ({
    type: walletConstants.UPDATING_PAYOUT_STARTED,
});

export const updatePayoutSuccess = (result) => ({
    type: walletConstants.UPDATING_PAYOUT_SUCCESS,
    ...result,
});

export const updatePayoutFailure = (error) => ({
    type: walletConstants.UPDATING_PAYOUT_FAILURE,
    ...error,
});

export const updatePayout = (payout) => {
    return (dispatch) => {
        dispatch(startUpdatePayout());

        const promise = walletApi.updatePayout(payout);

        promise
            .then((result) => {
                dispatch(updatePayoutSuccess(result));
            })
            .catch((error) => {
                dispatch(updatePayoutFailure(error));
            });

        return promise;
    };
};


/**
 * Check if he has funds available
 */

export const startCheckingFunds = () => ({
    type: walletConstants.CREATING_INVOICE_STARTED,
});

export const checkingFundsSuccess = (result) => ({
    type: walletConstants.CREATING_INVOICE_SUCCESS,
    ...result,
});

export const checkingFundsFailure = (error) => ({
    type: walletConstants.CREATING_INVOICE_FAILURE,
    ...error,
});

export const hasFunds = (amount) => {
    return (dispatch) => {
        dispatch(startCreateInvoice());

        const promise = walletApi.createInvoice(amount);

        promise
            .then((result) => {
                dispatch(createInvoiceSuccess(result));
            })
            .catch((error) => {
                dispatch(createInvoiceFailure(error));
            });

        return promise;
    };
};