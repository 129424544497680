import { platformConstants } from '../constants';

const initialState = {
    webSocket: null,
    error: null,
    tags: {},

    platformIsFetching: false,
    platform: null,

    isFetchingFormPlatform: false,
    formPlatform: null,

    isFetchingPlatforms: false,
    platforms: [],

    languagesIsFetching: false,
    languages: [],

    isFetchingCategories: false,
    categories: [],

    isFetchingFields: false,
    fields: [],

    isFetchingPermissions: false,
    permissions: [],

    isFetchingCategory: false,
    category: null,
    formCategory: null,

    isFetchingIndustries: false,
    industries: [],
    isFetchingIndustry: false,
    industry: null,
    formIndustry: null,

    isFetchingContact: false,
    contactMessage: null,
};

export default function platform(state = initialState, action) {
    switch (action.type) {
        case platformConstants.SET_PLATFORM:
            return {
                ...state,
                platformIsFetching: false,
                platform: action.platform,
                tags: {
                    title: action.platform.name,
                    description: action.platform.description,
                    keywords: action.platform.keywords,
                    favicon: action?.platform?.favicon?.url || '/images/Vynwork.png',
                },
            };

        case platformConstants.PLATFORM_CHANGE_VALUE:
            return { ...state, error: '', [action.name]: action.value };

        case platformConstants.GET_PLATFORM_STARTED:
            return { ...state, platformIsFetching: true, error: null };
        case platformConstants.GET_PLATFORM_FAILURE:
            return { ...state, platformIsFetching: false, error: 'Could not find platform' };
        case platformConstants.GET_PLATFORM_SUCCESS:
            return {
                ...state,
                platformIsFetching: false,
                platform: action.platform,
                languages: action.languages || [],
                categories: action.categories || [],
                industries: action.industries || [],
                fields: action.fields || [],
                tags: {
                    title: action.platform.name,
                    description: action.platform.description,
                    keywords: action.platform.keywords,
                },
            };

        case platformConstants.GET_PLATFORMS_STARTED:
            return { ...state, isFetchingPlatforms: true };
        case platformConstants.GET_PLATFORMS_FAILURE:
            return {
                ...state,
                isFetchingPlatforms: false,
                platforms: [],
            };
        case platformConstants.GET_PLATFORMS_SUCCESS:
            return {
                ...state,
                isFetchingPlatforms: false,
                platforms: action.from > 0 ? [...state.platforms, ...action.platforms] : action.platforms,
                platformsLoaded:
                    action.from > 0 ? state.platforms.length + action.platforms.length : action.platforms.length,
                platformsTotalResults: action.totalResults || 0,
            };

        case platformConstants.CREATE_PLATFORM_STARTED:
            return { ...state, isFetchingFormPlatform: true };
        case platformConstants.CREATE_PLATFORM_FAILURE:
            return { ...state, isFetchingFormPlatform: false, message: action.message || '' };
        case platformConstants.CREATE_PLATFORM_SUCCESS:
            return {
                ...state,
                isFetchingFormPlatform: false,
                formPlatform: action.platform,
                platforms: [...state.platforms, action.platform],
            };

        case platformConstants.UPDATE_PLATFORM_STARTED:
            return { ...state, isFetchingFormPlatform: true };
        case platformConstants.UPDATE_PLATFORM_FAILURE:
            return { ...state, isFetchingFormPlatform: false, message: action.message || '' };
        case platformConstants.UPDATE_PLATFORM_SUCCESS:
            return {
                ...state,
                isFetchingFormPlatform: false,
                formPlatform: action.platform,
                platforms: state.platforms.map((platform) =>
                    action.platform.id === platform.id ? action.platform : platform
                ),
            };

        case platformConstants.DELETE_PLATFORM_STARTED:
            return { ...state, isFetchingFormPlatform: true };
        case platformConstants.DELETE_PLATFORM_FAILURE:
            return { ...state, isFetchingFormPlatform: false, message: action.message || '' };
        case platformConstants.DELETE_PLATFORM_SUCCESS:
            return {
                ...state,
                isFetchingFormPlatform: false,
                platforms: state.platforms.filter((platform) => action.platform.id !== platform.id),
            };

        case platformConstants.GET_LANGUAGES_STARTED:
            return { ...state, languagesIsFetching: true };
        case platformConstants.GET_LANGUAGES_FAILURE:
            return {
                ...state,
                languagesIsFetching: false,
                error: action.message || 'Could not find languages',
            };
        case platformConstants.GET_LANGUAGES_SUCCESS:
            return { ...state, languagesIsFetching: false, languages: action.languages };

        case platformConstants.GET_INDUSTRIES_STARTED:
            return { ...state, isFetchingIndustries: true };
        case platformConstants.GET_INDUSTRIES_FAILURE:
            return {
                ...state,
                isFetchingIndustries: false,
                error: action.message || 'Could not find industries',
            };
        case platformConstants.GET_INDUSTRIES_SUCCESS:
            return { ...state, isFetchingIndustries: false, industries: action.industries || [] };

        case platformConstants.CONTACT_STARTED:
            return { ...state, isFetchingContact: true, contactMessage: null };
        case platformConstants.CONTACT_FAILURE:
            return { ...state, isFetchingContact: false, contactMessage: action.message };
        case platformConstants.CONTACT_SUCCESS:
            return { ...state, isFetchingContact: false, contactMessage: action.message };

        case platformConstants.GET_PERMISSIONS_STARTED:
            return { ...state, isFetchingPlatforms: true };
        case platformConstants.GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                isFetchingPermissions: false,
                permissions: [],
            };
        case platformConstants.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                isFetchingPermissions: false,
                permissions: action.from > 0 ? [...state.permissions, ...action.permissions] : action.permissions,
                permissionsLoaded:
                    action.from > 0 ? state.permissions.length + action.permissions.length : action.permissions.length,
                permissionsTotalResults: action.totalResults || 0,
            };

        case platformConstants.SET_WEBSOCKET_CONNECTION:
            return { ...state, webSocket: action.webSocket };

        default:
            return state;
    }
}
