import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateExperiencePage from "../../../components/cv/experience/UpdateExperiencePage";

import * as profileActions from "../../../state/actions/profileActions";
import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdateExperienceContainer extends Component {
    componentDidMount() {
        const { changeValue, targetExperience } = this.props;
        changeValue("formExperience", targetExperience);
    }

    render() {
        const { changeValue, updateExperience, searchBusinesses, ...props } = this.props;

        return (
            <UpdateExperiencePage
                {...props}
                onChange={changeValue}
                updateExperience={(experience) => updateExperience(experience)}
                searchBusinesses={(searchParameters, from, amount) =>
                    searchBusinesses(searchParameters, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(profileActions.changeValue(name, value)),
        updateExperience: (experience) =>
            dispatch(profileActions.updateExperience(experience)),
        searchBusinesses: (searchParameters, from, amount) =>
            dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateExperienceContainer));
