import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CancelApplicationContainer from './crud/CancelApplicationContainer';
import DenyApplicationContainer from './crud/DenyApplicationContainer';

import Applications from '../../components/task/Applications';

import * as taskActions from '../../state/actions/taskActions';
import * as navigationActions from '../../state/actions/navigationActions';

class TaskContainer extends Component {
    render() {
        const { acceptApplication, updateApplication, getTask, ...props } = this.props;
        const { task, addToStack, shift } = this.props;

        return (
            <Applications
                {...props}
                onAccept={(application) => {
                    const newApplication = { ...application };
                    newApplication.task = task.id;
                    newApplication.shift = shift.id;

                    acceptApplication(newApplication).then(() => {
                        getTask(task.id);
                    });
                }}
                onCancel={(application) => {
                    addToStack({
                        name: '',
                        component: (
                            <CancelApplicationContainer
                                targetApplication={application}
                                parentTask={{ ...task }}
                                parentShift={{ ...shift }}
                            />
                        ),
                    });
                }}
                onDeny={(application) => {
                    console.log(this.props);
                    addToStack({
                        name: '',
                        component: (
                            <DenyApplicationContainer
                                targetApplication={application}
                                parentTask={{ ...task }}
                                parentShift={{ ...shift }}
                            />
                        ),
                    });
                }}
                updateApplication={(application) =>
                    updateApplication(application).then(() => {
                        getTask(task.id);
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        userProfile: state.auth.profile,
        platform: state.platform.platform,
        ...state.task,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),

        getTask: (id) => dispatch(taskActions.getTask(id)),

        acceptApplication: (application) => dispatch(taskActions.acceptApplication(application)),
        updateApplication: (application) => dispatch(taskActions.updateApplication(application)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TaskContainer));
