import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SupportTicketPage from "../../components/support/SupportTicketPage";
import * as supportActions from "../../state/actions/supportActions";
import * as userActions from "../../state/actions/userActions";
import * as navigationActions from "../../state/actions/navigationActions";

class SupportTicketContainer extends Component {
    render() {
        const { changeValue, updateTicket, getTicket, searchUsers, ...props } = this.props;

        const { t } = this.props;

        return (
            <SupportTicketPage
                {...props}
                onChange={changeValue}
                fetchTicket={(id, token = "") => getTicket(id, token)}
                updateTicket={(ticket) => updateTicket(t, ticket)}
                
                searchUsers={(searchParameters, from, amount) => searchUsers(searchParameters, from, amount)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,

        supportTicket: state.support.supportTicket,
        isFetchingSupportTicket: state.support.isFetchingSupportTicket,

        isFetchingUsers: state.user.isFetchingUsers,
        users: state.user.users,
        usersTotalResults: state.user.usersTotalResults,
        usersLoaded: state.user.userLoaded,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(supportActions.changeValue(name, value)),
        updateTicket: (t, ticket) => dispatch(supportActions.updateTicket(t, ticket)),
        getTicket: (id, token) => dispatch(supportActions.getTicket(id, token)),

        searchUsers: (searchParameters, from, amount) => dispatch(userActions.searchUsers(searchParameters, from, amount)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(SupportTicketContainer));
