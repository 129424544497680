import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

import * as taskActions from "../../../state/actions/taskActions";
import * as navigationActions from "../../../state/actions/navigationActions";

import UpdateShiftPage from "../../../components/task/crud/UpdateShiftPage";

class UpdateShiftContainer extends Component {
    componentDidMount() {
        const { changeValue, targetShift } = this.props;

        const newShift = { ...targetShift };

        newShift.date = moment(newShift.startsAt).format("DD/MM/YYYY");
        newShift.startTime = moment(newShift.startsAt).format("HH:mm");
        newShift.endTime = moment(newShift.endsAt).format("HH:mm");
        changeValue("formShift", newShift);
    }

    render() {
        const { history, popStack, callback, changeValue, updateShift, ...props } = this.props;

        return (
            <UpdateShiftPage
                {...props}
                onChange={changeValue}
                updateShift={(shift) =>
                    updateShift(shift).then((response) => {
                        popStack && popStack();
                        callback && callback(response.shift);
                    })
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        categories: state.platform.categories,
        currencies: state.wallet.currencies,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        updateShift: (shift) => dispatch(taskActions.updateShift(shift)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateShiftContainer));
