import React, { Component } from 'react';
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';
import { doubleToInt } from '../../../utils/moneyUtils';

import Loader from '../../ui/components/Loader';
import PaymentOptions from './PaymentOptions';

export default class AddCreditPage extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            tab: 'credit',
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { createCharge } = this.props;
        createCharge();
    }

    changePaymentType(type) {
        const { onChange, charge } = this.props;

        this.setState({ tab: type });
        const newCharge = { ...charge };
        const source = { ...(charge.source || {}) };
        source.type = type;
        newCharge.source = source;

        onChange('charge', newCharge);
    }

    render() {
        const { onChange, t, isFetching, isFetchingCurrencies, charge, source, currencies } = this.props;

        if (isFetching || isFetchingCurrencies || !charge) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        if (source) {
            window.location = source.redirect.url;
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        const currencyOptions = currencies.map((currency) => ({
            value: currency.short,
            label: currency.name,
            sign: currency.sign,
            currency: currency,
        }));

        return (
            <div className="container big-box">
                <div className="wrapper normal top">
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <h1 className="center">{t('wallet.addCredit')}</h1>
                        <div className="input-container">
                            <div className="input-group">
                                <div className="input-group fifth">
                                    <Select
                                        options={currencyOptions}
                                        value={
                                            charge.currency
                                                ? charge.currency
                                                : currencies.length > 0
                                                ? currencyOptions[0]
                                                : null
                                        }
                                        onChange={(e) => {
                                            const newCharge = { ...charge };
                                            const amount = { ...newCharge.amount };

                                            amount.currency = e.currency;
                                            newCharge.amount = amount;

                                            onChange('charge', newCharge);
                                        }}
                                    />
                                </div>
                                <div className="input-group eighty">
                                    <CurrencyFormat
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        value={charge?.amount?.formattedValue}
                                        prefix={charge.currency ? charge.currency.sign : '€'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        onValueChange={(values) => {
                                            const newCharge = { ...charge };
                                            const amount = { ...newCharge.amount };

                                            amount.amount = doubleToInt(parseFloat(values.value));
                                            amount.formattedAmount = values.formattedValue;

                                            newCharge.amount = amount;

                                            onChange('charge', newCharge);
                                        }}
                                    />
                                    <label>{t('form.label.amount')}</label>
                                </div>
                            </div>
                            <div className="input-group more">
                                <PaymentOptions
                                    charge={charge}
                                    onChange={(newCharge) => {
                                        onChange('charge', newCharge);
                                    }}
                                />
                            </div>
                            <div className="input-group right">
                                <input type="submit" value={t('form.next')} onClick={(e) => e.stopPropagation()} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
