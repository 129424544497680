import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import Address from '../../models/general/Address';

import TaskUser from './TaskUser';
import TaskBusiness from './TaskBusiness';
import TaskShifts from './TaskShifts';
import Loader from '../ui/components/Loader';
import Marker from '../ui/components/Marker';
import ShareLink from '../ui/components/ShareLink';

import UpdateTaskContainer from '../../containers/task/crud/UpdateTaskContainer';
import CreateApplicationContainer from '../../containers/task/crud/CreateApplicationContainer';

import { intToDouble } from '../../utils/moneyUtils';
import { sluggify } from '../../utils/stringUtils';

export default class TaskPage extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 12,
    };

    state = {
        selectedShifts: [],
    };

    _openCreateApplication(negotiate = false) {
        const { selectedShifts } = this.state;
        const { t, popStack, history, addToStack, task } = this.props;

        addToStack({
            name: t('task.application.header'),
            component: (
                <CreateApplicationContainer
                    negotiate={negotiate}
                    shifts={selectedShifts}
                    task={task}
                    onNavigateTo={(url) => {
                        popStack();
                        history.push(url);
                    }}
                />
            ),
        });
    }

    componentDidMount() {
        const { getTask, match, userProfile } = this.props;
        getTask(match.params.id).then(() => {
            const { task, match } = this.props;
            if (task && task.shifts) {
                const shifts = task.shifts.filter((shift) => shift.amount > 0) || [];
                if (match.params.shift && shifts.length > 0) {
                    this.setState({
                        selectedShifts: [
                            ...(shifts
                                .filter((shift) => shift.id === match.params.shift)
                                .filter(
                                    (shift) => !shift.applications.map((a) => a.applicant.id).includes(userProfile.id)
                                )
                                .filter((shift) => moment(shift.startsAt).isAfter(moment())) || []),
                        ],
                    });
                }
            }
        });
    }

    render() {
        const { t, task, taskIsFetching, platform, user, addToStack } = this.props;
        const { selectedShifts } = this.state;

        if (!taskIsFetching) {
            if (task) {
                const address = task.location ? task.location.address : new Address();
                const shareUrl = `${window.location.protocol}//${window.location.host}/task/${task.id}/${sluggify(
                    task.title
                )}`;

                return (
                    <div className="wrapper top minimal">
                        <div className="task">
                            <div className="container">
                                <div className="banner">
                                    <img
                                        className="photo"
                                        src={task.banners.length > 0 ? task.banners[0].url : '/images/task-banner.svg'}
                                        alt="task banner"
                                    ></img>
                                </div>
                                <div className="container flex-container">
                                    <div className="three">
                                        <div className="title flex-container">
                                            <div className="four">
                                                <h1>{task.title}</h1>
                                                <h2>{`${address.street} ${address.houseNumber || ''}${
                                                    address.houseNumberSuffix || ''
                                                }, ${address.zipCode} ${address.city || ''} `}</h2>
                                                <div className="description">
                                                    <p>{task.description}</p>
                                                </div>
                                            </div>
                                            <div className="menu">
                                                <ShareLink url={shareUrl} title={task.title} />
                                                <div className="edit">
                                                    <div className="actions action-icons">
                                                        <div
                                                            className="action action-icon"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                addToStack({
                                                                    name: t('task.update'),
                                                                    component: (
                                                                        <UpdateTaskContainer targetTask={{ ...task }} />
                                                                    ),
                                                                });
                                                            }}
                                                        >
                                                            <ReactSVG src="/icons/pencil.svg" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {task.descriptionParts.map((part) => (
                                            <div className="description-part" key={`description-part-${part.id}`}>
                                                <h2>{part.header}</h2>
                                                <p>{part.body}</p>
                                            </div>
                                        ))}
                                        <div className="keywords">
                                            {task.keywords
                                                .sort((a, b) => a.header - b.header)
                                                .map((keyword) => (
                                                    <div className="keyword" key={`keyword-${keyword.id}`}>
                                                        <h2>{keyword.header}</h2>
                                                        <div className="tags">
                                                            {keyword.tags.map((tag) => (
                                                                <div
                                                                    className="tag"
                                                                    key={`keyword-${keyword.id}-tag-${tag.id}`}
                                                                >
                                                                    {tag.name}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        {task.type.toLowerCase() === 'shifts' && !task.isOwner && (
                                            <div>
                                                <div className="shifts">
                                                    <h2>{t('task.shifts.availableShifts')}</h2>
                                                    <TaskShifts
                                                        {...this.props}
                                                        defaultSelected={selectedShifts}
                                                        onChange={(selectedShifts) => {
                                                            this.setState({ selectedShifts });
                                                        }}
                                                    />
                                                </div>
                                                <div className={`apply${selectedShifts.length > 0 ? '' : ' disabled'}`}>
                                                    <div className="flex-container justify-between">
                                                        {selectedShifts.length > 0 ? (
                                                            <div className="price">
                                                                <span className="amount">
                                                                    {`${
                                                                        selectedShifts[0].payout.money.currency.sign
                                                                    }${intToDouble(
                                                                        selectedShifts
                                                                            .map(
                                                                                (shift) =>
                                                                                    (shift.payout.money.amount / 60) *
                                                                                    moment
                                                                                        .duration(
                                                                                            moment(shift.endsAt).diff(
                                                                                                moment(shift.startsAt)
                                                                                            )
                                                                                        )
                                                                                        .asMinutes()
                                                                            )
                                                                            .reduce((a, b) => a + b, 0),
                                                                        2,
                                                                        user.language.decimalDelimiter,
                                                                        user.language.thousandDelimiter
                                                                    )} `}
                                                                </span>
                                                                {`${t('task.forShifts', {
                                                                    count: selectedShifts.length,
                                                                })}`}
                                                            </div>
                                                        ) : (
                                                            <div className="price"></div>
                                                        )}
                                                        <div className={`apply`}>
                                                            <Link
                                                                to=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this._openCreateApplication(true);
                                                                }}
                                                            >
                                                                {t('task.counterOffer', {
                                                                    counter: selectedShifts.length,
                                                                })}
                                                            </Link>
                                                            <button
                                                                onClick={() => {
                                                                    this._openCreateApplication();
                                                                }}
                                                            >
                                                                {t('task.apply', {
                                                                    count: selectedShifts.length,
                                                                })}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="small-side">
                                        {task.employerType === 'personal' ? (
                                            <TaskUser {...this.props} profile={task.employerProfile} />
                                        ) : (
                                            <TaskBusiness {...this.props} business={task.employerBusiness} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {task.location && (
                                <MapContainer
                                    className="bottom-maps"
                                    center={[
                                        parseFloat(task.location.latitude || 52.0907),
                                        parseFloat(task.location.longitude || 5.1214),
                                    ]}
                                    zoom={11}
                                    preferCanvas={false}
                                    attributionControl={false}
                                    zoomControl={false}
                                    dragging={false}
                                    scrollWheelZoom={false}
                                >
                                    <TileLayer url="https://api.mapbox.com/styles/v1/thomas-n0name/ckgtd8z7521u919paxtu0q5m1/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhvbWFzLW4wbmFtZSIsImEiOiJja2d0ZDVrM2owYXBoMnFwMGJ5Mm93amM1In0.NgqXPSaGRLuK-OhI_2_6eQ" />

                                    <Marker
                                        lat={task.location.latitude}
                                        lng={task.location.longitude}
                                        name={task.name}
                                        color={platform.primaryColor}
                                    />
                                </MapContainer>
                            )}
                            {task.type.toLowerCase() === 'shifts' && selectedShifts.length > 0 && !task.isOwner && (
                                <div className="apply-banner">
                                    <div className="container">
                                        <div className="flex-container justify-between">
                                            <div className="price">
                                                <span className="amount">
                                                    {`${selectedShifts[0].payout.money.currency.sign}${intToDouble(
                                                        selectedShifts
                                                            .map(
                                                                (shift) =>
                                                                    (shift.payout.money.amount / 60) *
                                                                    moment
                                                                        .duration(
                                                                            moment(shift.endsAt).diff(
                                                                                moment(shift.startsAt)
                                                                            )
                                                                        )
                                                                        .asMinutes()
                                                            )
                                                            .reduce((a, b) => a + b, 0),
                                                        2,
                                                        user.language.decimalDelimiter,
                                                        user.language.thousandDelimiter
                                                    )} `}
                                                </span>
                                                {`${t('task.forShifts', {
                                                    count: selectedShifts.length,
                                                })}`}
                                            </div>
                                            <div className="apply">
                                                <Link
                                                    to=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this._openCreateApplication(true);
                                                    }}
                                                >
                                                    {t('task.counterOffer', {
                                                        counter: selectedShifts.length,
                                                    })}
                                                </Link>
                                                <button
                                                    onClick={() => {
                                                        this._openCreateApplication();
                                                    }}
                                                >
                                                    {t('task.apply', { count: selectedShifts.length })}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="absolute-center">
                <Loader />
            </div>
        );
    }
}
