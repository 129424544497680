import { profileConstants } from "../constants";

const initialState = {
    profileIsFetching: false,
    profile: null,
    formProfile: null,

    cv: null,
    isFetchingCV: false,

    profilesIsFetching: false,
    profiles: [],

    dateOfBirthValue: "",

    fetchingAvatar: false,
    fetchingBanner: false,

    education: null,
    formEducation: null,
    isFetchingEducation: false,

    experience: null,
    formExperience: null,
    isFetchingExperience: false,
};

export default function profile(state = initialState, action) {
    switch (action.type) {
        case profileConstants.CREATING_PROFILE_CHANGE_VALUE:
        case profileConstants.UPDATING_PROFILE_CHANGE_VALUE:
        case profileConstants.PROFILE_CHANGE_VALUE:
            return {
                ...state,
                error: "",
                [action.name]: action.value,
            };

        case profileConstants.CREATING_PROFILE_STARTED:
            return { ...state, profileIsFetching: true };
        case profileConstants.CREATING_PROFILE_FAILURE:
            return { ...state, profileIsFetching: false, message: action.message || "" };
        case profileConstants.CREATING_PROFILE_SUCCESS:
            return {
                ...state,
                cv: action.profile.cv,
                profile: action.profile,
                profileIsFetching: false,
                message: action.message || "",
            };

        case profileConstants.UPDATING_PROFILE_STARTED:
            return { ...state, profileIsFetching: true, message: "" };
        case profileConstants.UPDATING_PROFILE_FAILURE:
            return { ...state, profileIsFetching: false, message: action.message || "" };
        case profileConstants.UPDATING_PROFILE_SUCCESS:
            return {
                ...state,
                cv: action.profile.cv,
                profile: action.profile,
                profileIsFetching: false,
                message: action.message || "",
            };

        case profileConstants.UPDATE_PROFILE_AVATAR_STARTED:
            return { ...state, fetchingAvatar: true, message: "" };
        case profileConstants.UPDATE_PROFILE_AVATAR_FAILURE:
            return { ...state, fetchingAvatar: false, message: action.message || "" };
        case profileConstants.UPDATE_PROFILE_AVATAR_SUCCESS:
            const newProfileAvatar = { ...state.profile };
            newProfileAvatar.avatar = action.avatar;
            return { ...state, fetchingAvatar: false, profile: newProfileAvatar };

        case profileConstants.UPDATE_PROFILE_BANNER_STARTED:
            return { ...state, fetchingBanner: true, message: "" };
        case profileConstants.UPDATE_PROFILE_BANNER_FAILURE:
            return { ...state, fetchingBanner: false, message: action.message || "" };
        case profileConstants.UPDATE_PROFILE_BANNER_SUCCESS:
            const newProfileBanner = { ...state.profile };
            newProfileBanner.banner = action.banner;
            return { ...state, fetchingBanner: false, profile: newProfileBanner };

        case profileConstants.GET_PROFILE_STARTED:
            return { ...state, profileIsFetching: true, profile: null, cv: null, message: "" };
        case profileConstants.GET_PROFILE_FAILURE:
            return { ...state, profileIsFetching: false, message: action.message || "" };
        case profileConstants.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profileIsFetching: false,
                profile: action.profile,
                cv: action.profile.cv,
                message: action.message || "",
            };

        case profileConstants.SEARCH_PROFILE_STARTED:
            return { ...state, profilesIsFetching: true, profiles: [], message: "" };
        case profileConstants.SEARCH_PROFILE_FAILURE:
            return { ...state, profilesIsFetching: false, message: action.message || "" };
        case profileConstants.SEARCH_PROFILE_SUCCESS:
            return {
                ...state,
                profilesIsFetching: false,
                profiles: action.from > 0 ? [...state.profiles, ...action.profiles] : action.profiles,
                profilesLoaded:
                    action.from > 0 ? state.profiles.length + action.profiles.length : action.profiles.length,
                profilesTotalResults: action.totalResults || 0,
                message: action.message || "",
            };

        case profileConstants.UPDATE_CV_STARTED:
            return { ...state, isFetchingCV: true, cv: null, message: "" };
        case profileConstants.UPDATE_CV_FAILURE:
            return { ...state, isFetchingCV: false, message: action.message || "" };
        case profileConstants.UPDATE_CV_SUCCESS:
            return {
                ...state,
                cv: action.cv,
                isFetchingCV: false,
                message: action.message || "",
            };

        //Education
        case profileConstants.CREATE_EDUCATION_STARTED:
        case profileConstants.UPDATE_EDUCATION_STARTED:
        case profileConstants.DELETE_EDUCATION_STARTED:
            return {
                ...state,
                isFetchingEducation: true,
            };
        case profileConstants.CREATE_EDUCATION_FAILURE:
        case profileConstants.UPDATE_EDUCATION_FAILURE:
        case profileConstants.DELETE_EDUCATION_FAILURE:
            return {
                ...state,
                isFetchingEducation: false,
                message: action.message || "",
            };
        case profileConstants.CREATE_EDUCATION_SUCCESS:
        case profileConstants.UPDATE_EDUCATION_SUCCESS:
            const newCV = { ...state.cv };
            const newEducations = [...newCV.educations];
            const index = newEducations.findIndex((nE) => nE === action.education.id);
            newEducations[index > 0 ? index : 0] = action.education;
            newCV.educations = newEducations;
            return {
                ...state,
                isFetchingEducation: false,
                cv: newCV,
            };

        case profileConstants.DELETE_EDUCATION_SUCCESS:
            const newCV2 = { ...state.cv };
            const newEducations2 = [...newCV2.educations];
            newCV2.educations = newEducations2.filter(
                (newEducation) => action.education.id !== newEducation.id
            );
            return {
                ...state,
                isFetchingEducation: false,
                cv: newCV2,
            };

        //Experience
        case profileConstants.CREATE_EXPERIENCE_STARTED:
        case profileConstants.UPDATE_EXPERIENCE_STARTED:
        case profileConstants.DELETE_EXPERIENCE_STARTED:
            return {
                ...state,
                isFetchingExperience: true,
            };
        case profileConstants.CREATE_EXPERIENCE_FAILURE:
        case profileConstants.UPDATE_EXPERIENCE_FAILURE:
        case profileConstants.DELETE_EXPERIENCE_FAILURE:
            return {
                ...state,
                isFetchingExperience: false,
                message: action.message || "",
            };
        case profileConstants.CREATE_EXPERIENCE_SUCCESS:
        case profileConstants.UPDATE_EXPERIENCE_SUCCESS:
            const newCV3 = { ...state.cv };
            const newExperiences3 = [...newCV3.experiences];
            const index3 = newExperiences3.findIndex((nE) => nE === action.experience.id);
            newExperiences3[index3 > 0 ? index3 : 0] = action.experience;
            newCV3.experiences = newExperiences3;
            return {
                ...state,
                isFetchingExperience: false,
                cv: newCV3,
            };

        case profileConstants.DELETE_EXPERIENCE_SUCCESS:
            const newCV4 = { ...state.cv };
            const newExperiences4 = [...newCV4.experiences];
            newCV4.experiences = newExperiences4.filter(
                (newExperience) => action.experience.id !== newExperience.id
            );
            return {
                ...state,
                isFetchingExperience: false,
                cv: newCV4,
            };
        default:
            return state;
    }
}
