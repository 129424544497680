import React, { Component } from 'react';

import Loader from '../../../ui/components/Loader';
import AsideView from '../../../ui/components/AsideView';

import Review from '../../../../models/rating/review/Review';

import ReviewForm from './ReviewForm';

export default class CreateReviewPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        const newReview = new Review();
        newReview.type = 'task';
        onChange('formReview', newReview);
    }

    render() {
        const { t, formReview, isFetchingReview, createReview, popStack, callback } = this.props;

        if (isFetchingReview || !formReview) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t('rating.review.new.header')}</h1>
                <ReviewForm
                    {...this.props}
                    onSubmit={(rating, review) =>
                        createReview(rating, review).then((response) => {
                            popStack && popStack();
                            callback && callback(response.review);
                        })
                    }
                />
            </AsideView>
        );
    }
}
