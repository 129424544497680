import { socketConstants } from "../constants";

export const newChatMessage = (message) => ({
    type: socketConstants.NEW_CHAT_MESSAGE,
    ...message
});

export const registerSocket = (message) => ({
    type: socketConstants.REGISTER_SOCKET,
    ...message
});

export const newNotification = (message) => ({
    type: socketConstants.NEW_NOTIFICATION,
    ...message
});