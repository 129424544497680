import { chatConstants, socketConstants } from "../constants";
import moment from "moment";

import { flatten } from "../../utils/arrayUtils";

const initialState = {
    chatsIsFetching: false,
    chats: [],
    chatsLoaded: 0,
    chatsTotalResults: 0,

    chatIsFetching: false,
    chat: null,
    formChat: null,

    messagesIsFetching: false,
    message: null,
    messages: [],
    messagesLoaded: 0,
    messagesTotalResults: 0,
    messagesUnread: 0,
};

export default function chat(state = initialState, action) {
    switch (action.type) {
        case chatConstants.CHAT_CHANGE_VALUE:
            return {
                ...state,
                error: "",
                [action.name]: action.value,
            };

        case chatConstants.GET_CHATS_STARTED:
            return { ...state, chatsIsFetching: true };
        case chatConstants.GET_CHATS_FAILURE:
            return { ...state, chatsIsFetching: false, message: action.message || "" };
        case chatConstants.GET_CHATS_SUCCESS:
            return {
                ...state,
                chatsIsFetching: false,
                chats: action.chats || [],
                chatsTotalResults: action.totalResults,
                messagesUnread: action.messagesUnread,
            };

        case chatConstants.GET_CHAT_STARTED:
            return {
                ...state,
                chatIsFetching: true,
                messages: [],
                messagesLoaded: 0,
                messagesTotalResults: 0,
            };
        case chatConstants.GET_CHAT_FAILURE:
            return { ...state, chatIsFetching: false, message: action.message || "" };
        case chatConstants.GET_CHAT_SUCCESS:
            return { ...state, chatIsFetching: false, chat: action.chat };

        case chatConstants.GET_MESSAGES_STARTED:
            return { ...state, messagesIsFetching: true };
        case chatConstants.GET_MESSAGES_FAILURE:
            return { ...state, messagesIsFetching: false, message: action.message || "" };
        case chatConstants.GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messagesIsFetching: false,
                messages: groupMessages(
                    action.from > 0
                        ? [...action.messages, ...flatten([...state.messages].reverse())]
                        : action.messages || []
                ),
                messagesLoaded:
                    action.from > 0
                        ? [...action.messages, ...flatten([...state.messages].reverse())].length
                        : action.messages.length,
                messagesTotalResults: action.totalResults,
            };

        case chatConstants.CREATE_MESSAGE_STARTED:
            return {
                ...state,
                messages: groupMessages([...flatten([...state.messages].reverse()), action.message]),
            };
        case chatConstants.CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                messages: groupMessages([
                    ...flatten([...state.messages].reverse()).map((message) =>
                        action.message.nonce === message.nonce ? action.message : message
                    ),
                ]),
                chats: [...state.chats].map((chat) => {
                    if (chat.id === action.message.chat) {
                        const newChat = { ...chat };
                        newChat.latest = action.message;
                        return newChat;
                    } else return chat;
                }),
            };
        case chatConstants.READ_MESSAGE_SUCCESS:
            return {
                ...state,
                messagesUnread: state.messagesUnread - action.messages.length,
                messages: [...state.messages].map(
                    (obj) => action.messages.find((o) => o.id === obj.id) || obj
                ),
            };
        case socketConstants.NEW_CHAT_MESSAGE:
            console.log(action);

            if (state.chat && state.chat.id === action.message.chat) {
                return {
                    ...state,
                    messages: groupMessages([...flatten([...state.messages].reverse()), action.message]),
                    chats: [...state.chats].map((chat) => {
                        if (chat.id === action.message.chat) {
                            const newChat = { ...chat };
                            newChat.latest = action.message;
                            return newChat;
                        } else return chat;
                    }),
                };
            } else {
                return {
                    ...state,
                    chats: [...state.chats].map((chat) => {
                        if (chat.id === action.message.chat) {
                            const newChat = { ...chat };
                            newChat.latest = action.message;
                            return newChat;
                        } else return chat;
                    }),
                    messagesUnread: state.messagesUnread + 1,
                };
            }

        default:
            return state;
    }
}

const groupMessages = (messages) => {
    let groups = [];
    messages.forEach((message) => {
        const previousGroup = groups[groups.length - 1];
        if (
            previousGroup instanceof Array &&
            previousGroup.length > 0 &&
            previousGroup[previousGroup.length - 1].user === message.user &&
            moment(message.createdAt) - moment(previousGroup[previousGroup.length - 1].createdAt) < 300000
        ) {
            previousGroup.push(message);
        } else {
            groups.push([message]);
        }
    });
    return groups.reverse();
};
