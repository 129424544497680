import api from '../api';

export const get = (id = '') => {
    return api.get(`/business/${id}`);
};

export const create = (business) => {
    return api.post(`/business`, business);
};

export const update = (business) => {
    return api.post(`/business`, business);
};

export const updateBusinessLogo = (logo) => {
    return api.post(`/business/logo`, logo);
};

export const updateBusinessBanner = (banner) => {
    return api.post(`/business/banner`, banner);
};

export const latest = (from = 0, amount = 20) => {
    return api.get(`/businesses/${from}/${amount}`);
};

export const personal = () => {
    return api.get(`/businesses/personal`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
    return api.post(`/businesses/${from}/${amount}`, searchParameters);
};
