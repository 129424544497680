import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import * as fileApi from '../../../api/fileApi';
import { formatBytes } from '../../../utils/fileUtils';
import { base64ToBlob, getBase64 } from '../../../utils/imageUtils';
import Progress from './Progress';

export default class Attachments extends Component {
    static defaultProps = {
        max: -1,
        accept: 'image/*|pdf/*|doc/*',
    };

    state = {
        files: [],
    };

    uploadFile = (file) => {
        const { onChange } = this.props;
        getBase64(file).then((base64) => {
            fileApi
                .uploadFile(base64ToBlob(base64), 'files', (e) => {
                    const files = [...this.state.files];
                    const prevFileIndex = files.findIndex((f) => f.id === file.id);
                    if (prevFileIndex >= 0) {
                        files[prevFileIndex].progress = (e.total / e.loaded) * 100;
                        files[prevFileIndex].uploading = true;
                        if (!files[prevFileIndex].base64) files[prevFileIndex].base64 = base64;
                        this.setState({
                            files,
                        });
                    }
                })
                .then((response) => {
                    const files = [...this.state.files];
                    const prevFileIndex = files.findIndex((f) => f.id === file.id);
                    if (prevFileIndex >= 0) {
                        if (response.success) {
                            files[prevFileIndex].externalReference = response.file;
                            if (onChange) onChange(files);
                        } else {
                            files[prevFileIndex].error = response.message;
                        }
                        files[prevFileIndex].uploading = false;
                        files[prevFileIndex].uploaded = true;
                        this.setState({
                            files,
                        });
                    }
                });
        });
    };

    render() {
        const { t, max, accept } = this.props;
        const { files } = this.state;
        return (
            <div className="upload-attachments">
                {files.length > 0 && (
                    <div className="input-group uploadedFiles">
                        {files.map((file) => {
                            return (
                                <div className="file">
                                    <img src={file.base64 || file.url} alt="" />
                                    <div className="info">
                                        <div className="name">
                                            {file.error ? file.error : file.name}
                                            <span>{formatBytes(file.size)}</span>
                                        </div>
                                        {file.progress >= 100 ? (
                                            <div className="state">{t('attachments.uploaded')}</div>
                                        ) : (
                                            <div className="state">
                                                <span>{t('attachments.uploading')}</span>
                                                <Progress progress={file.progress || 0} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="actions">
                                        {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                                        {file.uploaded && (
                                            <i
                                                className="fa fa-times behind"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.setState({
                                                        files: this.state.files.filter((f) => f.id !== file.id),
                                                    });
                                                }}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {(max < 0 || files.size < max) && (
                    <Dropzone
                        ref={this.dropzoneRef}
                        style={{ border: 'none' }}
                        activeStyle={{ border: 'none' }}
                        onDrop={(acceptedFiles) => {
                            const files = acceptedFiles.map((file) => {
                                file.id = Math.random().toString(36).substring(7);
                                return file;
                            });
                            this.setState((prevState) => ({ files: prevState.files.concat(files) }));

                            files.forEach((file) => {
                                this.uploadFile(file);
                            });
                        }}
                        accept={accept}
                    >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                            return (
                                <div
                                    className={`input-group drag-and-drop${isDragActive ? ' dragging' : ''}${
                                        isDragReject ? ' rejected' : ''
                                    }`}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <p>
                                        {isDragActive
                                            ? isDragReject
                                                ? t('dragAndDrop.rejected')
                                                : t('dragAndDrop.dragging')
                                            : t('dragAndDrop.label')}
                                    </p>
                                </div>
                            );
                        }}
                    </Dropzone>
                )}
            </div>
        );
    }
}
