import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import PlatformForm from "./PlatformForm";

export default class UpdatePlatformPage extends Component {
    componentDidMount() {
        const { onChange, targetPlatform } = this.props;
        onChange("formPlatform", targetPlatform);
    }

    render() {
        const { t, formPlatform, isFetchingFormPlatform, updatePlatform } = this.props;

        if (!formPlatform || isFetchingFormPlatform) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("platform.update")}</h1>
                <PlatformForm {...this.props} onSubmit={(platform) => updatePlatform(platform)} />
            </AsideView>
        );
    }
}
