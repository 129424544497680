import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { isMobile } from 'react-device-detect';

import HeaderChatsView from './HeaderChatsView';
import HeaderNotificationView from './HeaderNotificationView';
import HeaderAccount from './HeaderAccount';

import CreateTaskContainer from '../../containers/task/crud/CreateTaskContainer';
import CreateSupportTicketContainer from '../../containers/support/crud/CreateSupportTicketContainer';
import CreateBusinessContainer from '../../containers/business/crud/CreateBusinessContainer';
import NotificationsContainer from '../../containers/notification/NotificationsContainer';

import { truncate } from '../../utils/stringUtils';

const initialState = {
    notificationBox: false,
    messagesBox: false,
    accountBox: false,
    platformDropdownActive: false,
};

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    clickedDocument = (e) => {
        const { readNotifications } = this.props;
        const target = e.target;
        const { notificationBox, messagesBox, accountBox, platformDropdownActive } = this.state;
        if (notificationBox || messagesBox || accountBox) {
            if (!this.hasParentNode(target, 'menu')) {
                this.setState(initialState);
                if (notificationBox) readNotifications();
            }
        }
        if (platformDropdownActive) {
            if (!this.hasParentNode(target, 'dropdown')) {
                this.setState(initialState);
            }
        }
    };

    componentDidMount() {
        const { isAuthenticated, notifications, chats, getChats, getNotifications } = this.props;

        document.addEventListener('click', this.clickedDocument);

        if (isAuthenticated && notifications.length < 1) getNotifications();
        if (isAuthenticated && chats.length < 1) getChats();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickedDocument);
    }

    render() {
        let beta = parseFloat(process.env.REACT_APP_VERSION) >= 1 || process.env.MODE === 'development' ? '' : 'beta';

        const {
            t,
            match,
            history,
            isAuthenticated,
            user,
            addToStack,
            popStack,
            notificationsUnread,
            messagesUnread,
            getChats,
            getNotifications,
            readNotifications,
            chatNotVisible,
            setPlatform,
            logoLeft,
        } = this.props;

        const { notificationBox, messagesBox, accountBox, platformDropdownActive } = this.state;
        const platform =
            user && user.platforms && user.platforms.length > 0
                ? user.platforms.find((platform) => platform.id === this.props.platform.id)
                : this.props.platform;

        if (isAuthenticated) {
            if (user.status !== 'REGISTERED') {
                return (
                    <div className={'header ' + beta}>
                        <div className="container flex">
                            <Link to="/">
                                <img
                                    className="logo"
                                    src={platform.logo ? platform.logo.url : '/images/Vynwork.svg'}
                                    alt={`${platform.name} logo`}
                                />
                            </Link>
                            <div className="wrapper flex-container justify-end self-center">
                                <Link to="/logout">
                                    <button>{t('logout')}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className={`header ${beta} ${accountBox ? 'show' : ''}`}>
                    <div className="container flex" id="computer_header">
                        {user && user.platforms && user.platforms.length > 1 ? null : (
                            <Link to="/">
                                <img
                                    className="logo"
                                    src={platform.logo ? platform.logo.url : '/images/Vynwork.png'}
                                    alt={`${platform.name} logo`}
                                />
                            </Link>
                        )}
                        {user && user.platforms && user.platforms.length > 1 ? (
                            <div
                                className={`dropdown${platformDropdownActive ? ' active' : ''}`}
                                onClick={(e) => this.setState({ platformDropdownActive: !platformDropdownActive })}
                            >
                                <div className={`item`}>
                                    <img
                                        src={platform.logo ? platform.logo.url : '/images/Vynwork.png'}
                                        alt="logo"
                                    />
                                    <div className="name">{truncate(platform.name, 10)}</div>
                                    <div className="carrot">
                                        <svg
                                            height="20"
                                            width="20"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                            focusable="false"
                                        >
                                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="items scrollable">
                                    {user.platforms.map((platform) => {
                                        return (
                                            <div
                                                className={`item${
                                                    platform.id === this.props.platform.id ? ' active' : ''
                                                } `}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    setPlatform(platform);
                                                }}
                                                key={platform.id}
                                            >
                                                <img
                                                    src={platform.logo ? platform.logo.url : '/images/Vynwork.png'}
                                                    alt=""
                                                />
                                                <div className="name">{truncate(platform.name, 10)}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                        <div className="wrapper flex-container justify-end">
                            <button
                                className="create"
                                onClick={() => {
                                    addToStack({
                                        name: t('task.new'),
                                        component: (
                                            <CreateTaskContainer
                                                onNavigateTo={(url) => {
                                                    popStack();
                                                    history.push(url);
                                                }}
                                            />
                                        ),
                                    });
                                    this.setState(initialState);
                                }}
                            >
                                {t('menu.new.task')}
                            </button>
                            <div className="menu">
                                <div
                                    className="icon"
                                    onClick={(e) => {
                                        history.push('/dashboard');
                                        popStack();
                                    }}
                                >
                                    <span>
                                        <ReactSVG src="/icons/home.svg" />
                                    </span>
                                </div>
                                {!chatNotVisible && (
                                    <div
                                        className={`icon messages${messagesBox ? ' active' : ''} ${
                                            messagesUnread > 0 ? 'new' : ''
                                        }`}
                                        data-content={messagesUnread}
                                    >
                                        <span
                                            onClick={() => {
                                                if (!messagesBox) getChats();

                                                this.setState({
                                                    messagesBox: !messagesBox,
                                                    accountBox: false,
                                                    notificationBox: false,
                                                });
                                            }}
                                        >
                                            <ReactSVG src="/icons/messages.svg" />
                                        </span>
                                        <div className={'box ' + (messagesBox ? 'show' : '')}>
                                            <div className="n_header">
                                                <span>{t('chats.header')}</span>
                                                <div className="actions">
                                                    <Link to="/messages/new">{t('chat.new')}</Link>
                                                    <Link to="/messages">{t('details.header')}</Link>
                                                </div>
                                            </div>
                                            <HeaderChatsView {...this.props} />
                                        </div>
                                    </div>
                                )}

                                <div
                                    className={`icon notifications${notificationBox ? ' active' : ''} ${
                                        notificationsUnread > 0 ? 'new' : ''
                                    }`}
                                    data-content={notificationsUnread}
                                >
                                    <span
                                        onClick={() => {
                                            if (!notificationBox) getNotifications();
                                            if (notificationBox) readNotifications();

                                            this.setState({
                                                notificationBox: !notificationBox,
                                                accountBox: false,
                                                messagesBox: false,
                                            });
                                        }}
                                    >
                                        <ReactSVG src="/icons/notification.svg" />
                                    </span>
                                    <div className={'box ' + (notificationBox ? 'show' : '')}>
                                        <div className="n_header">
                                            <span>{t('notifications.header')}</span>
                                            <div className="actions">
                                                <Link
                                                    to={'/'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addToStack({
                                                            name: t('notifications.header'),
                                                            component: (
                                                                <NotificationsContainer
                                                                    onNavigateTo={(url) => {
                                                                        popStack();
                                                                        history.push(url);
                                                                    }}
                                                                />
                                                            ),
                                                        });
                                                        this.setState(initialState);
                                                    }}
                                                >
                                                    {t('details.header')}
                                                </Link>
                                            </div>
                                        </div>
                                        <HeaderNotificationView
                                            {...this.props}
                                            goToPage={(e) => {
                                                e.preventDefault();
                                                addToStack({
                                                    name: t('notifications.header'),
                                                    component: (
                                                        <NotificationsContainer
                                                            onNavigateTo={(url) => {
                                                                popStack();
                                                                history.push(url);
                                                            }}
                                                        />
                                                    ),
                                                });
                                                this.setState(initialState);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="listbutton"
                                    onClick={() => {
                                        this.setState({
                                            accountBox: !accountBox,
                                            notificationBox: false,
                                            messagesBox: false,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/menu.svg" />
                                </div>
                                <nav className={accountBox ? 'show' : ''}>
                                    <div className="content-box scrollable">
                                        <div
                                            className="closebutton"
                                            onClick={(e) => {
                                                this.setState({
                                                    accountBox: !accountBox,
                                                    notificationBox: false,
                                                    messagesBox: false,
                                                });
                                            }}
                                        />
                                        <HeaderAccount {...this.props} />
                                        <ul>
                                            <Link to="/profile">
                                                <li>{t('menu.myProfile')}</li>
                                            </Link>
                                            <Link to="/wallet">
                                                <li>{t('menu.wallet')}</li>
                                            </Link>

                                            {user.role.level >= 1000 && (
                                                <Link to="/categories">
                                                    <li>{t('menu.categories')}</li>
                                                </Link>
                                            )}
                                            {user.role.level >= 1000 && (
                                                <Link to="/contracts">
                                                    <li>{t('menu.contracts')}</li>
                                                </Link>
                                            )}
                                            {user.role.level >= 1000 && (
                                                <Link to="/users">
                                                    <li>{t('menu.users')}</li>
                                                </Link>
                                            )}
                                            {user.role.level >= 1000 && (
                                                <Link to="/roles">
                                                    <li>{t('menu.roles')}</li>
                                                </Link>
                                            )}
                                            {user.role.level >= 800 && (
                                                <Link to="/support">
                                                    <li>{t('menu.supportCenter')}</li>
                                                </Link>
                                            )}

                                            {user.role.level >= 1001 && (
                                                <Link to="/platforms">
                                                    <li>{t('menu.platforms')}</li>
                                                </Link>
                                            )}
                                            <hr />
                                            <li
                                                onClick={() => {
                                                    addToStack({
                                                        name: t('task.new'),
                                                        component: (
                                                            <CreateTaskContainer
                                                                onNavigateTo={(url) => {
                                                                    popStack();
                                                                    history.push(url);
                                                                }}
                                                            />
                                                        ),
                                                    });
                                                    this.setState(initialState);
                                                }}
                                            >
                                                {t('menu.new.task')}
                                            </li>
                                            <li
                                                onClick={() => {
                                                    addToStack({
                                                        name: t('business.new'),
                                                        component: (
                                                            <CreateBusinessContainer
                                                                onNavigateTo={(url) => {
                                                                    popStack();
                                                                    history.push(url);
                                                                }}
                                                            />
                                                        ),
                                                    });
                                                    this.setState(initialState);
                                                }}
                                            >
                                                {t('menu.new.business')}
                                            </li>
                                            <li
                                                onClick={() => {
                                                    addToStack({
                                                        name: t('support.new'),
                                                        component: (
                                                            <CreateSupportTicketContainer
                                                                onNavigateTo={(url) => {
                                                                    popStack();
                                                                    history.push(url);
                                                                }}
                                                            />
                                                        ),
                                                    });
                                                    this.setState(initialState);
                                                }}
                                            >
                                                {t('menu.new.support')}
                                            </li>
                                            <hr />
                                            <Link to="/settings">
                                                <li>{t('menu.settings')}</li>
                                            </Link>
                                            <Link to="/logout">
                                                <li id="logout">{t('menu.logout')}</li>
                                            </Link>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-menu">
                        <Link to="/" className={'section ' + (match.path === '/dashboard' ? 'active' : '')}>
                            <ReactSVG src="/icons/home.svg" />
                        </Link>
                        <Link
                            to="/messages"
                            className={'section ' + (match.path === '/messages' ? 'active' : '')}
                            data-content={messagesUnread}
                        >
                            <ReactSVG src="/icons/messages.svg" />
                        </Link>
                        <Link
                            to="/notifications"
                            className={'section ' + (match.path === '/notifications' ? 'active' : '')}
                            data-content={notificationsUnread}
                        >
                            <ReactSVG src="/icons/notification.svg" />
                        </Link>
                        <Link to="/wallet" className={'section ' + (match.path === '/wallet' ? 'active' : '')}>
                            <ReactSVG src="/icons/wallet.svg" />
                        </Link>
                        <Link to="/profile" className={'section ' + (match.path === '/profile' ? 'active' : '')}>
                            <ReactSVG src="/icons/profile.svg" />
                        </Link>
                    </div>
                </div>
            );
        } else if (!this.props.noHeader) {
            return (
                <div className={'header clear ' + beta}>
                    <div className="container">
                        <Link to="/">
                            <img
                                alt="logo"
                                className={`${logoLeft ? 'left' : 'middle'}`}
                                src={platform.logo ? platform.logo.url : '/images/Vynwork.svg'}
                            />
                        </Link>
                    </div>
                </div>
            );
        }

        return null;
    }
}
