import React, { Component } from "react";

import BusinessView from "./BusinessView";
import Loader from "../ui/components/Loader";

export default class BusinessPage extends Component {
    componentDidMount() {
        const { match, getBusiness } = this.props;
        getBusiness(match.params.id);
    }

    render() {
        const { businessIsFetching, business } = this.props;

        if (businessIsFetching || !business) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="business container">
                <div className="wrapper top minimal">
                    <BusinessView {...this.props} />
                </div>
            </div>
        );
    }
}
