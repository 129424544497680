import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import InvoicesView from '../../../components/wallet/invoice/InvoicesView';

import * as walletActions from '../../../state/actions/walletActions';
import * as navigationActions from '../../../state/actions/navigationActions';

class InvoicesContainer extends Component {
    render() {
        const { getInvoices, ...props } = this.props;

        return <InvoicesView {...props} getInvoices={(from = 0, to = 20) => getInvoices(from, to)} />;
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.wallet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInvoices: (from, to) => dispatch(walletActions.getInvoices(from, to)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(InvoicesContainer));
