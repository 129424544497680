import { CACHE_DATA } from '../../constants/constants.js';
import * as cache from '../../utils/cache.js';

import { authConstants } from '../constants';
import { profileConstants } from '../constants';
import { socketConstants } from '../constants';

const logoutState = {
    isFetchingUser: false,
    isFetching: false,
    isAuthenticated: false,
    error: '',

    bearer: '',
    session: null,
    socketId: '',
    user: null,

    isFetchingSessions: false,
    sessions: [],

    isFetchingRole: false,
    role: null,
    formRole: null,

    isFetchingRoles: false,
    rolesTotalResults: 0,
    rolesLoaded: 0,
    roles: [],
};

const initialState = (cache.read(CACHE_DATA) || {}).auth || logoutState;

export default function auth(state = initialState, action) {
    let cacheData = cache.read(CACHE_DATA) || {};

    switch (action.type) {
        case authConstants.CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case authConstants.RESET_FORM_STARTED:
            return { ...state, isFetching: true };
        case authConstants.RESET_FORM_FAILURE:
            return { ...state, isFetching: false, message: action.message || '' };
        case authConstants.RESET_FORM_SUCCESS:
            return { ...state, isFetching: false };

        case authConstants.FORGOT_FORM_STARTED:
            return { ...state, isFetching: true };
        case authConstants.FORGOT_FORM_FAILURE:
            return { ...state, isFetching: false, message: action.message || '' };
        case authConstants.FORGOT_FORM_SUCCESS:
            return { ...state, isFetching: false };

        case authConstants.LOGIN_FORM_STARTED:
            return { ...state, isFetching: true };
        case authConstants.LOGIN_FORM_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                error: action.message || '',
                password: '',
            };
        case authConstants.LOGIN_FORM_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                session: action.session,
                user: action.session.user,
                bearer: action.session.access_token,
                sessionId: action.session.id,
                username: '',
                email: '',
                password: '',
                repeatPassword: '',
            };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;

        case authConstants.REGISTER_FORM_STARTED:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case authConstants.REGISTER_FORM_FAILURE:
            state = {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                error: action.message,
                password: '',
                repeatPassword: '',
            };
            return state;
        case authConstants.REGISTER_FORM_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                bearer: action.sessionId,
                email: '',
                password: '',
                repeatPassword: '',
            };
            return state;
        case profileConstants.GET_PROFILE_SUCCESS:
            if (action.profile.user.id === state.user.id) {
                state = {
                    ...state,
                    profile: action.profile,
                };
                cacheData.auth = state;
                cache.write(CACHE_DATA, cacheData);
            }
            return state;
        case profileConstants.CREATING_PROFILE_SUCCESS:
            const newUser = { ...action.profile.user };
            newUser.profile = action.profile;
            state = {
                ...state,
                user: newUser,
            };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;
        case profileConstants.UPDATE_PROFILE_AVATAR_SUCCESS:
            const newProfileAvatar = { ...state.profile };
            newProfileAvatar.avatar = action.avatar;
            const newAvatarProfileUser = { ...newProfileAvatar.user };
            newAvatarProfileUser.profile = newProfileAvatar;
            state = {
                ...state,
                user: newAvatarProfileUser,
            };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;
        case profileConstants.UPDATE_PROFILE_BANNER_SUCCESS:
            const newProfileBanner = { ...state.profile };
            newProfileBanner.banner = action.banner;
            const newBannerProfileUser = { ...newProfileBanner.user };
            newBannerProfileUser.profile = newProfileBanner;
            state = {
                ...state,
                user: newBannerProfileUser,
            };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;

        case authConstants.UPDATE_USER_SUCCESS:
            state = { ...state, user: action.user };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;

        case authConstants.GET_SESSIONS_STARTED:
            return { ...state, isFetchingSessions: true };
        case authConstants.GET_SESSIONS_FAILURE:
            return { ...state, isFetchingSessions: false };
        case authConstants.GET_SESSIONS_SUCCESS:
            return { ...state, isFetchingSessions: false, sessions: action.sessions };

        case authConstants.LOGOUT_ALL_OTHERS_STARTED:
            return { ...state, isFetchingSessions: true };
        case authConstants.LOGOUT_ALL_OTHERS_FAILURE:
            return { ...state, isFetchingSessions: false };
        case authConstants.LOGOUT_ALL_OTHERS_SUCCESS:
            return {
                ...state,
                isFetchingSessions: false,
                sessions: state.sessions.filter((session) => !action.sessions.map((s) => s.id).includes(session.id)),
            };

        case authConstants.UPDATE_PASSWORD_STARTED:
            return { ...state, isFetchingUser: true };
        case authConstants.UPDATE_PASSWORD_FAILURE:
            return { ...state, isFetchingUser: false };
        case authConstants.UPDATE_PASSWORD_SUCCESS:
            return { ...state, isFetchingUser: false, sessions: [state.session] };

        case socketConstants.REGISTERED_SOCKET:
            state = { ...state, socketId: action.connection.id || '' };
            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;

        case authConstants.ROLE_CHANGE_VALUE:
            return { ...state, error: '', [action.name]: action.value };

        case authConstants.GET_ROLE_STARTED:
            return { ...state, isFetchingRole: true, error: null };
        case authConstants.GET_ROLE_FAILURE:
            return { ...state, isFetchingRole: false, error: 'Could not find role' };
        case authConstants.GET_ROLE_SUCCESS:
            return {
                ...state,
                isFetchingRole: false,
                role: action.role,
            };

        case authConstants.GET_ROLES_STARTED:
            return { ...state, isFetchingRoles: true };
        case authConstants.GET_ROLES_FAILURE:
            return {
                ...state,
                isFetchingRoles: false,
                roles: [],
                message: action.message || '',
            };
        case authConstants.GET_ROLES_SUCCESS:
            return {
                ...state,
                isFetchingRoles: false,
                roles: action.roles,
                supportTickets: action.from > 0 ? [...state.roles, ...action.roles] : action.roles,
                rolesLoaded: action.from > 0 ? state.roles.length + action.roles.length : action.roles.length,
                rolesTotalResults: action.totalResults,
            };

        case authConstants.CREATE_ROLE_STARTED:
            return { ...state, isFetchingRole: true };
        case authConstants.CREATE_ROLE_FAILURE:
            return { ...state, isFetchingRole: false, message: action.message || '' };
        case authConstants.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                isFetchingRole: false,
                role: action.role,
                roles: [...state.roles, action.role],
            };

        case authConstants.UPDATE_ROLE_STARTED:
            return { ...state, isFetchingRole: true };
        case authConstants.UPDATE_ROLE_FAILURE:
            return { ...state, isFetchingRole: false, message: action.message || '' };
        case authConstants.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                isFetchingRole: false,
                role: action.role,
                roles: [...state.roles].map((role) => (action.role.id === role.id ? action.role : role)),
            };
        case authConstants.DELETE_ROLE_STARTED:
            return { ...state, isFetchingRole: true };
        case authConstants.DELETE_ROLE_FAILURE:
            return { ...state, isFetchingRole: false, message: action.message || '' };
        case authConstants.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                isFetchingRole: false,
                role: action.role,
                roles: [...state.roles].filter((role) => action.role.id !== role.id),
            };

        case authConstants.LOGOUT_SUCCESS:
        case authConstants.LOGOUT_FAILURE:
            cache.clear(CACHE_DATA);
            return logoutState;
        default:
            return state;
    }
}
