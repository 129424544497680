import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import AboutForm from "./AboutForm";

export default class UpdateAboutPage extends Component {
    render() {
        const { t, cv, isFetchingCV, updateCV } = this.props;

        if (!cv || isFetchingCV) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("cv.about.update")}</h1>
                <AboutForm
                    {...this.props}
                    onSubmit={(cv) => updateCV(cv) }
                />
            </AsideView>
        );
    }
}
