export const settingsConstants = {
    GET_SETTINGS_STARTED: "GET_SETTINGS_STARTED",
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",

    UPDATE_SETTINGS_STARTED: "UPDATE_SETTINGS_STARTED",
    UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
    UPDATE_SETTINGS_FAILURE: "UPDATE_SETTINGS_FAILURE",

    SETTINGS_ON_CHANGE: "SETTINGS_ON_CHANGE",
};
