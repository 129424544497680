import React, { Component } from "react";

import AsideView from "../../ui/components/AsideView";
import SupportTicketForm from "./SupportTicketForm";

import SupportTicket from "../../../models/support/SupportTicket";

export default class CreateSupportTicket extends Component {
    componentDidMount() {
        const { profile, user, onChange } = this.props;
        const supportTicket = new SupportTicket();

        if (profile && user) {
            supportTicket.firstName = profile.person.firstName;
            supportTicket.lastName = profile.person.lastName;
            supportTicket.phoneNumber = profile.person.phoneNumber;
            supportTicket.email = user.email;
        }
        onChange("formSupportTicket", supportTicket);
    }

    render() {
        const { t, createTicket, formSupportTicket } = this.props;

        return (
            <AsideView>
                <h1>{t("support.ticket.new.header")}</h1>
                <SupportTicketForm {...this.props} onSubmit={(e) => createTicket(formSupportTicket)} />
            </AsideView>
        );
    }
}
