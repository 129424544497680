import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as taskActions from '../../../state/actions/taskActions';
import * as navigationActions from '../../../state/actions/navigationActions';

import CreateShiftPage from '../../../components/task/crud/CreateShiftPage';

class CreateShiftContainer extends Component {

    render() {
        const { history, onNavigateTo, popStack, changeValue, createShift, ...props } = this.props;

        return (
            <CreateShiftPage
                {...props}
                onChange={changeValue}
                createShift={(task, shift) =>
                    createShift(task, shift).then((response) => {
                        onNavigateTo ? onNavigateTo('/dashboard/schedule') : history.push('/dashboard/schedule');
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        categories: state.platform.categories,
        currencies: state.wallet.currencies,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        createShift: (task, shift) => dispatch(taskActions.createShift(task, shift)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateShiftContainer));
