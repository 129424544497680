import { articleConstants } from "../constants";
import * as articleApi from "../../api/articleApi";


export const changeValue = (name, value) => ({
    type: articleConstants.ARTICLE_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching article
 */
export const startGetArticle = () => ({
    type: articleConstants.GET_ARTICLE_STARTED,
});

export const getArticleSuccess = (result) => ({
    type: articleConstants.GET_ARTICLE_SUCCESS,
    ...result,
});

export const getArticleFailure = (error) => ({
    type: articleConstants.GET_ARTICLE_FAILURE,
    ...error,
});

export const getArticle = (id) => {
    return (dispatch) => {
        dispatch(startGetArticle());

        const promise = articleApi.get(id);

        promise
            .then((result) => {
                dispatch(getArticleSuccess(result));
            })
            .catch((error) => {
                dispatch(getArticleFailure(error));
            });

        return promise;
    };
};

/**
 * Creating article
 */
export const changeCreateArticle = (name, value) => ({
    type: articleConstants.CREATING_ARTICLE_CHANGE_VALUE,
    name,
    value,
});
export const startCreateArticle = () => ({
    type: articleConstants.CREATING_ARTICLE_STARTED,
});

export const createArticleSuccess = (result) => ({
    type: articleConstants.CREATING_ARTICLE_SUCCESS,
    ...result,
});

export const createArticleFailure = (error) => ({
    type: articleConstants.CREATING_ARTICLE_FAILURE,
    ...error,
});

export const createArticle = (article) => {
    return (dispatch) => {
        dispatch(startCreateArticle());

        const promise = articleApi.create(article);

        promise
            .then((result) => {
                dispatch(createArticleSuccess(result));
            })
            .catch((error) => {
                dispatch(createArticleFailure(error));
            });

        return promise;
    };
};

/**
 * Updating article
 */
export const changeUpdateArticle = (name, value) => ({
    type: articleConstants.UPDATING_ARTICLE_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateArticle = () => ({
    type: articleConstants.UPDATING_ARTICLE_STARTED,
});

export const updateArticleSuccess = (result) => ({
    type: articleConstants.UPDATING_ARTICLE_SUCCESS,
    ...result,
});

export const updateArticleFailure = (error) => ({
    type: articleConstants.UPDATING_ARTICLE_FAILURE,
    ...error,
});

export const updateArticle = (article) => {
    return (dispatch) => {
        dispatch(startUpdateArticle());

        const promise = articleApi.update(article);

        promise
            .then((result) => {
                dispatch(updateArticleSuccess(result));
            })
            .catch((error) => {
                dispatch(updateArticleFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching articles
 */
export const startGetArticles = () => ({
    type: articleConstants.GET_ARTICLES_STARTED,
});

export const getArticlesSuccess = (result, from) => ({
    type: articleConstants.GET_ARTICLES_SUCCESS,
    from: from,
    ...result,
});

export const getArticlesFailure = (error) => ({
    type: articleConstants.GET_ARTICLES_FAILURE,
    ...error,
});

export const getArticles = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetArticles());

        const promise = articleApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getArticlesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getArticlesFailure(error));
            });

        return promise;
    };
};

export const searchArticles = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetArticles());

        const promise = articleApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getArticlesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getArticlesFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching articles
 */
export const startGetUserArticles = () => ({
    type: articleConstants.GET_USER_ARTICLES_STARTED,
});

export const getUserArticlesSuccess = (result) => ({
    type: articleConstants.GET_USER_ARTICLES_SUCCESS,
    ...result,
});

export const getUserArticlesFailure = (error) => ({
    type: articleConstants.GET_USER_ARTICLES_FAILURE,
    ...error,
});

export const getUserArticles = (user, from, amount) => {
    return (dispatch) => {
        dispatch(startGetUserArticles());

        const promise = articleApi.userLatest(user, from, amount);

        promise
            .then((result) => {
                dispatch(getUserArticlesSuccess(result));
            })
            .catch((error) => {
                dispatch(getUserArticlesFailure(error));
            });

        return promise;
    };
};
