import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ChatsPage from '../../components/chat/ChatsPage';

import * as chatActions from '../../state/actions/chatActions';

class ChatsContainer extends Component {
    render() {
        const { ...props } = this.props;
        return <ChatsPage {...props} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        platform: state.platform.platform,
        chatsIsFetching: state.chat.chatsIsFetching,
        chatsTotalResults: state.chat.chatsTotalResults,
        chatsLoaded: state.chat.chatsLoaded,
        chats: state.chat.chats,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getChats: (from, to) => dispatch(chatActions.getChats(from, to)),
        searchChats: (searchParameters, from, amount) =>
            dispatch(chatActions.searchChats(searchParameters, from, amount)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ChatsContainer));
