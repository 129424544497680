import moment from 'moment';

import { taskConstants } from '../constants';
import * as taskApi from '../../api/taskApi';

/**
 * Fetching task
 */
export const startGetTask = () => ({
    type: taskConstants.GET_TASK_STARTED,
});

export const getTaskSuccess = (result) => ({
    type: taskConstants.GET_TASK_SUCCESS,
    ...result,
});

export const getTaskFailure = (error) => ({
    type: taskConstants.GET_TASK_FAILURE,
    ...error,
});

export const getTask = (id) => {
    return (dispatch) => {
        dispatch(startGetTask());

        const promise = taskApi.get(id);

        promise
            .then((result) => {
                dispatch(getTaskSuccess(result));
            })
            .catch((error) => {
                dispatch(getTaskFailure(error));
            });

        return promise;
    };
};

/**
 * Apply task
 */
export const startCreateApplication = () => ({
    type: taskConstants.CREATE_APPLICATION_STARTED,
});

export const createApplicationSuccess = (result) => ({
    type: taskConstants.CREATE_APPLICATION_SUCCESS,
    ...result,
});

export const createApplicationFailure = (error) => ({
    type: taskConstants.CREATE_APPLICATION_FAILURE,
    ...error,
});
export const createApplication = (application) => {
    return (dispatch) => {
        dispatch(startCreateApplication());

        const promise = taskApi.createApplication(application);

        promise
            .then((result) => {
                dispatch(createApplicationSuccess(result));
            })
            .catch((error) => {
                dispatch(createApplicationFailure(error));
            });

        return promise;
    };
};

/**
 * Update application
 */
export const startUpdateApplication = (application) => ({
    type: taskConstants.UPDATE_APPLICATION_STARTED,
    application,
});

export const updateApplicationSuccess = (result) => ({
    type: taskConstants.UPDATE_APPLICATION_SUCCESS,
    ...result,
});

export const updateApplicationFailure = (error, application) => ({
    type: taskConstants.UPDATE_APPLICATION_FAILURE,
    application: application,
    ...error,
});
export const updateApplication = (application) => {
    return (dispatch) => {
        dispatch(startUpdateApplication(application));

        const promise = taskApi.updateApplication(application);

        promise
            .then((result) => {
                dispatch(updateApplicationSuccess(result));
            })
            .catch((error) => {
                dispatch(updateApplicationFailure(error, application));
            });

        return promise;
    };
};

/**
 * Cancel application
 */
export const startCancelApplication = (application) => ({
    type: taskConstants.CANCEL_APPLICATION_STARTED,
    application: application,
});

export const cancelApplicationSuccess = (result) => ({
    type: taskConstants.CANCEL_APPLICATION_SUCCESS,
    ...result,
});

export const cancelApplicationFailure = (error, application) => ({
    type: taskConstants.CANCEL_APPLICATION_FAILURE,
    application: application,
    ...error,
});

export const cancelApplication = (application) => {
    return (dispatch) => {
        dispatch(startCancelApplication(application));

        const promise = taskApi.cancelApplication(application);

        promise
            .then((result) => {
                dispatch(cancelApplicationSuccess(result));
            })
            .catch((error) => {
                dispatch(cancelApplicationFailure(error, application));
            });

        return promise;
    };
};

/**
 * Accept application
 */
export const startDenyApplication = (application) => ({
    type: taskConstants.DENY_APPLICATION_STARTED,
    application: application,
});

export const denyApplicationSuccess = (result) => ({
    type: taskConstants.DENY_APPLICATION_SUCCESS,
    ...result,
});

export const denyApplicationFailure = (error, application) => ({
    type: taskConstants.DENY_APPLICATION_FAILURE,
    application: application,
    ...error,
});

export const denyApplication = (application) => {
    return (dispatch) => {
        dispatch(startDenyApplication());

        const promise = taskApi.denyApplication(application);

        promise
            .then((result) => {
                dispatch(denyApplicationSuccess(result));
            })
            .catch((error) => {
                dispatch(denyApplicationFailure(error));
            });

        return promise;
    };
};

/**
 * Accept application
 */
export const startAcceptApplication = (application) => ({
    type: taskConstants.ACCEPT_APPLICATION_STARTED,
    application: application,
});

export const acceptApplicationSuccess = (result) => ({
    type: taskConstants.ACCEPT_APPLICATION_SUCCESS,
    ...result,
});

export const acceptApplicationFailure = (error, application) => ({
    type: taskConstants.ACCEPT_APPLICATION_FAILURE,
    application: application,
    ...error,
});

export const acceptApplication = (application) => {
    return (dispatch) => {
        dispatch(startAcceptApplication(application));

        const promise = taskApi.acceptApplication(application);

        promise
            .then((result) => {
                dispatch(acceptApplicationSuccess(result));
            })
            .catch((error) => {
                dispatch(acceptApplicationFailure(error, application));
            });

        return promise;
    };
};

/**
 * Creating task
 */
export const changeCreateTask = (name, value) => ({
    type: taskConstants.CREATING_TASK_CHANGE_VALUE,
    name,
    value,
});
export const startCreateTask = () => ({
    type: taskConstants.CREATING_TASK_STARTED,
});

export const createTaskSuccess = (result) => ({
    type: taskConstants.CREATING_TASK_SUCCESS,
    ...result,
});

export const createTaskFailure = (error) => ({
    type: taskConstants.CREATING_TASK_FAILURE,
    ...error,
});

export const createTask = (task) => {
    return (dispatch) => {
        dispatch(startCreateTask());

        const promise = taskApi.create(task);

        promise
            .then((result) => {
                dispatch(createTaskSuccess(result));
            })
            .catch((error) => {
                dispatch(createTaskFailure(error));
            });

        return promise;
    };
};

/**
 * Creating task
 */
export const changeUpdateTask = (name, value) => ({
    type: taskConstants.UPDATING_TASK_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateTask = () => ({
    type: taskConstants.UPDATING_TASK_STARTED,
});

export const updateTaskSuccess = (result) => ({
    type: taskConstants.UPDATING_TASK_SUCCESS,
    ...result,
});

export const updateTaskFailure = (error) => ({
    type: taskConstants.UPDATING_TASK_FAILURE,
    ...error,
});

export const updateTask = (task) => {
    return (dispatch) => {
        dispatch(startUpdateTask());

        const promise = taskApi.update(task);

        promise
            .then((result) => {
                dispatch(updateTaskSuccess(result));
            })
            .catch((error) => {
                dispatch(updateTaskFailure(error));
            });

        return promise;
    };
};

/**
 * Creating shift
 */
export const changeCreateShift = (name, value) => ({
    type: taskConstants.CREATING_SHIFT_CHANGE_VALUE,
    name,
    value,
});
export const startCreateShift = () => ({
    type: taskConstants.CREATING_SHIFT_STARTED,
});

export const createShiftSuccess = (result) => ({
    type: taskConstants.CREATING_SHIFT_SUCCESS,
    ...result,
});

export const createShiftFailure = (error) => ({
    type: taskConstants.CREATING_SHIFT_FAILURE,
    ...error,
});

export const createShift = (task, shift) => {
    return (dispatch) => {
        dispatch(startCreateShift());

        const promise = taskApi.createShift(task, shift);

        promise
            .then((result) => {
                dispatch(createShiftSuccess(result));
            })
            .catch((error) => {
                dispatch(createShiftFailure(error));
            });

        return promise;
    };
};

/**
 * Creating shift
 */
export const changeUpdateShift = (name, value) => ({
    type: taskConstants.UPDATING_SHIFT_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateShift = () => ({
    type: taskConstants.UPDATING_SHIFT_STARTED,
});

export const updateShiftSuccess = (result) => ({
    type: taskConstants.UPDATING_SHIFT_SUCCESS,
    ...result,
});

export const updateShiftFailure = (error) => ({
    type: taskConstants.UPDATING_SHIFT_FAILURE,
    ...error,
});

export const updateShift = (shift) => {
    return (dispatch) => {
        dispatch(startUpdateShift());

        const promise = taskApi.updateShift(shift);

        promise
            .then((result) => {
                dispatch(updateShiftSuccess(result));
            })
            .catch((error) => {
                dispatch(updateShiftFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching tasks
 */
export const startGetTasks = () => ({
    type: taskConstants.GET_TASKS_STARTED,
});

export const getTasksSuccess = (result, from = 0) => ({
    type: taskConstants.GET_TASKS_SUCCESS,
    from: from,
    ...result,
});

export const getTasksFailure = (error) => ({
    type: taskConstants.GET_TASKS_FAILURE,
    ...error,
});

export const getTasks = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetTasks());

        const promise = taskApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getTasksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getTasksFailure(error));
            });

        return promise;
    };
};
export const getPersonalTasks = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetTasks());

        const promise = taskApi.personalLatest(from, amount);

        promise
            .then((result) => {
                dispatch(getTasksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getTasksFailure(error));
            });

        return promise;
    };
};
export const getPersonalTasksByDate = (fromDate, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetTasks());

        const promise = taskApi.personalLatestByDate(fromDate, from, amount);

        promise
            .then((result) => {
                dispatch(getTasksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getTasksFailure(error));
            });

        return promise;
    };
};

export const searchTasks = (searchParameters = {}, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetTasks());

        const promise = taskApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getTasksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getTasksFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching Shift
 */
export const startGetShift = () => ({
    type: taskConstants.GET_SHIFT_STARTED,
});

export const getShiftSuccess = (result) => ({
    type: taskConstants.GET_SHIFT_SUCCESS,
    ...result,
});

export const getShiftFailure = (error) => ({
    type: taskConstants.GET_SHIFT_FAILURE,
    ...error,
});

export const getShift = (id) => {
    return (dispatch) => {
        dispatch(startGetShift());

        const promise = taskApi.getShift(id);

        promise
            .then((result) => {
                dispatch(getShiftSuccess(result));
            })
            .catch((error) => {
                dispatch(getShiftFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching shifts
 */
export const startGetShifts = () => ({
    type: taskConstants.GET_SHIFTS_STARTED,
});

export const getShiftsSuccess = (result, from = 0) => ({
    type: taskConstants.GET_SHIFTS_SUCCESS,
    from: from,
    ...result,
});

export const getShiftsFailure = (error) => ({
    type: taskConstants.GET_SHIFTS_FAILURE,
    ...error,
});
export const getShifts = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.latestShifts(from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

export const searchShifts = (searchParameters = {}, fromDate, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.searchShifts(searchParameters, fromDate, from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

export const getShiftTasksByDate = (start = moment(), end = moment(), from, amount) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.getShiftTasksByDate(start, end, from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result));
            })
            .catch((error) => {
                console.log(error);
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

export const getPersonalShifts = (start = moment(), end = moment(), from, amount) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.getPersonalShifts(start, end, from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result));
            })
            .catch((error) => {
                console.log(error);
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

export const getPersonalShiftsByDate = (start = moment(), end = moment(), from, amount) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.getPersonalShiftsByDate(start, end, from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result));
            })
            .catch((error) => {
                console.log(error);
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

export const getPersonalShiftsByDateAndStatus = (start = moment(), end = moment(), status = 1, from, amount) => {
    return (dispatch) => {
        dispatch(startGetShifts());

        const promise = taskApi.getPersonalShiftsByDateAndStatus(start, end, (status = 1), from, amount);

        promise
            .then((result) => {
                dispatch(getShiftsSuccess(result));
            })
            .catch((error) => {
                console.log(error);
                dispatch(getShiftsFailure(error));
            });

        return promise;
    };
};

/**
 * Check in
 */
export const startCheckIn = (check) => ({
    type: taskConstants.CHECK_IN_STARTED,
    check: check,
});

export const checkInSuccess = (result) => ({
    type: taskConstants.CHECK_IN_SUCCESS,
    ...result,
});

export const checkInFailure = (error, check) => ({
    type: taskConstants.CHECK_IN_FAILURE,
    check: check,
    ...error,
});

export const checkIn = (checkIn) => {
    return (dispatch) => {
        dispatch(startCheckIn(checkIn));

        const promise = taskApi.checkIn(checkIn);

        promise
            .then((result) => {
                dispatch(checkInSuccess(result));
            })
            .catch((error) => {
                dispatch(checkInFailure(error, checkIn));
            });

        return promise;
    };
};
/**
 * Check out
 */
export const startCheckOut = (check) => ({
    type: taskConstants.CHECK_OUT_STARTED,
    check: check,
});

export const checkOutSuccess = (result) => ({
    type: taskConstants.CHECK_OUT_SUCCESS,
    ...result,
});

export const checkOutFailure = (error, check) => ({
    type: taskConstants.CHECK_OUT_FAILURE,
    check: check,
    ...error,
});

export const checkOut = (checkOut) => {
    return (dispatch) => {
        dispatch(startCheckOut(checkOut));

        const promise = taskApi.checkOut(checkOut);

        promise
            .then((result) => {
                dispatch(checkOutSuccess(result));
            })
            .catch((error) => {
                dispatch(checkOutFailure(error, checkOut));
            });

        return promise;
    };
};

/**
 * Fetching checks
 */
export const startGetChecks = () => ({
    type: taskConstants.GET_CHECKS_STARTED,
});

export const getChecksSuccess = (result, from = 0) => ({
    type: taskConstants.GET_CHECKS_SUCCESS,
    from: from,
    ...result,
});

export const getChecksFailure = (error) => ({
    type: taskConstants.GET_CHECKS_FAILURE,
    ...error,
});

export const getChecks = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChecks());

        const promise = taskApi.checksLatest(from, amount);

        promise
            .then((result) => {
                dispatch(getChecksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChecksFailure(error));
            });

        return promise;
    };
};

export const getChecksByDate = (start, end, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChecks());

        const promise = taskApi.checksLatestByDate(start, end, from, amount);

        promise
            .then((result) => {
                dispatch(getChecksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChecksFailure(error));
            });

        return promise;
    };
};

export const getShiftChecks = (shift, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChecks());

        const promise = taskApi.shiftChecksLatest(shift, from, amount);

        promise
            .then((result) => {
                dispatch(getChecksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChecksFailure(error));
            });

        return promise;
    };
};

export const getShiftChecksByDate = (shift, start, end, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChecks());

        const promise = taskApi.shiftChecksLatestByDate(start, end, from, amount);

        promise
            .then((result) => {
                dispatch(getChecksSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChecksFailure(error));
            });

        return promise;
    };
};

/**
 * Accept check
 */
export const startApproveCheck = (check) => ({
    type: taskConstants.APPROVE_CHECK_STARTED,
    check: check,
});

export const approveCheckSuccess = (result) => ({
    type: taskConstants.APPROVE_CHECK_SUCCESS,
    ...result,
});

export const approveCheckFailure = (error, check) => ({
    type: taskConstants.APPROVE_CHECK_FAILURE,
    check: check,
    ...error,
});

export const approveCheck = (check) => {
    return (dispatch) => {
        dispatch(startApproveCheck(check));

        const promise = taskApi.approveCheck(check);

        promise
            .then((result) => {
                dispatch(approveCheckSuccess(result));
            })
            .catch((error) => {
                dispatch(approveCheckFailure(error, check));
            });

        return promise;
    };
};

/**
 * Create dispute check
 */
export const startCreateCheckDispute = (check) => ({
    type: taskConstants.CREATE_CHECK_DISPUTE_STARTED,
    check: check,
});

export const createCheckDisputeSuccess = (result) => ({
    type: taskConstants.CREATE_CHECK_DISPUTE_SUCCESS,
    ...result,
});

export const createCheckDisputeFailure = (error, check) => ({
    type: taskConstants.CREATE_CHECK_DISPUTE_FAILURE,
    check: check,
    ...error,
});

export const createCheckDispute = (check) => {
    return (dispatch) => {
        dispatch(startCreateCheckDispute(check));

        const promise = taskApi.createCheckDispute(check);

        promise
            .then((result) => {
                dispatch(createCheckDisputeSuccess(result));
            })
            .catch((error) => {
                dispatch(createCheckDisputeFailure(error, check));
            });

        return promise;
    };
};
