import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import User from "../../../models/user/User";
import UserForm from "./UserForm";

export default class UpdateUserPage extends Component {

    componentDidMount() {
        const { onChange, targetUser } = this.props;
        onChange("formUser", targetUser || new User());
    }

    render() {
        const { t, formUser, isFetchingUser, updateUser, inviteUser } = this.props;

        if (!formUser || isFetchingUser) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("user.update")}</h1>
                <UserForm
                    {...this.props}
                    key={formUser.id}
                    onSubmit={(user) => updateUser(user)}
                    onInvite={(user) => inviteUser(user)}
                />
            </AsideView>
        );
    }
}
