import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreatingProfilePage from '../../../components/profile/crud/CreatingProfilePage';

import * as profileActions from '../../../state/actions/profileActions';
import * as contractActions from '../../../state/actions/contractActions';
import * as fileApi from '../../../api/fileApi';

import Profile from '../../../models/user/profile/Profile';

class CreateProfileContainer extends Component {
    componentDidMount() {
        const { changeValue } = this.props;
        changeValue('formProfile', new Profile());
    }

    render() {
        const { changeValue, createProfile, getContractByTitle, ...props } = this.props;

        const { frontIdentification, backIdentification, contract, signature, formProfile, history } = this.props;

        const profile = { ...formProfile } || new Profile();
        const person = { ...profile.person };

        const identification = [];
        if (frontIdentification)
            identification.push({
                file: frontIdentification.id,
                side: 'front',
            });
        if (backIdentification)
            identification.push({
                file: backIdentification.id,
                side: 'back',
            });

        profile.person = person;
        profile.identification = identification;
        profile.contract = contract ? contract.id : '';
        profile.signature = signature ? signature.id : '';

        return (
            <CreatingProfilePage
                {...props}
                onChange={changeValue}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
                getContractByTitle={(title) => getContractByTitle(title)}
                createProfile={() => createProfile(profile).then(() => history.push('/'))}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
        industries: state.platform.industries,
        contract: state.contract.contract,
        contractIsFetching: state.contract.contractIsFetching,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(profileActions.changeCreateProfileValue(name, value)),
        createProfile: (profile) => dispatch(profileActions.createProfile(profile)),
        getContractByTitle: (title) => dispatch(contractActions.getContractByTitle(title)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateProfileContainer));
