import React, { Component } from "react";

export default class BusinessInformation extends Component {
    render() {
        const { business } = this.props;
        const { address } = business;
        return (
            <>
                <div className="name">{business.name}</div>
                <div className="address-top">{`${address.street || ""} ${address.houseNumber || ""}${
                    address.houseNumberAddition || ""
                }`}</div>
                <div className="address-bottom">{`${address.zipCode || ""},  ${address.city || ""}, ${
                    address.country || ""
                }`}</div>
            </>
        );
    }
}
