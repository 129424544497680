import Money from './Money';

export default class Charge {

    id;
    nonce; 
    
    name;
    description;
    amount;

    startsAt;
    endsAt;

    constructor() {
        this.nonce = Math.random().toString(36).substring(7);

        this.amount = new Money();
    }
}