import React, { Component } from "react";
import ReactSVG from "react-svg";
import moment from "moment";

import PopOver from "../../ui/components/PopOver";

import UpdateExperienceContainer from "../../../containers/cv/experience/UpdateExperienceContainer";

export default class ExperienceCard extends Component {
    state = {
        popOvers: [],
    };

    render() {
        const { t, experience, addToStack, profile, deleteExperience } = this.props;
        const { popOvers } = this.state;
        const experienceTypeOptions = [
            {
                value: "FULL_TIME",
                label: t("experience.full-time"),
            },
            {
                value: "PART_TIME",
                label: t("experience.part-time"),
            },
            {
                value: "SELF_EMPLOYED",
                label: t("experience.self-employed"),
            },
            {
                value: "FREELANCE",
                label: t("experience.freelance"),
            },
            {
                value: "CONTRACT",
                label: t("experience.contract"),
            },
            {
                value: "INTERNSHIP",
                label: t("experience.internship"),
            },
            {
                value: "PTP",
                label: t("experience.professional-training-pathway"),
            },
        ];
        return (
            <div className="experience">
                {popOvers.map((popOver) => popOver)}
                <div className="flex-container justify-between">
                    <div className="flex-container">
                        {experience.schoolObject && <div></div>}
                        <div>
                            <h3 className="name">
                                {experience.business ? experience.business.name : experience.businessName}
                            </h3>
                            <div className="info">
                                {experienceTypeOptions.find((option) => option.value === experience.type)
                                    .label + ', ' || ""}
                                {experience.title}
                            </div>
                            <div className="timeline">
                                {moment(experience.startedAt).format("YYYY")} -{" "}
                                {experience.endedAt ? moment(experience.endedAt).format("YYYY") : t("presentDay")}
                            </div>
                            {experience.description && (
                                <div className="description" style={{ whiteSpace: "pre-wrap" }}>
                                    {experience.description}
                                </div>
                            )}
                        </div>
                    </div>

                    {profile.isOwner && (
                        <div>
                            <div className="flex-container action-icons">
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        this.setState({
                                            popOvers: [
                                                ...popOvers,
                                                <PopOver
                                                    isActive={true}
                                                    className="select-pop-over"
                                                    onClose={() => {
                                                        this.setState({
                                                            popOvers: [],
                                                        });
                                                    }}
                                                >
                                                    <div className="box">
                                                        <div className="title">
                                                            {t("cv.experience.delete.header")}
                                                        </div>
                                                        <div className="text">
                                                            {t("cv.experience.delete.description")}
                                                        </div>

                                                        <div className="choice">
                                                            <button
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    this.setState({
                                                                        popOvers: [],
                                                                    });
                                                                }}
                                                            >
                                                                {t("cancel")}
                                                            </button>
                                                            <div
                                                                className="as-link"
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    this.setState({
                                                                        popOvers: [],
                                                                    });
                                                                    deleteExperience(experience.id);
                                                                }}
                                                            >
                                                                {t("delete")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </PopOver>,
                                            ],
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/trash.svg" />
                                </div>
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        addToStack({
                                            name: t("cv.experience.update.header"),
                                            component: (
                                                <UpdateExperienceContainer targetExperience={experience} />
                                            ),
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/pencil.svg" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
