import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ShiftsView from '../../components/task/ShiftsView';
import * as taskActions from '../../state/actions/taskActions';

class PersonalShiftsContainer extends Component {
    render() {
        const { getPersonalShifts, getPersonalShiftsByDate, checkIn, checkOut, updateApplication, cancelApplication, ...props } = this.props;

        return (
            <ShiftsView
                {...props}
                getPersonalShifts={(from, amount) => getPersonalShifts(from, amount)}
                getPersonalShiftsByDate={(start, from, amount) => getPersonalShiftsByDate(start, from, amount)}

                checkIn={(check) => checkIn(check)}
                checkOut={(check) => checkOut(check)}

                updateApplication={(application) => updateApplication(application)}
                cancelApplication={(application) => cancelApplication(application)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPersonalShifts: (from, amount) => dispatch(taskActions.getPersonalShifts(from, amount)),
        getPersonalShiftsByDate: (start, from, amount) => dispatch(taskActions.getPersonalShiftsByDate(start, from, amount)),
        searchShifts: (searchParameters, from, to) => dispatch(taskActions.searchShifts(searchParameters, from, to)),

        checkIn: (check) => dispatch(taskActions.checkIn(check)),
        checkOut: (check) => dispatch(taskActions.checkOut(check)),

        updateApplication: (application) => dispatch(taskActions.updateApplication(application)),
        cancelApplication: (application) => dispatch(taskActions.cancelApplication(application)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(PersonalShiftsContainer));
