import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";

import CountrySelector from "../../ui/components/CountrySelector";
import { activateInputs } from "../../../utils/formUtils";

import Address from "../../../models/general/Address";

class AddressInput extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {
            address: props.address ? props.address : new Address(),
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    updateAddress = (newAddress) => {
        const { onChange } = this.props;
        this.setState({
            address: newAddress,
        });
        onChange && onChange(newAddress);
    };

    render() {
        const { address } = this.state;
        const { t } = this.props;

        return (
            <>
                <div className="input-group no-margin-top">
                    <div className="input-group eighty">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={address.street}
                                onChange={(event) => {
                                    const newAddress = { ...address };
                                    newAddress.street = event.target.value;

                                    this.updateAddress(newAddress);
                                }}
                            />
                            <label>{t("form.label.street")}*</label>
                        </div>
                        {this.validator.message(t("form.label.street"), address.street, "required")}
                    </div>
                    <div className="input-group fifth">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={address.houseNumber}
                                onChange={(event) => {
                                    const newAddress = { ...address };
                                    newAddress.houseNumber = event.target.value;

                                    this.updateAddress(newAddress);
                                }}
                            />
                            <label>{t("form.label.houseNumber")}*</label>
                        </div>
                        {this.validator.message(t("form.label.houseNumber"), address.houseNumber, "required")}
                    </div>
                    <div className="input-group half">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={address.zipCode}
                                onChange={(event) => {
                                    const newAddress = { ...address };
                                    newAddress.zipCode = event.target.value;

                                    this.updateAddress(newAddress);
                                }}
                            />
                            <label>{t("form.label.zipCode")}*</label>
                        </div>
                        {this.validator.message(t("form.label.zipCode"), address.zipCode, "required")}
                    </div>
                    <div className="input-group half">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={address.city}
                                onChange={(event) => {
                                    const newAddress = { ...address };
                                    newAddress.city = event.target.value;

                                    this.updateAddress(newAddress);
                                }}
                            />
                            <label>{t("form.label.city")}*</label>
                        </div>
                        {this.validator.message(t("form.label.city"), address.city, "required")}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <CountrySelector
                                placeholder={`${t("form.label.selectCountry")}*`}
                                value={address.country}
                                onChange={(value) => {
                                    const newAddress = { ...address };
                                    newAddress.country = value.value;

                                    this.updateAddress(newAddress);
                                }}
                            />
                        </div>
                        {this.validator.message(t("form.label.country"), address.country, "required")}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation("translation")(AddressInput);
