import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CategoriesView from "../../components/category/CategoriesView";

import * as categoryActions from "../../state/actions/categoryActions";
import * as navigationActions from "../../state/actions/navigationActions";

class CategoriesContainer extends Component {
    render() {
        const { deleteCategory, getCategories, searchCategories, ...props } = this.props;

        return (
            <CategoriesView
                {...props}
                deleteCategory={(category) => deleteCategory(category)}
                getCategories={(from = 0, to = 20) => getCategories(from, to)}
                searchCategories={(searchParameters, from, to) =>
                    searchCategories(searchParameters, from, to)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.category,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteCategory: (category) => dispatch(categoryActions.deleteCategory(category)),
        getCategories: (from, to) => dispatch(categoryActions.getCategories(from, to)),
        searchCategories: (searchParameters, from, to) =>
            dispatch(categoryActions.searchCategories(searchParameters, from, to)),


        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CategoriesContainer));
