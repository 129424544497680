import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Charge from '../../models/wallet/Charge';
import Loader from '../ui/components/Loader';

export default class ChargeReturnPage extends Component {
    state = {
        redirect: false,
        path: '/wallet',
    };

    componentDidMount() {
        const { match, executeCharge } = this.props;

        const charge = new Charge();
        charge.id = match.params.id;

        executeCharge(charge).then(() =>
            this.setState({
                redirect: true,
            })
        );
    }

    render() {
        const { path, redirect } = this.state;
        if (redirect) return <Redirect to={path} />;

        return (
            <div className="absolute-center">
                <Loader />
            </div>
        );
    }
}
