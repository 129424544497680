import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Avatar from '../ui/components/Avatar';

class ChatInfoView extends Component {
    render() {
        const { t, chat } = this.props;
        return (
            <div className="chat-info">
                <div className="chat-info__image">
                    <Avatar imageUrl={chat.image.url} name={chat.title} />
                </div>
                <div className="chat-info__title">{chat.title}</div>
                <div className="chat-info__createdAt">{moment(chat.createdAt).format('DD MMM YYYY')}</div>
                {chat.users.length > 2 && (
                    <div className="chat-info__users">
                        <h2>{t('chat.users.header')}</h2>
                        {chat.users.map((user) => {
                            let imageUrl = null;
                            let name = null;
                            if (user.user && user.profile) {
                                imageUrl = user.profile.avatar.url;
                                name = user.profile.name;
                            }
                            return (
                                <div className="chat-info__user">
                                    <div className="chat-info__user__avatar">
                                        <Avatar imageUrl={imageUrl} name={name} />
                                    </div>
                                    <div className="chat-info__user__name">{name}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation('translation')(ChatInfoView);
