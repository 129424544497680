/**
 The statusses of tickets
 */
const supportStatusObject = {
    "open":         0,   
    "planned":      1,  
    "in_progress":  2, 
    "waiting":      3, 
    "closed":       4
};

const SupportStatus = Object.freeze(supportStatusObject);

export default SupportStatus;