import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import ReactSVG from 'react-svg';

import { getLocation } from '../../utils/locationUtils';
import { intToDouble } from '../../utils/moneyUtils';

import Loader from '../ui/components/Loader';
import NoData from '../ui/components/NoData';
import PopOver from '../ui/components/PopOver';

import CreateReviewContainer from '../../containers/rating/review/crud/CreateReviewContainer';

class ShiftsView extends Component {
    state = {
        popOvers: [],
    };

    render() {
        const {
            t,
            addToStack,
            history,
            user,
            profile,
            shifts,
            shiftsIsFetching,
            checkIn,
            checkOut,
            cancelApplication,
            updateApplication,
        } = this.props;

        const { popOvers } = this.state;

        if (shiftsIsFetching || !profile) {
            return <Loader />;
        }

        if (shifts.length < 1) {
            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('task.shifts.personalNotFound')}</NoData>
                    <div
                        className="action"
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/dashboard/search');
                        }}
                    >
                        {t('task.findTaskNow')}
                    </div>
                </div>
            );
        }

        const actionButtons = (shift, index) => {
            if (!profile) return null;
            const application = shift.applications.filter((application) => application.applicant.id === profile.id)[0];
            if (!application) return null;

            if (application.isFetching || !application)
                return (
                    <div className="actions">
                        <Loader className="self-center" sideMargin small />
                    </div>
                );
            switch (application.taskApplicationStatus.toLowerCase()) {
                case 'submitted':
                    return (
                        <div className="actions action-icons">
                            <div
                                className="action action-icon"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t('task.application.cancel.header')}</div>
                                                    <div className="text">
                                                        {t('task.application.cancel.description')}
                                                    </div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t('no')}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                cancelApplication && cancelApplication(application);
                                                            }}
                                                        >
                                                            {t('yes')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                }}
                            >
                                <ReactSVG src="/icons/cross.svg" />
                            </div>
                        </div>
                    );
                case 'accepted':
                    return (
                        <div className="actions self-center">
                            <div
                                className="action button green"
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (checkIn) {
                                        getLocation()
                                            .then((coords) => {
                                                console.log('hey? GOOD', coords);
                                                checkIn({
                                                    id: shift.id,
                                                    latitude: coords.latitude,
                                                    longitude: coords.longitude,
                                                });
                                            })
                                            .catch(() => {
                                                console.log('Hey, bad!');
                                                checkIn({
                                                    id: shift.id,
                                                });
                                            });
                                    }
                                }}
                            >
                                {t('task.shift.checkIn')}
                            </div>
                        </div>
                    );
                case 'checked_in':
                    return (
                        <div className="actions self-center">
                            <div
                                className="action button green"
                                onClick={(e) => {
                                    e.preventDefault();

                                    checkOut &&
                                        getLocation()
                                            .then((coords) => {
                                                checkOut({
                                                    id: shift.id,
                                                    latitude: coords.latitude,
                                                    longitude: coords.longitude,
                                                });
                                            })
                                            .catch(() => {
                                                checkOut({
                                                    id: shift.id,
                                                });
                                            });
                                }}
                            >
                                {t('task.shift.checkOut')}
                            </div>
                        </div>
                    );
                case 'finished':
                    return (
                        <div className="actions self-center">
                            {!application.employerReview && (
                                <div
                                    className="action button green"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addToStack({
                                            name: t('review.create'),
                                            component: (
                                                <CreateReviewContainer
                                                    rating={
                                                        shift.task.employerBusiness
                                                            ? shift.task.employerBusiness.rating
                                                            : shift.task.employerProfile.rating
                                                    }
                                                    question={t('task.shift.employeeReviewQuestion', {
                                                        name: shift.task.employerBusiness
                                                            ? shift.task.employerBusiness.name
                                                            : `${shift.task.employerProfile.person.firstName} ${shift.task.employerProfile.person.lastName}`,
                                                    })}
                                                    callback={(review) => {
                                                        const newApplication = { ...application };
                                                        newApplication.employerReview = review;
                                                        updateApplication(newApplication);
                                                    }}
                                                />
                                            ),
                                        });
                                    }}
                                >
                                    {t('task.shift.writeReview')}
                                </div>
                            )}
                        </div>
                    );
                default:
                    return null;
            }
        };

        const status = (application) => {
            if (application) {
                switch (application.taskApplicationStatus) {
                    case 'submitted':
                    default:
                        return <span className="status self-center gray">{t('task.application.submitted')}</span>;
                    case 'denied':
                        return <span className="status self-center red">{t('task.application.denied')}</span>;
                    case 'cancelled':
                        return <span className="status self-center red">{t('task.application.cancelled')}</span>;
                    case 'accepted':
                        return <span className="status self-center green">{t('task.application.accepted')}</span>;
                    case 'not_chosen':
                        return <span className="status self-center gray">{t('task.application.notChosen')}</span>;
                    case 'replaced':
                        return <span className="status self-center gray">{t('task.application.replaced')}</span>;
                    case 'checked_in':
                        return <span className="status self-center green">{t('task.application.checkedIn')}</span>;
                    case 'checked_out':
                        return <span className="status self-center green">{t('task.application.checkedOut')}</span>;
                    case 'in_dispute':
                        return <span className="status self-center red">{t('task.application.inDispute')}</span>;
                    case 'finished':
                        return <span className="status self-center green">{t('task.application.finished')}</span>;
                }
            }
            return null;
        };

        return (
            <div className="shifts-view">
                {popOvers.map((popOver) => popOver)}
                {shifts
                    .filter((shift) => shift.applications && shift.applications.length > 0)
                    .map((shift, index) => {
                        const application = shift.applications.filter(
                            (application) => application.applicant.id === profile.id
                        )[0];
                        const address = shift.task.location.address;
                        return (
                            <div
                                className="shift flex-container justify-between"
                                key={`shift-${shift.id}-${application ? application.taskApplicationStatus : ''}`}
                            >
                                <div className="flex-container justify-between">
                                    <div>
                                        <div className="title">{shift.task.title}</div>
                                        <div className="address">
                                            {`${address.street} ${address.houseNumber || ''}${
                                                address.houseNumberSuffix || ''
                                            }, ${address.zipCode} ${address.city || ''}`}
                                        </div>
                                        <div className="date">
                                            {moment(shift.startsAt).format('DD-MM-YYYY')} |{' '}
                                            <span className="time">
                                                {moment(shift.startsAt).format('HH:mm')} -{' '}
                                                {moment(shift.endsAt).format('HH:mm')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="amount">
                                        <div>
                                            {application && application.payout
                                                ? `${application.payout.money.currency.sign}${intToDouble(
                                                      application.payout.money.amount,
                                                      2,
                                                      user.language.decimalDelimiter,
                                                      user.language.thousandDelimiter
                                                  )} `
                                                : null}
                                            {t('p/h')}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-container align-items-start">
                                    {application.isFetching ? (
                                        <Loader className="self-center no-margin" />
                                    ) : (
                                        <div className="flex-container self-center align-items-start">
                                            {application && status(application, index)} {actionButtons(shift, index)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default withTranslation('translation')(ShiftsView);
