export default class Experience {

    id;
    nonce;


    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }

}
