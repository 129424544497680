import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Loader from '../ui/components/Loader';
import NoData from '../ui/components/NoData';

import CheckView from './CheckView';

import CreateCheckDisputeContainer from '../../containers/task/crud/CreateCheckDisputeContainer';

class ChecksView extends Component {
    handleScroll = () => {
        const { checksIsFetching, checksTotalResults, checksLoaded } = this.props;
        if (
            window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50 &&
            !checksIsFetching
        ) {
            if (checksTotalResults > checksLoaded) {
                this.fetch(checksLoaded, 20);
            }
        }
    };

    fetch = (offset, amount) => {
        const { getChecks, getShiftChecks, targetShift } = this.props;

        if (targetShift) {
            getShiftChecks(targetShift, offset, amount);
        } else {
            getChecks(offset, amount);
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        this.fetch(0, 20);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { t, platform, user, checks, checksIsFetching, onApprove, addToStack } = this.props;

        if (checksIsFetching) {
            return <Loader />;
        }

        const actionButtons = (check) => {
            if (check.isFetching) {
                return <Loader className="self-center" small sideMargin />;
            }
            if (check.approved) {
                return (
                    <div className="actions">
                        {}
                        <div className="status green">{t('task.check.approved')}</div>
                    </div>
                );
            }

            if (check.disputed) {
                return (
                    <div className="actions">
                        <div className="status green">{t('task.check.disputed')}</div>
                    </div>
                );
            }

            if (user.id !== check.user.id) {
                return (
                    <div className="actions action-icons">
                        <div
                            className="action action-icon green"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onApprove && onApprove(check);
                            }}
                        >
                            <ReactSVG src="/icons/checkmark.svg" />
                        </div>
                        <div
                            className="action action-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                addToStack({
                                    name: '',
                                    component: <CreateCheckDisputeContainer check={check} />,
                                });
                            }}
                        >
                            <ReactSVG src="/icons/cross.svg" />
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="actions">
                        <div className="status gray">{t('task.check.awaitingResponse')}</div>
                    </div>
                );
            }
        };

        if (checks.length > 0) {
            return (
                <div className="checks-view">
                    {checks.map((check) => {
                        const profile = check.application.applicant;
                        return (
                            <div
                                className="check flex-container justify-between"
                                key={check.id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: check.shift.task.title,
                                        component: (
                                            <CheckView check={check} platform={platform} addToStack={addToStack} />
                                        ),
                                    });
                                }}
                            >
                                <div className="flex-container justify-between align-items-start one">
                                    <div className="type">{t(`task.check.type.${check.type}`)}</div>
                                    <div className="check-info">
                                        <div className="check-info-time">
                                            {moment(check.date).format('DD/MM/YYYY')}
                                            <span>{moment(check.date).format('HH:mm')}</span>
                                        </div>
                                        <div className="profile flex-container">
                                            <img
                                                className="rounded"
                                                src={profile.avatar ? profile.avatar.url : '/images/user.svg'}
                                                alt=""
                                            />
                                            <div className="name">
                                                {profile.person.firstName} {profile.person.lastName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shift-info">
                                        <div className="name">{check.shift.task.title}</div>
                                        <div className="date">
                                            {moment(check.shift.startsAt).format('DD-MM-YYYY')} |{' '}
                                            <span className="time">
                                                {moment(check.shift.startsAt).format('HH:mm')} -{' '}
                                                {moment(check.shift.endsAt).format('HH:mm')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {actionButtons(check)}
                            </div>
                        );
                    })}
                </div>
            );
        } else
            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('task.checks.notFound')}</NoData>
                </div>
            );
    }
}

export default withTranslation('translation')(ChecksView);
