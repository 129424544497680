import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import UpdateProfilePage from '../../../components/profile/crud/UpdateProfilePage';

import * as profileActions from '../../../state/actions/profileActions';
import * as navigationActions from '../../../state/actions/navigationActions';
import * as fileApi from '../../../api/fileApi';

class UpdateProfileContainer extends Component {
    componentDidMount() {
        const { t, changeValue, targetProfile } = this.props;
        const newProfile = { ...targetProfile };
        if (newProfile.field) {
            newProfile.fieldValue = {
                value: newProfile.field.id,
                label: t(`field.${newProfile.field.name}`),
            };
        }
        changeValue('dateOfBirthValue', moment(newProfile.person.birthday).format('DD/MM/YYYY'));
        changeValue('formProfile', newProfile);
    }

    render() {
        const { history, popStack, changeValue, updateProfile, ...props } = this.props;
        const { formProfile } = this.props;
        return (
            <UpdateProfilePage
                {...props}
                onChange={changeValue}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
                updateProfile={() => updateProfile(formProfile).then(() => popStack())}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
        fields: state.platform.fields,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(profileActions.changeCreateProfileValue(name, value)),
        updateProfile: (profile) => dispatch(profileActions.updateProfile(profile)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(UpdateProfileContainer));
