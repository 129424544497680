import { businessConstants } from "../constants";

const initialState = {
    businessIsFetching: false,
    business: null,
    formBusiness: null,

    businessesIsFetching: false,
    businessTotalResults: 0,
    loadedBusinesses: 0,
    businesses: [],

    personalBusinessesIsFetching: false,
    personalBusinesses: [],

    error: "",
};

export default function business(state = initialState, action) {
    switch (action.type) {
        case businessConstants.CREATING_BUSINESS_CHANGE_VALUE:
        case businessConstants.UPDATING_BUSINESS_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case businessConstants.GET_BUSINESSES_STARTED:
            return { ...state, businessesIsFetching: true };
        case businessConstants.GET_BUSINESSES_FAILURE:
            return { ...state, businessesIsFetching: false, error: action.message || "" };
        case businessConstants.GET_BUSINESSES_SUCCESS:
            return { ...state, businessesIsFetching: false, businesses: action.businesses || [] };

        case businessConstants.GET_PERSONAL_BUSINESSES_STARTED:
            return { ...state, personalBusinessesIsFetching: true };
        case businessConstants.GET_PERSONAL_BUSINESSES_FAILURE:
            return { ...state, personalBusinessesIsFetching: false, error: action.message || "" };
        case businessConstants.GET_PERSONAL_BUSINESSES_SUCCESS:
            return {
                ...state,
                personalBusinessesIsFetching: false,
                personalBusinesses: action.businesses || [],
            };

        case businessConstants.GET_BUSINESS_STARTED:
            return { ...state, businessIsFetching: true };
        case businessConstants.GET_BUSINESS_FAILURE:
            return { ...state, businessIsFetching: false };
        case businessConstants.GET_BUSINESS_SUCCESS:
            return { ...state, businessIsFetching: false, business: action.business };

        case businessConstants.CREATING_BUSINESS_STARTED:
            return { ...state, businessIsFetching: true, error: null };
        case businessConstants.CREATING_BUSINESS_FAILURE:
            return { ...state, businessIsFetching: false, error: action.message || "" };
        case businessConstants.CREATING_BUSINESS_SUCCESS:
            return {
                ...state,
                businessIsFetching: false,
                business: action.business,
                businesses: [...state.businesses, action.business],
                personalBusinesses: [...state.personalBusinesses, action.business],
            };

        case businessConstants.UPDATING_BUSINESS_STARTED:
            return { ...state, businessIsFetching: true, error: null };
        case businessConstants.UPDATING_BUSINESS_FAILURE:
            return { ...state, businessIsFetching: false, error: action.message || "" };
        case businessConstants.UPDATING_BUSINESS_SUCCESS:
            return {
                ...state,
                businessIsFetching: false,
                business: action.business,
                businesses: [...state.businesses, action.business],
                personalBusinesses: [...state.personalBusinesses, action.business],
            };
        default:
            return state;
    }
}
