
export default class Role {

    id;
    nonce; 

    name;
    level;
    permissions;
    
    constructor() {
        this.nonce = Math.random().toString(36).substring(7);

    }
}