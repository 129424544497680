import React, { Component } from 'react';
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../../utils/formUtils';
import { doubleToInt } from "../../../../utils/moneyUtils";

import Loader from "../../../ui/components/Loader";

export default class PayoutForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formPayout } = this.props;

        onSubmit && onSubmit(formPayout);
    }

    render() {
        const { t, user, currencies, onChange, formPayout, isFetchingPayout } = this.props;

        if(isFetchingPayout) {
            return <Loader />
        }

        return (
            <div className="step-form">
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group fifth">
                                <Select
                                    options={currencies.map((currency) => ({
                                        value: currency.short,
                                        label: currency.name,
                                        sign: currency.sign,
                                        currency: currency
                                    }))}
                                    value={
                                        formPayout.currency
                                            ? formPayout.currency
                                            : currencies.length > 0
                                            ? currencies.map((currency) => ({
                                                  value: currency.short,
                                                  label: currency.name,
                                                  sign: currency.sign,
                                                  currency: currency
                                              }))[0]
                                            : null
                                    }
                                    onChange={(e) => {
                                        const newPayout = { ...formPayout };
                                        const amount = { ...newPayout.amount };

                                        amount.currency = e.currency;
                                        newPayout.amount = amount;

                                        onChange('formPayout', newPayout);
                                    }}
                                />
                            </div>
                            <div className="input-group eighty">
                                <CurrencyFormat
                                    thousandSeparator={user.language.thousandDelimiter}
                                    decimalSeparator={user.language.decimalDelimiter}
                                    value={formPayout.amount.formattedValue}
                                    prefix={formPayout.currency ? formPayout.currency.sign : '€'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onValueChange={(values) => {
                                        const newPayout = { ...formPayout };
                                        const amount = { ...newPayout.amount };

                                        amount.amount = doubleToInt(parseFloat(values.value));
                                        amount.formattedAmount = values.formattedValue;

                                        newPayout.amount = amount;

                                        onChange('formPayout', newPayout);
                                    }}
                                />
                                <label>{t('form.label.amount')}</label>
                            </div>
                        </div>
                        <div className="input-group more right">
                            <input type="submit" onClick={(e) => e.stopPropagation()} value={t('form.save')} />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
