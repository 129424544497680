import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import RegisterPage from "../../components/authentication/RegisterPage";
import * as authActions from "../../state/actions/authActions.js";

class RegisterContainer extends Component {
    render() {
        const { changeValue, register, ...props } = this.props;

        const { username, email, password, repeatPassword, terms, newsletter, recaptcha, i18n, history } = props;

        return (
            <RegisterPage
                {...props}
                onChange={changeValue}
                onRegister={() => {
                    register(username, email, password, repeatPassword, terms, newsletter, recaptcha, i18n.language)
                        .then((response) => history.push("/welcome/" + response.user.username) )
                        .catch((error) => {});
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        error: state.auth.error,
        username: state.auth.username,
        email: state.auth.email,
        password: state.auth.password,
        repeatPassword: state.auth.repeatPassword,
        recaptcha: state.auth.recaptcha,
        terms: state.auth.terms,
        newsletter: state.auth.newsletter,
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        register: (username, email, password, repeatPassword, terms, newsletter, recaptcha, language) =>
            dispatch(
                authActions.register(username, email, password, repeatPassword, terms, newsletter, recaptcha, language)
            ),
        changeValue: (name, value) => dispatch(authActions.changeRegisterValue(name, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(RegisterContainer));
