import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import DenyApplicationForm from "./DenyApplicationForm";

export default class DenyApplicationPage extends Component {
    render() {
        const {
            t,
            formApplication,
            isFetchingApplication,
            denyApplication,
            parentShift,
            parentTask,
        } = this.props;

        if (!formApplication || isFetchingApplication) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.application.deny")}</h1>
                <DenyApplicationForm
                    {...this.props}
                    onSubmit={(formApplication) =>
                        denyApplication({ ...formApplication, shift: parentShift, task: parentTask })
                    }
                />
            </AsideView>
        );
    }
}
