export const taskConstants = {
    GET_TASK_STARTED: "GET_TASK_STARTED",
    GET_TASK_SUCCESS: "GET_TASK_SUCCESS",
    GET_TASK_FAILURE: "GET_TASK_FAILURE",

    GET_TASKS_STARTED: "GET_TASKS_STARTED",
    GET_TASKS_SUCCESS: "GET_TASKS_SUCCESS",
    GET_TASKS_FAILURE: "GET_TASKS_FAILURE",

    CREATING_TASK_CHANGE_VALUE: "CREATING_TASK_CHANGE_VALUE",
    CREATING_TASK_STARTED: "CREATING_TASK_STARTED",
    CREATING_TASK_SUCCESS: "CREATING_TASK_SUCCESS",
    CREATING_TASK_FAILURE: "CREATING_TASK_FAILURE",

    UPDATING_TASK_CHANGE_VALUE: "UPDATING_TASK_CHANGE_VALUE",
    UPDATING_TASK_STARTED: "UPDATING_TASK_STARTED",
    UPDATING_TASK_SUCCESS: "UPDATING_TASK_SUCCESS",
    UPDATING_TASK_FAILURE: "UPDATING_TASK_FAILURE",

    DELETE_TASK_STARTED: "DELETE_TASK_STARTED",
    DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
    DELETE_TASK_FAILURE: "DELETE_TAKS_FAILURE",



    GET_SHIFT_STARTED: "GET_SHIFT_STARTED",
    GET_SHIFT_SUCCESS: "GET_SHIFT_SUCCESS",
    GET_SHIFT_FAILURE: "GET_SHIFT_FAILURE",

    GET_SHIFTS_STARTED: "GET_SHIFTS_STARTED",
    GET_SHIFTS_SUCCESS: "GET_SHIFTS_SUCCESS",
    GET_SHIFTS_FAILURE: "GET_SHIFTS_FAILURE",

    CREATING_SHIFT_CHANGE_VALUE: "CREATING_SHIFT_CHANGE_VALUE",
    CREATING_SHIFT_STARTED: "CREATING_SHIFT_STARTED",
    CREATING_SHIFT_SUCCESS: "CREATING_SHIFT_SUCCESS",
    CREATING_SHIFT_FAILURE: "CREATING_SHIFT_FAILURE",

    UPDATING_SHIFT_CHANGE_VALUE: "UPDATING_SHIFT_CHANGE_VALUE",
    UPDATING_SHIFT_STARTED: "UPDATING_SHIFT_STARTED",
    UPDATING_SHIFT_SUCCESS: "UPDATING_SHIFT_SUCCESS",
    UPDATING_SHIFT_FAILURE: "UPDATING_SHIFT_FAILURE",

    DELETE_SHIFT_STARTED: "DELETE_SHIFT_STARTED",
    DELETE_SHIFT_SUCCESS: "DELETE_SHIFT_SUCCESS",
    DELETE_SHIFT_FAILURE: "DELETE_SHIFT_FAILURE",



    CREATE_APPLICATION_STARTED: "CREATE_APPLICATION_STARTED",
    CREATE_APPLICATION_SUCCESS: "CREATE_APPLICATION_SUCCESS",
    CREATE_APPLICATION_FAILURE: "CREATE_APPLICATION_FAILURE",

    UPDATE_APPLICATION_STARTED: "UPDATE_APPLICATION_STARTED",
    UPDATE_APPLICATION_SUCCESS: "UPDATE_APPLICATION_SUCCESS",
    UPDATE_APPLICATION_FAILURE: "UPDATE_APPLICATION_FAILURE",

    CANCEL_APPLICATION_STARTED: "CANCEL_APPLICATION_STARTED",
    CANCEL_APPLICATION_SUCCESS: "CANCEL_APPLICATION_SUCCESS",
    CANCEL_APPLICATION_FAILURE: "CANCEL_APPLICATION_FAILURE",

    ACCEPT_APPLICATION_STARTED: "ACCEPT_APPLICATION_STARTED",
    ACCEPT_APPLICATION_SUCCESS: "ACCEPT_APPLICATION_SUCCESS",
    ACCEPT_APPLICATION_FAILURE: "ACCEPT_APPLICATION_FAILURE",

    DENY_APPLICATION_STARTED: "DENY_APPLICATION_STARTED",
    DENY_APPLICATION_SUCCESS: "DENY_APPLICATION_SUCCESS",
    DENY_APPLICATION_FAILURE: "DENY_APPLICATION_FAILURE",
    
    

    GET_CHECK_STARTED: "GET_CHECK_STARTED",
    GET_CHECK_SUCCESS: "GET_CHECK_SUCCESS",
    GET_CHECK_FAILURE: "GET_CHECK_FAILURE",

    GET_CHECKS_STARTED: "GET_CHECKS_STARTED",
    GET_CHECKS_SUCCESS: "GET_CHECKS_SUCCESS",
    GET_CHECKS_FAILURE: "GET_CHECKS_FAILURE",

    CHECK_IN_STARTED: "CHECK_IN_STARTED",
    CHECK_IN_SUCCESS: "CHECK_IN_SUCCESS", 
    CHECK_IN_FAILURE: "CHECK_IN_FAILURE", 
    
    CHECK_OUT_STARTED: "CHECK_OUT_STARTED", 
    CHECK_OUT_SUCCESS: "CHECK_OUT_SUCCESS", 
    CHECK_OUT_FAILURE: "CHECK_OUT_FAILURE", 

    APPROVE_CHECK_STARTED: "APPROVE_CHECK_STARTED",
    APPROVE_CHECK_SUCCESS: "APPROVE_CHECK_SUCCESS",
    APPROVE_CHECK_FAILURE: "APPROVE_CHECK_FAILURE",

    CREATE_CHECK_DISPUTE_STARTED: "CREATE_CHECK_DISPUTE_STARTED",
    CREATE_CHECK_DISPUTE_SUCCESS: "CREATE_CHECK_DISPUTE_SUCCESS",
    CREATE_CHECK_DISPUTE_FAILURE: "CREATE_CHECK_DISPUTE_FAILURE",

};
