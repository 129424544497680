import * as notificationApi from "../../api/notificationApi";
import { notificationConstants } from "../constants";

/**
 * Fetching notifications
 */
export const startGetNotifications = () => ({
    type: notificationConstants.GET_NOTIFICATIONS_STARTED,
});

export const getNotificationsSuccess = (result, from) => ({
    type: notificationConstants.GET_NOTIFICATIONS_SUCCESS,
    from: from,
    ...result,
});

export const getNotificationsFailure = (error) => ({
    type: notificationConstants.GET_NOTIFICATIONS_FAILURE,
    ...error,
});

export const getNotifications = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetNotifications());

        const promise = notificationApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getNotificationsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getNotificationsFailure(error));
            });

        return promise;
    };
};

/**
    Reading notifications
*/
export const startReadingNotifications = () => ({
    type: notificationConstants.READ_NOTIFICATIONS_STARTED,
});

export const readingNotificationsSuccess = (result) => ({
    type: notificationConstants.READ_NOTIFICATIONS_SUCCESS,
    ...result,
});

export const readingNotificationsFailure = (error) => ({
    type: notificationConstants.READ_NOTIFICATIONS_FAILURE,
    ...error,
});

export const readNotifications = (notifications) => {
    return (dispatch) => {
        dispatch(startReadingNotifications());

        const promise = notificationApi.read(notifications);

        promise
            .then((result) => {
                dispatch(readingNotificationsSuccess(result));
            })
            .catch((error) => {
                dispatch(readingNotificationsFailure(error));
            });

        return promise;
    };
};
