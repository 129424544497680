import React, { Component } from 'react';

import Alert from './Alert';

export default class ComponentStack extends Component {
    render() {
        const { alerts, popStack } = this.props;

        const onClose = () => {
            popStack && popStack();
        };

        return (
            <div className="alert-stack">
                {alerts.map((alert, index) => (
                    <Alert
                        alert={alert}
                        onClose={onClose}
                        className={`${
                            alert.animatingOut ? ' animateOut' : ' animateIn'
                        }`}
                        key={`alert-stack-${alert.id}`}
                    />
                ))}
            </div>
        );
    }
}
