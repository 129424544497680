import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SupportTicketErrors from "../../components/support/SupportTicketErrors";
import * as supportActions from "../../state/actions/supportActions";

class SupportTicketErrorsContainer extends Component {
    render() {
        const { fetchErrors, ...props } = this.props;

        const { supportTicket } = this.props;

        return (
            <SupportTicketErrors
                {...props}
                key={supportTicket.id}
                fetchErrors={(from = 0, amount = 20) => fetchErrors(supportTicket, from, amount)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        supportTicket: state.support.supportTicket,
        isFetchingErrors: state.support.isFetchingErrors,
        errors: state.support.errors,
        errorsTotalResults: state.support.errorsTotalResults,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchErrors: (supportTicket, from, amount) =>
            dispatch(supportActions.getSupportTicketErrors(supportTicket, from, amount)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(SupportTicketErrorsContainer));
