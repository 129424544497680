import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../utils/formUtils";

import TaskDescriptionPart from "../../../models/task/TaskDescriptionPart";
import TaskKeyword from "../../../models/task/TaskKeyword";

import CloseButton from "../../ui/components/CloseButton";

export default class CreateCategory extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    _addKeywordCategory() {
        const { onChange, formCategory } = this.props;

        const category = { ...formCategory };
        const keywords = [...category.keywords];
        keywords.push(new TaskKeyword());
        category.keywords = keywords;

        onChange("formCategory", category);
    }

    _addDescriptionPart() {
        const { onChange, formCategory } = this.props;

        const category = { ...formCategory };
        const parts = [...category.descriptionParts];
        parts.push(new TaskDescriptionPart());
        category.descriptionParts = parts;

        onChange("formCategory", category);
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formCategory } = this.props;

        onSubmit && onSubmit(formCategory);
    }

    render() {
        const { onChange, t, formCategory } = this.props;
        const { descriptionParts, keywords } = formCategory;

        return (
            <div className="step-form" key={formCategory.id}>
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formCategory.name}
                                    onChange={(event) => {
                                        const newCategory = { ...formCategory };
                                        newCategory.name = event.target.value;

                                        onChange("formCategory", newCategory, event);
                                    }}
                                />
                                <label>{t("form.label.name")}*</label>
                            </div>
                            {this.validator.message(t("form.label.name"), formCategory.name, "required")}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formCategory.abbreviated}
                                    onChange={(event) => {
                                        const newCategory = { ...formCategory };
                                        newCategory.abbreviated = event.target.value;

                                        onChange("formCategory", newCategory, event);
                                    }}
                                />
                                <label>{t("form.label.abbreviated")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.abbreviated"),
                                formCategory.abbreviated,
                                "required"
                            )}
                        </div>
                        {/* <div className="input-group">
                            <div>{t("form.label.isParent")}</div>
                            <Switch
                                checked={formCategory.isParent}
                                onChange={(e, newState) => {
                                    const newCategory = { ...formCategory };
                                    newCategory.isParent = newState;

                                    onChange("formCategory", newCategory);
                                }}
                            />
                        </div> */}
                        <div className="input-container">
                            {descriptionParts.map((part, index) => (
                                <div className="list-sector" key={part.id}>
                                    <div className="list-actions">
                                        <div></div>
                                        <CloseButton
                                            onClick={() => {
                                                const newCategory = { ...formCategory };
                                                const parts = [...newCategory.descriptionParts];

                                                parts.splice(index, 1);

                                                newCategory.descriptionParts = parts;
                                                onChange("formCategory", newCategory);
                                            }}
                                        />
                                    </div>
                                    <div className="input-group no-margin-top" key={part.id}>
                                        <div className="input-group no-margin-top">
                                            <input
                                                type="text"
                                                value={part.header}
                                                onChange={(event) => {
                                                    const newCategory = { ...formCategory };
                                                    const parts = [...newCategory.descriptionParts];

                                                    const currentPart = { ...parts[index] };
                                                    currentPart.header = event.target.value;

                                                    parts[index] = currentPart;
                                                    newCategory.descriptionParts = parts;

                                                    onChange("formCategory", newCategory, event);
                                                }}
                                            />
                                            <label>{t("form.label.descriptionHeader")}</label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="input-group right">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this._addDescriptionPart();
                                    }}
                                >
                                    {t("form.label.addDescriptionPart")}
                                </button>
                            </div>
                        </div>

                        <div className="input-container">
                            {keywords
                                .sort((a, b) => a.header - b.header)
                                .map((keyword, index) => (
                                    <div className="list-sector" key={keyword.id}>
                                        <div className="list-actions">
                                            <div></div>
                                            <CloseButton
                                                onClick={() => {
                                                    const newCategory = { ...formCategory };
                                                    const newKeywords = [...newCategory.keywords];

                                                    newKeywords.splice(index, 1);

                                                    newCategory.keywords = newKeywords;
                                                    onChange("formCategory", newCategory);
                                                }}
                                            />
                                        </div>
                                        <div className="input-group no-margin-top" key={keyword.id}>
                                            <div className="input-group no-margin-top">
                                                <input
                                                    type="text"
                                                    value={keyword.header}
                                                    onChange={(event) => {
                                                        const newCategory = { ...formCategory };
                                                        const newKeywords = [...newCategory.keywords];

                                                        const currentKeyword = { ...newKeywords[index] };
                                                        currentKeyword.header = event.target.value;

                                                        newKeywords[index] = currentKeyword;

                                                        newCategory.keywords = newKeywords;

                                                        onChange("formCategory", newCategory, event);
                                                    }}
                                                />
                                                <label>{t("form.label.keywordHeader")}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="input-group right">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this._addKeywordCategory();
                                    }}
                                >
                                    {t("form.label.addKeywordPart")}
                                </button>
                            </div>
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
