export default class Money {

    id;
    nonce;

    amount;
    formattedAmount;
    currency;

    constructor() {
        this.nonce = Math.random().toString(36).substring(7);
        
        this.amount = 0;
        this.formattedAmount = 0;
        this.currency = "eur";
    }
}