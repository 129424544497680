import React, { Component } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';

export default class ContractForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();

        const { onSubmit, formContract } = this.props;
        onSubmit && onSubmit(formContract);
    }

    render() {
        const { t, onChange, languages, formContract } = this.props;
        const languageOptions = languages.map((language) => {
            return { label: language.localName, value: language.id };
        });
        return (
            <div className="step-form" key={formContract.id}>
                <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formContract.title}
                                    onChange={(event) => {
                                        const newContract = { ...formContract };
                                        newContract.title = event.target.value;

                                        onChange('formContract', newContract, event);
                                    }}
                                />
                                <label>{t('form.label.title')}*</label>
                            </div>
                            {this.validator.message(t('form.label.title'), formContract.title, 'required')}
                        </div>
                        <div className="input-group">
                            <Select
                                placeholder={t('form.label.selectLanguage')}
                                options={languageOptions}
                                value={languageOptions.find((languageOption) =>
                                    languageOption.value === formContract.language ? formContract.language.id : null
                                )}
                                noOptionsMessage={() => t('noOptions')}
                                onChange={(e) => {
                                    const newContract = { ...formContract };
                                    const newLanguage = { ...newContract.language };

                                    newLanguage.localName = e.label;
                                    newLanguage.id = e.value;
                                    newContract.languages = newLanguage;

                                    onChange('formContract', newContract);
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    value={formContract.description}
                                    onChange={(event) => {
                                        const newContract = { ...formContract };
                                        newContract.description = event.target.value;

                                        onChange('formContract', newContract, event);
                                    }}
                                ></textarea>
                                <label>{t('form.label.contract')}</label>
                            </div>
                        </div>
                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => this.handleSubmit(e)}
                                value={t('form.save')}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
