import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import moment from 'moment';

import NoData from '../ui/components/NoData';
import Loader from '../ui/components/Loader';
import Collapsible from '../ui/components/Collapsible';

import CreateShiftContainer from '../../containers/task/crud/CreateShiftContainer';
import CreateTaskContainer from '../../containers/task/crud/CreateTaskContainer';
import TaskShiftViewContainer from '../../containers/task/TaskShiftViewContainer';

class ShiftPlanner extends Component {
    state = {
        startOfWeek: moment().startOf('week'),
        week: moment().week(),
    };
    render() {
        const { t, history, tasks, addToStack, tasksIsFetching } = this.props;
        const { startOfWeek, week } = this.state;

        const weekStart = startOfWeek.clone().startOf('week');
        const days = [];

        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        }

        return (
            <div className="planner">
                <div className="planner-top">
                    <div className="date">
                        <div className="week">
                            {t('week')} {week}
                        </div>
                        <div className="monthAndYear">{startOfWeek.format('MMMM YYYY')}</div>
                    </div>
                    <div className="navigator">
                        <div
                            className="left"
                            onClick={(e) => {
                                e.preventDefault();
                                const newStartOfWeek = startOfWeek.clone().subtract(7, 'd');
                                this.setState({
                                    startOfWeek: newStartOfWeek,
                                    week: newStartOfWeek.week(),
                                });
                            }}
                        >
                            <ReactSVG src="/icons/back.svg" />
                        </div>
                        <div
                            className="today"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    startOfWeek: moment().startOf('week'),
                                    week: moment().week(),
                                });
                            }}
                        >
                            {t('today')}
                        </div>
                        <div
                            className="right"
                            onClick={(e) => {
                                e.preventDefault();
                                const newStartOfWeek = startOfWeek.clone().add(7, 'd');
                                this.setState({
                                    startOfWeek: newStartOfWeek,
                                    week: newStartOfWeek.week(),
                                });
                            }}
                        >
                            <ReactSVG src="/icons/back.svg" />
                        </div>
                    </div>
                </div>
                <div className="week-grid">
                    {days.map((day) => {
                        return (
                            <div
                                key={day.format('DD/MM/YYYY')}
                                className={`element day${
                                    day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? ' active' : ''
                                }`}
                            >
                                {day.format('ddd')}
                                <span>{day.format('DD')}</span>
                            </div>
                        );
                    })}
                </div>
                {tasks.length > 0 ? (
                    <>
                        {tasks.map((task) => (
                            <Collapsible className="" name={task.title} defaultIsOpen={true}>
                                <div className="week-grid task-agenda">
                                    {days.map((day) => {
                                        const dayShifts = task.shifts.filter(
                                            (shift) =>
                                                moment(shift.startsAt).format('DD/MM/YYYY') === day.format('DD/MM/YYYY')
                                        );
                                        return (
                                            <div
                                                className={`element agenda${
                                                    day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
                                                        ? ' active'
                                                        : ''
                                                }`}
                                            >
                                                {dayShifts.length > 0 && (
                                                    <div
                                                        key={day.format('DD/MM/YYYY')}
                                                        className={`day shifts${
                                                            day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
                                                                ? ' active'
                                                                : ''
                                                        }`}
                                                    >
                                                        {dayShifts
                                                            .sort((a, b) =>
                                                                moment(a.startsAt).utc().diff(moment.utc(b.startsAt))
                                                            )
                                                            .map((shift) => {
                                                                const acceptedApplications = shift.applications.filter(
                                                                    (applicant) =>
                                                                        [
                                                                            'accepted',
                                                                            'looking_for_replacement',
                                                                            'checked_in',
                                                                            'checked_out',
                                                                            'finished',
                                                                        ].includes(applicant.taskApplicationStatus)
                                                                );

                                                                const hasActionsToTake =
                                                                    shift.applications.filter((application) =>
                                                                        [
                                                                            'submitted',
                                                                            'checked_in',
                                                                            'checked_out',
                                                                        ].includes(application.taskApplicationStatus)
                                                                    ).length > 0;

                                                                return (
                                                                    <div
                                                                        className={`shift${
                                                                            shift.amount === 0 ? '' : ' red'
                                                                        }${hasActionsToTake ? ' orange' : ''} `}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            const newShift = { ...shift };
                                                                            newShift.task = { ...task };
                                                                            addToStack({
                                                                                name: 'Shift view',
                                                                                component: (
                                                                                    <TaskShiftViewContainer
                                                                                        targetShift={newShift}
                                                                                    />
                                                                                ),
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div className="amount darken">
                                                                            {acceptedApplications.length}/
                                                                            {shift.amount + acceptedApplications.length}
                                                                        </div>
                                                                        <div className="time">
                                                                            {moment(shift.startsAt).tz("CEST").format('HH:mm')} -{' '}
                                                                            {moment(shift.endsAt).format('HH:mm')}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                )}
                                                <div
                                                    className={`day${
                                                        day.isBefore(moment().startOf('day').format('YYYY-MM-DD'))
                                                            ? ''
                                                            : ' creatable'
                                                    } ${
                                                        day.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
                                                            ? ' active'
                                                            : ''
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (
                                                            !day.isBefore(moment().startOf('day').format('YYYY-MM-DD'))
                                                        ) {
                                                            addToStack({
                                                                name: 'Create shift',
                                                                component: (
                                                                    <CreateShiftContainer
                                                                        targetDate={day}
                                                                        targetTask={task}
                                                                        onNavigateTo={(path) => {
                                                                            history.push(path);
                                                                        }}
                                                                    />
                                                                ),
                                                            });
                                                        }
                                                    }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Collapsible>
                        ))}
                    </>
                ) : tasksIsFetching ? (
                    <Loader />
                ) : (
                    <div className="not-found-action-box">
                        <div className="icon">
                            <ReactSVG src="/icons/space.svg" />
                        </div>
                        <NoData>{t('tasks.notFound')}</NoData>
                        <div
                            className="action"
                            onClick={(e) => {
                                e.preventDefault();
                                addToStack({
                                    name: t('task.new'),
                                    component: <CreateTaskContainer />,
                                });
                            }}
                        >
                            {t('tasks.createTaskNow')}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation('translation')(ShiftPlanner);
