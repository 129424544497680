export default class Chat {

    id;
    nonce;

    title;
    image;
    active;

    starter;

    users; 

    startedAt;
    modifiedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.title = "";
        
        this.users = [];
    }
}