import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import moment from "moment";

import { activateInputs } from "../../../utils/formUtils";

export default class EducationForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formEducation } = this.props;

        onSubmit && onSubmit(formEducation);
    }

    updateEducation = (newEducation) => {
        const { onChange } = this.props;
        onChange("formEducation", newEducation);
    };

    render() {
        const { t, formEducation } = this.props;

        const now = new Date().getUTCFullYear();
        const years = Array(now - (now - 100))
            .fill("")
            .map((v, idx) => now - idx)
            .map((year) => ({ value: year, label: year }));

        return (
            <div className="step-form">
                <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        {/* Business search OR just string */}
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formEducation.school}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.school = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                />
                                <label>{t("form.label.school")}*</label>
                            </div>
                            {this.validator.message(t("form.label.school"), formEducation.school, "required")}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formEducation.title}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.title = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                />
                                <label>{t("form.label.title")}*</label>
                            </div>
                            {this.validator.message(t("form.label.title"), formEducation.title, "required")}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formEducation.fieldOfStudy}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.fieldOfStudy = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                />
                                <label>{t("form.label.fieldOfStudy")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.fieldOfStudy"),
                                formEducation.fieldOfStudy,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formEducation.grade}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.grade = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                />
                                <label>{t("form.label.grade")}</label>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group half no-margin-top">
                                <Select
                                    options={years}
                                    placeholder={t("form.label.startYear")}
                                    value={years.find(
                                        (year) =>
                                            moment(formEducation.startYear).format("YYYY") ===
                                            year.value.toString()
                                    )}
                                    onChange={(e) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.startYear = moment(e.value, "YYYY");
                                        this.updateEducation(newEducation);
                                    }}
                                />
                            </div>

                            <div className="input-group half no-margin-top">
                                <Select
                                    options={years}
                                    placeholder={t("form.label.endYear")}
                                    value={years.find(
                                        (year) =>
                                            moment(formEducation.endYear).format("YYYY") ===
                                            year.value.toString()
                                    )}
                                    onChange={(e) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.endYear = moment(e.value, "YYYY");
                                        this.updateEducation(newEducation);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <textarea
                                    value={formEducation.activities}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.activities = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                ></textarea>
                                <label>{t("form.label.activities")}</label>
                            </div>
                            {this.validator.message(t("form.label.activities"), formEducation.activities, "")}
                        </div>
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <textarea
                                    value={formEducation.description}
                                    onChange={(event) => {
                                        const newEducation = { ...formEducation };
                                        newEducation.description = event.target.value;
                                        this.updateEducation(newEducation);
                                    }}
                                ></textarea>
                                <label>{t("form.label.description")}</label>
                            </div>
                            {this.validator.message(
                                t("form.label.description"),
                                formEducation.description,
                                ""
                            )}
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
