import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../utils/formUtils";

export default class DenyApplicationForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formApplication } = this.props;

        onSubmit && onSubmit(formApplication);
    }

    render() {
        const { onChange, t, formApplication } = this.props;

        return (
            <div className="step-form">
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formApplication.denyReason}
                                    onChange={(event) => {
                                        const newApplication = { ...formApplication };
                                        newApplication.denyReason = event.target.value;

                                        onChange("formApplication", newApplication, event);
                                    }}
                                ></textarea>
                                <label>{t("form.label.denyReason")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.denyReason"),
                                formApplication.denyReason,
                                "required"
                            )}
                        </div>
                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.send")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
