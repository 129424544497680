import User from '../user/User';

export default class SupportTicket {

    id; 
    nonce;

    firstName;
    lastName;
    
    email;
    phone;

    user; 

    subject;
    message;
    attachment;

    token;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.user = new User();
        this.attachment = [];
    }
}