import React, { Component } from "react";

import AsideView from "../../ui/components/AsideView";
import SupportTicketActionForm from "./SupportTicketActionForm";

import SupportTicketAction from "../../../models/support/SupportTicketAction";

export default class CreateSupportTicketAction extends Component {
    componentDidMount() {
        const { onChange, supportTicket } = this.props;
        const supportTicketAction = new SupportTicketAction();
        supportTicketAction.supportTicket = supportTicket.id;

        onChange("formSupportTicketAction", supportTicketAction);
    }

    render() {
        const { t, createTicketAction, formSupportTicketAction, match } = this.props;

        return (
            <AsideView>
                <h1>{t("support.action.new")}</h1>
                <SupportTicketActionForm
                    {...this.props}
                    onSubmit={(e) => createTicketAction(formSupportTicketAction, match ? match.params.token : null)}
                />
            </AsideView>
        );
    }
}
