import React, { Component } from "react";
import ReactSVG from "react-svg";

import { base64ToBlob } from "../../utils/imageUtils";

import Loader from "../ui/components/Loader";
import AvatarPicker from "../ui/components/AvatarPicker";
import BannerPicker from "../ui/components/BannerPicker";

import Rating from "../rating/Rating";

import UpdateProfileContainer from "../../containers/profile/crud/UpdateProfileContainer";

export default class ProfileTopView extends Component {
    state = {
        avatar: "/images/user.svg",
        avatarPicker: false,
        uploadingAvatar: false,

        banner: "/images/banner.svg",
        bannerPicker: false,
        uploadingBanner: false,
    };

    render() {
        const {
            profileIsFetching,
            profile,
            t,
            addToStack,
            user,
            updateProfileAvatar,
            updateProfileBanner,
            fetchingAvatar,
            fetchingBanner,
            uploadFile,
        } = this.props;

        const { avatar, avatarPicker, banner, bannerPicker, uploadingAvatar, uploadingBanner } = this.state;

        if (!profileIsFetching && profile) {
            const isMe = profile.user.id === user.id;
            return (
                <div className="top-profile">
                    <AvatarPicker
                        {...this.props}
                        title={t("avatar.upload")}
                        active={avatarPicker}
                        isLoading={fetchingAvatar || uploadingAvatar}
                        handleSubmit={(croppedImage) => {
                            this.setState({
                                avatar: croppedImage,
                                uploadingAvatar: true,
                            });
                            uploadFile(base64ToBlob(croppedImage), "profile").then((response) => {
                                updateProfileAvatar(response.file.id).then(() => {
                                    this.setState({
                                        avatarPicker: !avatarPicker,
                                        uploadingAvatar: false,
                                    });
                                });
                            });
                        }}
                        onClick={() => this.setState({ avatarPicker: !avatarPicker })}
                        onClose={() => this.setState({ avatarPicker: !avatarPicker })}
                    />
                    <BannerPicker
                        {...this.props}
                        title={t("banner.upload")}
                        active={bannerPicker}
                        isLoading={fetchingBanner || uploadingBanner}
                        handleSubmit={(croppedImage) => {
                            this.setState({
                                banner: croppedImage,
                                uploadingBanner: true,
                            });
                            uploadFile(base64ToBlob(croppedImage), "profile").then((response) => {
                                updateProfileBanner(response.file.id).then(() => {
                                    this.setState({
                                        bannerPicker: !bannerPicker,
                                        uploadingBanner: false,
                                    });
                                });
                            });
                        }}
                        onClick={() => this.setState({ bannerPicker: !bannerPicker })}
                        onClose={() => this.setState({ bannerPicker: !bannerPicker })}
                    />
                    <img
                        src={profile.banner ? profile.banner.url : banner}
                        className={`banner${isMe ? " editable" : ""}`}
                        onClick={(e) => {
                            isMe && this.setState({ bannerPicker: !bannerPicker });
                        }}
                        alt=""
                    />
                    <div className="flex-container">
                        <div className="two info justify-center">
                            <div className="picture">
                                <img src={profile.avatar ? profile.avatar.url : avatar} alt="" />
                                {isMe && (
                                    <div className="action-icons">
                                        <div
                                            className="action-icon"
                                            onClick={(e) => {
                                                this.setState({ avatarPicker: !avatarPicker });
                                            }}
                                        >
                                            <ReactSVG src="/icons/pencil.svg" />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="name">{profile.name}</div>
                            <Rating rating={profile.rating ? profile.rating.totalRating || 0.0 : 0.0}>
                                {t("rating.reviews.total", {
                                    count: profile.rating ? profile.rating.totalReviews || 0 : 0,
                                })}
                            </Rating>
                            {profile.headline && <div className="headline">{profile.headline}</div>}
                            {profile.person.address && (
                                <div className="location">
                                    {profile.person.address.city}, {profile.person.address.country}
                                </div>
                            )}
                        </div>

                        <div className="one flex-container row-reverse ">
                            <div className="action-icons">
                                {isMe && (
                                    <div
                                        className="action-icon"
                                        onClick={(e) => {
                                            addToStack({
                                                name: t("profile.update"),
                                                component: (
                                                    <UpdateProfileContainer targetProfile={{ ...profile }} />
                                                ),
                                            });
                                        }}
                                    >
                                        <ReactSVG src="/icons/pencil.svg" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ height: "300px", position: "relative" }}>
                <div className="absolute-center">
                    <Loader />
                </div>
            </div>
        );
    }
}
