import React, { Component } from "react";
import ReactSVG from "react-svg";

import { base64ToBlob } from "../../utils/imageUtils";

import Loader from "../ui/components/Loader";
import AvatarPicker from "../ui/components/AvatarPicker";
import BannerPicker from "../ui/components/BannerPicker";

import Rating from "../rating/Rating";

import UpdateBusinessContainer from "../../containers/business/crud/UpdateBusinessContainer";

export default class BusinessView extends Component {
    state = {
        logo: "/images/business.svg",
        logoPicker: false,
        uploadingAvatar: false,

        banner: "/images/banner.svg",
        bannerPicker: false,
        uploadingBanner: false,
    };

    render() {
        const {
            t,
            history,
            businessIsFetching,
            business,
            addToStack,
            updateBusinessAvatar,
            updateBusinessBanner,
            fetchingAvatar,
            fetchingBanner,
            uploadFile,
        } = this.props;

        const { logo, logoPicker, banner, bannerPicker, uploadingAvatar, uploadingBanner } = this.state;

        if (!businessIsFetching && business) {
            return (
                <div className="top-business">
                    <AvatarPicker
                        {...this.props}
                        title={t("form.uploadLogo")}
                        active={logoPicker}
                        isLoading={fetchingAvatar || uploadingAvatar}
                        handleSubmit={(croppedImage) => {
                            this.setState({
                                logo: croppedImage,
                                uploadingAvatar: true,
                            });
                            uploadFile(base64ToBlob(croppedImage), "business").then((response) => {
                                updateBusinessAvatar(response.file.id).then(() => {
                                    this.setState({
                                        logoPicker: !logoPicker,
                                        uploadingAvatar: false,
                                    });
                                });
                            });
                        }}
                        onClick={() => this.setState({ logoPicker: !logoPicker })}
                        onClose={() => this.setState({ logoPicker: !logoPicker })}
                    />
                    <BannerPicker
                        {...this.props}
                        title={t("banner.upload")}
                        active={bannerPicker}
                        isLoading={fetchingBanner || uploadingBanner}
                        handleSubmit={(croppedImage) => {
                            this.setState({
                                banner: croppedImage,
                                uploadingBanner: true,
                            });
                            uploadFile(base64ToBlob(croppedImage), "business").then((response) => {
                                updateBusinessBanner(response.file.id).then(() => {
                                    this.setState({
                                        bannerPicker: !bannerPicker,
                                        uploadingBanner: false,
                                    });
                                });
                            });
                        }}
                        onClick={() => this.setState({ bannerPicker: !bannerPicker })}
                        onClose={() => this.setState({ bannerPicker: !bannerPicker })}
                    />
                    <img
                        src={business.banner ? business.banner.url : banner}
                        className={`banner${business.isOwner ? " editable" : ""}`}
                        onClick={(e) => {
                            business.isOwner && this.setState({ bannerPicker: !bannerPicker });
                        }}
                        alt=""
                    />
                    <div className="flex-container">
                        <div className="two info justify-center">
                            <div className="picture">
                                <img src={business.logo ? business.logo.url : logo} alt="" />
                                {business.isOwner && (
                                    <div className="action-icons">
                                        <div
                                            className="action-icon"
                                            onClick={(e) => {
                                                this.setState({ logoPicker: !logoPicker });
                                            }}
                                        >
                                            <ReactSVG src="/icons/pencil.svg" />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="name">{business.name}</div>
                            <Rating rating={business.rating ? business.rating.totalRating || 0.0 : 0.0}>
                                {t("rating.reviews.total", {
                                    count: business.rating ? business.rating.totalReviews || 0 : 0,
                                })}
                            </Rating>{" "}
                            {business.headline && <div className="headline">{business.headline}</div>}
                            {business.location.length > 0 && (
                                <div className="location">
                                    {business.location[0].address.city},{" "}
                                    {business.location[0].address.country}
                                </div>
                            )}
                        </div>
                        {business.isOwner && (
                            <div className="one flex-container row-reverse ">
                                <div className="action-icons">
                                    <div
                                        className="action-icon"
                                        onClick={() => {
                                            console.log(business);
                                            addToStack({
                                                name: t("business.update"),
                                                component: (
                                                    <UpdateBusinessContainer overrideBusiness={{ ...business }} />
                                                ),
                                            });
                                        }}
                                    >
                                        <ReactSVG src="/icons/pencil.svg" />
                                    </div>
                                    <div
                                        className="action-icon"
                                        onClick={() => {
                                            history.push(`/wallet/${business.id}`);
                                        }}
                                    >
                                        <ReactSVG src="/icons/wallet.svg" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {business.description && (
                        <div className="content-block">
                            <h2>{t("business.about", { name: business.name })}</h2>
                            <p>{business.description}</p>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div style={{ height: "300px", position: "relative" }}>
                <div className="absolute-center">
                    <Loader />
                </div>
            </div>
        );
    }
}
