import React, { Component } from 'react';

import Loader from '../../ui/components/Loader';
import AsideView from '../../ui/components/AsideView';

import Role from '../../../models/role/Role';
import RoleForm from './RoleForm';

export default class CreateRolePage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange('formRole', new Role());
    }

    render() {
        const { t, formRole, isFetchingRole, createRole } = this.props;

        if (!formRole || isFetchingRole) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t('role.new')}</h1>
                <RoleForm {...this.props} key={formRole.id} onSubmit={(role) => createRole(role)} />
            </AsideView>
        );
    }
}
