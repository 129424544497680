import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class BankAccountView extends Component {

    render() {
        const { bankAccount } = this.props;
        return (
            <div className="bankAccount-view">
                <div className="">{bankAccount.accountHolderName}</div>
            </div>
        )
    }
}

export default withTranslation("translation")(BankAccountView);