import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import ReCAPTCHA from "react-google-recaptcha";

import Loader from "../../ui/components/Loader";
import { activateInputs } from "../../../utils/formUtils";

export default class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });

        this.state = {
            email: "",
            subject: "",
            message: "",
            recaptcha: "",
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, subject, message, recaptcha } = this.state;

        const { contact, t } = this.props;

        if (this.validator.allValid()) {
            // send contact e-mail
            contact(email, subject, message, recaptcha)
                .then(() => {
                    this.state({
                        responseMessage: t("contact.success"),
                    });
                })
                .error(() => {
                    this.state({
                        responseMessage: t("contact.error"),
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        const { isFetchingContact, t } = this.props;
        const { email, subject, message, responseMessage } = this.state;

        if (isFetchingContact) {
            return <Loader />;
        }

        return (
            <form className="contact__form" method="post" onSubmit={(e) => this.handleSubmit(e)}>
                <div className="input-container">
                    <h2 className="top">Contact formulier</h2>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                value={email}
                                type="email"
                                onChange={(e) => {
                                    this.setState({
                                        email: e.target.value,
                                    });
                                }}
                            />
                            <label>{t("form.label.email")}*</label>
                        </div>
                        {this.validator.message(t("form.label.email"), email, "required|email")}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={subject}
                                onChange={(e) => {
                                    this.setState({
                                        subject: e.target.value,
                                    });
                                }}
                            />
                            <label>{t("form.label.subject")}*</label>
                        </div>
                        {this.validator.message(t("form.label.subject"), subject, "required")}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <textarea
                                value={message}
                                onChange={(e) => {
                                    this.setState({
                                        message: e.target.value,
                                    });
                                }}
                            ></textarea>
                            <label>{t("form.label.message")}*</label>
                        </div>
                        {this.validator.message(t("form.label.message"), message, "required")}
                    </div>
                    <div className="input-group" style={{overflow: 'hidden'}}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA || ""}
                            onChange={(event) => {
                                this.setState({
                                    recaptcha: event,
                                });
                            }}
                        />
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{responseMessage}</div>
                        <input
                            type="submit"
                            value={t("form.send")}
                            disabled={!this.validator.allValid()}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                </div>
            </form>
        );
    }
}
