import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PlatformsView from '../../components/platform/PlatformsView';

import * as platformActions from '../../state/actions/platformActions';
import * as navigationActions from '../../state/actions/navigationActions';

class PlatformsContainer extends Component {
    render() {
        const { getPlatforms, searchPlatforms, ...props } = this.props;

        return (
            <PlatformsView
                {...props}
                getPlatforms={(from = 0, to = 20) => getPlatforms(from, to)}
                searchPlatforms={(searchParameters, from, to) => searchPlatforms(searchParameters, from, to)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.platform,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPlatforms: (from, to) => dispatch(platformActions.getPlatforms(from, to)),
        searchPlatforms: (searchParameters, from, to) =>
            dispatch(platformActions.searchPlatforms(searchParameters, from, to)),
        deletePlatform: (platform) => dispatch(platformActions.deletePlatform(platform)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(PlatformsContainer));
