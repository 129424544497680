import React, { Component } from 'react';

export default class Steps extends Component {
    render() {
        const { steps, step, onStepChange } = this.props;
        return (
            <div className="stepper">
                {steps.map((s, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => onStepChange && index < step && onStepChange(index)}
                            className={'step ' + (index === step ? 'active' : index < step ? '' : 'disabled')}
                        >
                            <div className="number">{index + 1}</div>
                            <div className="text">{s.title}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
