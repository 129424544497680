import React, { Component } from 'react';
import ReactSVG from 'react-svg';

export default class Collapsible extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen:
                this.props.isOpen !== undefined && this.props.isOpen !== null
                    ? this.props.isOpen
                    : this.props.defaultIsOpen !== undefined && this.props.defaultIsOpen !== null
                    ? this.props.defaultIsOpen
                    : true,
        };
    }

    render() {
        const { isOpen, onOpenChange } = this.state;
        const { name, children } = this.props;

        return (
            <>
                <div
                    className={`collapsible${isOpen ? ' open' : ' closed'} flex-container`}
                    onClick={(e) => {
                        onOpenChange && onOpenChange(!isOpen);
                        this.setState({ isOpen: !isOpen });
                    }}
                >
                    <div className="name">{name}</div>
                    <ReactSVG src="/icons/back.svg" />
                </div>
                {isOpen && <>{children}</>}
            </>
        );
    }
}
