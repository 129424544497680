import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import NoData from '../ui/components/NoData';
import FilePreview from '../ui/components/FilePreview';

class SupportTicketActions extends Component {
    render() {
        const { t, actions } = this.props;

        if (actions.length > 0) {
            return (
                <div className="support-ticket-actions scrollable">
                    {actions
                        .sort((a, b) => moment(b.updatedAt).format('X') - moment(a.updatedAt).format('X'))
                        .map((action) => (
                            <div className="support-ticket-action">
                                <div className="flex-container justify-between">
                                    {action.user && (
                                        <div className="name">
                                            {action.user.username}{' '}
                                            {action.visibleToClient ? (
                                                <span>{t('support.ticket.action.visibleToClient')}</span>
                                            ) : (
                                                <span>{t('support.ticket.action.notVisibleToClient')}</span>
                                            )}
                                        </div>
                                    )}
                                    <div className="flex-container">
                                        <div className="date">
                                            {moment(action.createdAt).format('DD/MM/YYYY HH:mm')}
                                        </div>
                                    </div>
                                </div>
                                <div className="action-message">{action.message}</div>
                                <div className="attachments">
                                    {action.attachments.length > 0
                                        ? action.attachments.map((attachment) => (
                                              <div
                                                  className="attachment"
                                                  onClick={() => {
                                                      this.setState({
                                                          filePreviewOpen: true,
                                                          files: action.attachments,
                                                          focussedFile: attachment,
                                                      });
                                                  }}
                                              >
                                                  <FilePreview file={attachment} />
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </div>
                        ))}
                </div>
            );
        }

        return <NoData className="left" />;
    }
}

export default withTranslation('translation')(SupportTicketActions);
