import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import Select from 'react-select';

import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { intToDouble, doubleToInt } from '../../../utils/moneyUtils';

class ShiftForm extends Component {
    state = {};
    onChange = (newShift) => {
        const { onChange } = this.props;
        onChange && onChange(newShift);
    };

    render() {
        const { t, currencies, user, shift } = this.props;
        return (
            <>
                <div className="input-group">
                    <input
                        type="number"
                        value={shift.amount}
                        min="1"
                        onChange={(event) => {
                            const newShift = { ...shift };
                            newShift.amount = event.target.value;

                            this.onChange(newShift);
                        }}
                    />
                    <label>{t('form.label.amountOfShifts')}</label>
                </div>
                <div className="input-group">
                    <DayPickerInput
                        format="DD/MM/YYYY"
                        value={shift.date}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{ disabledDays: { before: new Date() } }}
                        onDayChange={(day) => {
                            const newShift = { ...shift };
                            newShift.date = moment(day).format('DD/MM/YYYY');

                            this.onChange(newShift);
                        }}
                        component={(props) => (
                            <div>
                                <input
                                    {...props}
                                    placeholder
                                    className={props.isActive || (props.value && 'has-value')}
                                />
                                <label>{t('form.label.date')}</label>
                            </div>
                        )}
                    />
                </div>
                <div className="input-group">
                    <div className="input-group half no-margin-top">
                        <input
                            type="time"
                            value={shift.startTime}
                            onChange={(event) => {
                                const newShift = { ...shift };
                                newShift.startTime = event.target.value;

                                this.onChange(newShift);
                            }}
                        />
                        <label>{t('form.label.startTime')}</label>
                    </div>

                    <div className="input-group half no-margin-top">
                        <input
                            type="time"
                            value={shift.endTime}
                            onChange={(event) => {
                                const newShift = { ...shift };
                                newShift.endTime = event.target.value;

                                this.onChange(newShift);
                            }}
                        />
                        <label>{t('form.label.endTime')}</label>
                    </div>
                </div>
                <div className="input-group more">
                    <div className="input-group fifth">
                        <Select
                            options={currencies.map((currency) => ({
                                value: currency.short,
                                label: currency.name,
                                sign: currency.sign,
                                currency: currency,
                            }))}
                            value={
                                shift.payout.money.selectedCurrency
                                    ? shift.payout.money.selectedCurrency
                                    : currencies.length > 0
                                    ? currencies.map((currency) => ({
                                          value: currency.short,
                                          label: currency.name,
                                          sign: currency.sign,
                                          currency: currency,
                                      }))[0]
                                    : null
                            }
                            onChange={(e) => {
                                const newShift = { ...shift };
                                const payout = { ...newShift.payout };
                                const money = { ...payout.money };

                                money.sign = e.sign;
                                money.currency = e.currency;
                                money.selectedCurrency = e;

                                payout.money = money;
                                newShift.payout = payout;

                                this.onChange(newShift);
                            }}
                        />
                    </div>
                    <div className="input-group eighty">
                        <CurrencyFormat
                            thousandSeparator={user.language.thousandDelimiter}
                            decimalSeparator={user.language.decimalDelimiter}
                            value={
                                shift.payout.money.formattedAmount ||
                                intToDouble(
                                    shift.payout.money.amount,
                                    2,
                                    user.language.decimalDelimiter,
                                    user.language.thousandDelimiter
                                )
                            }
                            prefix={shift.payout.money.sign || '€'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            onValueChange={(values) => {
                                const newShift = { ...shift };
                                const payout = { ...newShift.payout };
                                const money = { ...payout.money };

                                money.amount = doubleToInt(parseFloat(values.value));
                                money.formattedAmount = values.formattedValue;

                                payout.money = money;
                                newShift.payout = payout;

                                this.onChange(newShift);
                            }}
                        />
                        <label>{t('form.label.payoutPerHour')}</label>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation('translation')(ShiftForm);
