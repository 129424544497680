import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ChecksView from '../../components/task/ChecksView';

import * as taskActions from '../../state/actions/taskActions';

class PersonalShiftsContainer extends Component {
    render() {
        const { getChecks, getChecksByDate, getShiftChecks, getShiftChecksByDate, approveCheck, ...props } = this.props;

        return (
            <ChecksView
                {...props}
                getChecks={(from, amount) => getChecks(from, amount)}
                getChecksByDate={(start, end, from, amount) => getChecksByDate(start, end, from, amount)}
                getShiftChecks={(shift, from, amount) => getShiftChecks(shift, from, amount)}
                getShiftChecksByDate={(shift, start, end, from, amount) => getChecksByDate(shift, start, end, from, amount)}
                onApprove={(check) => approveCheck(check)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getChecks: (from, amount) => dispatch(taskActions.getChecks(from, amount)),
        getChecksByDate: (start, end, from, amount) => dispatch(taskActions.getChecksByDate(start, end, from, amount)),

        getShiftChecks: (shift, from, amount) => dispatch(taskActions.getShiftChecks(shift, from, amount)),
        getShiftChecksByDate: (shift, start, end, from, amount) => dispatch(taskActions.getShiftChecksByDate(shift, start, end, from, amount)),

        approveCheck: (check) => dispatch(taskActions.approveCheck(check)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(PersonalShiftsContainer));
