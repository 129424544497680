import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        const { t, i18n, isAuthenticated, platform, languages, noFooter, redFooter } = this.props;

        const languageOptions = languages.map((language) => {
            return { label: language.localName, value: language.id, code: language.code.toLowerCase() };
        });

        if (!isAuthenticated && !noFooter) {
            return (
                <footer className={redFooter ? 'red' : ''}>
                    <div className="container">
                        <div className="top">
                            <div className="middle">
                                <div className="list">
                                    <h1>{t('footer.company.header')}</h1>
                                    <Link to="/about">{t('footer.company.aboutUs')}</Link>
                                    <Link to="/pitch">{t('footer.company.pitch')}</Link>
                                    <Link to="/contact">{t('footer.company.getInTouch')}</Link>
                                </div>
                                <div className="list">
                                    <h1>{t('footer.legal.header')}</h1>
                                    <Link to="/legal/cookies">{t('footer.legal.cookies')}</Link>
                                    <Link to="/legal/terms-of-service">{t('footer.legal.termsOfService')}</Link>
                                    <Link to="/legal/data-usage">{t('footer.legal.dataUsage')}</Link>
                                </div>
                                <div className="list two">
                                    <h1>{t('footer.info.header')}</h1>
                                    <a href={`mailto: ${platform.contactEmail}`}>{platform.contactEmail}</a>A{' '}
                                    <a
                                        className="kolmans"
                                        href="http://kolmans.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Kolmans B.V.
                                    </a>{' '}
                                    &{' '}
                                    <a
                                        className="kolmans"
                                        href="http://n0name.eu"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        No Name B.V.
                                    </a>
                                    company
                                </div>
                            </div>
                            <div className="flex-container ">
                                <div className="three social">
                                    <a href="http://facebook.com/vynwurk" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                    <a href="http://instagram.com/vynwork" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </div>
                                <div className="one justify-end">
                                    <div className="form no-padding">
                                        <div className="input-group">
                                            <Select
                                                options={languageOptions}
                                                value={languageOptions.find(
                                                    (languageOption) => languageOption.code === i18n.language
                                                )}
                                                onChange={(e) => {
                                                    i18n.changeLanguage(e.code);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="copy">
                                <img
                                    className="white"
                                    src="/images/Vynwork_letters_white.png"
                                    alt="Vynwork letter logo"
                                />
                                <img className="red" src="/images/Vynwork_letters.png" alt="Vynwork letter logo" />
                                &copy; {new Date().getFullYear()} - All rights reserved.
                            </div>
                            <div className="toTop"></div>
                        </div>
                    </div>
                </footer>
            );
        }

        return null;
    }
}
