import { contractConstants } from '../constants/contract.constants';

const initialState = {
    isFetchingContract: false,
    formContract: null,
    contract: null,

    isFetchingContracts: false,
    contractsTotalResults: 0,
    contractsLoaded: 0,
    contractsFrom: 0,
    contracts: [],
};

export default function contract(state = initialState, action) {
    switch (action.type) {
        case contractConstants.CONTRACT_CHANGE_VALUE:
            return { ...state, error: '', [action.name]: action.value };

        case contractConstants.GET_CONTRACT_STARTED:
            return { ...state, isFetchingContract: true };
        case contractConstants.GET_CONTRACT_FAILURE:
            return { ...state, isFetchingContract: false, error: action.message || '' };
        case contractConstants.GET_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetchingContract: false,
                contract: action.contract,
            };

        case contractConstants.GET_CONTRACTS_STARTED:
            return { ...state, isFetchingContracts: true, contractsFrom: action.from || 0 };
        case contractConstants.GET_CONTRACTS_FAILURE:
            return { ...state, isFetchingContracts: false, error: action.message || '' };
        case contractConstants.GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                isFetchingContracts: false,
                contracts: action.from > 0 ? [...state.contracts, ...action.contracts] : action.contracts,
                contractsLoaded:
                    action.from > 0 ? state.contracts.length + action.contracts.length : action.contracts.length,
                contractsTotalResults: action.totalResults || 0,
            };

        case contractConstants.SEARCH_CONTRACTS_STARTED:
            return { ...state, isFetchingContracts: true, contractsFrom: action.from || 0 };
        case contractConstants.SEARCH_CONTRACTS_FAILURE:
            return { ...state, isFetchingContracts: false, error: action.message || '' };
        case contractConstants.SEARCH_CONTRACTS_SUCCESS:
            return {
                ...state,
                isFetchingContracts: false,
                contracts: action.from > 0 ? [...state.contracts, ...action.contracts] : action.contracts,
                contractsLoaded:
                    action.from > 0 ? state.contracts.length + action.contracts.length : action.contracts.length,
                contractsTotalResults: action.totalResults || 0,
            };

        case contractConstants.CREATING_CONTRACT_STARTED:
            return { ...state, isFetchingContract: true };
        case contractConstants.CREATING_CONTRACT_FAILURE:
            return { ...state, isFetchingContract: false, error: action.message || '' };
        case contractConstants.CREATING_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetchingContract: false,
                contract: action.contract,
                contracts: [action.contract, ...state.contracts],
            };

        case contractConstants.UPDATING_CONTRACT_STARTED:
            return { ...state, isFetchingContract: true };
        case contractConstants.UPDATING_CONTRACT_FAILURE:
            return { ...state, isFetchingContract: false, error: action.message || '' };
        case contractConstants.UPDATING_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetchingContract: false,
                contract: action.contract,
                contracts: [...state.contracts].map((contract) => {
                    if (action.contract.id === contract.id) {
                        return action.contract;
                    }
                    return contract;
                }),
            };

        case contractConstants.DELETE_CONTRACT_STARTED:
            return { ...state, isFetchingContract: true };
        case contractConstants.DELETE_CONTRACT_FAILURE:
            return { ...state, isFetchingContract: false, message: action.message || '' };
        case contractConstants.DELETE_CONTRACT_SUCCESS:
            return {
                ...state,
                isFetchingContract: false,
                contract: action.contract,
                contracts: [...state.contracts].filter((contract) => action.contract.id !== contract.id),
            };
        default:
            return state;
    }
}
