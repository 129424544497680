import Person from './Person';

export default class Profile {

    id;
    nonce; 

    profilePicture;
    person;
    accountNumber;

    contract;
    signature;

    createdAt;
    updatedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.person = new Person();
    }
    
}