import { supportConstants } from "../constants";

const initialState = {
    isFetchingSupportTickets: false,
    supportTicketsTotalResults: 0,
    supportTicketsLoaded: 0,
    supportTickets: [],
    isFetchingSupportTicket: false,

    supportTicket: null,
    formSupportTicket: null,

    requests: [],
    requestsTotalResults: 0,
    requestsLoaded: 0,
    isFetchingRequests: false,

    errors: [],
    errorsTotalResults: 0,
    errorsLoaded: 0,
    isFetchingErrors: false,
};

export default function support(state = initialState, action) {
    switch (action.type) {
        case supportConstants.SUPPORT_TICKET_CHANGE_VALUE:
            return {
                ...state,
                error: "",
                [action.name]: action.value,
            };

        case supportConstants.GET_SUPPORT_TICKETS_STARTED:
            return { ...state, isFetchingSupportTickets: true };
        case supportConstants.GET_SUPPORT_TICKETS_FAILURE:
            return { ...state, isFetchingSupportTickets: false, message: action.message || "" };
        case supportConstants.GET_SUPPORT_TICKETS_SUCCESS:
            return {
                ...state,
                isFetchingSupportTickets: false,
                supportTickets:
                    action.from > 0
                        ? [...state.supportTickets, ...action.supportTickets]
                        : action.supportTickets,
                supportTicketsLoaded:
                    action.from > 0
                        ? state.supportTickets.length + action.supportTickets.length
                        : action.supportTickets.length,
                supportTicketsTotalResults: action.totalResults,
            };

        case supportConstants.GET_SUPPORT_TICKET_STARTED:
            return { ...state, isFetchingSupportTicket: true };
        case supportConstants.GET_SUPPORT_TICKET_FAILURE:
            return { ...state, isFetchingSupportTicket: false, message: action.message || "" };
        case supportConstants.GET_SUPPORT_TICKET_SUCCESS:
            return { ...state, isFetchingSupportTicket: false, supportTicket: action.supportTicket };

        case supportConstants.UPDATE_SUPPORT_TICKET_STARTED:
            return { ...state, isFetchingSupportTicket: true };
        case supportConstants.UPDATE_SUPPORT_TICKET_FAILURE:
            return { ...state, isFetchingSupportTicket: false, message: action.message || "" };
        case supportConstants.UPDATE_SUPPORT_TICKET_SUCCESS:
            return { ...state, isFetchingSupportTicket: false, supportTicket: action.supportTicket };

        case supportConstants.CREATE_SUPPORT_TICKET_ACTION_STARTED:
            return { ...state, isFetchingSupportTicket: true };
        case supportConstants.CREATE_SUPPORT_TICKET_ACTION_FAILURE:
            return { ...state, isFetchingSupportTicket: false, message: action.message || "" };
        case supportConstants.CREATE_SUPPORT_TICKET_ACTION_SUCCESS:
            return { ...state, isFetchingSupportTicket: false, supportTicket: action.supportTicket };

        case supportConstants.GET_SUPPORT_TICKET_REQUESTS_STARTED:
            return { ...state, isFetchingRequests: true };
        case supportConstants.GET_SUPPORT_TICKET_REQUESTS_FAILURE:
            return { ...state, isFetchingRequests: false, message: action.message || "" };
        case supportConstants.GET_SUPPORT_TICKET_REQUESTS_SUCCESS:
            return {
                ...state,
                isFetchingRequests: false,
                requests: action.from > 0 ? [...state.requests, ...action.requests] : action.requests,
                requestsLoaded:
                    action.from > 0 ? state.requests.length + action.requests.length : action.requests.length,
                requestsTotalResults: action.totalResults,
            };

        case supportConstants.GET_SUPPORT_TICKET_ERRORS_STARTED:
            return { ...state, isFetchingErrors: true };
        case supportConstants.GET_SUPPORT_TICKET_ERRORS_FAILURE:
            return { ...state, isFetchingErrors: false, message: action.message || "" };
        case supportConstants.GET_SUPPORT_TICKET_ERRORS_SUCCESS:
            return {
                ...state,
                isFetchingErrors: false,
                errors: action.from > 0 ? [...state.errors, ...action.errors] : action.errors,
                errorsLoaded:
                    action.from > 0 ? state.errors.length + action.errors.length : action.errors.length,
                requestsTotalResults: action.totalResults,
            };
        default:
            return state;
    }
}
