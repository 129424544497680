export default class TaskDescriptionPart {
    
    id;
    nonce; 

    header;
    body;
    order; 

    constructor(header = '') {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.header = header;
    }

}