import React, { Component } from "react";
import Select from "react-select";
import Cleave from "cleave.js/react";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../utils/formUtils";
import Loader from "../../ui/components/Loader";
import Attachments from "../../ui/components/Attachments";

export default class SupportTicketForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit } = this.props;
        if (onSubmit) onSubmit(e);
    }

    render() {
        const { onChange, t, formSupportTicket, supportTicketIsFetching } = this.props;

        if (!formSupportTicket || supportTicketIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="step-form">
                <form
                    className={"form active"}
                    onSubmit={(e) => {
                        this.handleSubmit(e);
                    }}
                >
                    <div className="input-container">
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    disabled={this.props.profile && this.props.user}
                                    value={formSupportTicket.firstName}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.firstName = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                />
                                <label>{t("form.label.firstName")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.firstName"),
                                formSupportTicket.firstName,
                                "required"
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    disabled={this.props.profile && this.props.user}
                                    value={formSupportTicket.lastName}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.lastName = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                />
                                <label>{t("form.label.lastName")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.lastName"),
                                formSupportTicket.lastName,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    disabled={this.props.profile && this.props.user}
                                    value={formSupportTicket.email}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.email = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                />
                                <label>{t("form.label.email")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.email"),
                                formSupportTicket.email,
                                "required|email"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Cleave
                                    disabled={this.props.profile && this.props.user}
                                    value={formSupportTicket.phoneNumber}
                                    options={{ phone: true, phoneRegionCode: "NL" }}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.phoneNumber = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                />
                                <label>{t("form.label.phoneNumber")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.phoneNumber"),
                                formSupportTicket.phoneNumber,
                                "required"
                            )}
                        </div>
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formSupportTicket.subject}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.subject = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                />
                                <label>{t("form.label.subject")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.subject"),
                                formSupportTicket.subject,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Select
                                    placeholder={`${t("form.label.selectSupportPriority")}*`}
                                    options={[
                                        { value: 0, label: t("support.ticket.priority.low") },
                                        { value: 1, label: t("support.ticket.priority.normal") },
                                        { value: 2, label: t("support.ticket.priority.high") },
                                        { value: 3, label: t("support.ticket.priority.urgent") },
                                    ]}
                                    onChange={(e) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.priority = e.value;
                                        onChange("formSupportTicket", supportTicket, e);
                                    }}
                                />
                            </div>
                            {this.validator.message("Prioriteit", formSupportTicket.priority, "required")}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formSupportTicket.message}
                                    onChange={(event) => {
                                        const supportTicket = { ...formSupportTicket };
                                        supportTicket.message = event.target.value;
                                        onChange("formSupportTicket", supportTicket, event);
                                    }}
                                ></textarea>
                                <label>{t("form.label.description")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.description"),
                                formSupportTicket.message,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <h1>{t("form.label.attachments")}</h1>
                            <Attachments
                                {...this.props}
                                onChange={(files) => {
                                    const supportTicket = { ...formSupportTicket };
                                    supportTicket.attachment = files.map((file) => file.externalReference.id);
                                    onChange("formSupportTicket", supportTicket);
                                }}
                            />
                        </div>
                        <div className="input-group right more">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                value={t("form.post")}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
