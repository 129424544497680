import React, { Component } from 'react';

import CreateUserContainer from '../../containers/user/crud/CreateUserContainer';
import UpdateUserContainer from '../../containers/user/crud/UpdateUserContainer';

import TableView from '../ui/views/TableView';
import PopOver from '../ui/components/PopOver';

export default class UsersView extends Component {
    state = {
        selectedOptions: ['username', 'email'],
        popOvers: [],
        searchQuery: '',
    };

    componentDidMount() {
        const { getUsers } = this.props;
        getUsers(0, 20);
    }

    render() {
        const { t, users, isFetchingUsers, addToStack, deleteUser, searchUsers, getUsers, block, unblock } = this.props;
        const { selectedOptions, popOvers } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        isLoading={isFetchingUsers}
                        values={users}
                        title={t('users.header')}
                        searchEnabled
                        searchPlaceholder={t('search.placeholder')}
                        onSearchChange={(searchQuery) => {
                            searchQuery ? searchUsers({ query: searchQuery }, 0, 20) : getUsers(0, 20);

                            this.setState({
                                searchQuery: searchQuery,
                            });
                        }}
                        onScrolledToBottom={() => {
                            getUsers(0, 20);
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t('user.create'),
                                component: <CreateUserContainer />,
                            });
                        }}
                        createNewLabel={t('user.create')}
                        rowOnClick={(e) => {
                            // nothing
                        }}
                        options={[
                            {
                                name: 'username',
                                size: 'one',
                                header: t('user.username'),
                                type: 'string',
                                selector: 'username',
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'email',
                                size: 'one',
                                header: t('user.email'),
                                type: 'string',
                                selector: 'email',
                            },
                        ]}
                        selectedOptions={selectedOptions}
                        actions={[
                            {
                                name: (user) => (user.id !== this.props.authUser.id ? user.locked ? t('form.unblock') : t('form.block') : ""),
                                enabled: true,
                                action: (e, user) => {
                                    if(user.id !== this.props.authUser.id) {
                                        user.locked ? unblock(user) : block(user);
                                    }
                                },
                            },
                            {
                                name: t('form.edit'),
                                enabled: true,
                                action: (e, user) => {
                                    addToStack({
                                        name: t('user.update'),
                                        component: <UpdateUserContainer targetUser={user} />,
                                    });
                                },
                            },
                            {
                                name: t('form.delete'),
                                enabled: true,
                                action: (e, user) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t('user.delete.header')}</div>
                                                    <div className="text">{t('user.delete.description')}</div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deleteUser(user);
                                                            }}
                                                        >
                                                            {t('delete')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                        ]}
                        noDataLabel={t('users.notFound')}
                    />
                </div>
            </div>
        );
    }
}
