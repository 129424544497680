import React, { Component } from 'react';

import CaretPositioning from '../../utils/editCaretPositioning';

export default class ContentEditable extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            isFocused: false,
            caretPosition: {
                start: 0,
                end: 0,
            },
        };
    }

    newCaretPosition(caretPosition) {
        console.log(caretPosition)
        this.setState({
            caretPosition: caretPosition,
        });
        CaretPositioning.restoreSelection(this.ref.current, caretPosition);
    }

    onChangeHandler(e) {
        const { onChange } = this.props;
        this.setState({
            caretPosition: CaretPositioning.saveSelection(e.currentTarget),
        });

        onChange && onChange(e.target.innerHTML);
    }

    componentDidUpdate() {
        const { isFocused, caretPosition } = this.state;
        if (isFocused) CaretPositioning.restoreSelection(this.ref.current, caretPosition);
    }

    render() {
        const { value } = this.props;
        return (
            <div
                ref={this.ref}
                contentEditable="true"
                suppressContentEditableWarning="true"
                onInput={(e) => this.onChangeHandler(e)}
                onBlur={(e) => {
                    this.setState({
                        isFocused: false,
                    });
                    this.onChangeHandler(e);
                }}
                onFocus={(e) =>
                    this.setState({
                        isFocused: true,
                    })
                }
                dangerouslySetInnerHTML={{ __html: value }}
                {...this.props}
            />
        );
    }
}
