import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import TaskPage from "../../components/task/TaskPage";

import * as taskActions from "../../state/actions/taskActions";
import * as profileActions from "../../state/actions/profileActions";
import * as navigationActions from "../../state/actions/navigationActions";

class TaskContainer extends Component {
    render() {
        const { getTask, getUserProfile, acceptApplication, ...props } = this.props;
        const { task, popStack } = this.props;

        return (
            <TaskPage
                {...props}
                getTask={(id) => getTask(id)}
                acceptApplication={(shift, application) => {
                    const newApplication = { ...application };
                    newApplication.task = task.id;
                    newApplication.shift = shift.id;

                    acceptApplication(newApplication);
                    popStack();
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        userProfile: state.auth.profile,
        platform: state.platform.platform,
        profileIsFetching: state.profile.isFetching,
        profile: state.profile.profile,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
        getTask: (id) => dispatch(taskActions.getTask(id)),
        getUserProfile: (id) => dispatch(profileActions.getUserProfile(id)),

        acceptApplication: (application) => dispatch(taskActions.acceptApplication(application)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(TaskContainer));
