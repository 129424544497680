import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.nl";
import moment from "moment";

import { activateInputs } from "../../../utils/formUtils";

export default class ExperienceForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formExperience } = this.props;

        onSubmit && onSubmit(formExperience);
    }

    updateExperience = (newExperience) => {
        const { onChange } = this.props;

        onChange("formExperience", newExperience);
    };

    render() {
        const { t, onChange, formExperience, startDate, endDate } = this.props;

        const experienceTypeOptions = [
            {
                value: "FULL_TIME",
                label: t("experience.full-time"),
            },
            {
                value: "PART_TIME",
                label: t("experience.part-time"),
            },
            {
                value: "SELF_EMPLOYED",
                label: t("experience.self-employed"),
            },
            {
                value: "FREELANCE",
                label: t("experience.freelance"),
            },
            {
                value: "CONTRACT",
                label: t("experience.contract"),
            },
            {
                value: "INTERNSHIP",
                label: t("experience.internship"),
            },
            {
                value: "PTP",
                label: t("experience.professional-training-pathway"),
            },
        ];

        return (
            <div className="step-form">
                <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formExperience.title}
                                    onChange={(event) => {
                                        const newExperience = { ...formExperience };
                                        newExperience.title = event.target.value;
                                        this.updateExperience(newExperience);
                                    }}
                                />
                                <label>{t("form.label.jobTitle")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.jobTitle"),
                                formExperience.title,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formExperience.businessName}
                                    onChange={(event) => {
                                        const newExperience = { ...formExperience };
                                        newExperience.businessName = event.target.value;
                                        this.updateExperience(newExperience);
                                    }}
                                />
                                <label>{t("form.label.businessName")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.businessName"),
                                formExperience.businessName,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Select
                                    placeholder={t("form.label.experienceType")}
                                    options={experienceTypeOptions}
                                    value={experienceTypeOptions.find(
                                        (option) => option.value === formExperience.type
                                    )}
                                    onChange={(event) => {
                                        const newExperience = { ...formExperience };
                                        newExperience.type = event.value;
                                        this.updateExperience(newExperience);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group half">
                                <h1>{t("form.label.startedAt")}</h1>
                                <div className="input-group no-margin-top">
                                    <Cleave
                                        placeholder="dd/mm/yyyy"
                                        value={startDate}
                                        options={{ date: true, datePattern: ["d", "m", "Y"] }}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("startDate", value);

                                            const experienceStartDate = moment(value, "DD/MM/YYYY");
                                            if (experienceStartDate.isValid) {
                                                const newExperience = { ...formExperience };
                                                newExperience.startedAt = experienceStartDate;
                                                this.updateExperience(newExperience);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-group half">
                                <h1>{t("form.label.endedAt")}</h1>
                                <div className="input-group no-margin-top">
                                    <Cleave
                                        placeholder="dd/mm/yyyy (optional)"
                                        value={endDate}
                                        options={{ date: true, datePattern: ["d", "m", "Y"] }}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("endDate", value);

                                            const experienceEndDate = moment(value, "DD/MM/YYYY");
                                            if (experienceEndDate.isValid) {
                                                const newExperience = { ...formExperience };
                                                newExperience.endedAt = experienceEndDate;
                                                this.updateExperience(newExperience);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="input-group more">
                            <div className="input-group">
                                <textarea
                                    value={formExperience.description}
                                    onChange={(event) => {
                                        const newExperience = { ...formExperience };
                                        newExperience.description = event.target.value;
                                        this.updateExperience(newExperience);
                                    }}
                                ></textarea>
                                <label>{t("form.label.description")}</label>
                            </div>
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
