export const categoryConstants = {
    CATEGORY_CHANGE_VALUE: "CATEGORY_CHANGE_VALUE",

    GET_CATEGORY_STARTED: "GET_CATEGORY_STARTED",
    GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
    GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",

    GET_CATEGORIES_STARTED: "GET_CATEGORIES_STARTED",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

    CREATE_CATEGORY_STARTED: "CREATE_CATEGORY_STARTED",
    CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
    CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",

    UPDATE_CATEGORY_STARTED: "UPDATE_CATEGORY_STARTED",
    UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
    UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",


    DELETE_CATEGORY_STARTED: "DELETE_CATEGORY_STARTED",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
};
