import React, { Component } from 'react';

import ArticleCard from './ArticleCard';
import Loader from '../ui/components/Loader';

export default class ArticlesPage extends Component {
    handleScroll = () => {
        const { articlesLoaded, articlesTotalResults, getUserArticles } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight) {
            if (articlesLoaded < articlesTotalResults) {
                getUserArticles && getUserArticles(articlesLoaded, 20);
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { articles, articlesIsFetching } = this.props;

        if (articlesIsFetching) {
            return <Loader />;
        }

        return (
            <div className="articles">
                {articles.map((article) => (
                    <ArticleCard article={article} />
                ))}
            </div>
        );
    }
}
