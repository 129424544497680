import React, { Component } from "react";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";

import BusinessPage from "../../components/business/BusinessPage";

import * as businessActions from "../../state/actions/businessActions";
import * as navigationActions from "../../state/actions/navigationActions";

class BusinessContainer extends Component {
    render() {
        const { getBusiness, updateBusinessLogo, updateBusinessBanner, ...props } = this.props;

        return (
            <BusinessPage
                {...props}
                getBusiness={(id) => getBusiness(id)}
                updateBusinessLogo={(logo) => updateBusinessLogo(logo)}
                updateBusinessBanner={(banner) => updateBusinessBanner(banner)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.business,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBusiness: (id) => dispatch(businessActions.getBusiness(id)),
        updateBusinessLogo: (logo) => dispatch(businessActions.updateBusinessLogo(logo)),
        updateBusinessBanner: (banner) =>
            dispatch(businessActions.updateBusinessBanner(banner)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(BusinessContainer));
