import { i18n, store } from '../index';
import * as navigationActions from "../state/actions/navigationActions";
import Alert from "../models/Alert";

export const calculateCrow = (lat1var, lon1, lat2var, lon2) => {
    var R = 6371; // km
    var dLat = toRad(lat2var - lat1var);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1var);
    var lat2 = toRad(lat2var);

    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

export const toRad = (Value) => {
    return (Value * Math.PI) / 180;
};

export const getLocation = () => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => resolve(position.coords),
                (error) => {
                    store.dispatch(
                        navigationActions.createAlert(
                            new Alert(
                                i18n.t('notification.getLocation.error.title'),
                                i18n.t('notification.getLocation.error.description'),
                                'error'
                            )
                        )
                    );
                    resolve({ latitude: 0, longitude: 0 });
                }
            );
        } else {
            resolve({ latitude: 0, longitude: 0 });
        }
    });
};
