import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../../api/fileApi";

import UpdatePlatformPage from "../../../components/platform/crud/UpdatePlatformPage.js";

import * as platformActions from "../../../state/actions/platformActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdatePlatformContainer extends Component {
    render() {
        const { changeValue, updatePlatform, popStack, ...props } = this.props;

        return (
            <UpdatePlatformPage
                {...props}
                onChange={changeValue}
                updatePlatform={(platform) =>
                    updatePlatform(platform).then(() => {
                        popStack();
                    })
                }
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.platform,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updatePlatform: (platform) => dispatch(platformActions.updatePlatform(platform)),
        changeValue: (name, value) => dispatch(platformActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdatePlatformContainer));
