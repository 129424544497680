import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import Category from "../../../models/category/Category";
import CategoryForm from "./CategoryForm";

export default class CreateCategoryPage extends Component {
    componentDidMount() {
        const { onChange } = this.props;
        onChange("formCategory", new Category());
    }

    render() {
        const { t, formCategory, isFetchingCategory, createCategory } = this.props;

        console.log(this.props);

        if (!formCategory || isFetchingCategory) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("category.new")}</h1>
                <CategoryForm
                    {...this.props}
                    key={formCategory.id}
                    onSubmit={(category) => createCategory(category)}
                />
            </AsideView>
        );
    }
}
