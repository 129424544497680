import api from '../api';

export const login = (email = '', password = '') => {
    return api.post(`/user/login`, { username: email, password: password });
};

export function register(
    username = '',
    email = '',
    password = '',
    repeatPassword = '',
    terms = false,
    newsletter = false,
    recaptcha = '',
    language = ''
) {
    return api.post(`/user/register`, {
        username: username,
        email: email,
        password: password,
        repeatPassword: repeatPassword,
        terms: terms,
        newsletter: newsletter,
        recaptcha: recaptcha,
        language: language,
    });
}

export const forgot = (email = '') => {
    return api.post(`/user/forgot`, { email });
};

export const reset = (user, token, password, repeatPassword) => {
    return api.post(`/user/recover`, {
        id: user,
        token: token,
        password: password,
        repeatPassword: repeatPassword,
    });
};

export const updatePassword = (currentPassword, newPassword, repeatPassword) => {
    return api.put(`/user/password`, {
        currentPassword,
        newPassword,
        repeatPassword: repeatPassword,
    });
};

export const update = (user) => {
    return api.put(`/user`, { ...user });
};

export function getActiveSessions() {
    return api.get(`/user/sessions`);
}

export function logout() {
    return api.get(`/user/logout`);
}

export function logoutAllOthers() {
    return api.get(`/user/logout/all`);
}

export function confirmEmail(user = '', token = '') {
    return api.get(`/user/${user}/verify/${token}`);
}

// Roles CRUD
export const getRole = (id) => {
    return api.get(`/role/${id}`);
};
export const createRole = (role) => {
    return api.post(`/role`, role);
};

export const updateRole = (role) => {
    return api.put(`/role`, role);
};
export const removeRole = (role) => {
    return api.delete(`/role/${role.id}`);
};

// Fetching and finding roles
export const allRoles = (from = 0, amount = 20) => {
    return api.get(`/roles/${from}/${amount}`);
};

export const searchRoles = (searchParameters, from = 0, amount = 20) => {
    return api.post(`/roles/${from}/${amount}`, searchParameters);
};