import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Loader from '../../ui/components/Loader';

import BankAccountView from '../BankAccountView';

class PayoutView extends Component {
    render() {
        const { t, payout, payoutIsFetching } = this.props;
        if (payoutIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }
        return (
            <div className="payout-view">
                <h1 className="no-margin-top center">{t('wallet.payout.header')}</h1>
                <h3 className="no-margin-bottom no-margin-top center">#{payout.id}</h3>

                <div className="payout-view__bankAccount">
                    <BankAccountView bankAccount={payout.bankAccount} />
                </div>
            </div>
        );
    }
}
export default withTranslation('translation')(PayoutView)