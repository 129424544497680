import React, { Component } from "react";

import CloseButton from "./CloseButton";
import BreadCrumbs from "./BreadCrumbs";
import PopOver from "./PopOver";

export default class ComponentStack extends Component {
    state = {
        inTransition: false,
    };
    render() {
        const { components, popStack } = this.props;

        const onClose = () => {
            popStack && popStack();
        };
        const componentsLength = components.length;

        return (
            <PopOver isActive={components && componentsLength > 0} onClose={() => onClose()}>
                <div className="component-stack">
                    {components.map((component, index) => {
                        return (
                            <div
                                className={`rightview scrollable thick ${
                                    component.animateOut ? "animateOut" : "animateIn"
                                } ${componentsLength - 1 === index ? "" : " below"} ${
                                    component.fullWidth ? " full-width" : ""
                                }`}
                                key={`component-stack-${index}`}
                                style={{
                                    zIndex: 11 + componentsLength,
                                }}
                                onClick={(e) => {
                                    if (componentsLength - 1 !== index) {
                                        onClose();
                                    }
                                }}
                            >
                                <div className="pop-up-container">
                                    <div className="actions">
                                        <BreadCrumbs crumbs={[]} />
                                        <CloseButton onClick={() => onClose()} />
                                    </div>
                                    {component ? component.component : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </PopOver>
        );
    }
}
