import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Cropper from "react-easy-crop";

import { getCroppedImg, getBase64 } from "../../../utils/imageUtils";

import Loader from "../../ui/components/Loader";
import CloseButton from "../../ui/components/CloseButton";

const initialState = {
    src: null,
    crop: { x: 0, y: 0 },
    zoom: 1,
    zoomSpeed: 0.3,
    aspect: 16 / 7,
    croppedAreaPixels: null,
    isCropping: false,
};

export default class TaskBannerPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {...initialState, src: this.props.banner ? this.props.banner.url : null};

        this.dropzoneRef = React.createRef();
        this.cropperRef = React.createRef();
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ croppedAreaPixels });
    };

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    };

    getResult = () => {
        const { src, croppedAreaPixels } = this.state;

        return new Promise((resolve, reject) => {
            if (!src || !croppedAreaPixels) return reject();
            try {
                this.setState({
                    isCropping: true,
                });
                getCroppedImg(src, croppedAreaPixels).then((croppedImage) => {
                    this.setState({
                        croppedImage,
                        isCropping: false,
                    });
                    this.setState(initialState);
                    resolve(croppedImage);
                });
            } catch (e) {
                this.setState({
                    isCropping: false,
                });
                reject(e);
            }
        });
    };

    handleSubmit = (e) => {
        const { src, croppedAreaPixels } = this.state;
        e.preventDefault();
        try {
            if (!src || !croppedAreaPixels) return;
            this.setState({
                isCropping: true,
            });
            getCroppedImg(src, croppedAreaPixels).then((croppedImage) => {
                this.setState({
                    croppedImage,
                    isCropping: false,
                });
                this.props.handleSubmit(croppedImage);
                this.setState(initialState);
            });
        } catch (e) {
            this.setState({
                isCropping: false,
            });
        }
    };

    componentDidMount() {
        this.setState({

        });
    }

    render() {
        const { t } = this.props;
        const { src, crop, zoom, aspect, zoomSpeed, isCropping } = this.state;

        return (
            <div className="input-container">
                {isCropping ? (
                    <div className="input-group">
                        <Loader />
                    </div>
                ) : src ? (
                    <div className="input-group image-cropper banner">
                        <CloseButton
                            onClick={() => {
                                this.setState({ src: null });
                            }}
                        />
                        <Cropper
                            image={src}
                            crop={crop}
                            zoom={zoom}
                            zoomSpeed={zoomSpeed}
                            aspect={aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                            showGrid={false}
                        />
                    </div>
                ) : (
                    <Dropzone
                        ref={this.dropzoneRef}
                        onDrop={(acceptedFiles) => {
                            if (acceptedFiles.length > 0) {
                                getBase64(acceptedFiles[0]).then((result) => {
                                    this.setState({ src: result });
                                });
                            }
                        }}
                        accept="image/*"
                    >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                            return (
                                <div
                                    className={`input-group drag-and-drop${isDragActive ? " dragging" : ""}${
                                        isDragReject ? " rejected" : ""
                                    }`}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <p>
                                        {isDragActive
                                            ? isDragReject
                                                ? t("dragAndDrop.rejected")
                                                : t("dragAndDrop.dragging")
                                            : t("dragAndDrop.label")}
                                    </p>
                                </div>
                            );
                        }}
                    </Dropzone>
                )}
            </div>
        );
    }
}
