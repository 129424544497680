import moment from "moment";

export const enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];

    const currDate = moment(startDate).startOf("day");
    const lastDate = moment(endDate).startOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 1) {
        dates.push(currDate.clone());
    }

    return dates;
};
