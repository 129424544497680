import React, { Component } from 'react';
import Tags from 'react-meta-tags';

export default class MetaTags extends Component {
    render() {
        const { tags } = this.props;
        return (
            <Tags>
                <title>{tags.title}</title>
                <meta name="twitter:title" content={`${tags.title}`} />
                <meta property="og:title" content={`${tags.title}`} />
                <meta property="og:site_name" content={`${tags.title}`} />
                <meta itemProp="name" content={`${tags.title}`} />

                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="twitter:site" content="" />

                <meta property="og:image" content={`${tags.card || '/images/card.jpg'}`} />
                <meta name="twitter:image:src" content={`${tags.card || '/images/card.jpg'}`} />
                <meta name="twitter:card" content={`${tags.card || '/images/card.jpg'}`} />

                <meta name="description" content={`${tags.description || ''}`} />
                <meta itemProp="description" content={`${tags.description || ''}`} />
                <meta name="twitter:description" content={`${tags.description || ''}`} />
                <meta property="og:description" content={`${tags.description || ''}`} />

                <meta property="og:type" content={`${tags.type || "website"}`} />
                <meta property="og:url" content={window.location.href} />
                <meta name="keywords" content={tags.keywords} />

                <link rel="shortcut icon" type="image/png" href={`${tags.favicon}`}/>
            </Tags>
        );
    }
}
