import React, { Component } from 'react';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';
import Loader from '../../ui/components/Loader';
import Switch from '../../ui/components/Switch';
import Attachments from '../../ui/components/Attachments';

export default class SupportTicketActionForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit } = this.props;

        if (onSubmit) onSubmit(e);
    }

    render() {
        const { t, onChange, user, isFetchingSupportTicket, formSupportTicketAction } = this.props;

        if (!formSupportTicketAction || isFetchingSupportTicket) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        const supportAdmin = user && user.role.level >= 800;

        return (
            <div className="step-form">
                <form className={'form active'} onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        {supportAdmin && (
                            <div className="input-group no-margin-top">
                                <div className="flex-container">
                                    <div className="one">
                                        <div>{t('form.label.visibleToClient')}</div>
                                        <Switch
                                            checked={formSupportTicketAction.visibleToClient}
                                            onChange={(_, newState) => {
                                                const newSupportTicketAction = { ...formSupportTicketAction };
                                                newSupportTicketAction.visibleToClient = newState;
                                                onChange('formSupportTicketAction', newSupportTicketAction);
                                            }}
                                        />
                                    </div>
                                    <div className="one">
                                        <div>{t('form.label.sendEmail')}</div>
                                        <Switch
                                            disabled={!formSupportTicketAction.visibleToClient}
                                            checked={
                                                formSupportTicketAction.visibleToClient &&
                                                formSupportTicketAction.sendEmail
                                            }
                                            onChange={(_, newState) => {
                                                const newSupportTicketAction = { ...formSupportTicketAction };
                                                newSupportTicketAction.sendEmail = newState;
                                                onChange('formSupportTicketAction', newSupportTicketAction);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formSupportTicketAction.message}
                                    onChange={(event) => {
                                        const newSupportTicketAction = { ...formSupportTicketAction };
                                        newSupportTicketAction.message = event.target.value;
                                        onChange('formSupportTicketAction', newSupportTicketAction, event);
                                    }}
                                ></textarea>
                                <label>{t('form.label.description')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.description'),
                                formSupportTicketAction.message,
                                'required'
                            )}
                        </div>
                        {supportAdmin && (
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="time"
                                        value={formSupportTicketAction.timeSpentValue}
                                        onChange={(event) => {
                                            const newSupportTicketAction = { ...formSupportTicketAction };
                                            newSupportTicketAction.timeSpent = moment
                                                .duration(moment(event.target.value, 'HH:mm').format('HH:mm'))
                                                .asMinutes();
                                            newSupportTicketAction.timeSpentValue = event.target.value;
                                            onChange('formSupportTicketAction', newSupportTicketAction, event);
                                        }}
                                    />
                                    <label>{t('form.label.timeSpent')}*</label>
                                </div>
                                {this.validator.message(
                                    t('form.label.timeSpent'),
                                    formSupportTicketAction.timeSpent,
                                    'required|number'
                                )}
                            </div>
                        )}

                        <div className="input-group">
                            <h1>{t('form.label.attachments')}</h1>
                            <Attachments
                                onChange={(files) => {
                                    const newSupportTicketAction = { ...formSupportTicketAction };
                                    newSupportTicketAction.attachment = files.map((file) => file.externalReference.id);
                                    onChange('formSupportTicketAction', newSupportTicketAction);
                                }}
                                {...this.props}
                            />
                        </div>

                        <div className="input-group right more">
                            <input type="submit" disabled={!this.validator.allValid()} value={t('form.post')} />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
