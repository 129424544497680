import React, { Component } from "react";
import FViewer from "react-file-viewer";

import PopOver from "./PopOver";

export default class FileViewer extends Component {
    render() {
        const { t, isActive, focussedFile, onClose } = this.props;

        return (
            <PopOver isActive={isActive} onClose={onClose}>
                <div className="container absolute-center filePreviewer-container">
                    {isActive && focussedFile && (
                        <FViewer
                            fileType={focussedFile.extension}
                            filePath={focussedFile.url}
                            errorComponent={() => <div>{t("canNotLoadFile")}</div>}
                            onError={() => {}}
                        />
                    )}
                </div>
            </PopOver>
        );
    }
}
