import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';
import { intToDouble } from '../../../utils/moneyUtils';

import Loader from '../../ui/components/Loader';
import PaymentOptions from './PaymentOptions';

export default class AddCreditPage extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            tab: 'credit',
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { createCharge } = this.props;
        createCharge();
    }

    changePaymentType(type) {
        const { onChange, charge } = this.props;

        this.setState({ tab: type });
        const newCharge = { ...charge };
        const source = { ...(charge.source || {}) };
        source.type = type;
        newCharge.source = source;

        onChange('charge', newCharge);
    }

    render() {
        const { onChange, t, isFetching, isFetchingCurrencies, charge, source, user } = this.props;

        if (isFetching || isFetchingCurrencies || !charge) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        if (source) {
            window.location = source.redirect.url;
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="container big-box">
                <div className="wrapper normal top">
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <h1 className="center">{t('wallet.addCredit')}</h1>
                        <h2 className="center">
                            {`${charge.amount.currency.sign || '€'}${intToDouble(
                                charge.amount.amount,
                                2,
                                user.language.decimalDelimiter,
                                user.language.thousandDelimiter
                            )}`}
                        </h2>
                        <div className="input-container">
                            <div className="input-group more">
                                <PaymentOptions
                                    charge={charge}
                                    onChange={(newCharge) => {
                                        onChange('charge', newCharge);
                                    }}
                                />
                            </div>
                            <div className="input-group right">
                                <input type="submit" value={t('form.next')} onClick={(e) => e.stopPropagation()} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
