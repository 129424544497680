import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import ContractsView from '../../components/contract/ContractsView';

import * as contractActions from '../../state/actions/contractActions';
import * as navigationActions from '../../state/actions/navigationActions';

class ContractsContainer extends Component {
    render() {
        const { getContracts, searchContracts, ...props } = this.props;
        return (
            <ContractsView
                {...props}
                getContracts={(from = 0, to = 20) => getContracts(from, to)}
                searchContracts={(searchParameters, from, to) => searchContracts(searchParameters, from, to)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.contract,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getContracts: (from, to) => dispatch(contractActions.getContracts(from, to)),
        deleteContract: (id) => dispatch(contractActions.deleteContract(id)),

        searchContracts: (searchParameters, from, to) =>
            dispatch(contractActions.searchContracts(searchParameters, from, to)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ContractsContainer));
