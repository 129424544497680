import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ConfirmEmailPage from "../../components/authentication/ConfirmEmailPage";
import * as authActions from "../../state/actions/authActions.js";

class ConfirmEmailContainer extends Component {
    render() {
        const { t, confirmEmail, match, history, ...props } = this.props;

        return (
            <ConfirmEmailPage
                {...props}
                onLoad={() => {
                    confirmEmail(match.params.userId, match.params.token, t).then(
                        (response) => {
                            this.props.history.push("/login");
                        }
                    );
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        isFetching: state.auth.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        confirmEmail: (userId, token, t) => dispatch(authActions.confirmEmail(userId, token, t)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(ConfirmEmailContainer));
