import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import moment from "moment";

import Loader from "../ui/components/Loader";
import NoData from "../ui/components/NoData";

import { truncate } from "../../utils/stringUtils";

import SupportStatus from "../../models/support/SupportStatus";

export default class SupportTicketsPage extends Component {
    searchTimeout = null;
    constructor(props) {
        super(props);
        this.state = {
            tab: SupportStatus.open,
            searchQuery: "",
        };
    }

    handleScroll = () => {
        const { tab } = this.state;
        const { supportTicketsTotalResults, supportTicketsLoaded } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector(".page").offsetHeight) {
            if (supportTicketsTotalResults > supportTicketsLoaded) {
                this.getTickets(tab, supportTicketsLoaded, supportTicketsLoaded + 20);
            }
        }
    };

    componentDidMount() {
        const { tab } = this.state;
        this.getTickets(tab);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    getTickets = (status, from = 0, amount = 20) => {
        const { getStatusTickets, searchStatusTickets } = this.props;
        const { searchQuery } = this.state;

        if (searchQuery) {
            searchStatusTickets(status, { query: searchQuery }, from, amount);
        } else {
            getStatusTickets(status, from, amount);
        }
    };

    render() {
        const { t, supportTickets, isFetchingSupportTickets } = this.props;
        const { tab, searchQuery } = this.state;
        return (
            <div className="container support-center">
                <div className="wrapper top">
                    <div className="top-functions">
                        <h1 className="no-margin">{t("support.header")}</h1>

                        <div className="search-block">
                            <input
                                type="text"
                                className="search"
                                value={searchQuery}
                                placeholder={t("support.tickets.searchPlaceholder")}
                                onChange={(e) => {
                                    const searchQuery = e.target.value;
                                    this.setState({
                                        searchQuery,
                                    });
                                    if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                    this.searchTimeout = setTimeout(() => {
                                        this.getTickets(tab);
                                    }, 800);
                                }}
                            />
                        </div>
                    </div>
                    <div className="tabs">
                        <ul>
                            <li
                                className={"tab " + (tab === SupportStatus.open ? "active" : "")}
                                onClick={() => {
                                    this.setState({ tab: SupportStatus.open });
                                    this.getTickets(SupportStatus.open);
                                }}
                            >
                                {t("support.ticket.status.open")}
                            </li>
                            <li
                                className={"tab " + (tab === SupportStatus.in_progress ? "active" : "")}
                                onClick={() => {
                                    this.setState({ tab: SupportStatus.in_progress });
                                    this.getTickets(SupportStatus.in_progress);
                                }}
                            >
                                {t("support.ticket.status.inProgress")}
                            </li>
                            <li
                                className={"tab " + (tab === SupportStatus.waiting ? "active" : "")}
                                onClick={() => {
                                    this.setState({ tab: SupportStatus.waiting });
                                    this.getTickets(SupportStatus.waiting);
                                }}
                            >
                                {t("support.ticket.status.waiting")}
                            </li>
                            <li
                                className={"tab " + (tab === SupportStatus.closed ? "active" : "")}
                                onClick={() => {
                                    this.setState({ tab: SupportStatus.closed });
                                    this.getTickets(SupportStatus.closed);
                                }}
                            >
                                {t("support.ticket.status.closed")}
                            </li>
                        </ul>
                    </div>
                    <div className="support-tickets">
                        {isFetchingSupportTickets ? (
                            <Loader />
                        ) : supportTickets.length > 0 ? (
                            supportTickets.map((supportTicket) => (
                                <Link
                                    to={`/support/ticket/${supportTicket.id}`}
                                    className="ticket flex-container"
                                >
                                    <div className="one">
                                        <div className="subject">{truncate(supportTicket.subject, 30)}</div>
                                        <div className="flex-container">
                                            <div className="date">
                                                {moment(supportTicket.createdAt).format("DD/MM/YYYY HH:mm")}
                                            </div>
                                            <div className="priority">{supportTicket.priority}</div>
                                        </div>
                                    </div>
                                    <div className="one self-center">
                                        <div>
                                            <b>{t("support.ticket.plannedAt")}</b>
                                        </div>
                                        <div>
                                            {supportTicket.planned
                                                ? moment(supportTicket.planned).format("DD/MM/YYYY")
                                                : t("support.ticket.notPlanned")}
                                        </div>
                                    </div>
                                    <div className="one self-center">
                                        <div>
                                            <b>{t("support.ticket.user.name")}:</b>
                                        </div>
                                        <div>{`${supportTicket.firstName} ${supportTicket.lastName}`}</div>
                                    </div>
                                    <div className="one self-center">
                                        <div>
                                            <b>{t("support.ticket.assignedTo")}</b>
                                        </div>
                                        <div>
                                            {supportTicket.assignedTo
                                                ? supportTicket.assignedTo.username
                                                : t("support.ticket.notAssigned")}
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <div className="not-found-action-box">
                                <div className="icon">
                                    <ReactSVG src="/icons/space.svg" />
                                </div>
                                <NoData>{t("support.tickets.notFound")}</NoData>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
