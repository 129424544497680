export default class ChatUser {

    id;
    nonce;

    user;
    role;

    createdAt;
    updatedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }
}