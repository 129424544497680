import React, { Component } from "react";
import { isMobile } from "react-device-detect";

import ChatsList from "./ChatsList";

import ChatContainer from "../../containers/chat/ChatContainer";
import CreateChatContainer from "../../containers/chat/crud/CreateChatContainer";

import Loader from "../ui/components/Loader";

export default class ChatsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newChat: false,
            activeChat: null,
            hasProcessedUrl: false,
        };
    }

    componentDidMount() {
        const { match, getChats } = this.props;
        if (match.params.id) {
            this.setState({
                activeChat: match.params.id,
                hasProcessedUrl: true,
            });
        } else {
            this.setState({
                hasProcessedUrl: true,
            });
        }
        getChats(0, 20);
    }

    render() {
        const { getChats } = this.props;
        const { activeChat, hasProcessedUrl } = this.state;
        return (
            <div className="wrapper top">
                <div className="container chat">
                    <div className="flex-container">
                        {((isMobile && !activeChat) || !isMobile) && (
                            <div className="two">
                                <ChatsList
                                    {...this.props}
                                    activeChat={activeChat}
                                    openChat={(chat) => {
                                        window.history.pushState("", chat.title, `/messages/${chat.id}`);
                                        this.setState({ activeChat: chat.id });
                                    }}
                                />
                            </div>
                        )}
                        {(isMobile && activeChat && hasProcessedUrl) || (!isMobile && hasProcessedUrl) ? (
                            activeChat ? (
                                <div className="four">
                                    <ChatContainer key={activeChat} activeChat={activeChat} />
                                </div>
                            ) : (
                                <div className="four">
                                    <CreateChatContainer
                                        onChatCreated={(chat) => {
                                            this.setState({ activeChat: chat.id });
                                            getChats(0, 20);
                                        }}
                                    />
                                </div>
                            )
                        ) : !isMobile || (isMobile && activeChat) ? (
                            <div className="four">
                                <Loader />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
