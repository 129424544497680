import api from '../api';

export const reviews = (rating, from, amount) => {
    return api.get(`/rating/${rating.id}/reviews/${from}/${amount}`);
};

export const getReview = (id) => {
    return api.get(`/rating/review/${id}`);
};

export const createReview = (rating, review) => {
    return api.post(`/rating/${rating.id}/review`, review);
};

export const updateReview = (review) => {
    return api.put(`/rating/review`, review);
};

export const latestReviews = (rating, from = 0, amount = 20) => {
    return api.get(`/rating/${rating.id}/reviews/${from}/${amount}`);
};

export const searchReviews = (rating, searchParameters = [], from = 0, amount = 20) => {
    return api.post(`/rating/${rating.id}/reviews/${from}/${amount}`, searchParameters);
};
