import React, { Component } from "react";
import Loader from "../../ui/components/Loader";

export default class RedirectView extends Component {
    componentDidMount() {
        const { to } = this.props;
        window.location.href = to;
    }
    render() {
        return <div className="fullpage"><Loader className="absolute-center" /></div>;
    }
}
