import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreatePayoutPage from '../../../../components/wallet/payout/crud/CreatePayoutPage';

import * as walletActions from '../../../../state/actions/walletActions';
import * as navigationActions from '../../../../state/actions/navigationActions';

import Payout from '../../../../models/wallet/payout/Payout';

class CreatePayoutContainer extends Component {
    componentWillMount() {
        const { changeValue } = this.props;
        changeValue('formPayout', new Payout());
    }

    render() {
        const { changeValue, createPayout, popStack, callback, ...props } = this.props;
        const { t } = this.props;
        return (
            <CreatePayoutPage
                {...props}
                onChange={changeValue}
                createPayout={(payout) =>
                    createPayout(payout, t).then((response) => {
                        popStack && popStack();
                        callback && callback(response);
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        ...state.wallet,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(walletActions.changeValue(name, value)),
        createPayout: (payout, t) => dispatch(walletActions.createPayout(payout, t)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreatePayoutContainer));
