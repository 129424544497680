import User from '../user/User';

export default class SupportTicketAction {

    id;
    nonce;

    supportTicket; 
    
    user;
    
    message;
    attachments;

    visibleToClient;
    sendEmail; 

    timeSpent;
    timeSpentValue;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.user = new User()
        this.attachments = [];

        this.visibleToClient = false;
        this.sendEmail = false;

        this.timeSpent = 0;
        this.timeSpentValue = "00:00";
    }
}