export const platformConstants = {
    SET_PLATFORM: 'SET_PLATFORM',

    PLATFORM_CHANGE_VALUE: 'PLATFORM_CHANGE_VALUE',

    GET_PLATFORM_STARTED: 'GET_PLATFORM_STARTED',
    GET_PLATFORM_SUCCESS: 'GET_PLATFORM_SUCCESS',
    GET_PLATFORM_FAILURE: 'GET_PLATFORM_FAILURE',

    GET_PLATFORMS_STARTED: 'GET_PLATFORMS_STARTED',
    GET_PLATFORMS_SUCCESS: 'GET_PLATFORMS_SUCCESS',
    GET_PLATFORMS_FAILURE: 'GET_PLATFORMS_FAILURE',

    CREATE_PLATFORM_STARTED: 'CREATE_PLATFORM_STARTED',
    CREATE_PLATFORM_SUCCESS: 'CREATE_PLATFORM_SUCCESS',
    CREATE_PLATFORM_FAILURE: 'CREATE_PLATFORM_FAILURE',

    UPDATE_PLATFORM_STARTED: 'UPDATE_PLATFORM_SUCCESS',
    UPDATE_PLATFORM_SUCCESS: 'UPDATE_PLATFORM_SUCCESS',
    UPDATE_PLATFORM_FAILURE: 'UPDATE_PLATFORM_FAILURE',

    DELETE_PLATFORM_STARTED: 'DELETE_PLATFORM_STARTED',
    DELETE_PLATFORM_SUCCESS: 'DELETE_PLATFORM_SUCCESS',
    DELETE_PLATFORM_FAILURE: 'DELETE_PLATFORM_FAILURE',

    GET_LANGUAGES_STARTED: 'GET_LANGUAGES_STARTED',
    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_FAILURE: 'GET_LANGUAGES_FAILURE',

    GET_INDUSTRIES_STARTED: 'GET_INDUSTRIES_STARTED',
    GET_INDUSTRIES_SUCCESS: 'GET_INDUSTRIES_SUCCESS',
    GET_INDUSTRIES_FAILURE: 'GET_INDUSTRIES_FAILURE',

    GET_PERMISSIONS_STARTED: 'GET_PERMISSIONS_STARTED',
    GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
    GET_PERMISSIONS_FAILURE: 'GET_PERMISSIONS_FAILURE',

    CREATE_INDUSTRY_STARTED: 'CREATE_INDUSTRY_STARTED',
    CREATE_INDUSTRY_SUCCESS: 'CREATE_INDUSTRY_SUCCESS',
    CREATE_INDUSTRY_FAILURE: 'CREATE_INDUSTRY_FAILURE',

    UPDATE_INDUSTRY_STARTED: 'UPDATE_INDUSTRY_STARTED',
    UPDATE_INDUSTRY_SUCCESS: 'UPDATE_INDUSTRY_SUCCESS',
    UPDATE_INDUSTRY_FAILURE: 'UPDATE_INDUSTRY_FAILURE',

    CONTACT_STARTED: 'CONTACT_STARTED',
    CONTACT_SUCCESS: 'CONTACT_SUCCESS',
    CONTACT_FAILURE: 'CONTACT_FAILURE',

    SET_WEBSOCKET_CONNECTION: 'SET_WEBSOCKET_CONNECTION',
};
