import { articleConstants } from "../constants/article.constants";

const initialState = {
    isFetchingArticle: false,
    formArticle: null,
    article: null,

    isFetchingArticles: false,
    articlesTotalResults: 0,
    articlesLoaded: 0,
    articles: [],
};

export default function article(state = initialState, action) {
    switch (action.type) {
        case articleConstants.ARTICLE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case articleConstants.GET_ARTICLES_STARTED:
            return { ...state, isFetchingArticles: true };
        case articleConstants.GET_ARTICLES_FAILURE:
            return { ...state, isFetchingArticles: false, error: action.message || "" };
        case articleConstants.GET_ARTICLES_SUCCESS:
            return {
                ...state,
                isFetchingArticles: false,
                articles: action.from > 0 ? [...state.articles, ...action.articles] : action.articles,
                articlesLoaded:
                    action.from > 0 ? state.articles.length + action.articles.length : action.articles.length,
                articlesTotalResults: action.totalResults || 0,
            };

        case articleConstants.SEARCH_ARTICLES_STARTED:
            return { ...state, isFetchingArticles: true };
        case articleConstants.SEARCH_ARTICLES_FAILURE:
            return { ...state, isFetchingArticles: false, error: action.message || "" };
        case articleConstants.SEARCH_ARTICLES_SUCCESS:
            return {
                ...state,
                isFetchingArticles: false,
                articles: action.from > 0 ? [...state.articles, ...action.articles] : action.articles,
                articlesLoaded:
                    action.from > 0 ? state.articles.length + action.articles.length : action.articles.length,
                articlesTotalResults: action.totalResults || 0,
            };

        case articleConstants.CREATING_ARTICLE_STARTED:
            return { ...state, isFetchingArticle: true };
        case articleConstants.CREATING_ARTICLE_FAILURE:
            return { ...state, isFetchingArticle: false, error: action.message || "" };
        case articleConstants.CREATING_ARTICLE_SUCCESS:
            return { ...state, isFetchingArticle: false, article: action.article };

        case articleConstants.UPDATING_ARTICLE_STARTED:
            return { ...state, isFetchingArticle: true };
        case articleConstants.UPDATING_ARTICLE_FAILURE:
            return { ...state, isFetchingArticle: false, error: action.message || "" };
        case articleConstants.UPDATING_ARTICLE_SUCCESS:
            return { ...state, isFetchingArticle: false, article: action.article };
        default:
            return state;
    }
}
