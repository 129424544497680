import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import IdealBankOptions from '../IdealBankOptions';

class PaymentOptions extends Component {
    state = {
        tab: this.props.enabledPaymentOptions[0] || 'ideal',
    };


    componentDidMount = () => {
        this.changePaymentType(this.state.tab)
    }

    changePaymentType(type) {
        const { onChange, charge } = this.props;

        this.setState({ tab: type });
        const newCharge = { ...charge };
        const source = { ...(charge.source || {}) };
        source.type = type;
        newCharge.source = source;

        onChange(newCharge);
    }

    render() {
        const { t, enabledPaymentOptions, onChange, charge } = this.props;
        return (
            <>
                <div className="tabs">
                    <ul>
                        {enabledPaymentOptions.includes('creditcard') && (
                            <li
                                className={this.state.tab === 'creditcard' ? 'tab active' : 'tab'}
                                onClick={(e) => this.changePaymentType('credit')}
                            >
                                {t('wallet.payment.type.creditcard')}
                            </li>
                        )}
                        {enabledPaymentOptions.includes('ideal') && (
                            <li
                                className={this.state.tab === 'ideal' ? 'tab active' : 'tab'}
                                onClick={(e) => this.changePaymentType('ideal')}
                            >
                                {t('wallet.payment.type.ideal')}
                            </li>
                        )}
                    </ul>
                </div>
                <section className={this.state.tab === 'creditcard' ? 'activity active' : 'activity'}>
                    <div className="form-row">
                        <div id="card-element"></div>
                        <div className="msg" id="card-errors" role="alert"></div>
                    </div>
                </section>
                <section className={this.state.tab === 'ideal' ? 'activity active' : 'activity'}>
                    <IdealBankOptions
                        onSelected={(bank) => {
                            const newCharge = { ...charge };
                            const source = { ...(newCharge.source || {}) };
                            const extra = { ...(source.extra || {}) };
                            extra.ideal = { bank };
                            source.extra = extra;
                            newCharge.source = source;

                            onChange(newCharge);
                        }}
                    />
                </section>
            </>
        );
    }

    static defaultProps = {
        onChange: (newCharge) => null,
        enabledPaymentOptions: ['ideal'],
    };
}
export default withTranslation("translation")(PaymentOptions)