import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CurriculumVitaeView from "../../components/cv/CurriculumVitaeView";

import * as profileActions from "../../state/actions/profileActions";
import * as navigationActions from "../../state/actions/navigationActions";

class CurriculumVitaeContainer extends Component {
    render() {
        const { getUserProfile, deleteEducation, deleteExperience, ...props } = this.props;

        const { user } = this.props;

        return (
            <CurriculumVitaeView
                {...props}
                getUserProfile={() => getUserProfile(user.id)}
                deleteEducation={(id) => deleteEducation(id)}
                deleteExperience={(id) => deleteExperience(id)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserProfile: (id) => dispatch(profileActions.getUserProfile(id)),

        deleteEducation: (id) => dispatch(profileActions.deleteEducation(id)),
        deleteExperience: (id) => dispatch(profileActions.deleteExperience(id)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CurriculumVitaeContainer));
