import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../utils/formUtils";

export default class EducationForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, cv } = this.props;

        onSubmit && onSubmit(cv);
    }

    updateCV = (newCV) => {
        const { onChange } = this.props;

        onChange("cv", newCV);
    };

    render() {
        const { t, cv } = this.props;

        return (
            <div className="step-form">
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    value={cv.about}
                                    onChange={(event) => {
                                        const newCV = { ...cv };
                                        newCV.about = event.target.value;
                                        this.updateCV(newCV);
                                    }}
                                ></textarea>
                                <label>{t("form.label.summary")}</label>
                            </div>
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
