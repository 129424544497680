import api from '../api';

export const get = (id = '') => {
    return api.get(`/wallet/${id}`);
};

export const getFromUser = (id = '') => {
    return api.get(`/wallet/user/${id}`);
};

// Funds
export const hasFunds = (id = '', amount) => {
    return api.post(`/wallet/${id}/hasFunds`, { amount })
}

/**
 * Charges
 */
export const createCharge = (charge) => {
    return api.post(`/charge`, charge);
};

export const updateCharge = (charge) => {
    return api.put(`/charge`, charge);
};

export const executeCharge = (charge) => {
    return api.post(`/charge/execute`, charge);
};

/**
 * Transactions
 */
export const getTransaction = (id) => {
    return api.get(`/transaction/${id}`);
};

export const getTransactions = (from, amount) => {
    return api.get(`/transactions/${from}/${amount}`);
};
export const getTransactionsByDate = (startDate, toDate) => {
    return api.get(`/transactions/date/${startDate.format('YYYY-MM-DD')}/${toDate.format('YYYY-MM-DD')}`);
};

export const getWalletTransactions = (wallet, from, amount) => {
    return api.get(`/transactions/${wallet.id}/${from}/${amount}`);
};

/**
 * Invoice CRUD
 */
export const getInvoice = (id) => {
    return api.get(`/invoice/${id}`);
};

export const createInvoice = (invoice) => {
    return api.post(`/invoice`, invoice);
};

export const updateInvoice = (invoice) => {
    return api.put(`/invoice`, invoice);
};

export const getInvoices = (from, amount) => {
    return api.get(`/invoices/${from}/${amount}`);
};

/**
 * Payouts
 */
export const createPayout = (payout) => {
    return api.post(`/payout`, payout);
};

export const updatePayout = (payout) => {
    return api.put(`/payout`, payout);
};

export const getPayout = (id) => {
    return api.get(`/payout/${id}`);
};

export const getPayouts = (from, amount) => {
    return api.get(`/payouts/${from}/${amount}`);
};

// Rest
export const getCurrencies = () => {
    return api.get(`/currencies`);
};

