import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateSupportTicket from "../../../components/support/crud/CreateSupportTicket.js";
import * as supportActions from "../../../state/actions/supportActions";

class CreateSupportTicketContainer extends Component {
    render() {
        const { changeValue, createTicket, onNavigateTo, ...props } = this.props;

        return (
            <CreateSupportTicket
                {...props}
                onChange={changeValue}
                createTicket={(ticket) => {
                    return createTicket(ticket).then((response) => {
                        onNavigateTo(
                            `/support/ticket/${response.supportTicket.id}/${response.supportTicket.token}`
                        );
                    });
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.support,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createTicket: (ticket) => dispatch(supportActions.createTicket(ticket)),
        changeValue: (name, value) => dispatch(supportActions.changeValue(name, value)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateSupportTicketContainer));
