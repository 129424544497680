export const profileConstants = {
    PROFILE_CHANGE_VALUE: "PROFILE_CHANGE_VALUE",

    CREATING_PROFILE_CHANGE_VALUE: "CREATING_PROFILE_CHANGE_VALUE",
    CREATING_PROFILE_STARTED: "CREATING_PROFILE_STARTED",
    CREATING_PROFILE_SUCCESS: "CREATING_PROFILE_SUCCESS",
    CREATING_PROFILE_FAILURE: "CREATING_PROFILE_FAILURE",

    UPDATING_PROFILE_CHANGE_VALUE: "UPDATING_PROFILE_CHANGE_VALUE",
    UPDATING_PROFILE_STARTED: "UPDATING_PROFILE_STARTED",
    UPDATING_PROFILE_SUCCESS: "UPDATING_PROFILE_SUCCESS",
    UPDATING_PROFILE_FAILURE: "UPDATING_PROFILE_FAILURE",

    UPDATE_PROFILE_AVATAR_STARTED: "UPDATE_PROFILE_AVATAR_STARTED",
    UPDATE_PROFILE_AVATAR_SUCCESS: "UPDATE_PROFILE_AVATAR_SUCCESS",
    UPDATE_PROFILE_AVATAR_FAILURE: "UPDATE_PROFILE_AVATAR_FAILURE",

    UPDATE_PROFILE_BANNER_STARTED: "UPDATE_PROFILE_BANNER_STARTED",
    UPDATE_PROFILE_BANNER_SUCCESS: "UPDATE_PROFILE_BANNER_SUCCESS",
    UPDATE_PROFILE_BANNER_FAILURE: "UPDATE_PROFILE_BANNER_FAILURE",

    GET_PROFILE_STARTED: "GET_PROFILE_STARTED",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

    SEARCH_PROFILE_STARTED: "SEARCH_PROFILE_STARTED",
    SEARCH_PROFILE_SUCCESS: "SEARCH_PROFILE_SUCCESS",
    SEARCH_PROFILE_FAILURE: "SEARCH_PROFILE_FAILURE",

    UPDATE_CV_STARTED: "UPDATE_CV_STARTED",
    UPDATE_CV_SUCCESS: "UPDATE_CV_SUCCESS",
    UPDATE_CV_FAILURE: "UPDATE_CV_FAILURE",

    CREATE_EDUCATION_STARTED: "CREATE_EDUCATION_STARTED",
    CREATE_EDUCATION_SUCCESS: "CREATE_EDUCATION_SUCCESS",
    CREATE_EDUCATION_FAILURE: "CREATE_EDUCATION_FAILURE",

    UPDATE_EDUCATION_STARTED: "UPDATE_EDUCATION_STARTED",
    UPDATE_EDUCATION_SUCCESS: "UPDATE_EDUCATION_SUCCESS",
    UPDATE_EDUCATION_FAILURE: "UPDATE_EDUCATION_FAILURE",

    DELETE_EDUCATION_STARTED: "DELETE_EDUCATION_STARTED",
    DELETE_EDUCATION_SUCCESS: "DELETE_EDUCATION_SUCCESS",
    DELETE_EDUCATION_FAILURE: "DELETE_EDUCATION_FAILURE",

    CREATE_EXPERIENCE_STARTED: "CREATE_EXPERIENCE_STARTED",
    CREATE_EXPERIENCE_SUCCESS: "CREATE_EXPERIENCE_SUCCESS",
    CREATE_EXPERIENCE_FAILURE: "CREATE_EXPERIENCE_FAILURE",

    UPDATE_EXPERIENCE_STARTED: "UPDATE_EXPERIENCE_STARTED",
    UPDATE_EXPERIENCE_SUCCESS: "UPDATE_EXPERIENCE_SUCCESS",
    UPDATE_EXPERIENCE_FAILURE: "UPDATE_EXPERIENCE_FAILURE",

    DELETE_EXPERIENCE_STARTED: "DELETE_EXPERIENCE_STARTED",
    DELETE_EXPERIENCE_SUCCESS: "DELETE_EXPERIENCE_SUCCESS",
    DELETE_EXPERIENCE_FAILURE: "DELETE_EXPERIENCE_FAILURE",
};
