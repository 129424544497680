import React, { Component } from "react";
import moment from "moment";

import Loader from "../ui/components/Loader";
import NoData from "../ui/components/NoData";

export default class SupportTicketErrors extends Component {
    componentDidMount() {
        const { fetchErrors } = this.props;
        fetchErrors();
    }

    render() {
        const { t, errors, isFetchingErrors } = this.props;

        if (isFetchingErrors) return <Loader />;
        if (errors.length < 1) return <NoData className="left">{t("support.errors.notFound")}</NoData>;

        return (
            <div className="support-ticket-errors scrollable">
                {errors.map((error) => (
                    <div className="support-ticket-error" key={error.id}>
                        <div className="flex-container justify-between">
                            <div className={`responseCode ${error.requestLog.responseCode}`}>
                                {error.requestLog.responseCode}
                            </div>
                            <div className="responseTime">{error.requestLog.responseTime}ms</div>
                        </div>
                        <div
                            className={`flex-container no-wrap error.request ${error.requestLog.requestMethod}`}
                        >
                            <div className="error.requestMethod">{error.requestLog.requestMethod}</div>
                            <div className="url">{error.requestLog.url}</div>
                        </div>
                        <div className="date">{moment(error.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        <div className="ip">{error.requestLog.ip}</div>
                        <div className="agent">{error.requestLog.userAgent}</div>
                        <div className="error">{error.error}</div>
                    </div>
                ))}
            </div>
        );
    }
}
