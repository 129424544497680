import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import ContractForm from "./ContractForm";

export default class UpdateContractPage extends Component {
    componentDidMount() {
        const { onChange, targetContract } = this.props;
        const newContract = { ...targetContract };
        onChange("formContract", newContract);
    }

    render() {
        const { t, formContract, isFetchingContract, updateContract } = this.props;

        if (!formContract || isFetchingContract) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("contract.update")}</h1>
                <ContractForm
                    {...this.props}
                    key={formContract.id}
                    onSubmit={(contract) => updateContract(contract)}
                />
            </AsideView>
        );
    }
}
