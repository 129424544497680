import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateRolePage from "../../../components/role/crud/UpdateRolePage";

import * as authActions from "../../../state/actions/authActions";
import * as navigationActions from "../../../state/actions/navigationActions";
import * as platformActions from "../../../state/actions/platformActions";

class UpdateRoleContainer extends Component {

    componentDidMount() {
        const { getPermissions, changeValue, targetRole } = this.props;
        getPermissions();
        changeValue("formRole", targetRole);
    }
    
    render() {
        const { changeValue, updateRole, getPermissions, popStack, ...props } = this.props;

        return (
            <UpdateRolePage
                {...props}
                onChange={changeValue}
                getPermissions={getPermissions}
                updateRole={(role) =>
                    updateRole(role).then(() => {
                        popStack && popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        permissions: state.platform.permissions,
        ...state.auth,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateRole: (role) => dispatch(authActions.updateRole(role)),
        changeValue: (name, value) => dispatch(authActions.changeValue(name, value)),

        getPermissions: () => dispatch(platformActions.getPermissions()),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateRoleContainer));
