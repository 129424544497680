import React, { Component } from 'react';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.nl';

import { base64ToBlob } from '../../../utils/imageUtils';
import { activateInputs, enableSwitchCheckboxes } from '../../../utils/formUtils';
import { truncate } from '../../../utils/stringUtils';
import { ibanRegex, phoneRegex } from '../../../constants/regexConstants';

import Steps from '../../ui/components/Steps';
import Loader from '../../ui/components/Loader';
import AsideView from '../../ui/components/AsideView';
import AvatarPicker from '../../ui/components/AvatarPicker';
import UploadButton from '../../ui/components/UploadButton';
import CountrySelector from '../../ui/components/CountrySelector';
import BusinessForm from '../../business/crud/BusinessForm';
export default class CreateProfilePage extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.sigCanvas = null;

        this.state = {
            avatar: '/images/user.svg',
            avatarPicker: false,
            uploadingAvatar: false,

            step: 0,
        };
    }

    componentDidMount() {
        const { getContractByTitle } = this.props;

        activateInputs();
        enableSwitchCheckboxes();

        getContractByTitle('default');
    }

    componentDidUpdate() {
        activateInputs();
        enableSwitchCheckboxes();
    }

    handlePrevious(e) {
        e.preventDefault();

        this.setState({
            step: this.state.step - 1,
        });
    }

    handleSubmit(e) {
        const { uploadFile, onChange, createProfile } = this.props;
        const signature = base64ToBlob(this.sigCanvas.toDataURL());

        uploadFile(signature, 'signature').then((response) => {
            onChange('signature', { ...response.file, loading: false });
            createProfile();
        });
    }

    handleNext(e) {
        if (e) e.preventDefault();

        const { platform } = this.props;
        const { step } = this.state;
        const max = platform.createBusinessAtProfile ? 2 : 1;

        if (this.validator.allValid()) {
            if (step >= max) {
                this.handleSubmit(e);
            } else {
                this.setState({
                    step: step + 1,
                });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    _renderPerson(isActive) {
        const { onChange, t, formProfile } = this.props;
        const { avatar, avatarPicker } = this.state;

        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                <div className="input-container">
                    {this._renderStepper()}
                    <div className="input-group left">
                        <img className="profile-image" src={avatar} alt="avatar" />
                        <label
                            className="picture"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    avatarPicker: !avatarPicker,
                                });
                            }}
                        >
                            {t('form.label.chooseAvatar')}
                        </label>
                        <p>{t('form.label.chooseAvatar.advice')}</p>
                    </div>
                    <div className="input-group more">
                        <h1>{t('form.label.fullName')}</h1>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.firstName}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.firstName = event.target.value;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.firstName')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.firstName'),
                                formProfile.person.firstName,
                                'required'
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.lastName}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.lastName = event.target.value;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.lastName')}*</label>
                            </div>
                            {this.validator.message(t('form.label.lastName'), formProfile.person.lastName, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Cleave
                                    value={formProfile.person.phoneNumber}
                                    options={{ phone: true, phoneRegionCode: 'NL' }}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.phoneNumber = event.target.value.replaceAll(/\s/g,'');
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.phoneNumber')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.phoneNumber'),
                                formProfile.person.phoneNumber,
                                ['required', {regex: phoneRegex}]
                            )}
                        </div>
                    </div>
                    <div className="input-group more">
                        <div className="input-group half">
                            <h1>{t('form.label.gender')}</h1>
                            <div className="input-group third">
                                <input
                                    type="checkbox"
                                    name="gender"
                                    id="MALE"
                                    value="MALE"
                                    checked={formProfile.person.gender === 'MALE' || !formProfile.person.gender}
                                    onClick={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.gender = event.target.checked ? 'MALE' : 'FEMALE';
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label className="normal gray" htmlFor="MALE">
                                    {t('form.label.male')}
                                </label>
                            </div>
                            <div className="input-group third">
                                <input
                                    type="checkbox"
                                    name="gender"
                                    id="FEMALE"
                                    value="FEMALE"
                                    checked={formProfile.person.gender === 'FEMALE'}
                                    onClick={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.gender = event.target.checked ? 'FEMALE' : 'MALE';
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label className="normal gray" htmlFor="FEMALE">
                                    {t('form.label.female')}
                                </label>
                            </div>
                            <div className="input-group third">
                                <input
                                    type="checkbox"
                                    name="gender"
                                    id="OTHER"
                                    value="OTHER"
                                    checked={formProfile.person.gender === 'OTHER'}
                                    onClick={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.gender = event.target.checked ? 'OTHER' : 'MALE';
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label className="normal gray" htmlFor="OTHER">
                                    {t('form.label.other')}
                                </label>
                            </div>
                        </div>
                        <div className="input-group half">
                            <h1>{t('form.label.dateOfBirth')}*</h1>
                            <div className="input-group">
                                <Cleave
                                    placeholder="dd/mm/yyyy"
                                    value={this.props.dateOfBirthValue}
                                    options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange('dateOfBirthValue', value);

                                        const dateOfBirth = moment(value, 'DD/MM/YYYY');
                                        if (dateOfBirth.isValid) {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            person.birthday = dateOfBirth;
                                            profile.person = person;
                                            onChange('formProfile', profile, event);
                                        }
                                    }}
                                />
                            </div>
                            {this.validator.message('Geboorte datum', formProfile.person.birthday, 'required')}
                        </div>
                    </div>
                    <div className="input-group more left">
                        <h1>{t('form.label.identification')}</h1>
                        <div className="input-group half">
                            <UploadButton
                                loading={
                                    this.props.frontIdentification ? this.props.frontIdentification.loading : false
                                }
                                uploaded={
                                    this.props.frontIdentification ? !this.props.frontIdentification.loading : false
                                }
                                value={
                                    this.props.frontIdentification
                                        ? truncate(this.props.frontIdentification.originalName, 15)
                                        : null
                                }
                                onReset={(e) => onChange('frontIdentification', null)}
                                onChange={(event) => {
                                    onChange('frontIdentification', {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    });
                                    this.props
                                        .uploadFile(event.path[0].files[0], 'identification')
                                        .then((response) => {
                                            onChange('frontIdentification', {
                                                ...response.file,
                                                loading: false,
                                            });
                                        })
                                        .catch((exception) => {
                                            onChange('frontIdentification', null);
                                        });
                                }}
                            >
                                {t('form.label.identification.front')}*
                            </UploadButton>
                            {this.validator.message('Identificatie', this.props.frontIdentification, 'required')}
                        </div>
                        <div className="input-group half">
                            <UploadButton
                                loading={this.props.backIdentification ? this.props.backIdentification.loading : false}
                                uploaded={
                                    this.props.backIdentification ? !this.props.backIdentification.loading : false
                                }
                                value={
                                    this.props.backIdentification
                                        ? truncate(this.props.backIdentification.originalName, 15)
                                        : null
                                }
                                onReset={(e) => onChange('backIdentification', null)}
                                onChange={(event) => {
                                    onChange('backIdentification', {
                                        originalName: event.path[0].files[0].name,
                                        loading: true,
                                    });
                                    this.props
                                        .uploadFile(event.path[0].files[0], 'identification')
                                        .then((response) => {
                                            onChange('backIdentification', {
                                                ...response.file,
                                                loading: false,
                                            });
                                        })
                                        .catch((exception) => {
                                            onChange('backIdentification', null);
                                        });
                                }}
                            >
                                {t('form.label.identification.back')}*
                            </UploadButton>
                            {this.validator.message('Identificatie', this.props.backIdentification, 'required')}
                        </div>
                    </div>
                    <div className="input-group more">
                        <h1>{t('form.label.address')}</h1>
                        <div className="input-group eighty">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.address.street}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        const address = { ...person.address };
                                        address.street = event.target.value;
                                        person.address = address;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.street')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.street'),
                                formProfile.person.address.street,
                                'required'
                            )}
                        </div>
                        <div className="input-group fifth">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.address.houseNumber}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        const address = { ...person.address };
                                        address.houseNumber = event.target.value;
                                        person.address = address;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.houseNumber')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.houseNumber'),
                                formProfile.person.address.houseNumber,
                                'required'
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.address.zipCode}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        const address = { ...person.address };
                                        address.zipCode = event.target.value;
                                        person.address = address;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.zipCode')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.zipCode'),
                                formProfile.person.address.zipCode,
                                'required'
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.address.city}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        const address = { ...person.address };
                                        address.city = event.target.value;
                                        person.address = address;
                                        profile.person = person;
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.city')}*</label>
                            </div>
                            {this.validator.message(t('form.label.city'), formProfile.person.address.city, 'required')}
                        </div>
                        <div className="input-group">
                            <div classname="input-group no-margin-top">
                                <CountrySelector
                                    placeholder={`${t('form.label.selectCountry')}*`}
                                    value={formProfile.person.address.countryCode}
                                    onChange={(value) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        const address = { ...person.address };
                                        address.country = value.label;
                                        address.countryCode = value.value;
                                        person.address = address;
                                        profile.person = person;
                                        onChange('formProfile', profile);
                                    }}
                                />
                            </div>
                            {this.validator.message(
                                t('form.label.country'),
                                formProfile.person.address.country,
                                'required'
                            )}
                        </div>
                    </div>
                    <div className="input-group more">
                        <h1>{t('form.label.bankDetails')}</h1>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.accountNumber}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        profile.accountNumber = event.target.value.replaceAll(/\s/g,'');
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.iban')}*</label>
                            </div>
                            {this.validator.message(t('form.label.iban'), formProfile.accountNumber, [
                                'required',
                                { regex: ibanRegex },
                            ])}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.accountNumberControl}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        profile.accountNumberControl = event.target.value.replaceAll(/\s/g,'');
                                        onChange('formProfile', profile, event);
                                    }}
                                />
                                <label>{t('form.label.ibanRepeat')}*</label>
                            </div>
                            {this.validator.message(t('form.label.ibanRepeat'), formProfile.accountNumberControl, [
                                'required',
                                { in: [formProfile.accountNumber] },
                            ])}
                        </div>
                    </div>
                    <div className="input-group right">
                        <input type="submit" value={t('next')} onClick={(e) => this.handleNext(e)} />
                    </div>
                </div>
            </form>
        );
    }

    _renderBusiness = (isActive) => {
        const { t, onChange, formProfile } = this.props;
        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                <div className="input-container">{this._renderStepper()}</div>
                <BusinessForm
                    {...this.props}
                    submitLabel={t('next')}
                    onBackClick={(e) => {
                        this.handlePrevious(e);
                    }}
                    onChange={(_, newBusiness) => {
                        const profile = { ...formProfile };
                        profile.business = newBusiness;
                        onChange('formProfile', profile);
                    }}
                    formBusiness={formProfile.business || {}}
                    onSubmit={(business) => {
                        this.handleNext();
                    }}
                />
            </form>
        );
    };

    _renderContract = (isActive) => {
        const { t, contract } = this.props;
        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                <div className="input-container">
                    {this._renderStepper()}
                    <div className="input-group">
                        <div className="text contract scroll">
                            {contract ? contract.description : 'No contract found'}
                        </div>
                    </div>
                    <div className="input-group">
                        <h1>{t('form.label.signature')}</h1>
                        <div className="input-group">
                            <SignatureCanvas
                                ref={(ref) => {
                                    this.sigCanvas = ref;
                                }}
                                canvasProps={{
                                    className: 'signature',
                                    width: 400,
                                    height: 165,
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-group right">
                        <button className="back" onClick={(e) => this.handlePrevious(e)}>
                            {t('back')}
                        </button>
                        <input type="submit" onClick={(e) => this.handleNext(e)} value={t('form.save')} />
                    </div>
                </div>
            </form>
        );
    };

    _renderStepper() {
        const { step } = this.state;
        const { t, platform } = this.props;

        let steps = [{ title: t('profile.steps.person') }];
        if (platform.createBusinessAtProfile) {
            steps.push({ title: t('profile.steps.business') });
        }
        steps.push({ title: t('profile.steps.contract') });

        return <Steps step={step} steps={steps} onStepChange={(step) => this.setState({ step })} />;
    }

    render() {
        const { platform, profileIsFetching, formProfile, onChange, uploadFile, fetchingAvatar } = this.props;
        const { avatarPicker, uploadingAvatar, step } = this.state;

        if (profileIsFetching || !formProfile) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView className="register">
                <AvatarPicker
                    {...this.props}
                    active={avatarPicker}
                    isLoading={fetchingAvatar || uploadingAvatar}
                    handleSubmit={(croppedImage) => {
                        this.setState({
                            avatar: croppedImage,
                            uploadingAvatar: true,
                        });
                        uploadFile(base64ToBlob(croppedImage), 'profile').then((avatar) => {
                            this.setState({
                                avatarPicker: !avatarPicker,
                                uploadingAvatar: false,
                            });
                            const newProfile = { ...formProfile };
                            newProfile.avatar = avatar.file.id;
                            onChange('formProfile', newProfile);
                        });
                    }}
                    onClick={() => this.setState({ avatarPicker: !avatarPicker })}
                    onClose={() => this.setState({ avatarPicker: !avatarPicker })}
                />
                <div className="step-form">
                    {this._renderPerson(step === 0)}
                    {platform.createBusinessAtProfile && this._renderBusiness(step === 1)}
                    {this._renderContract(step === (platform.createBusinessAtProfile ? 2 : 1))}
                </div>
            </AsideView>
        );
    }
}
