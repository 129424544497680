import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import WelcomePage from "../../components/authentication/WelcomePage";

class WelcomeContainer extends Component {
    render() {
        const { ...props } = this.props;

        return <WelcomePage {...props} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        isFetching: state.auth.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(WelcomeContainer));
