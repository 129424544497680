import React, { Component } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';
import { base64ToBlob } from '../../../utils/imageUtils';

import AvatarPicker from '../../ui/components/AvatarPicker';
import AddressInput from '../../general/crud/AddressInput';

import Address from '../../../models/general/Address';

export default class BusinessForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: '/images/business.svg',
            logoPicker: false,
        };
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formBusiness } = this.props;

        onSubmit && onSubmit(formBusiness);
    }

    render() {
        const { uploadFile, onChange, t, formBusiness, industries, submitLabel, onBackClick } = this.props;
        const { logoPicker, logo } = this.state;

        const industryOptions = industries.map((industry) => ({
            value: industry.id,
            label: t(`industry.${industry.name}`),
        }));

        return (
            <div className="step-form" key={formBusiness.nonce + formBusiness.id}>
                <AvatarPicker
                    {...this.props}
                    title={t('form.uploadLogo')}
                    active={logoPicker}
                    handleSubmit={(croppedImage) => {
                        this.setState({
                            logo: croppedImage,
                            logoPicker: !logoPicker,
                        });
                        uploadFile(base64ToBlob(croppedImage), 'business').then((formBusinessLogo) => {
                            const newBusiness = { ...formBusiness };
                            newBusiness.logo = formBusinessLogo.file.id;
                            onChange('formBusiness', newBusiness);
                        });
                    }}
                    onClick={() => this.setState({ logoPicker: !logoPicker })}
                    onClose={() => this.setState({ logoPicker: !logoPicker })}
                />
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group no-margin-top">
                            <img className="profile-image" src={logo} alt="formBusiness logo" />
                            <label
                                className="picture"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        logoPicker: !logoPicker,
                                    });
                                }}
                            >
                                {t('form.label.chooseLogo')}
                            </label>
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.name}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.name = event.target.value;

                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.name')}*</label>
                            </div>
                            {this.validator.message(t('form.label.name'), formBusiness.name, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.kvk}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.kvk = event.target.value;

                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.kvk')}*</label>
                            </div>
                            {this.validator.message(t('form.label.kvk'), formBusiness.kvk, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Select
                                    placeholder={`${t('form.label.selectIndustry')}*`}
                                    noOptionsMessage={() => t('noOptions')}
                                    options={industryOptions}
                                    value={industryOptions.filter((option) => (option.value === (formBusiness.industry instanceof Object ? formBusiness.industry.id : formBusiness.industry)))[0]}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.industry = event.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                            </div>
                            {this.validator.message(t('form.label.industry'), formBusiness.industry, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.vat}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.vat = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.vat')}</label>
                            </div>
                            {this.validator.message(t('form.label.vat'), formBusiness.vat, 'string')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.slogan}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.slogan = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.slogan')}</label>
                            </div>
                            {this.validator.message(t('form.label.slogan'), formBusiness.slogan, 'string')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formBusiness.description}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.description = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                ></textarea>
                                <label>{t('form.label.description')}</label>
                            </div>
                            {this.validator.message(t('form.label.description'), formBusiness.description, 'string')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.website}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.website = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.website')}</label>
                            </div>
                            {this.validator.message(t('form.label.website'), formBusiness.website, 'url')}
                        </div>

                        <div className="input-group more">
                            <AddressInput
                                address={
                                    formBusiness.location && formBusiness.location.length > 0
                                        ? formBusiness.location[0].address
                                        : new Address()
                                }
                                onChange={(address) => {
                                    const newBusiness = { ...formBusiness };
                                    const locations = newBusiness.location ? [...newBusiness.location] : [];

                                    if (locations.length < 1) {
                                        locations.push(new Address());
                                    }

                                    const location = { ...locations[0] };

                                    location.address = address;

                                    locations[0] = location;
                                    newBusiness.location = locations;
                                    onChange('formBusiness', newBusiness);
                                }}
                            />
                        </div>
                        <div className="input-group more right">
                            {onBackClick && (
                                <button className="back" onClick={(e) => onBackClick()}>
                                    {t('back')}
                                </button>
                            )}
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={submitLabel ? submitLabel : t('form.create')}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
