import React, { Component } from "react";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import CurrencyFormat from "react-currency-format";

import Switch from "../../ui/components/Switch";
import Loader from "../../ui/components/Loader";

import { activateInputs } from "../../../utils/formUtils";
import { intToDouble, doubleToInt } from "../../../utils/moneyUtils";

export default class ApplicationForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {
            negotiating: props.negotiate,
            originalShifts: [...props.shifts],
            shifts: [...props.shifts],
        };
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formApplication } = this.props;

        onSubmit && onSubmit(formApplication);
    }

    render() {
        const { onChange, t, formApplication, user } = this.props;
        const { negotiating, shifts, originalShifts } = this.state;

        if (shifts.length < 1) {
            return <Loader />;
        }

        return (
            <div className="step-form">
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <div>{t("form.label.negotiate")}</div>
                                <Switch
                                    checked={negotiating}
                                    onChange={(e, newState) => {
                                        this.setState({
                                            negotiating: newState,
                                            shifts: [...originalShifts],
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formApplication.message}
                                    onChange={(event) => {
                                        const newApplication = { ...formApplication };
                                        newApplication.message = event.target.value;

                                        onChange("formApplication", newApplication, event);
                                    }}
                                ></textarea>
                                <label>{t("form.label.message")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.message"),
                                formApplication.message,
                                "required"
                            )}
                        </div>
                        <div className="task-summary">
                            <div className="shifts">
                                <h2>{t("task.shifts.header")}</h2>
                                {shifts.map((shift, index) => (
                                    <div className="shift">
                                        <div className="shift-field">{shift.amount}x</div>
                                        <div className="date shift-field">
                                            <div className="">
                                                {moment(shift.startsAt).format("dddd DD MMM")}
                                            </div>
                                            <div className="">
                                                {`${moment(shift.startsAt).format("HH:mm")} - ${moment(
                                                    shift.endsAt
                                                ).format("HH:mm")}`}
                                            </div>
                                        </div>
                                        {negotiating ? (
                                            <div className="input-group no-margin-top dynamic-input-field">
                                                <div className="input-group sixty no-margin-top">
                                                    <CurrencyFormat
                                                        thousandSeparator={user.language.thousandDelimiter}
                                                        decimalSeparator={user.language.decimalDelimiter}
                                                        value={
                                                            shift.payout.money.formattedAmount ||
                                                            intToDouble(
                                                                shift.payout.money.amount,
                                                                2,
                                                                user.language.decimalDelimiter,
                                                                user.language.thousandDelimiter
                                                            )
                                                        }
                                                        prefix={shift.payout.money.sign || "€"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        onValueChange={(values) => {
                                                            const newShifts = [...shifts];

                                                            const currentShift = { ...newShifts[index] };
                                                            const payout = { ...currentShift.payout };
                                                            const money = { ...payout.money };

                                                            money.amount = doubleToInt(
                                                                parseFloat(values.value)
                                                            );
                                                            money.formattedAmount = values.formattedValue;
                                                            payout.money = money;
                                                            currentShift.payout = payout;

                                                            newShifts[index] = currentShift;

                                                            this.setState({
                                                                shifts: newShifts,
                                                            });

                                                            const newApplication = { ...formApplication };
                                                            newApplication.shifts = newShifts;

                                                            onChange("formApplication", newApplication);
                                                        }}
                                                    />
                                                    <label>{t("form.label.payoutPerHour")}</label>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="price shift-field">
                                                <div className="">{`${
                                                    shift.payout.money.sign || "€"
                                                }${intToDouble(
                                                    shift.payout.money.amount,
                                                    2,
                                                    user.language.decimalDelimiter,
                                                    user.language.thousandDelimiter
                                                )} p/u`}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <hr></hr>
                            <div className="flex-container justify-between">
                                <div>{t("task.summary.averageShift")}</div>
                                <div>{`${shifts[0].payout.money.sign || "€"}${intToDouble(
                                    shifts
                                        .map(
                                            (shift) =>
                                                (shift.payout.money.amount / 60) *
                                                moment
                                                    .duration(
                                                        moment(shift.endsAt).diff(moment(shift.startsAt))
                                                    )
                                                    .asMinutes()
                                        )
                                        .reduce((a, b) => a + b, 0) / shifts.length,
                                    2,
                                    user.language.decimalDelimiter,
                                    user.language.thousandDelimiter
                                )}`}</div>
                            </div>
                            <div className="flex-container justify-between">
                                <div>
                                    <b>{t("task.summary.total")}</b>
                                </div>
                                <div>
                                    <b>{`${shifts[0].payout.money.sign || "€"}${intToDouble(
                                        Math.round(
                                            shifts
                                                .map(
                                                    (shift) =>
                                                        (shift.payout.money.amount / 60) *
                                                        moment
                                                            .duration(
                                                                moment(shift.endsAt).diff(
                                                                    moment(shift.startsAt)
                                                                )
                                                            )
                                                            .asMinutes()
                                                )
                                                .reduce((a, b) => a + b, 0)
                                        ),
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )}`}</b>
                                </div>
                            </div>
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.send")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
