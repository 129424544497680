import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import LogoutPage from "../../components/authentication/LogoutPage";
import * as authActions from "../../state/actions/authActions.js";

class LogoutContainer extends Component {
    render() {
        const { logout, isAuthenticated, history, ...props } = this.props;

        return (
            <LogoutPage
                {...props}
                onLogout={() => {
                    if (isAuthenticated) {
                        logout().then((response) => {
                            history.push("/");
                        });
                    } else {
                        history.push("/");
                    }
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        isFetching: state.auth.isFetching,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(authActions.logout()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(LogoutContainer));
