import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import AddCreditPage from "../../../components/wallet/crud/AddCreditPage";

import * as walletActions from "../../../state/actions/walletActions";

import Charge from "../../../models/wallet/Charge";

class AddCreditContainer extends Component {
    componentWillMount() {
        const { changeValue, user } = this.props;

        const newCharge = new Charge();
        newCharge.name = "Adding credit";
        newCharge.description = "Adding credit through the platform";
        newCharge.toUser = user.id;
        newCharge.fromUser = user.id;

        changeValue("charge", newCharge);
    }

    render() {
        const { changeValue, createCharge, ...props } = this.props;
        const { charge } = this.props;

        return (
            <AddCreditPage
                {...props}
                onChange={changeValue}
                createCharge={() => createCharge(charge)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        ...state.wallet,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(walletActions.changeValue(name, value)),
        createCharge: (charge) => dispatch(walletActions.createCharge(charge)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(AddCreditContainer));
