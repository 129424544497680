import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as taskActions from "../../../state/actions/taskActions";
import * as navigationActions from "../../../state/actions/navigationActions";

import DenyApplicationPage from "../../../components/task/crud/DenyApplicationPage";

class DenyApplicationContainer extends Component {
    componentDidMount() {
        const { changeValue, targetApplication, parentTask, parentShift } = this.props;
        const newApplication = { ...targetApplication };
        newApplication.task = parentTask.id;
        newApplication.shift = parentShift.id;
        changeValue("formApplication", newApplication);
    }

    render() {
        const { popStack, changeValue, denyApplication, ...props } = this.props;

        return (
            <DenyApplicationPage
                {...props}
                onChange={changeValue}
                denyApplication={(application) => {
                    denyApplication(application);
                    popStack();
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        industries: state.platform.industries,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        denyApplication: (application) => dispatch(taskActions.denyApplication(application)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(DenyApplicationContainer));
