import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChargeReturnPage from '../../components/wallet/ChargeReturnPage';

import * as walletActions from '../../state/actions/walletActions';

class ChargeReturnContainer extends Component {

    render() {

        const {
            executeCharge,
            ...props
        } = this.props;

        

        return (
            <ChargeReturnPage
                {...props}
                executeCharge={(charge) => executeCharge(charge)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        ...state.wallet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        executeCharge: (charge) => dispatch(walletActions.executeCharge(charge))
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(ChargeReturnContainer);