import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SupportTicketRequests from "../../components/support/SupportTicketRequests";
import * as supportActions from "../../state/actions/supportActions";

class SupportTicketRequestsContainer extends Component {
    render() {
        const { fetchRequests, ...props } = this.props;

        const { supportTicket } = this.props;

        return (
            <SupportTicketRequests
                {...props}
                key={supportTicket.id}
                fetchRequests={(from = 0, amount = 20) => fetchRequests(supportTicket, from, amount)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        supportTicket: state.support.supportTicket,
        isFetchingRequests: state.support.isFetchingRequests,
        requests: state.support.requests,
        requestsTotalResults: state.support.requestsTotalResults,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRequests: (supportTicket, from, amount) =>
            dispatch(supportActions.getSupportTicketRequests(supportTicket, from, amount)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(SupportTicketRequestsContainer));
