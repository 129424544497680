import React, { Component } from "react";
import { connect } from "react-redux";

import ComponentStack from "../components/ui/components/ComponentStack";
import * as navigationActions from "../state/actions/navigationActions";

class ComponentStackContainer extends Component {
    render() {
        const { components, popStack, ...props } = this.props;

        return <ComponentStack components={components} popStack={popStack} {...props} />;
    }
}

function mapStateToProps(state) {
    return {
        ...state.navigation,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ComponentStackContainer);
