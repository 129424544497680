import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";

import TaskKeyword from "../../../models/task/TaskKeyword";
import TaskTag from "../../../models/task/TaskTag";

export default class TaskKeywordsForm extends Component {
    
    _addKeywordCategory() {
        const { onChange, formTask } = this.props;

        const task = { ...formTask };
        const keywords = [...task.keywords];
        keywords.push(new TaskKeyword());
        task.keywords = keywords;

        onChange("formTask", task);
    }

    render() {
        const { t, onChange, formTask } = this.props;
        const { keywords } = formTask;

        return (
            <div className="input-container">
                {keywords.sort((a, b) => a.header - b.header).map((keyword, index) => {
                    return (
                        <div className="input-group" key={keyword.id}>
                            <h1>{keyword.header}</h1>
                            <div className="input-group no-margin-top">
                                <CreatableSelect
                                    isClearable
                                    isMulti={true}
                                    placeholder={t("form.label.keywords")}
                                    noOptionsMessage={(inputValue) => t("form.label.startTyping")}
                                    formatCreateLabel={(inputValue) =>
                                        t("form.addCreatable", { value: inputValue })
                                    }
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                    }}
                                    onChange={(e) => {
                                        const newTask = { ...formTask };
                                        const keywords = [...newTask.keywords];
                                        const currentKeyword = { ...keywords[index] };
                                        if (e && e.length) {
                                            currentKeyword.tags = e.map((e) => {
                                                const newTaskKeyword = new TaskTag();
                                                newTaskKeyword.id = e.value;
                                                newTaskKeyword.name = e.label;
                                                return newTaskKeyword;
                                            });
                                        } else {
                                            currentKeyword.tags = [];
                                        }
                                        keywords[index] = currentKeyword;
                                        newTask.keywords = keywords;
                                        onChange("formTask", newTask);
                                    }}
                                    onCreateOption={(e) => {
                                        const newTask = { ...formTask };
                                        const keywords = [...newTask.keywords];

                                        const currentKeyword = { ...keywords[index] };

                                        const newTaskTag = new TaskTag();
                                        newTaskTag.name = e;

                                        const tags = [...currentKeyword.tags];
                                        tags.push(newTaskTag);

                                        currentKeyword.tags = tags;

                                        keywords[index] = currentKeyword;

                                        newTask.keywords = keywords;

                                        onChange("formTask", newTask);
                                    }}
                                    value={
                                        keyword.tags.length
                                            ? keyword.tags.map((keyword) => ({
                                                  value: keyword.id,
                                                  label: keyword.name,
                                              }))
                                            : []
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
