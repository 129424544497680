import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import Loader from '../ui/components/Loader';
import L from 'leaflet';

import ProfileContainer from '../../containers/profile/ProfileContainer';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: '/icons/marker.svg',
    iconUrl: '/icons/marker.svg',
    shadowUrl: null,
});

class CheckView extends Component {
    render() {
        const { t, platform, check, addToStack } = this.props;
        const { shift, latitude, longitude } = check;

        if (!check) {
            return <Loader />;
        }
        const profile = check.application.applicant;

        return (
            <div className="check-view">
                <h1 className="center">{t(`task.check.type.${check.type}`)}</h1>
                <h2 className="center">
                    {moment(check.date).format('DD/MM/YYYY')} <span>{moment(check.date).format('HH:mm')}</span>
                </h2>
                <div className="profile flex-container justify-center" onClick={(e) => {
                    e.preventDefault();
                    addToStack({
                        name: profile.name,
                        component: <ProfileContainer profileId={profile.id} />
                    })
                }}>
                    <img className="rounded" src={profile.avatar ? profile.avatar.url : '/images/user.svg'} alt="" />
                    <div className="name">
                        {profile.person.firstName} {profile.person.lastName}
                    </div>
                </div>
                <MapContainer
                    className="maps"
                    center={{
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                    }}
                    zoom={16}
                    preferCanvas={false}
                    attributionControl={false}
                    zoomControl={false}
                >
                    <TileLayer url="https://api.mapbox.com/styles/v1/thomas-n0name/ckh89831u1cq019osxtgbot2m/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidGhvbWFzLW4wbmFtZSIsImEiOiJja2d0ZDVrM2owYXBoMnFwMGJ5Mm93amM1In0.NgqXPSaGRLuK-OhI_2_6eQ" />

                    <Marker
                        position={[parseFloat(latitude), parseFloat(longitude)]}
                        name={shift.task.title}
                        color={platform.primaryColor}
                    />
                </MapContainer>
            </div>
        );
    }
}
export default withTranslation('translation')(CheckView);
