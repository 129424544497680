import React from 'react';

class DataUsage extends React.Component{
    render(){
        return(
            <div className="container">
                <div className="document">
                    <h2 className="top">Data Usage</h2>
                    <h1>How we use cookies</h1>
                    Cookies are very small text files that are stored on your computer when you visit some websites. <br /><br />

                    We use cookies to help identify your computer so we can tailor your user experience, track shopping basket contents and remember where you are in the order process.<br /><br />

                    You can disable any cookies already stored on your computer, but these may stop our website from functioning properly.

                    The following is strictly necessary in the operation of our website.<br /><br />

                    This Website Will: <br /><br />

                    Remember what is in your shopping basket<br />
                    Remember where you are in the order process<br />
                    Remember that you are logged in and that your session is secure.  You need to be logged in to complete an order.<br />
                    The following are not Strictly Necessary, but are required to provide you with the best user experience and also to <br />tell us which pages you find most interesting (anonymously).<br /><br />

                    Functional Cookies<br /><br />

                    This Website Will:<br /><br />

                    Offer Live Chat Support (If available)<br />
                    Track the pages you visits via Google Analytics<br />
                    Targeting Cookies <br />

                    This Website Will:<br /><br />

                    Allow you to share pages with social networks such as Facebook (If available)<br />
                    Allow you to share pages via Add This (If available)<br />
                    To view the ‘Add This’ Privacy Policy or to opt out of any online behavioural advertising, please visit Add This and click on the ‘Opt Out’ button.<br />

                    This website will not<br /><br />

                    Share any personal information with third parties.<br />
                </div>
            </div>
        ); 
    }
}
export default DataUsage;