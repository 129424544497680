import React, { Component } from "react";
import ReactSVG from "react-svg";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class NotFound extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="page error-page" style={{ position: "relative" }}>

                <div className="absolute-center center">
                    <ReactSVG src="/icons/404.svg" />
                    <h1>404 ERROR</h1>
                    <p>Can't find this location, please try again</p>
                    <Link to="/">Back to main page</Link>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(NotFound);
