import React, { Component } from 'react';
import moment from 'moment';

import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';

export const defaultFilters = {
    searchQuery: '',
    fromDate: moment(),
    selectedCategories: [],
};

let searchTimeout;
export default class TaskFilterView extends Component {
    state = this.props.filters ? this.props.filters : defaultFilters;

    componentDidMount() {
        const { getCategories } = this.props;

        getCategories().then((data) => {
            this.setState({
                selectedCategories: data.categories,
            });
        });
    }

    onChange = (newState) => {
        const { onChange } = this.props;
        onChange && onChange(newState);
    };

    render() {
        const { searchQuery, fromDate, selectedCategories } = this.state;
        const { t, categories } = this.props;

        return (
            <div className="filters">
                <h1>{t('filters.header')}</h1>
                <div>
                    <h4>{t('filters.search')}</h4>
                    <input
                        type="text"
                        className="self-center"
                        placeholder={t('search.placeholder')}
                        value={searchQuery}
                        onChange={(e) => {
                            this.setState(
                                {
                                    searchQuery: e.target.value,
                                },
                                () => {
                                    if (searchTimeout) clearTimeout(searchTimeout);
                                    searchTimeout = setTimeout(() => {
                                        this.onChange(this.state);
                                    }, 800);
                                }
                            );
                        }}
                    />
                </div>
                <div>
                    <h4>{t('filters.fromDate')}</h4>
                    <DayPicker
                        format="DD/MM/YYYY"
                        selectedDays={[fromDate.toDate()]}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        disabledDays={{ before: new Date() }}
                        onDayClick={(day) => {
                            this.setState(
                                {
                                    fromDate: moment(day),
                                },
                                () => {
                                    this.onChange(this.state);
                                }
                            );
                        }}
                    />
                </div>
                <div>
                    <h4>{t('filters.categories')}</h4>
                    {categories.map((category) => (
                        <div
                            key={`filter-category-${category.id}-${
                                selectedCategories.map((c) => c.id).includes(category.id) ? '-checked' : ''
                            }}`}
                            style={{ marginTop: '3px' }}
                        >
                            <input
                                className="self-center"
                                type="checkbox"
                                value="selected"
                                id={`option-action-item-${category.id}`}
                                checked={selectedCategories.map((c) => c.id).includes(category.id)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    const newSelectedCategories = selectedCategories
                                        .map((c) => c.id)
                                        .includes(category.id)
                                        ? selectedCategories.filter((s) => s.id !== category.id)
                                        : categories.filter(
                                              (s) =>
                                                  category.id === s.id ||
                                                  selectedCategories.map((c) => c.id).includes(s.id)
                                          );
                                    this.setState(
                                        {
                                            selectedCategories: newSelectedCategories,
                                        },
                                        () => {
                                            this.onChange(this.state);
                                        }
                                    );
                                }}
                            />
                            <label className="name normal" htmlFor={`option-action-item-${category.id}`}>
                                {category.name}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
