import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ForgotResetPage from "../../components/authentication/ForgotResetPage";
import * as authActions from "../../state/actions/authActions.js";

class ForgotResetcontainer extends Component {
    render() {
        const { changeValue, reset, ...props } = this.props;

        const { history, password, repeatPassword } = props;
        const { user, token } = this.props.match.params;

        return (
            <ForgotResetPage
                {...props}
                onChange={changeValue}
                onReset={() => {
                    reset(user, token, password, repeatPassword).then((response) => {
                        history.push("/login");
                    });
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
        error: state.auth.error,
        password: state.auth.password,
        repeatPassword: state.auth.repeatPassword,
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: (user, token, password, repeatPassword) =>
            dispatch(authActions.reset(user, token, password, repeatPassword)),
        changeValue: (name, value) => dispatch(authActions.changeValue(name, value)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(ForgotResetcontainer));
