import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SupportTicketsPage from "../../components/support/SupportTicketsPage";
import * as supportActions from "../../state/actions/supportActions";

class SupportTicketsContainer extends Component {
    render() {
        const { getTickets, getStatusTickets, searchTickets, searchStatusTickets, ...props } = this.props;

        

        return (
            <SupportTicketsPage
                {...props}
                getTickets={(from = 0, amount = 20) => getTickets(from, amount)}
                getStatusTickets={(status, from, amount) => getStatusTickets(status, from, amount)}
                searchTickets={(from = 0, amount = 20) => searchTickets(from, amount)}
                searchStatusTickets={(status, from, amount) =>
                    searchStatusTickets(status, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.support,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTickets: (from, amount) => dispatch(supportActions.getTickets(from, amount)),
        getStatusTickets: (status, from, amount) =>
            dispatch(supportActions.getStatusTickets(status, from, amount)),

        searchTickets: (from, searchParameters, amount) =>
            dispatch(supportActions.searchTickets(searchParameters, from, amount)),
        searchStatusTickets: (status, searchParameters, from, amount) =>
            dispatch(supportActions.searchStatusTickets(status, searchParameters, from, amount)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(SupportTicketsContainer));
