export const chatConstants = {
    CHAT_CHANGE_VALUE: "CHAT_CHANGE_VALUE",

    GET_CHAT_STARTED: "GET_CHAT_STARTED",
    GET_CHAT_SUCCESS: "GET_CHAT_SUCCESS",
    GET_CHAT_FAILURE: "GET_CHAT_FAILURE",

    CREATE_CHAT_STARTED: "CREATE_CHAT_STARTED",
    CREATE_CHAT_SUCCESS: "CREATE_CHAT_SUCCESS",
    CREATE_CHAT_FAILURE: "CREATE_CHAT_FAILURE",

    GET_CHATS_STARTED: "GET_CHATS_STARTED",
    GET_CHATS_SUCCESS: "GET_CHATS_SUCCESS",
    GET_CHATS_FAILURE: "GET_CHATS_FAILURE",

    GET_MESSAGES_STARTED: "GET_MESSAGES_STARTED",
    GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
    GET_MESSAGES_FAILURE: "GET_MESSAGES_FAILURE",

    READ_MESSAGES_STARTED: "READ_MESSAGES_STARTED",
    READ_MESSAGES_SUCCESS: "READ_MESSAGES_SUCCESS",
    READ_MESSAGES_FAILURE: "READ_MESSAGES_FAILURE",

    CREATE_MESSAGE_STARTED: "CREATE_MESSAGE_STARTED",
    CREATE_MESSAGE_SUCCESS: "CREATE_MESSAGE_SUCCESS",
    CREATE_MESSAGE_FAILURE: "CREATE_MESSAGE_FAILURE",
};
