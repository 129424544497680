export const navigationConstants = {
    ADD_TO_STACK: "ADD_TO_STACK",
    REMOVE_FROM_STACK: "REMOVE_FROM_STACK",
    POP_STACK: "POP_STACK",
    CLEAR_STACK: "CLEAR_STACK",
    ANIMATE_LAST_OUT: "ANIMATE_LAST_OUT",

    CREATE_ALERT: "CREATE_ALERT",
    REMOVE_ALERT: "REMOVE_ALERT",
    POP_ALERT: "POP_ALERT",
    CLEAR_ALL_ALERTS: "CLEAR_ALL_ALERTS",
    ANIMATE_ALERT_OUT: "ANIMATE_ALERT_OUT",
};
