import React, { Component } from "react";

export default class Loader extends Component {
    render() {
        const { text, style, small, sideMargin, noMargin, className } = this.props;
        if (text) {
            return (
                <div style={style} classname={className ? className : ""}>
                    <div
                        className={`spinner${small ? " small" : ""}${sideMargin ? " side-margin" : ""} ${
                            noMargin ? " no-margin" : ""
                        }`}
                    ></div>
                    <h2 className="center">{text}</h2>
                </div>
            );
        }
        return (
            <div
                style={style}
                className={`spinner${small ? " small" : ""}${sideMargin ? " side-margin" : ""} ${
                    noMargin ? " no-margin" : ""
                } ${className ? className : ""}`}
            ></div>
        );
    }
}
