import React, { Component } from "react";
import { connect } from "react-redux";

import AlertStack from "../components/ui/components/AlertStack";

import * as navigationActions from "../state/actions/navigationActions";

class AlertStackContainer extends Component {
    render() {
        const { stack, popStack, ...props } = this.props;
        
        return <AlertStack stack={stack} popStack={popStack} {...props} />;
    }
}

function mapStateToProps(state) {
    return {
        ...state.navigation,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        popAlert: () => dispatch(navigationActions.popAlert()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AlertStackContainer);
