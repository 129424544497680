import React, { Component } from "react";

import Loader from "../../../ui/components/Loader";
import AsideView from "../../../ui/components/AsideView";

import PayoutForm from "./PayoutForm";

export default class CreatePayoutPage extends Component {
    render() {
        const { t, formPayout, isFetchingPayout, createPayout } = this.props;

        if (!formPayout || isFetchingPayout) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("wallet.payout.create")}</h1>
                <PayoutForm
                    {...this.props}
                    onSubmit={(payout) => createPayout(payout) }
                />
            </AsideView>
        );
    }
}
