import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import CategoryForm from "./CategoryForm";

export default class UpdateCategoryPage extends Component {
    componentDidMount() {
        const { onChange, overrideCategory } = this.props;
        onChange("formCategory", overrideCategory);
    }

    render() {
        const { t, formCategory, isFetchingCategory, updateCategory } = this.props;

        if (!formCategory || isFetchingCategory) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("category.update")}</h1>
                <CategoryForm
                    {...this.props}
                    key={formCategory.id}
                    onSubmit={(category) => updateCategory(category)}
                />
            </AsideView>
        );
    }
}
