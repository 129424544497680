export const intToDouble = (integer, n = 2, c, s) => {
    const amount = parseFloat((integer / 100).toFixed(2));
    var re = "\\d(?=(\\d{" + 3 + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = amount.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","));
};

export const doubleToInt = (double) => {
    return parseInt(double.toFixed(2) * 100);
};
