import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from 'react-google-recaptcha';

import AuthenticationContainer from './AuthenticationContainer';

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.onRegister();
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { onChange, t, platform } = this.props;

        return (
            <AuthenticationContainer {...this.props}>
                <form method="post" id="register" onSubmit={(e) => this.onSubmit(e)}>
                    <h2 className="top">{t('register.header')}</h2>
                    {platform.canRegister ? (
                        <>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={this.props.username}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange('username', value, event);
                                        }}
                                    />
                                    <label>{t('form.label.username')}</label>
                                </div>
                                {this.validator.message('username', this.props.username, 'required')}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={this.props.email}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange('email', value, event);
                                        }}
                                    />
                                    <label>{t('form.label.email')}</label>
                                </div>
                                {this.validator.message('email', this.props.email, 'required|email')}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="password"
                                        autoComplete="off"
                                        value={this.props.password}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange('password', value, event);
                                        }}
                                    />
                                    <label>{t('form.label.password')}</label>
                                </div>
                                {this.validator.message('password', this.props.password, 'required|min:6')}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="password"
                                        autoComplete="off"
                                        value={this.props.repeatPassword}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange('repeatPassword', value, event);
                                        }}
                                    />
                                    <label>{t('form.label.passwordRepeat')}</label>
                                </div>
                                {this.validator.message('repeatPassword', this.props.repeatPassword, 'required')}
                            </div>
                            <div className="input-group more">
                                <input
                                    type="checkbox"
                                    id="register_terms"
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange('terms', value === 'on', event);
                                    }}
                                />
                                <label className="normal" htmlFor="register_terms">
                                    Ik ga akkoord met de <Link to="/legal/tos">algemene voorwaarden</Link>.
                                </label>
                                {this.validator.message('Terms and conditions', this.props.terms, 'required')}
                            </div>
                            <div className="input-group">
                                <input
                                    type="checkbox"
                                    id="newsletter"
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange('newsletter', value === 'on', event);
                                    }}
                                />
                                <label className="normal" htmlFor="newsletter">
                                    {t('register.newsletter', { platformName: platform.name })}
                                </label>
                            </div>
                            <div className="input-group">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA || ''}
                                    onChange={(event) => {
                                        onChange('recaptcha', event, event);
                                    }}
                                />
                            </div>
                            <div className="input-group right">
                                <div className="msg bottom">{this.props.error}</div>
                                <input
                                    type="submit"
                                    name="register"
                                    value={t('form.register')}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </>
                    ) : (
                        <p>{t('register.notOpen', {name: platform.name})}</p>
                    )}
                </form>
                <div className="or">{t('form.or')}</div>
                <Link to="/login">
                    <button className="full">{t('register.alreadyHasAccount')}</button>
                </Link>
            </AuthenticationContainer>
        );
    }
}
