import React from 'react';

class Cookies extends React.Component{
    render(){
        return(
            <div className="container">
                <div className="document">
                    <h2 className="top">Cookies</h2>
                    <h1>How we use cookies</h1>
                    <p>
                    Cookies are very small text files that are stored on your computer when you visit some websites.
                    </p>
                    <p>
                    We use cookies to help identify your computer so we can tailor your user experience, save forms and/or other personal saved processes.
                    You can disable any cookies already stored on your computer, but these may stop our website from functioning properly.
                    The following is strictly necessary in the operation of our website.
                    </p>
                    <h2>1. This Website Will</h2>
                    <ol type="a">
                        <li>Remember that you are logged in and that your session is secure.  </li>
                        <li>The following are not Strictly Necessary, but are required to provide you with the best user experience and also to tell us which pages you find most interesting (anonymously).</li>
                    </ol>

                    <h1>Functional Cookies</h1>
                    <h2>1. This Website Will</h2>
                    <ol type="a">
                        <li>Track the pages you visits via Google Analytics</li>
                        <li>Targeting Cookies</li>
                        <li>Allow you to share pages with social networks such as Facebook, Twitter and others</li>
                    </ol>
                    <h2>2. This website will not</h2>
                    <ol type="a">
                        <li>Share any personal information with third parties.</li>
                    </ol>
                </div>
            </div>
        ); 
    }
}
export default Cookies;