import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import RolesView from "../../components/role/RolesView";

import * as authActions from "../../state/actions/authActions";
import * as navigationActions from "../../state/actions/navigationActions";

class RolesContainer extends Component {
    render() {
        const { deleteRole, getRoles, searchRoles, ...props } = this.props;

        return (
            <RolesView
                {...props}
                deleteRole={(role) => deleteRole(role)}
                getRoles={(from = 0, to = 20) => getRoles(from, to)}
                searchRoles={(searchParameters, from, to) =>
                    searchRoles(searchParameters, from, to)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        ...state.auth,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteRole: (role) => dispatch(authActions.deleteRole(role)),
        getRoles: (from, amount) => dispatch(authActions.getRoles(from, amount)),
        searchRoles: (searchParameters, from, amount) =>
            dispatch(authActions.searchRoles(searchParameters, from, amount)),


        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(RolesContainer));
