import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import PayoutsView from "../../../components/wallet/payout/PayoutsView";

import * as walletActions from "../../../state/actions/walletActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class PayoutsContainer extends Component {
    render() {
        const { getPayouts, ...props } = this.props;

        return <PayoutsView {...props} getPayouts={(from = 0, to = 20) => getPayouts(from, to)} />;
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.wallet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPayouts: (from, to) => dispatch(walletActions.getPayouts(from, to)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(PayoutsContainer));
