import React, { Component } from "react";
import ReactSVG from "react-svg";

import Loader from "../components/Loader";
import NoData from "../components/NoData";

export default class TableView extends Component {
    constructor(props) {
        super(props);

        this.searchTimeout = null;
        this.searchRef = React.createRef();

        this.state = {
            searchQuery: "",
            searchIsFocused: false,
            searchCaretPosition: {
                start: 0,
                end: 0,
            },
            selectedOptions:
                localStorage && localStorage.getItem(`${props.title.toLowerCase()}-selected-fields`)
                    ? JSON.parse(localStorage.getItem(`${props.title.toLowerCase()}-selected-fields`))
                    : props.selectedOptions,
            selectOptionsMenu: false,
            selected: [],
        };
    }

    static defaultProps = {
        value: [],
        options: [],
        actions: [],
        filters: [],
        noDataLabel: "No data",
        createNewLabel: "+ new",
        canSelect: false,
        rowOnClick: () => null,
    };

    handleScroll = () => {
        const { scrolledToBottom } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector(".page").offsetHeight) {
            scrolledToBottom && scrolledToBottom();
        }
    };

    onSearchChangeHandler(e) {
        this.setState({
            searchCaretPosition: {
                start: e.currentTarget.selectionStart,
                end: e.currentTarget.selectionEnd,
            },
        });
    }

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === "string" || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        const { searchIsFocused, searchCaretPosition } = this.state;
        if (searchIsFocused) {
            this.searchRef.current.focus();
            this.searchRef.current.setSelectionRange(searchCaretPosition.start, searchCaretPosition.end);
        }

        document.addEventListener("click", (e) => {
            const target = e.target;
            const { selectOptionsMenu } = this.state;
            if (selectOptionsMenu) {
                if (!this.hasParentNode(target, "action")) {
                    this.setState({
                        selectOptionsMenu: false,
                    });
                }
            }
        });
    }

    componentDidUpdate() {
        const { searchIsFocused, searchCaretPosition } = this.state;
        if (searchIsFocused) {
            this.searchRef.current.focus();
            this.searchRef.current.setSelectionRange(searchCaretPosition.start, searchCaretPosition.end);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        const {
            t,
            title,
            noDataLabel,
            onCreate,
            createNewLabel,
            values,
            isLoading,
            options,
            actions,
            searchEnabled,
            searchPlaceholder,
            onSearchChange,
            rowOnClick,
            canSelect,
            optionsSelectable,
            filters,
        } = this.props;

        const { searchQuery, selectedOptions, selected, selectOptionsMenu } = this.state;

        const byString = (o, s) => {
            try {
                s = s.replace(/\[(\w+)\]/g, ".$1");
                s = s.replace(/^\./, "");
                var a = s.split(".");
                for (var i = 0, n = a.length; i < n; ++i) {
                    var k = a[i];
                    if (k in o) {
                        o = o[k];
                    } else {
                        return;
                    }
                }
                return o;
            } catch (e) {
                return undefined;
            }
        };

        const isFunction = (value) => {
            if (value instanceof Function) {
                return true;
            }
            return false;
        };

        return (
            <div className="table" key={`${values.length}-${isLoading}-${title}`}>
                {title ? <h1>{title}</h1> : null}
                <div className="top-functions flex-container">
                    <div className="flex-container one">
                        <div className="search-block self-center">
                            {searchEnabled ? (
                                <input
                                    ref={this.searchRef}
                                    type="text"
                                    className="search"
                                    value={searchQuery}
                                    placeholder={searchPlaceholder}
                                    onBlur={(e) => {
                                        this.setState({
                                            searchIsFocused: false,
                                        });
                                        this.onSearchChangeHandler(e);
                                    }}
                                    onChange={(e) => {
                                        const searchQuery = e.target.value;
                                        this.onSearchChangeHandler(e);
                                        this.setState({
                                            searchQuery: searchQuery,
                                            searchIsFocused: true,
                                        });
                                        if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                        this.searchTimeout = setTimeout(() => {
                                            if (onSearchChange) onSearchChange(searchQuery);
                                        }, 800);
                                    }}
                                />
                            ) : null}
                        </div>
                        {optionsSelectable || filters.length > 0 ? (
                            <div className="actions action-icons self-center">
                                {optionsSelectable && (
                                    <div className="action">
                                        <div
                                            className="action-icon"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    selectOptionsMenu: !selectOptionsMenu,
                                                });
                                            }}
                                        >
                                            <ReactSVG src="/icons/settings.svg" />
                                        </div>
                                        <div className={`action-menu${selectOptionsMenu ? " show" : ""}`}>
                                            <div className="scrollable menu-container">
                                                {options.map((option) => (
                                                    <div
                                                        className="action-item flex-container"
                                                        key={`action-item-${option.name}${
                                                            selectedOptions.includes(option.name)
                                                                ? "-selected"
                                                                : ""
                                                        }`}
                                                    >
                                                        <input
                                                            className="self-center"
                                                            type="checkbox"
                                                            value="selected"
                                                            id={`option-action-item-${option.name}`}
                                                            checked={selectedOptions.includes(option.name)}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const newSelectedOptions = selectedOptions.includes(
                                                                    option.name
                                                                )
                                                                    ? selectedOptions.filter(
                                                                          (o) => o !== option.name
                                                                      )
                                                                    : options
                                                                          .filter(
                                                                              (o) =>
                                                                                  option.name === o.name ||
                                                                                  selectedOptions.includes(
                                                                                      o.name
                                                                                  )
                                                                          )
                                                                          .map((o) => o.name);

                                                                this.setState({
                                                                    selectedOptions: newSelectedOptions,
                                                                });

                                                                // Save to store
                                                                if (localStorage) {
                                                                    localStorage.setItem(
                                                                        `${title.toLowerCase()}-selected-fields`,
                                                                        JSON.stringify(newSelectedOptions)
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="name normal"
                                                            htmlFor={`option-action-item-${option.name}`}
                                                        >
                                                            {option.header || option.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {filters.length > 0 && (
                                    <div className="action">
                                        <div className="action-icon">
                                            <ReactSVG src="/icons/filter.svg" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className="self-center">
                        {onCreate && <button onClick={onCreate}>{createNewLabel}</button>}
                    </div>
                </div>
                <div className="table-view">
                    <div className="table-header">
                        {canSelect ? (
                            <div
                                className="input-group no-margin-top select-box"
                                key={`selected-items-${selected.length}`}
                            >
                                <input
                                    type="checkbox"
                                    value="selected"
                                    checked={values.length > 0 && selected.length === values.length}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                            selected:
                                                selected.length === values.length
                                                    ? []
                                                    : values.map((v) => v.id),
                                        });
                                    }}
                                />
                            </div>
                        ) : null}
                        {selectedOptions.map((selectedOption) => {
                            const option = options.find((option) => selectedOption === option.name);
                            return (
                                <>
                                    <div
                                        className={`table-header-column ${option.size}`}
                                        key={`selected-option${selectedOption}`}
                                    >
                                        {option.header}
                                    </div>
                                </>
                            );
                        })}
                        {actions
                            .filter((action) => action.enabled)
                            .map((action) => (
                                <div
                                    className="table-column action"
                                    key={`action-header-${isFunction(action.name) ? action.name(action) : action.name}`}
                                ></div>
                            ))}
                    </div>
                    {isLoading && values && values.length < 1 ? (
                        <Loader />
                    ) : values && values.length < 1 ? (
                        <div className="not-found-action-box">
                            <div className="icon">
                                <ReactSVG src="/icons/space.svg" />
                            </div>
                            <NoData>{noDataLabel}</NoData>
                        </div>
                    ) : (
                        <>
                            <div className="table-items">
                                {values.map((value) => (
                                    <div
                                        className="table-row"
                                        onClick={(e) => rowOnClick(e, value)}
                                        key={`table-row${value.id}${
                                            selected.includes(value.id) ? "-selected" : ""
                                        }`}
                                    >
                                        {canSelect ? (
                                            <div className="input-group no-margin-top select-box">
                                                <input
                                                    type="checkbox"
                                                    value="selected"
                                                    checked={selected.includes(value.id)}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.setState({
                                                            selected: selected.includes(value.id)
                                                                ? selected.filter((s) => s !== value.id)
                                                                : [...selected, value.id],
                                                        });
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        {selectedOptions.map((selectedOption) => {
                                            const option = options.find(
                                                (option) => selectedOption === option.name
                                            );

                                            // Execute function if selector is a function
                                            if (isFunction(option.selector)) {
                                                return (
                                                    <div
                                                        className={`table-column ${option.size}`}
                                                        onClick={option.onClick}
                                                        key={`${value.id}-${option.name}`}
                                                    >
                                                        {option.selector(value)}
                                                    </div>
                                                );
                                            }

                                            switch (option.type) {
                                                case "string":
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            {byString(value, option.selector)}
                                                        </div>
                                                    );
                                                case "boolean":
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            {byString(value, option.selector) === true
                                                                ? t("yes")
                                                                : t("no")}
                                                        </div>
                                                    );
                                                case "array":
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            {byString(value, option.selector)
                                                                .map((selectedValue) =>
                                                                    byString(
                                                                        selectedValue,
                                                                        option.valueSelector
                                                                    )
                                                                )
                                                                .join(", ")}
                                                        </div>
                                                    );
                                                case "image":
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            <img
                                                                src={
                                                                    byString(value, option.selector)
                                                                        ? byString(value, option.selector)
                                                                        : option.defaultValue
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    );
                                                default:
                                                    return (
                                                        <div
                                                            key={`${value.id}-${option.name}`}
                                                            className={`table-column ${option.size}`}
                                                        ></div>
                                                    );
                                            }
                                        })}
                                        {actions
                                            .filter((action) => action.enabled)
                                            .map((action) => (
                                                <div
                                                    className="table-column action"
                                                    key={`${action.name}-action-${value.id}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        action.action && action.action(e, value);
                                                    }}
                                                >
                                                    {isFunction(action.name) ? action.name(value) : action.name}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                            {isLoading && <Loader />}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
