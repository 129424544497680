import React, { Component } from 'react';

export default class UploadButton extends Component {
    openFileSelect(e) {
        e.preventDefault();
        const { accept, onChange } = this.props;

        const id = `upload-button-${Math.random().toString(36).substring(7)}`;

        var input = document.createElement('input');
        input.hidden = true;
        input.type = 'file';
        input.accept = accept || 'image/*';
        input.id = id;
        input.click();

        input.addEventListener('change', (e) => {
            onChange(e);
            const element = document.getElementById(id);
            if(element) element.remove();
        });
    }

    render() {
        const { loading, uploaded, value, children, onReset, className } = this.props;
        return (
            <button type="button" onClick={(e) => this.openFileSelect(e)} className={className}>
                {loading && <i className="fa fa-circle-notch fa-spin"></i>}
                {uploaded && <i className="fa fa-check"></i>}
                {value || children}
                {uploaded && (
                    <i
                        className="fa fa-times behind"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onReset) onReset();
                        }}
                    ></i>
                )}
            </button>
        );
    }
}
