import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import moment from "moment";

import { intToDouble } from "../../utils/moneyUtils";

import NoData from "../ui/components/NoData";

import CreateReviewContainer from "../../containers/rating/review/crud/CreateReviewContainer";

export default class Applications extends Component {
    
    render() {
        const {
            t,
            addToStack,
            updateApplication,
            onCancel,
            onAccept,
            onDeny,
            applications,
            task,
            user,
            fullWidth,
            popStack,
        } = this.props;

        const actionButtons = (application) => {
            if (task.isOwner) {
                switch (application.taskApplicationStatus) {
                    case "submitted":
                        return (
                            <div className="actions action-icons">
                                <div
                                    className="action action-icon green"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onAccept && onAccept(application);
                                    }}
                                >
                                    <ReactSVG src="/icons/checkmark.svg" />
                                </div>
                                <div
                                    className="action action-icon"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDeny && onDeny(application);
                                    }}
                                >
                                    <ReactSVG src="/icons/cross.svg" />
                                </div>
                            </div>
                        );
                    case "cancelled":
                        return (
                            <div className="actions">
                                <div className="status red">{t("task.application.cancelled")}</div>
                            </div>
                        );
                    case "denied":
                        return (
                            <div className="actions">
                                <div className="status red">{t("task.application.denied")}</div>
                            </div>
                        );
                    case "accepted":
                        return (
                            <div className="actions">
                                <div className="status green">{t("task.application.accepted")}</div>
                            </div>
                        );
                    case "not_chosen":
                        return (
                            <div className="actions">
                                <div className="status gray">{t("task.application.notChosen")}</div>
                            </div>
                        );
                    case "checked_in":
                        return (
                            <div className="actions">
                                <div className="status green">{t("task.application.checkedIn")}</div>
                            </div>
                        );
                    case "checked_out":
                        return (
                            <div className="actions">
                                <div className="status green">{t("task.application.checkedOut")}</div>
                            </div>
                        );
                    case "replaced":
                        return (
                            <div className="actions">
                                <div className="status gray">{t("task.application.replaced")}</div>
                            </div>
                        );
                    case "finished":
                        return (
                            <div className="actions">
                                <div className="status green">{t("task.application.finished")}</div>
                                {application.employeeReview ? null : (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            addToStack({
                                                name: "Create review",
                                                component: (
                                                    <CreateReviewContainer
                                                        rating={
                                                            application.business
                                                                ? application.business.rating
                                                                : application.applicant.rating
                                                        }
                                                        question={t("task.shift.employerReviewQuestion", {
                                                            name: application.business
                                                                ? application.business.name
                                                                : `${application.applicant.person.firstName} ${application.applicant.person.lastName}`,
                                                        })}
                                                        callback={(review) => {
                                                            const newApplication = { ...application };
                                                            newApplication.employeeReview = review;
                                                            updateApplication(newApplication);
                                                        }}
                                                    />
                                                ),
                                            });
                                        }}
                                    >
                                        {t("task.shift.writeReview")}
                                    </button>
                                )}
                            </div>
                        );
                    default:
                        return null;
                }
            } else if (user.id === application.creator.id) {
                return (
                    <div className="actions">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                onCancel && onCancel(application);
                            }}
                        >
                            {t("task.application.cancel")}
                        </button>
                    </div>
                );
            } else return <div className="actions"></div>;
        };

        if (applications.length > 0) {
            return (
                <div className={`applications${fullWidth ? " full" : ""}`}>
                    {applications.map((application) => (
                        <div className="application">
                            <div className="flex-container">
                                <img
                                    className="self-center"
                                    src={
                                        application.applicant.avatar
                                            ? application.applicant.avatar.url
                                            : "/images/user.svg"
                                    }
                                    alt=""
                                ></img>
                                <div className="info">
                                    <div className="name">
                                        <Link
                                            to={`/profile/${application.applicant.user.id}`}
                                            onClick={() => popStack()}
                                        >
                                            {application.applicant.name}
                                        </Link>
                                        <span>
                                            {moment(application.createdAt).format("DD/MM/YYYY HH:mm")}
                                        </span>
                                    </div>
                                    <div className="offer">
                                        {`${application.payout.money.currency.sign}${intToDouble(
                                            application.payout.money.amount,
                                            2,
                                            user.language.decimalDelimiter,
                                            user.language.thousandDelimiter
                                        )} ${t("p/h")}`}
                                    </div>
                                    <div className="message">{application.message}</div>
                                </div>
                            </div>
                            {actionButtons(application)}
                        </div>
                    ))}
                </div>
            );
        }
        return (
            <div className="not-found-action-box">
                <div className="icon">
                    <ReactSVG src="/icons/space.svg" />
                </div>
                <NoData>{t("task.shift.applications.notFound")}</NoData>
            </div>
        );
    }
}
