import { userConstants } from '../constants/user.constants';

const initialState = {
    isFetchingUser: false,
    formUser: null,
    user: null,

    isFetchingUsers: false,
    usersTotalResults: 0,
    usersLoaded: 0,
    users: [],
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.USER_CHANGE_VALUE:
            return { ...state, error: '', [action.name]: action.value };

        case userConstants.GET_USERS_STARTED:
            return { ...state, isFetchingUsers: true };
        case userConstants.GET_USERS_FAILURE:
            return { ...state, isFetchingUsers: false, error: action.message || '' };
        case userConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                isFetchingUsers: false,
                users: action.from > 0 ? [...state.users, ...action.users] : action.users,
                usersLoaded: action.from > 0 ? state.users.length + action.users.length : action.users.length,
                usersTotalResults: action.totalResults || 0,
            };

        case userConstants.SEARCH_USERS_STARTED:
            return { ...state, isFetchingUsers: true };
        case userConstants.SEARCH_USERS_FAILURE:
            return { ...state, isFetchingUsers: false, error: action.message || '' };
        case userConstants.SEARCH_USERS_SUCCESS:
            return {
                ...state,
                isFetchingUsers: false,
                users: action.from > 0 ? [...state.users, ...action.users] : action.users,
                usersLoaded: action.from > 0 ? state.users.length + action.users.length : action.users.length,
                usersTotalResults: action.totalResults || 0,
            };

        case userConstants.CREATING_USER_STARTED:
            return { ...state, isFetchingUser: true };
        case userConstants.CREATING_USER_FAILURE:
            return { ...state, isFetchingUser: false, error: action.message || '' };
        case userConstants.CREATING_USER_SUCCESS:
            return { ...state, isFetchingUser: false, user: action.user, users: [action.user, ...state.users] };

        case userConstants.INVITING_USER_STARTED:
            return { ...state, isFetchingUser: true };
        case userConstants.INVITING_USER_FAILURE:
            return { ...state, isFetchingUser: false, error: action.message || '' };
        case userConstants.INVITING_USER_SUCCESS:
            return {
                ...state,
                isFetchingUser: false,
                user: action.user,
                users: state.users.includes(action.user)
                    ? [...state.users].map((user) => (user.id === action.user.id ? action.user : user))
                    : [action.user, ...state.users],
            };

        case userConstants.UPDATING_USER_STARTED:
            return { ...state, isFetchingUser: true };
        case userConstants.UPDATING_USER_FAILURE:
            return { ...state, isFetchingUser: false, error: action.message || '' };
        case userConstants.UPDATING_USER_SUCCESS:
            return {
                ...state,
                isFetchingUser: false,
                user: action.user,
                users: [...state.users].map((user) => (user.id === action.user.id ? action.user : user)),
            };
        case userConstants.DELETE_USER_STARTED:
            return { ...state, isFetchingUser: true };
        case userConstants.DELETE_USER_FAILURE:
            return { ...state, isFetchingUser: false, error: action.message || '' };
        case userConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                isFetchingUser: false,
                user: action.user,
                users: [...state.users].filter((user) => user.id !== action.user.id),
            };
        default:
            return state;
    }
}
