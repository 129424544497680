import api from '../api';

export const get = () => {
    return api.get(`/category`);
};

export const all = () => {
    return api.get(`/categories`);
};

export const search = (searchParameters) => {
    return api.post(`/categories`, searchParameters);
};

export const create = (category) => {
    return api.post(`/category`, category);
};

export const update = (category) => {
    return api.put(`/category`, category);
};

export const remove = (category) => {
    return api.delete(`/category${category.id}`);
};
