import React, { Component } from "react";

import UpdateRoleContainer from "../../containers/role/crud/UpdateRoleContainer";
import CreateRoleContainer from "../../containers/role/crud/CreateRoleContainer";

import TableView from "../ui/views/TableView";
import PopOver from "../ui/components/PopOver";

export default class RolesView extends Component {
    state = {
        selectedOptions: ["name", "level"],
        popOvers: [],
        searchQuery: "",
    };

    componentDidMount() {
        const { getRoles } = this.props;
        getRoles(0, 20);
    }

    render() {
        const { t, roles, isFetchingRoles, rolesLoaded,  rolesTotalResults, addToStack, deleteRole, searchRoles, getRoles } = this.props;
        const { selectedOptions, popOvers, searchQuery } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        isLoading={isFetchingRoles}
                        values={roles}
                        title={t("roles.header")}
                        searchEnabled
                        searchPlaceholder={t("search.placeholder")}
                        onSearchChange={(searchQuery) => {
                            searchQuery ? searchRoles({query: searchQuery}, 0, 20) : getRoles(0, 20)
                        
                            this.setState({
                                searchQuery: searchQuery
                            })
                        }}
                        onScrolledToBottom={() => {
                            if (rolesTotalResults > rolesLoaded ) {
                                searchQuery ? searchRoles({query: searchQuery}, rolesLoaded, rolesLoaded + 20) : getRoles(rolesLoaded, rolesLoaded + 20)
                            }
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t("role.create"),
                                component: <CreateRoleContainer />,
                            });
                        }}
                        createNewLabel={t("role.create")}
                        options={[
                            {
                                name: "name",
                                size: "one",
                                header: t("role.name"),
                                type: "string",
                                selector: "name",
                                defaultValue: t("noValue"),
                            },
                            {
                                name: "level",
                                size: "one",
                                header: t("role.level"),
                                type: "string",
                                selector: "level"
                            }
                        ]}
                        selectedOptions={selectedOptions}
                        actions={[
                            {
                                name: t("form.edit"),
                                enabled: true,
                                action: (e, role) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: t("role.update"),
                                        component: <UpdateRoleContainer targetRole={role} />,
                                    });
                                },
                            },
                            {
                                name: t("form.delete"),
                                enabled: true,
                                action: (e, role) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t("role.delete.header")}</div>
                                                    <div className="text">
                                                        {t("role.delete.description")}
                                                    </div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t("cancel")}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deleteRole(role)
                                                            }}
                                                        >
                                                            {t("delete")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                        ]}
                        noDataLabel={t("roles.notFound")}
                    />
                </div>
            </div>
        );
    }
}
