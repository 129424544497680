import { businessConstants } from "../constants";
import * as businessApi from "../../api/businessApi";

export const changeValue = (name, value) => ({
    type: businessConstants.CREATING_BUSINESS_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching business
 */
export const startGetBusiness = () => ({
    type: businessConstants.GET_BUSINESS_STARTED,
});

export const getBusinessSuccess = (result) => ({
    type: businessConstants.GET_BUSINESS_SUCCESS,
    ...result,
});

export const getBusinessFailure = (error) => ({
    type: businessConstants.GET_BUSINESS_FAILURE,
    ...error,
});

export const getBusiness = (id) => {
    return (dispatch) => {
        dispatch(startGetBusiness());

        const promise = businessApi.get(id);

        promise
            .then((result) => {
                dispatch(getBusinessSuccess(result));
            })
            .catch((error) => {
                dispatch(getBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Creating business
 */
export const changeCreateBusiness = (name, value) => ({
    type: businessConstants.CREATING_BUSINESS_CHANGE_VALUE,
    name,
    value,
});
export const startCreateBusiness = () => ({
    type: businessConstants.CREATING_BUSINESS_STARTED,
});

export const createBusinessSuccess = (result) => ({
    type: businessConstants.CREATING_BUSINESS_SUCCESS,
    ...result,
});

export const createBusinessFailure = (error) => ({
    type: businessConstants.CREATING_BUSINESS_FAILURE,
    ...error,
});

export const createBusiness = (business) => {
    return (dispatch) => {
        dispatch(startCreateBusiness());

        const promise = businessApi.create(business);

        promise
            .then((result) => {
                dispatch(createBusinessSuccess(result));
            })
            .catch((error) => {
                dispatch(createBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Updating business
 */
export const changeUpdateBusiness = (name, value) => ({
    type: businessConstants.UPDATING_BUSINESS_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateBusiness = () => ({
    type: businessConstants.UPDATING_BUSINESS_STARTED,
});

export const updateBusinessSuccess = (result) => ({
    type: businessConstants.UPDATING_BUSINESS_SUCCESS,
    ...result,
});

export const updateBusinessFailure = (error) => ({
    type: businessConstants.UPDATING_BUSINESS_FAILURE,
    ...error,
});

export const updateBusiness = (business) => {
    return (dispatch) => {
        dispatch(startUpdateBusiness());

        const promise = businessApi.update(business);

        promise
            .then((result) => {
                dispatch(updateBusinessSuccess(result));
            })
            .catch((error) => {
                dispatch(updateBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Update business Logo
 */
export const startUpdateBusinessLogo = () => ({
    type: businessConstants.UPDATE_BUSINESS_LOGO_STARTED,
});

export const updateBusinessLogoSucces = (result) => ({
    type: businessConstants.UPDATE_BUSINESS_LOGO_SUCCESS,
    ...result,
});

export const updateBusinessLogoFailure = (error) => ({
    type: businessConstants.UPDATE_BUSINESS_LOGO_FAILURE,
    ...error,
});

export const updateBusinessLogo = (avatar) => {
    return (dispatch) => {
        dispatch(startUpdateBusinessLogo());

        const promise = businessApi.updateBusinessLogo(avatar);

        promise
            .then((result) => {
                dispatch(updateBusinessLogoSucces(result));
            })
            .catch((error) => {
                dispatch(updateBusinessLogoFailure(error));
            });

        return promise;
    };
};

/**
 * Update business Banner
 */
export const startUpdateBusinessBanner = () => ({
    type: businessConstants.UPDATE_BUSINESS_BANNER_STARTED,
});

export const updateBusinessBannerSucces = (result) => ({
    type: businessConstants.UPDATE_BUSINESS_BANNER_SUCCESS,
    ...result,
});

export const updateBusinessBannerFailure = (error) => ({
    type: businessConstants.UPDATE_BUSINESS_BANNER_FAILURE,
    ...error,
});

export const updateBusinessBanner = (banner) => {
    return (dispatch) => {
        dispatch(startUpdateBusinessBanner());

        const promise = businessApi.updateBusinessBanner(banner);

        promise
            .then((result) => {
                dispatch(updateBusinessBannerSucces(result));
            })
            .catch((error) => {
                dispatch(updateBusinessBannerFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching businesses
 */
export const startGetBusinesses = () => ({
    type: businessConstants.GET_BUSINESSES_STARTED,
});

export const getBusinessesSuccess = (result, from) => ({
    type: businessConstants.GET_BUSINESSES_SUCCESS,
    from: from,
    ...result,
});

export const getBusinessesFailure = (error) => ({
    type: businessConstants.GET_BUSINESSES_FAILURE,
    ...error,
});

export const getBusinesses = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBusinesses());

        const promise = businessApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getBusinessesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBusinessesFailure(error));
            });

        return promise;
    };
};

export const searchBusinesses = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBusinesses());

        const promise = businessApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getBusinessesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBusinessesFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching businesses
 */
export const startGetPersonalBusinesses = () => ({
    type: businessConstants.GET_PERSONAL_BUSINESSES_STARTED,
});

export const getPersonalBusinessesSuccess = (result) => ({
    type: businessConstants.GET_PERSONAL_BUSINESSES_SUCCESS,
    ...result,
});

export const getPersonalBusinessesFailure = (error) => ({
    type: businessConstants.GET_PERSONAL_BUSINESSES_FAILURE,
    ...error,
});

export const getPersonalBusinesses = (token) => {
    return (dispatch) => {
        dispatch(startGetPersonalBusinesses());

        const promise = businessApi.personal(token);

        promise
            .then((result) => {
                dispatch(getPersonalBusinessesSuccess(result));
            })
            .catch((error) => {
                dispatch(getPersonalBusinessesFailure(error));
            });

        return promise;
    };
};
