export const authConstants = {
    CHANGE_VALUE: "CHANGE_VALUE",

    LOGIN_FORM_STARTED: "LOGIN_FORM_STARTED",
    LOGIN_FORM_SUCCESS: "LOGIN_FORM_SUCCESS",
    LOGIN_FORM_FAILURE: "LOGIN_FORM_FAILURE",

    REGISTER_FORM_CHANGE_VALUE: "REGISTER_FORM_CHANGE_VALUE",
    REGISTER_FORM_STARTED: "REGISTER_FORM_STARTED",
    REGISTER_FORM_SUCCESS: "REGISTER_FORM_SUCCESS",
    REGISTER_FORM_FAILURE: "REGISTER_FORM_FAILURE",

    FORGOT_FORM_STARTED: "FORGOT_FORM_STARTED",
    FORGOT_FORM_SUCCESS: "FORGOT_FORM_SUCCESS",
    FORGOT_FORM_FAILURE: "FORGOT_FORM_FAILURE",

    RESET_FORM_STARTED: "RESET_FORM_STARTED",
    RESET_FORM_SUCCESS: "RESET_FORM_SUCCESS",
    RESET_FORM_FAILURE: "RESET_FORM_FAILURE",

    UPDATE_USER_STARTED: "UPDATE_USER_STARTED",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

    UPDATE_PASSWORD_STARTED: "UPDATE_PASSWORD_STARTED",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

    LOGOUT_STARTED: "LOGOUT_STARTED",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILURE: "LOGOUT_FAILURE",

    LOGOUT_ALL_OTHERS_STARTED: "LOGOUT_ALL_OTHERS_STARTED",
    LOGOUT_ALL_OTHERS_SUCCESS: "LOGOUT_ALL_OTHERS_SUCCESS",
    LOGOUT_ALL_OTHERS_FAILURE: "LOGOUT_ALL_OTHERS_FAILURE",

    CONFIRM_EMAIL_STARTED: "CONFIRM_EMAIL_STARTED",
    CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
    CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",

    GET_SESSIONS_STARTED: "GET_SESSIONS_STARTED",
    GET_SESSIONS_SUCCESS: "GET_SESSIONS_SUCCESS",
    GET_SESSIONS_FAILURE: "GET_SESSIONS_FAILURE",

    
    GET_ROLES_STARTED: "GET_ROLES_STARTED",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

    SEARCH_ROLES_STARTED: "SEARCH_ROLES_STARTED",
    SEARCH_ROLES_SUCCESS: "SEARCH_ROLES_SUCCESS",
    SEARCH_ROLES_FAILURE: "SEARCH_ROLES_FAILURE",

    GET_ROLE_STARTED: "GET_ROLE_STARTED",
    GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
    GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

    CREATE_ROLE_STARTED: "CREATE_ROLE_STARTED",
    CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
    CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",

    UPDATE_ROLE_STARTED: "UPDATE_ROLE_STARTED",
    UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

    DELETE_ROLE_STARTED: "DELETE_ROLE_STARTED",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
};
