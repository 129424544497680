import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateChatPage from "../../../components/chat/crud/CreateChatPage";

import * as chatActions from "../../../state/actions/chatActions";
import * as profileActions from "../../../state/actions/profileActions";

class CreateChatContainer extends Component {
    render() {
        const {
            changeValue,
            getChat,
            createChat,
            createMessage,
            searchProfiles,
            onChatCreated,
            ...props
        } = this.props;

        

        return (
            <CreateChatPage
                {...props}
                onChange={changeValue}
                getChat={(id) => getChat(id)}
                createChat={(chat, message) =>
                    createChat(chat, message).then((response) => {
                        createMessage(response.chat.id, message).then(() => {
                            onChatCreated && onChatCreated(response.chat);
                        })
                    })
                }
                searchProfiles={(query, from, to) => searchProfiles(query, from, to)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        profiles: state.profile.profiles,
        profilesIsFetching: state.profile.profilesIsFetching,

        ...state.chat,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(chatActions.changeChatValue(name, value)),

        getChat: (id) => dispatch(chatActions.getChat(id)),
        createChat: (chat, message) => dispatch(chatActions.createChat(chat, message)),
        createMessage: (id, message) => dispatch(chatActions.createMessage(id, message)),

        searchProfiles: (query, from, to) =>
            dispatch(profileActions.searchProfiles(query, from, to)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(CreateChatContainer));
