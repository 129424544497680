import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Loader from '../../ui/components/Loader';
import NoData from '../../ui/components/NoData';
import { intToDouble } from '../../../utils/moneyUtils';

import TransactionView from './TransactionView';

export default class TransactionsView extends Component {
    handleScroll = () => {
        const { max, transactionsLoaded, transactionsTotalResults, isFetchingTransactions } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (!max || transactionsLoaded < max) {
                if (!isFetchingTransactions && transactionsTotalResults > transactionsLoaded) {
                    this.fetch(transactionsLoaded, 20);
                }
            }
        }
    };

    fetch = (offset, amount) => {
        const { getTransactions } = this.props;
        getTransactions(offset, amount);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        this.fetch(0, 5);
    }

    plusOrMinusTransaction(transaction) {
        return transaction.amount.amount >= 0 ? 'plus' : 'minus';
    }

    render() {
        const { t, isFetchingTransactions, minimal, transactions, addToStack, user } = this.props;

        if (!isFetchingTransactions) {
            if (transactions.length > 0) {
                if (minimal) {
                    return (
                        <div className="transaction-list last-no-border">
                            {transactions.map((transaction, index) => (
                                <div
                                    to={`/transaction/${transaction.id}`}
                                    className="transaction-list-item minimal"
                                    key={index}
                                    onClick={() => {
                                        addToStack({
                                            name: t('transaction'),
                                            component: <TransactionView user={user} transaction={transaction} t={t} />,
                                        });
                                    }}
                                >
                                    <div className={'sort ' + this.plusOrMinusTransaction(transaction)}></div>
                                    <div className="amount">
                                        {transaction.amount.currency.sign}
                                        {intToDouble(
                                            transaction.amount.amount,
                                            2,
                                            user.language.decimalDelimiter,
                                            user.language.thousandDelimiter
                                        )}
                                    </div>
                                    <div className="date">
                                        {moment.utc(transaction.createdAt).local().format('DD/MM/YY HH:mm')}
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                }
                return (
                    <div className="transaction-list last-no-border">
                        {transactions.map((transaction, index) => (
                            <div
                                to={`/transaction/${transaction.id}`}
                                className="transaction-list-item"
                                key={index}
                                onClick={() => {
                                    addToStack({
                                        name: t('transaction'),
                                        component: <TransactionView user={user} transaction={transaction} t={t} />,
                                    });
                                }}
                            >
                                <div className="amount">
                                    <div className={'sort ' + this.plusOrMinusTransaction(transaction)}></div>
                                    {transaction.amount.currency.sign}
                                    {intToDouble(
                                        transaction.amount.amount,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )}
                                </div>
                                <div className=""></div>
                                <div className="date">
                                    {moment.utc(transaction.createdAt).local().format('DD/MM/YY HH:mm')}
                                </div>
                            </div>
                        ))}
                    </div>
                );
            }
            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('wallet.transactions.notFound')}</NoData>
                </div>
            );
        }
        return <Loader />;
    }
}
