import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { bankOptions } from './../IdealBankOptions';

class IDEAL extends Component {
    render() {
        const { t, source } = this.props;
        const { ideal } = source;
        console.log(source, ideal);
        return (
            <div className="source flex-container">
                <div className="source-part">
                    <img src="/images/wallet/ideal.png" alt="ideal" />
                    <div className="name">
                        {t('wallet.payment.type.ideal')} ****{ideal.iban_last4}
                    </div>
                </div>
                <div className="source-part">
                    <img
                        src={bankOptions.find((option) => option.label === ideal.bank).image}
                        alt={bankOptions.find((option) => option.label === ideal.bank).name}
                    />
                    <div className="">{ideal.bic}</div>
                </div>
            </div>
        );
    }
}
export default withTranslation('translation')(IDEAL);
