import React, { Component } from "react";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../utils/formUtils";

export default class CreateRole extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formRole } = this.props;

        onSubmit && onSubmit(formRole);
    }

    render() {
        const { onChange, t, formRole, permissions } = this.props;

        return (
            <div className="step-form" key={formRole.id}>
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formRole.name}
                                    onChange={(event) => {
                                        const newRole = { ...formRole };
                                        newRole.name = event.target.value;

                                        onChange("formRole", newRole, event);
                                    }}
                                />
                                <label>{t("form.label.name")}*</label>
                            </div>
                            {this.validator.message(t("form.label.name"), formRole.name, "required")}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="number"
                                    value={formRole.level}
                                    onChange={(event) => {
                                        const newRole = { ...formRole };
                                        newRole.level = event.target.value;

                                        onChange("formRole", newRole, event);
                                    }}
                                />
                                <label>{t("form.label.accessLevel")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.accessLevel"),
                                formRole.level,
                                "required"
                            )}
                        </div>
                        <div className="input-group">
                            <Select 
                                isMulti={true}
                                options={permissions.map((permission) => ({
                                    label: permission.name,
                                    value: permission.id
                                }))}
                                onChange={(e) => {
                                    const newRole = { ...formRole };
                                    

                                    onChange("formRole", newRole);
                                }}
                            />
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
