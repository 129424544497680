import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as taskActions from "../../../state/actions/taskActions";
import * as navigationActions from "../../../state/actions/navigationActions";

import CreateCheckDisputePage from "../../../components/task/crud/CreateCheckDisputePage";

class CreateCheckDisputeContainer extends Component {
    componentDidMount() {
        const { changeValue, check } = this.props;
        changeValue("formCheckDispute", check);
    }

    render() {
        const { popStack, changeValue, createCheckDispute, ...props } = this.props;

        return (
            <CreateCheckDisputePage
                {...props}
                onChange={changeValue}
                createCheckDispute={(check) => {
                    createCheckDispute(check);
                    popStack();
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        platform: state.platform.platform,
        ...state.task,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        createCheckDispute: (check) => dispatch(taskActions.createCheckDispute(check)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateCheckDisputeContainer));
