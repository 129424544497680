import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from '../../components/header/Header.js';
import * as notificationActions from '../../state/actions/notificationActions';
import * as profileActions from '../../state/actions/profileActions';
import * as chatActions from '../../state/actions/chatActions';
import * as navigationActions from '../../state/actions/navigationActions';
import * as platformActions from '../../state/actions/platformActions';

class HeaderContainer extends Component {
    render() {
        const { getNotifications, readNotifications, getChats, getUserProfile, ...props } = this.props;
        const { user, notifications } = this.props;

        return (
            <Header
                {...props}
                getNotifications={(from = 0, amount = 20) => getNotifications(from, amount)}
                readNotifications={() => {
                    const unreadNotifications = [...notifications]
                        .filter((notification) => notification.read.filter((r) => r.by.id === user.id).length < 1)
                        .map((notification) => notification.id);
                    if (unreadNotifications.length > 0) readNotifications(unreadNotifications);
                }}
                getChats={(from = 0, amount = 20) => getChats(from, amount)}
                getUserProfile={() => getUserProfile(user.id)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        platform: state.platform.platform,
        notificationsIsFetching: state.notification.notificationsIsFetching,
        notifications: state.notification.notifications,
        notificationsTotalResults: state.notification.notificationsTotalResults,
        notificationsUnread: state.notification.notificationsUnread,
        profileIsFetching: state.profile.profileIsFetching,
        profile: state.auth.profile,
        chats: state.chat.chats,
        chatsIsFetching: state.chat.chatsIsFetching,
        chatsTotalResults: state.chat.chatsTotalResults,
        messagesUnread: state.chat.messagesUnread,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNotifications: (from, to) => dispatch(notificationActions.getNotifications(from, to)),
        readNotifications: (notifications) => dispatch(notificationActions.readNotifications(notifications)),
        getUserProfile: (id) => dispatch(profileActions.getUserProfile(id)),
        getChats: (from, amount) => dispatch(chatActions.getChats(from, amount)),

        setPlatform: (platform) => dispatch(platformActions.setPlatform(platform)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(HeaderContainer));
