import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import LoginPage from "../../components/authentication/LoginPage";
import * as authActions from "../../state/actions/authActions.js";

class LoginContainer extends Component {
    render() {
        const { changeValue, login, ...props } = this.props;

        const { t, email, password, history } = props;
        
        return (
            <LoginPage
                {...props}
                onChange={changeValue}
                onLogin={() => {
                    login(email, password, t)
                        .then((response) => {
                            console.log("still has a response??", response)
                            history.push("/dashboard");
                        })
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        platform: state.platform.platform,
        error: state.auth.error,
        email: state.auth.email,
        password: state.auth.password,
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(authActions.changeLoginValue(name, value)),
        login: (email, password, t) => dispatch(authActions.login(email, password, t)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(LoginContainer));
