import React, { Component } from 'react';

export const bankOptions = [
    { label: 'abn_amro', image: '/images/banks/abn_amro.jpg' },
    { label: 'asn_bank', image: '/images/banks/asn.png' },
    { label: 'bunq', image: '/images/banks/bunq.jpg' },
    { label: 'handelsbanken', image: '/images/banks/handelsbanken.png' },
    { label: 'ing', image: '/images/banks/ing.jpg' },
    { label: 'knab', image: '/images/banks/knab.jpg' },
    { label: 'moneyou', image: '/images/banks/moneyou.png' },
    { label: 'rabobank', image: '/images/banks/rabobank.jpg' },
    { label: 'regiobank', image: '/images/banks/regiobank.png' },
    { label: 'sns_bank', image: '/images/banks/sns.png' },
    { label: 'triodos_bank', image: '/images/banks/triodos.png' },
    { label: 'van_lanschot', image: '/images/banks/van_lancshot.jpg' },
];

export default class IdealBankOptions extends Component {
    static defaultProps = {
        bankOptions: bankOptions,
    };

    state = {
        bank: 'abn_amro',
    };

    componentDidMount() {
        const { bank } = this.state;
        const { onSelected } = this.props;
        if (onSelected) onSelected(bank);
    }

    onSelect(bank) {
        const { onSelected } = this.props;
        if (onSelected) onSelected(bank);
        this.setState({ bank });
    }

    render() {
        const { bank } = this.state;
        const { bankOptions } = this.props;

        return (
            <div className="bank-options">
                {bankOptions.map((bankOption) => {
                    return (
                        <div
                            className={'bank-option' + (bank === bankOption.label ? ' selected' : '')}
                            onClick={(e) => this.onSelect(bankOption.label)}
                            key={`bank-option-${bankOption.label}`}
                        >
                            <img src={bankOption.image} alt={bankOption.label} />
                        </div>
                    );
                })}
            </div>
        );
    }
}
