import Address from "../general/Address";

export default class BusinessLocation {
    id;
    nonce;

    pictures;
    address;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.address = new Address();
    }
}
