import * as profileApi from "../../api/profileApi";
import { profileConstants } from "../constants";

/**
 * Create profile actions
 */
export const changeCreateProfileValue = (name, value) => ({
    type: profileConstants.CREATING_PROFILE_CHANGE_VALUE,
    name,
    value,
});

export const changeValue = (name, value) => ({
    type: profileConstants.PROFILE_CHANGE_VALUE,
    name,
    value,
});

export const startCreateProfile = () => ({
    type: profileConstants.CREATING_PROFILE_STARTED,
});

export const createProfileSuccess = (result) => ({
    type: profileConstants.CREATING_PROFILE_SUCCESS,
    ...result,
});

export const createProfileFailure = (error) => ({
    type: profileConstants.CREATING_PROFILE_FAILURE,
    ...error,
});

export const createProfile = (profile) => {
    return (dispatch) => {
        dispatch(startCreateProfile());

        const promise = profileApi.createProfile(profile);

        promise
            .then((result) => {
                dispatch(createProfileSuccess(result));
            })
            .catch((error) => {
                dispatch(createProfileFailure(error));
            });

        return promise;
    };
};

/**
 * Updating profile
 */
export const startUpdateProfile = () => ({
    type: profileConstants.UPDATING_PROFILE_STARTED,
});

export const updateProfileSucces = (result) => ({
    type: profileConstants.UPDATING_PROFILE_SUCCESS,
    ...result,
});

export const updateProfileFailure = (error) => ({
    type: profileConstants.UPDATING_PROFILE_FAILURE,
    ...error,
});

export const updateProfile = (profile) => {
    return (dispatch) => {
        dispatch(startUpdateProfile());

        const promise = profileApi.updateProfile(profile);

        promise
            .then((result) => {
                dispatch(updateProfileSucces(result));
            })
            .catch((error) => {
                dispatch(updateProfileFailure(error));
            });

        return promise;
    };
};

/**
 * Update profile Avatar
 */
export const startUpdateProfileAvatar = () => ({
    type: profileConstants.UPDATE_PROFILE_AVATAR_STARTED,
});

export const updateProfileAvatarSucces = (result) => ({
    type: profileConstants.UPDATE_PROFILE_AVATAR_SUCCESS,
    ...result,
});

export const updateProfileAvatarFailure = (error) => ({
    type: profileConstants.UPDATE_PROFILE_AVATAR_FAILURE,
    ...error,
});

export const updateProfileAvatar = (avatar) => {
    return (dispatch) => {
        dispatch(startUpdateProfileAvatar());

        const promise = profileApi.updateProfileAvatar(avatar);

        promise
            .then((result) => {
                dispatch(updateProfileAvatarSucces(result));
            })
            .catch((error) => {
                dispatch(updateProfileAvatarFailure(error));
            });

        return promise;
    };
};

/**
 * Update profile Banner
 */
export const startUpdateProfileBanner = () => ({
    type: profileConstants.UPDATE_PROFILE_BANNER_STARTED,
});

export const updateProfileBannerSucces = (result) => ({
    type: profileConstants.UPDATE_PROFILE_BANNER_SUCCESS,
    ...result,
});

export const updateProfileBannerFailure = (error) => ({
    type: profileConstants.UPDATE_PROFILE_BANNER_FAILURE,
    ...error,
});

export const updateProfileBanner = (banner) => {
    return (dispatch) => {
        dispatch(startUpdateProfileBanner());

        const promise = profileApi.updateProfileBanner(banner);

        promise
            .then((result) => {
                dispatch(updateProfileBannerSucces(result));
            })
            .catch((error) => {
                dispatch(updateProfileBannerFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching profile
 */
export const startGetProfile = () => ({
    type: profileConstants.GET_PROFILE_STARTED,
});

export const getProfileSuccess = (result) => ({
    type: profileConstants.GET_PROFILE_SUCCESS,
    ...result,
});

export const getProfileFailure = (error) => ({
    type: profileConstants.GET_PROFILE_FAILURE,
    ...error,
});

export const getProfile = (id) => {
    return (dispatch) => {
        dispatch(startGetProfile());

        const promise = profileApi.get(id);

        promise
            .then((result) => {
                dispatch(getProfileSuccess(result));
            })
            .catch((error) => {
                dispatch(getProfileFailure(error));
            });

        return promise;
    };
};

export const getUserProfile = (id) => {
    return (dispatch) => {
        dispatch(startGetProfile());

        const promise = profileApi.getFromUser(id);

        promise
            .then((result) => {
                dispatch(getProfileSuccess(result));
            })
            .catch((error) => {
                dispatch(getProfileFailure(error));
            });

        return promise;
    };
};

/**
 * Searching profile
 */
export const startSearchingProfile = () => ({
    type: profileConstants.SEARCH_PROFILE_STARTED,
});

export const searchingProfileSuccess = (result) => ({
    type: profileConstants.SEARCH_PROFILE_SUCCESS,
    ...result,
});

export const searchingProfileFailure = (error) => ({
    type: profileConstants.SEARCH_PROFILE_FAILURE,
    ...error,
});

export const searchProfiles = (searchParameters, from, to) => {
    return (dispatch) => {
        dispatch(startSearchingProfile());

        const promise = profileApi.searchProfiles(searchParameters, from, to);

        promise
            .then((result) => {
                dispatch(searchingProfileSuccess(result));
            })
            .catch((error) => {
                dispatch(searchingProfileFailure(error));
            });

        return promise;
    };
};

/**
 * Updating CV
 */
export const startUpdateCV = () => ({
    type: profileConstants.UPDATE_CV_STARTED,
});

export const updateCVSucces = (result) => ({
    type: profileConstants.UPDATE_CV_SUCCESS,
    ...result,
});

export const updateCVFailure = (error) => ({
    type: profileConstants.UPDATE_CV_FAILURE,
    ...error,
});

export const updateCV = (cv) => {
    return (dispatch) => {
        dispatch(startUpdateCV());

        const promise = profileApi.updateCV(cv);

        promise
            .then((result) => {
                dispatch(updateCVSucces(result));
            })
            .catch((error) => {
                dispatch(updateCVFailure(error));
            });

        return promise;
    };
};

/**
 * Create education
 */
export const startCreateEducation = () => ({
    type: profileConstants.CREATE_EDUCATION_STARTED,
});

export const createEducationSuccess = (result) => ({
    type: profileConstants.CREATE_EDUCATION_SUCCESS,
    ...result,
});

export const createEducationFailure = (error) => ({
    type: profileConstants.CREATE_EDUCATION_FAILURE,
    ...error,
});

export const createEducation = (education) => {
    return (dispatch) => {
        dispatch(startCreateEducation());

        const promise = profileApi.createEducation(education);

        promise
            .then((result) => {
                dispatch(createEducationSuccess(result));
            })
            .catch((error) => {
                dispatch(createEducationFailure(error));
            });

        return promise;
    };
};

/**
 * Update education
 */
export const startUpdateEducation = () => ({
    type: profileConstants.UPDATE_EDUCATION_STARTED,
});

export const updateEducationSuccess = (result) => ({
    type: profileConstants.UPDATE_EDUCATION_SUCCESS,
    ...result,
});

export const updateEducationFailure = (error) => ({
    type: profileConstants.UPDATE_EDUCATION_FAILURE,
    ...error,
});

export const updateEducation = (education) => {
    return (dispatch) => {
        dispatch(startUpdateEducation());

        const promise = profileApi.updateEducation(education);

        promise
            .then((result) => {
                dispatch(updateEducationSuccess(result));
            })
            .catch((error) => {
                dispatch(updateEducationFailure(error));
            });

        return promise;
    };
};

/**
 * Delete education
 */
export const startDeleteEducation = () => ({
    type: profileConstants.DELETE_EDUCATION_STARTED,
});

export const deleteEducationSuccess = (result) => ({
    type: profileConstants.DELETE_EDUCATION_SUCCESS,
    ...result,
});

export const deleteEducationFailure = (error) => ({
    type: profileConstants.DELETE_EDUCATION_FAILURE,
    ...error,
});

export const deleteEducation = (id) => {
    return (dispatch) => {
        dispatch(startDeleteEducation());

        const promise = profileApi.deleteEducation(id);

        promise
            .then((result) => {
                dispatch(deleteEducationSuccess(result));
            })
            .catch((error) => {
                dispatch(deleteEducationFailure(error));
            });

        return promise;
    };
};

/**
 * Create experience
 */
export const startCreateExperience = () => ({
    type: profileConstants.CREATE_EXPERIENCE_STARTED,
});

export const createExperienceSuccess = (result) => ({
    type: profileConstants.CREATE_EXPERIENCE_SUCCESS,
    ...result,
});

export const createExperienceFailure = (error) => ({
    type: profileConstants.CREATE_EXPERIENCE_FAILURE,
    ...error,
});

export const createExperience = (experience) => {
    return (dispatch) => {
        dispatch(startCreateExperience());

        const promise = profileApi.createExperience(experience);

        promise
            .then((result) => {
                dispatch(createExperienceSuccess(result));
            })
            .catch((error) => {
                dispatch(createExperienceFailure(error));
            });

        return promise;
    };
};

/**
 * Update experience
 */
export const startUpdateExperience = () => ({
    type: profileConstants.UPDATE_EXPERIENCE_STARTED,
});

export const updateExperienceSuccess = (result) => ({
    type: profileConstants.UPDATE_EXPERIENCE_SUCCESS,
    ...result,
});

export const updateExperienceFailure = (error) => ({
    type: profileConstants.UPDATE_EXPERIENCE_FAILURE,
    ...error,
});

export const updateExperience = (experience) => {
    return (dispatch) => {
        dispatch(startUpdateExperience());

        const promise = profileApi.updateExperience(experience);

        promise
            .then((result) => {
                dispatch(updateExperienceSuccess(result));
            })
            .catch((error) => {
                dispatch(updateExperienceFailure(error));
            });

        return promise;
    };
};

/**
 * Delete experience
 */
export const startDeleteExperience = () => ({
    type: profileConstants.DELETE_EXPERIENCE_STARTED,
});

export const deleteExperienceSuccess = (result) => ({
    type: profileConstants.DELETE_EXPERIENCE_SUCCESS,
    ...result,
});

export const deleteExperienceFailure = (error) => ({
    type: profileConstants.DELETE_EXPERIENCE_FAILURE,
    ...error,
});

export const deleteExperience = (id) => {
    return (dispatch) => {
        dispatch(startDeleteExperience());

        const promise = profileApi.deleteExperience(id);

        promise
            .then((result) => {
                dispatch(deleteExperienceSuccess(result));
            })
            .catch((error) => {
                dispatch(deleteExperienceFailure(error));
            });

        return promise;
    };
};
