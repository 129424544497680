export const ratingConstants = {
    RATING_CHANGE_VALUE: "RATING_CHANGE_VALUE",

    GET_REVIEW_STARTED: "GET_REVIEW_STARTED",
    GET_REVIEW_SUCCESS: "GET_REVIEW_SUCCESS",
    GET_REVIEW_FAILURE: "GET_REVIEW_FAILURE",

    GET_REVIEWS_STARTED: "GET_REVIEWS_STARTED",
    GET_REVIEWS_SUCCESS: "GET_REVIEWS_SUCCESS",
    GET_REVIEWS_FAILURE: "GET_REVIEWS_FAILURE",

    SEARCH_REVIEWS_STARTED: "SEARCH_REVIEWS_STARTED",
    SEARCH_REVIEWS_SUCCESS: "SEARCH_REVIEWS_SUCCESS",
    SEARCH_REVIEWS_FAILURE: "SEARCH_REVIEWS_FAILURE",

    CREATING_REVIEW_STARTED: "CREATING_REVIEW_STARTED",
    CREATING_REVIEW_SUCCESS: "CREATING_REVIEW_SUCCESS",
    CREATING_REVIEW_FAILURE: "CREATING_REVIEW_FAILURE",

    UPDATING_REVIEW_STARTED: "UPDATING_REVIEW_STARTED",
    UPDATING_REVIEW_SUCCESS: "UPDATING_REVIEW_SUCCESS",
    UPDATING_REVIEW_FAILURE: "UPDATING_REVIEW_FAILURE",

    DELETE_REVIEW_STARTED: "DELETE_REVIEW_STARTED",
    DELETE_REVIEW_SUCCESS: "DELETE_REVIEW_SUCCESS",
    DELETE_REVIEW_FAILURE: "DELETE_REVIEW_FAILURE",
};
