import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Loader from '../ui/components/Loader';
import Avatar from '../ui/components/Avatar';
import OptionsMenu from '../ui/components/OptionsMenu';

import ChatInfoView from './ChatInfoView';

import CreateMessageContainer from '../../containers/chat/crud/CreateMessageContainer';
import MessagesContainer from '../../containers/chat/MessagesContainer';

class ChatPage extends Component {
    constructor(props) {
        super(props);

        this.messagesRef = React.createRef();
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    componentDidMount() {
        const { getChat, activeChat } = this.props;
        getChat(activeChat);
    }

    render() {
        const { t, chat, chatIsFetching, addToStack } = this.props;

        if (!chatIsFetching && chat) {
            return (
                <div className="chat-main">
                    <div className="information flex-container justify-between">
                        <div className="flex-container">
                            <Avatar imageUrl={chat.image.url} name={chat.title} />
                            <div className="right-part">
                                <h1>{chat.title || t('chat.noTitle')}</h1>
                            </div>
                        </div>
                        <div className="self-center">
                            <OptionsMenu
                                className="self-center"
                                items={[
                                    {
                                        name: t('chat.information'),
                                        onClick: (e) => {
                                            e.preventDefault();

                                            addToStack({
                                                name: chat.title,
                                                component: <ChatInfoView chat={chat} />,
                                            });
                                        },
                                    },
                                    {
                                        name: t('form.delete'),
                                        onClick: (e) => {
                                            e.preventDefault();
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <MessagesContainer chat={chat} />
                    <CreateMessageContainer
                        onMessageCreate={() =>
                            (this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeigh)
                        }
                    />
                </div>
            );
        }
        return <Loader />;
    }
}

export default withTranslation('translation')(ChatPage);
