import { taskConstants } from '../constants';

const initialState = {
    taskIsFetching: false,
    task: null,

    formTask: null,

    tasksIsFetching: false,
    tasksIsFetchingFrom: 0,
    tasksTotalResults: 0,
    tasksLoaded: 0,
    tasks: [],

    shiftIsFetching: false,
    shift: null,
    formShift: null,

    shiftsIsFetching: false,
    shfitsIsFetchingFrom: 0,
    shiftsTotalResults: 0,
    shiftsLoaded: 0,
    shifts: [],

    checks: [],
    checksTotalResults: 0,
    checksLoaded: 0,
    checksIsFetching: false,

    isFetchingApplication: false,
    application: null,
    formApplication: null,

    applications: [],
};

export default function task(state = initialState, action) {
    switch (action.type) {
        case taskConstants.CREATING_TASK_CHANGE_VALUE:
        case taskConstants.UPDATING_TASK_CHANGE_VALUE:
        case taskConstants.CREATING_SHIFT_CHANGE_VALUE:
        case taskConstants.UPDATING_SHIFT_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };

        case taskConstants.GET_TASK_STARTED:
            return { ...state, taskIsFetching: true };
        case taskConstants.GET_TASK_FAILURE:
            return { ...state, taskIsFetching: false };
        case taskConstants.GET_TASK_SUCCESS:
            return { ...state, taskIsFetching: false, task: action.task };

        case taskConstants.GET_TASKS_STARTED:
            return { ...state, tasksIsFetching: true, tasksIsFetchingFrom: action.from || 0 };
        case taskConstants.GET_TASKS_FAILURE:
            return { ...state, tasksIsFetching: false };
        case taskConstants.GET_TASKS_SUCCESS:
            return {
                ...state,
                tasksIsFetching: false,
                tasks: action.from > 0 ? [...state.tasks, ...action.tasks] : action.tasks,
                tasksLoaded: action.from > 0 ? state.tasks.length + action.tasks.length : action.tasks.length,
                tasksTotalResults: action.totalResults || 0,
            };

        case taskConstants.CREATING_TASK_STARTED:
            return { ...state, taskIsFetching: true, error: null };
        case taskConstants.CREATING_TASK_FAILURE:
            return { ...state, taskIsFetching: false, error: action.message || '' };
        case taskConstants.CREATING_TASK_SUCCESS:
            return { ...state, taskIsFetching: false, task: action.task };

        case taskConstants.UPDATING_TASK_STARTED:
            return { ...state, taskIsFetching: true, error: null };
        case taskConstants.UPDATING_TASK_FAILURE:
            return { ...state, taskIsFetching: false, error: action.message || '' };
        case taskConstants.UPDATING_TASK_SUCCESS:
            return { ...state, taskIsFetching: false, task: action.task, formTask: action.task };

        case taskConstants.DELETE_TASK_STARTED:
            return { ...state, taskIsFetching: true, error: null };
        case taskConstants.DELETE_TASK_FAILURE:
            return { ...state, taskIsFetching: false, error: action.message || '' };
        case taskConstants.DELETE_TASK_SUCCESS:
            return { ...state, taskIsFetching: false, task: action.task };

        case taskConstants.GET_SHIFT_STARTED:
            return { ...state, shiftIsFetching: true };
        case taskConstants.GET_SHIFT_FAILURE:
            return { ...state, shiftIsFetching: false };
        case taskConstants.GET_SHIFT_SUCCESS:
            return { ...state, shiftIsFetching: false, shift: action.shift };

        case taskConstants.GET_SHIFTS_STARTED:
            return { ...state, shiftsIsFetching: true, shfitsIsFetchingFrom: action.from || 0 };
        case taskConstants.GET_SHIFTS_FAILURE:
            return { ...state, shiftsIsFetching: false };
        case taskConstants.GET_SHIFTS_SUCCESS:
            return {
                ...state,
                shiftsIsFetching: false,
                shifts: action.from > 0 ? [...state.shifts, ...action.shifts] : action.shifts,
                shiftsLoaded: action.from > 0 ? state.shifts.length + action.shifts.length : action.shifts.length,
                shiftsTotalResults: action.totalResults || 0,
            };
        case taskConstants.CREATING_SHIFT_STARTED:
            return { ...state, shiftIsFetching: true, error: null };
        case taskConstants.CREATING_SHIFT_FAILURE:
            return { ...state, shiftIsFetching: false, error: action.message || '' };
        case taskConstants.CREATING_SHIFT_SUCCESS:
            return {
                ...state,
                shiftIsFetching: false,
                shift: action.shift,
                task: action.task,
                tasks: updateTasks(state, action.task),
            };

        case taskConstants.UPDATING_SHIFT_STARTED:
            return { ...state, shiftIsFetching: true, error: null };
        case taskConstants.UPDATING_SHIFT_FAILURE:
            return { ...state, shiftIsFetching: false, error: action.message || '' };
        case taskConstants.UPDATING_SHIFT_SUCCESS:
            return {
                ...state,
                shiftIsFetching: false,
                shift: action.shift,
                task: action.task,
                tasks: updateTasks(state, action.task),
            };

        case taskConstants.DELETE_SHIFT_STARTED:
            return { ...state, shiftIsFetching: true, error: null };
        case taskConstants.DELETE_SHIFT_FAILURE:
            return { ...state, shiftIsFetching: false, error: action.message || '' };
        case taskConstants.DELETE_SHIFT_SUCCESS:
            return { ...state, shiftIsFetching: false, shift: action.shift };

        case taskConstants.CREATE_APPLICATION_STARTED:
            return { ...state, isFetchingApplication: true, error: null };
        case taskConstants.CREATE_APPLICATION_FAILURE:
            return { ...state, isFetchingApplication: false, error: action.message || '' };
        case taskConstants.CREATE_APPLICATION_SUCCESS: {
            return { ...state, isFetchingApplication: false, task: action.task };
        }

        case taskConstants.UPDATE_APPLICATION_STARTED:
            return {
                ...state,
                isFetchingApplication: true,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: true }),
                applications: updateApplications(state, { ...action.application, isFetching: true }),
                error: null,
            };
        case taskConstants.UPDATE_APPLICATION_FAILURE:
            return {
                ...state,
                isFetchingApplication: false,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
                error: action.message || '',
            };
        case taskConstants.UPDATE_APPLICATION_SUCCESS: {
            return {
                ...state,
                isFetchingApplication: false,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                application: action.application,
                applications: updateApplications(state, { ...action.application, isFetching: false }),
            };
        }

        case taskConstants.DENY_APPLICATION_STARTED:
            return {
                ...state,
                isFetchingApplication: true,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: true }),
                applications: updateApplications(state, { ...action.application, isFetching: true }),
                error: null,
            };
        case taskConstants.DENY_APPLICATION_FAILURE:
            return {
                ...state,
                isFetchingApplication: false,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                error: action.message || '',
            };
        case taskConstants.DENY_APPLICATION_SUCCESS: {
            return {
                ...state,
                isFetchingApplication: false,
                application: action.application,
                task: action.task,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
            };
        }

        case taskConstants.CANCEL_APPLICATION_STARTED:
            return {
                ...state,
                isFetchingApplication: true,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: true }),
                applications: updateApplications(state, { ...action.application, isFetching: true }),
                error: null,
            };
        case taskConstants.CANCEL_APPLICATION_FAILURE:
            return {
                ...state,
                isFetchingApplication: false,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
                error: action.message || '',
            };
        case taskConstants.CANCEL_APPLICATION_SUCCESS: {
            return {
                ...state,
                isFetchingApplication: false,
                application: action.application,
                task: action.task,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
            };
        }

        case taskConstants.ACCEPT_APPLICATION_STARTED:
            return {
                ...state,
                isFetchingApplication: true,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: true }),
                applications: updateApplications(state, { ...action.application, isFetching: true }),
                error: null,
            };
        case taskConstants.ACCEPT_APPLICATION_FAILURE:
            return {
                ...state,
                isFetchingApplication: false,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
                error: action.message || '',
            };
        case taskConstants.ACCEPT_APPLICATION_SUCCESS: {
            return {
                ...state,
                isFetchingApplication: false,
                application: action.application,
                task: action.task,
                shifts: updateShiftApplication(state, { ...action.application, isFetching: false }),
                applications: updateApplications(state, { ...action.application, isFetching: false }),
            };
        }

        case taskConstants.GET_CHECKS_STARTED:
            return {
                ...state,
                checksIsFetching: true,
                checks: updateChecks(state, { ...action.check, isFetching: true }),
            };
        case taskConstants.GET_CHECKS_FAILURE:
            return {
                ...state,
                checksIsFetching: false,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
            };
        case taskConstants.GET_CHECKS_SUCCESS:
            return {
                ...state,
                checksIsFetching: false,
                checks: action.from > 0 ? [...state.checks, ...action.checks] : action.checks,
                checksLoaded: action.from > 0 ? state.checks.length + action.checks.length : action.checks.length,
                checksTotalResults: action.totalResults || 0,
            };

        case taskConstants.CHECK_IN_STARTED:
        case taskConstants.CHECK_OUT_STARTED:
            return {
                ...state,
                checkIsFetching: true,
                checks: updateChecks(state, { ...action.check, isFetching: true }),
                shifts: updateShiftApplication(state, { ...action.check.application, isFetching: true }),
            };
        case taskConstants.CHECK_IN_FAILURE:
        case taskConstants.CHECK_OUT_FAILURE:
            return {
                ...state,
                checkIsFetching: false,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
                shifts: updateShiftApplication(state, { ...action.check.application, isFetching: false }),
            };
        case taskConstants.CHECK_IN_SUCCESS:
        case taskConstants.CHECK_OUT_SUCCESS:
            return {
                ...state,
                checkIsFetching: false,
                check: action.check,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
                shifts: updateShiftApplication(state, { ...action.check.application, isFetching: false }),
            };

        case taskConstants.APPROVE_CHECK_STARTED:
            return {
                ...state,
                checkIsFetching: true,
                checks: updateChecks(state, { ...action.check, isFetching: true }),
            };
        case taskConstants.APPROVE_CHECK_FAILURE:
            return {
                ...state,
                checkIsFetching: false,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
            };
        case taskConstants.APPROVE_CHECK_SUCCESS:
            return {
                ...state,
                checkIsFetching: false,
                check: action.check,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
            };

        case taskConstants.CREATE_CHECK_DISPUTE_STARTED:
            return {
                ...state,
                checkIsFetching: true,
                checks: updateChecks(state, { ...action.check, isFetching: true }),
            };
        case taskConstants.CREATE_CHECK_DISPUTE_FAILURE:
            return {
                ...state,
                checkIsFetching: false,
                checks: updateChecks(state, { ...action.check, isFetching: true }),
            };
        case taskConstants.CREATE_CHECK_DISPUTE_SUCCESS:
            return {
                ...state,
                checkIsFetching: false,
                check: action.check,
                checks: updateChecks(state, { ...action.check, isFetching: false }),
            };

        default:
            return state;
    }
}

const updateShiftApplication = (state, newApplication) => {
    return [...state.shifts].map((shift) => {
        const application = shift.applications.find((application) => application.id === newApplication.id);
        if (application) {
            const newShift = { ...shift };
            newShift.applications = [...shift.applications].map((application) => {
                if (application.id === newApplication.id) {
                    return { ...newApplication };
                } else return application;
            });
            return newShift;
        } else return shift;
    });
};

const updateApplications = (state, newApplication) => {
    return [...state.applications].map((application) => {
        if (application.id === newApplication.id) {
            return { ...newApplication };
        } else return application;
    });
};

const updateChecks = (state, newCheck) => {
    return [...state.checks].map((check) => {
        if (check.id === newCheck.id) {
            return { ...newCheck };
        } else return check;
    });
};

const updateTasks = (state, newTask) => {
    return [...state.tasks].map((task) => {
        if (task.id === newTask.id) {
            return { ...newTask };
        } else return task;
    });
};
