import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import moment from 'moment';

import Loader from '../../ui/components/Loader';
import NoData from '../../ui/components/NoData';

import InvoiceView from './InvoiceView';
import { intToDouble } from '../../../utils/moneyUtils';

export default class InvoicesView extends Component {
    handleScroll = () => {
        const { invoicesLoaded, invoicesTotalResults, isFetchingInvoices } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (!isFetchingInvoices && invoicesTotalResults > invoicesLoaded) {
                this.fetch(invoicesLoaded, 20);
            }
        }
    };

    fetch = (offset, amount) => {
        const { getInvoices } = this.props;
        getInvoices(offset, amount);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.fetch(0, 20);
    }

    render() {
        const { t, user, profile, addToStack, invoices, isFetchingInvoices } = this.props;

        if (isFetchingInvoices) {
            return (
                <div className="invoices">
                    <Loader />
                </div>
            );
        }

        if (invoices.length > 0) {
            return (
                <div className="invoices">
                    {invoices.map((invoice) => {
                        return (
                            <div
                                className="invoice"
                                key={`invoice-${invoice.id}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: invoice.name,
                                        component: (
                                            <InvoiceView
                                                invoice={invoice}
                                                user={user}
                                                profile={profile}
                                                addToStack={addToStack}
                                            />
                                        ),
                                    });
                                }}
                            >
                                <div className="type">
                                    {invoice.targetProfile
                                        ? invoice.targetProfile.id === profile.id
                                            ? t('invoice.in')
                                            : t('invoice.out')
                                        : t('invoice.out')}
                                </div>
                                <div className="name">{invoice.name}</div>
                                <div className="amount">
                                    {invoice.total.currency.sign || '€'}
                                    {intToDouble(
                                        invoice.total.amount,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )}
                                </div>
                                <div className="date">{moment(invoice.dueAt).format('DD/MM/YYYY')}</div>
                                <div className="status">{invoice.status}</div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="not-found-action-box">
                <div className="icon">
                    <ReactSVG src="/icons/space.svg" />
                </div>
                <NoData>{t('wallet.invoices.notFound')}</NoData>
            </div>
        );
    }
}
