import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import RoleForm from "./RoleForm";

export default class UpdateRolePage extends Component {

    render() {
        const { t, formRole, isFetchingRole, updateRole } = this.props;

        if (!formRole || isFetchingRole) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("role.update")}</h1>
                <RoleForm
                    {...this.props}
                    key={formRole.id}
                    onSubmit={(role) => updateRole(role)}
                />
            </AsideView>
        );
    }
}
