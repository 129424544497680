import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { flatten } from "../../utils/arrayUtils";

import Messages from "../../components/chat/Messages";
import * as chatActions from "../../state/actions/chatActions";

class MessagesContainer extends Component {
    getChatUserByUserId = (id) => {
        const { chat } = this.props;
        return chat.users.find((user) => user.user.id === id);
    };

    render() {
        const { readMessages, getMessages, ...props } = this.props;

        const { chat, messages, user } = this.props;

        return (
            <Messages
                {...props}
                getMessages={(from, amount) => getMessages(chat.id, from, amount)}
                readMessages={() => {
                    const unreadMessages = flatten([...messages])
                        .filter(
                            (message) =>
                                message.read.filter((r) => r.user === this.getChatUserByUserId(user.id).id)
                                    .length < 1 && !message.isOffline
                        )
                        .map((message) => message.id);
                    if (unreadMessages.length > 0) readMessages(unreadMessages);
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        chat: state.chat.chat,
        messagesIsFetching: state.chat.messagesIsFetching,
        messages: state.chat.messages,
        messagesTotalResults: state.chat.messagesTotalResults,
        messagesLoaded: state.chat.messagesLoaded,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMessages: (chat, from, amount) =>
            dispatch(chatActions.getMessages(chat, from, amount)),
        readMessages: (messages) => dispatch(chatActions.readMessages(messages)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(MessagesContainer));
