import React, { Component } from "react";

export default class OverlayModal extends Component {
    render() {
        const { onClose, children, active } = this.props;
        return (
            <div className={active ? "overlay active" : "overlay"}>
                <div className="background" onClick={() => onClose && onClose()}></div>
                <div className="children">{children}</div>
            </div>
        );
    }
}
