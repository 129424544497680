import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import moment from 'moment';

import PopOver from '../../ui/components/PopOver';
import UpdateEducationContainer from '../../../containers/cv/education/UpdateEducationContainer';

export default class EducationCard extends Component {
    state = {
        popOvers: [],
    };

    render() {
        const { t, education, addToStack, profile, deleteEducation } = this.props;
        const { popOvers } = this.state;

        return (
            <div className="education">
                {popOvers.map((popOver) => popOver)}
                <div className="flex-container justify-between">
                    <div className="flex-container">
                        {education.schoolObject && <div></div>}
                        <div>
                            <h3 className="name">
                                {education.schoolObject ? education.schoolObject.name : education.school}
                            </h3>
                            <div className="info">
                                {education.title}, {education.fieldOfStudy}
                            </div>
                            <div className="timeline">
                                {moment(education.startYear).format('YYYY')} -{' '}
                                {education.endYear ? moment(education.endYear).format('YYYY') : t('presentDay')}
                            </div>
                            {education.activities && (
                                <div className="activities" style={{ whiteSpace: 'pre-wrap' }}>
                                    {education.activities}
                                </div>
                            )}
                            {education.description && (
                                <div className="description" style={{ whiteSpace: 'pre-wrap' }}>
                                    {education.description}
                                </div>
                            )}
                        </div>
                    </div>

                    {profile.isOwner && (
                        <div>
                            <div className="flex-container action-icons">
                                <div
                                    className="action-icon"
                                    onClick={(_) => {
                                        this.setState({
                                            popOvers: [
                                                ...popOvers,
                                                <PopOver
                                                    isActive={true}
                                                    className="select-pop-over"
                                                    onClose={() => {
                                                        this.setState({
                                                            popOvers: [],
                                                        });
                                                    }}
                                                >
                                                    <div className="box">
                                                        <div className="title">{t('cv.education.delete.header')}</div>
                                                        <div className="text">
                                                            {t('cv.education.delete.description')}
                                                        </div>

                                                        <div className="choice">
                                                            <button
                                                                href=""
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    this.setState({
                                                                        popOvers: [],
                                                                    });
                                                                }}
                                                            >
                                                                {t('cancel')}
                                                            </button>
                                                            <div
                                                                className="as-link"
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    this.setState({
                                                                        popOvers: [],
                                                                    });
                                                                    deleteEducation(education.id);
                                                                }}
                                                            >
                                                                {t('delete')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </PopOver>,
                                            ],
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/trash.svg" />
                                </div>
                                <div
                                    className="action-icon"
                                    onClick={(e) => {
                                        addToStack({
                                            name: t('cv.education.update.header'),
                                            component: <UpdateEducationContainer targetEducation={education} />,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/pencil.svg" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
