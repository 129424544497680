import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateCategoryPage from "../../../components/category/crud/UpdateCategoryPage";

import * as categoryActions from "../../../state/actions/categoryActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdateCategoryContainer extends Component {
    render() {
        const { changeValue, updateCategory, popStack, ...props } = this.props;

        return (
            <UpdateCategoryPage
                {...props}
                onChange={changeValue}
                updateCategory={(category) =>
                    updateCategory(category).then(() => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.category,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateCategory: (category) => dispatch(categoryActions.updateCategory(category)),
        changeValue: (name, value) => dispatch(categoryActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateCategoryContainer));
