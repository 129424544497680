import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; 

import ContactFormContainer from '../../containers/pages/crud/ContactFormContainer';

import { activateInputs } from '../../utils/formUtils';

class Contact extends Component {
    componentDidMount() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { t } = this.props; 

        return (
            <div className="wrapper contact">
                <div className="contact-info-container">
                    <div className="container">
                        <div className="contact-info">
                            <h2 className="top">{t("contact.header")}</h2>
                            <h3>{t("contact.text")}
                            </h3>
                            <ul>
                                <li>
                                    <b>{t("contact.name")}:</b> Vynwork
                                </li>
                                <li>
                                    <b>{t("contact.email")}:</b> <a href="mailto: info@vynwork.com">info@vynwork.com</a>
                                </li>
                                <li>
                                    <b>{t("contact.phone")}:</b> <a href="tel: +31649777284">+31649777284</a>
                                </li>

                                <li>
                                    <b>{t("contact.address")}:</b> Dierenriem 44, 7071TH, Ulft
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className=" contact">
                    <div className="container">
                        <div className="flex-container justify-center">
                            <div className="one">
                                <ContactFormContainer />
                            </div>
                        </div>
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2462.776329371453!2d6.3815541157838105!3d51.883295279698636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c78183b6099bf3%3A0xbf0bd7e01979c247!2sNo%20Name%20B.V.!5e0!3m2!1sen!2snl!4v1631646882283!5m2!1sen!2snl"
                        allowFullScreen
                        title="google"
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(Contact);