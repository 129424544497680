import React from 'react';
import Alert from '../models/Alert';
import { i18n, store } from '../index';
import * as navigationActions from '../state/actions/navigationActions';
import CreateSupportTicketContainer from '../containers/support/crud/CreateSupportTicketContainer';

export function handleError(error) {
    const response = error.response || error;
    if (!error && !response) return null;

    if (response.request && response.request.status === 401) {
        // Go to logout if an authentication error occured when not logged in
        if (response.data.isLoggedIn === false && window.location.href !== '/logout') {
            window.location.href = '/logout';
        } else {
            store.dispatch(
                navigationActions.createAlert(
                    new Alert(
                        i18n.t('notification.noAuthorization.error.title'),
                        i18n.t('notification.noAuthorization.error.description'),
                        'error'
                    )
                )
            );
        }
        return response.data;
    }

    if (response.request && response.request.status === 500) {
        store.dispatch(
            navigationActions.createAlert(
                new Alert(i18n.t('notification.error.title'), i18n.t('notification.error.description'), 'error')
            )
        );
        store.dispatch(
            navigationActions.addToStack({
                name: i18n.t('support.ticket.new.header'),
                component: <CreateSupportTicketContainer />,
            })
        );
    }
    return response.data;
}

export function handleResponse(response) {
    return response.data;
}
