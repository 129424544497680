import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import ArticlesPage from '../../components/article/ArticlesPage';

import * as articleActions from '../../state/actions/articleActions';
import * as navigationActions from '../../state/actions/navigationActions';

class ArticleContainer extends Component {
    render() {
        const { getArticles, updateArticleLogo, updateArticleBanner, ...props } = this.props;
        return <ArticlesPage {...props} getArticles={(offset, amount) => getArticles(offset, amount)} />;
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.article,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getArticles: (offset, amount) => dispatch(articleActions.getArticles(offset, amount)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ArticleContainer));
