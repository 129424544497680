import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import ExperienceForm from "./ExperienceForm";

export default class UpdateExperiencePage extends Component {
    render() {
        const { t, formExperience, isFetchingExperience, updateExperience, popStack } = this.props;

        if (isFetchingExperience || !formExperience) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("cv.experience.new.header")}</h1>
                <ExperienceForm
                    {...this.props}
                    onSubmit={(experience) =>
                        updateExperience(experience).then(() => {
                            popStack();
                        })
                    }
                />
            </AsideView>
        );
    }
}
