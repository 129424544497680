import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../../api/fileApi";

import * as chatActions from "../../../state/actions/chatActions";

import CreateMessageForm from "../../../components/chat/crud/CreateMessageForm";

class CreateMessageContainer extends Component {
    render() {
        const { changeValue, createMessage, ...props } = this.props;

        return (
            <CreateMessageForm
                {...props}
                onChange={changeValue}
                createMessage={(chat, message) => createMessage(chat, message)}
                hasValidCreationFields={() => true}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.chat,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(chatActions.changeChatValue(name, value)),
        createMessage: (chat, message) => dispatch(chatActions.createMessage(chat, message)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(CreateMessageContainer));
