import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../../api/fileApi";

import CreatePlatformPage from "../../../components/platform/crud/CreatePlatformPage.js";

import * as platformActions from "../../../state/actions/platformActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreatePlatformContainer extends Component {
    render() {
        const { changeValue, createPlatform, popStack, ...props } = this.props;

        return (
            <CreatePlatformPage
                {...props}
                onChange={changeValue}
                createPlatform={(platform) =>
                    createPlatform(platform).then((response) => {
                        popStack();
                    })
                }
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.platform,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createPlatform: (platform) => dispatch(platformActions.createPlatform(platform)),
        changeValue: (name, value) => dispatch(platformActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreatePlatformContainer));
