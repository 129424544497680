/* Dependencies */
import 'babel-polyfill';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import i18nInitiator from './i18n';

import configureStore from './state/store/configureStore.js';

import moment from 'moment-timezone';

import IndexContainer from './IndexContainer';
import WebSocketWrapper from './WebSocketWrapper';

/**
 *    The instance of Vynwork.
 **/
export const store = configureStore();
export const i18n = i18nInitiator;

export default class Vynwork extends Component {
    state = {
        csrf: '',
        timezone: moment.tz.guess(),
    };

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <WebSocketWrapper Page={() => <IndexContainer {...this.props} {...this.state} />} />
                </BrowserRouter>
            </Provider>
        );
    }
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration)  => {
    })
    .catch((err) => {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(<Vynwork />, document.getElementById('vynwork'));
