import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as ratingActions from "../../../../state/actions/ratingActions";
import * as navigationActions from "../../../../state/actions/navigationActions";

import CreateReviewPage from "../../../../components/rating/review/crud/CreateReviewPage";

class CreateReviewContainer extends Component {
    render() {
        const { changeValue, createReview, ...props } = this.props;

        return (
            <CreateReviewPage
                {...props}
                onChange={changeValue}
                createReview={(rating, review) => createReview(rating, review)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.rating,
        ...ownProps
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(ratingActions.changeValue(name, value)),
        createReview: (rating, review) => dispatch(ratingActions.createReview(rating, review)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateReviewContainer));
