import React, { Component } from "react";
import { Link } from "react-router-dom";

import Loader from "../ui/components/Loader";
import Rating from "../rating/Rating";

export default class TaskBusiness extends Component {
    render() {
        const { t, business, businessIsFetching } = this.props;

        if (business && !businessIsFetching) {
            return (
                <div className="side-profile highlighted">
                    <div className="picture">
                        <img
                            src={business.logo ? business.logo.url : "/images/business.svg"}
                            alt={business.logo ? business.logo.originalName : ""}
                        ></img>
                    </div>
                    <Link to={`/business/${business.id}`} className="name">{`${business.name}`}</Link>
                    <Rating rating={business.rating ? business.rating.totalRating || 0.0 : 0.0} small>
                        {t("rating.reviews.total", {
                            count: business.rating ? business.rating.totalReviews || 0 : 0,
                        })}
                    </Rating>
                </div>
            );
        }
        return <Loader />;
    }
}
