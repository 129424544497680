import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Select, { components } from 'react-select';

import moment from 'moment';

import { activateInputs, enableSwitchCheckboxes } from '../../../utils/formUtils';
import { base64ToBlob } from '../../../utils/imageUtils';
import { intToDouble } from '../../../utils/moneyUtils';

import Steps from '../../ui/components/Steps';
import NoData from '../../ui/components/NoData';
import Attachments from '../../ui/components/Attachments';
import CountrySelector from '../../ui/components/CountrySelector';

import TaskShiftsForm from './TaskShiftsForm';
import TaskKeywordsForm from './TaskKeywordsForm';
import TaskDescriptionsForm from './TaskDescriptionsForm';
import TaskBannerPicker from './TaskBannerPicker';

export default class TaskForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.bannerRef = React.createRef();

        this.state = {
            step: 0,
        };
    }

    componentDidMount() {
        activateInputs();
        enableSwitchCheckboxes();
    }

    componentDidUpdate() {
        activateInputs();
        enableSwitchCheckboxes();
    }

    handlePrevious(e) {
        e.preventDefault();
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { uploadFile, formTask, onSubmit } = this.props;

        this.bannerRef.current
            .getResult()
            .then((banner) => {
                uploadFile(base64ToBlob(banner), 'task').then((response) => {
                    const task = { ...formTask };

                    const banners = [...task.banners];
                    banners.push(response.file.id);
                    task.banners = banners;

                    onSubmit(task);
                });
            })
            .catch(() => {
                onSubmit(formTask);
            });
    }

    handleNext(e) {
        const { step } = this.state;
        e.preventDefault();
        if (step >= 3) {
            if (this.validator.allValid()) {
                this.handleSubmit(e);
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        } else {
            this.setState({
                step: step + 1,
            });
        }
    }

    _renderWhat(isActive) {
        const { onChange, t, formTask, categories, businesses, profile, platform } = this.props;
        const { Option } = components;

        const LoadingMessage = (props) => <NoData className="center">{t('loading')}</NoData>;
        const SelectOption = (props) => (
            <Option {...props} className="select-option">
                <img src={props.data.avatar} alt={props.data.label} />
                <span>{props.data.label}</span>
            </Option>
        );
        const SelectValue = (props) => (
            <div className="select-value">
                <img src={props.data.avatar} alt={props.data.label} />
                {props.data.label}
            </div>
        );

        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                {this._renderStepper()}
                <div className="input-container">
                    {!platform.businessOnly && (
                        <div className="input-group no-margin-top">
                            <>
                                <div className="input-group half">
                                    <input
                                        type="checkbox"
                                        id="personal"
                                        value="personal"
                                        defaultChecked
                                        checked={formTask.employerType === 'personal'}
                                        onClick={(event) => {
                                            const newTask = { ...formTask };
                                            newTask.employerType = event.target.checked ? 'personal' : 'business';
                                            onChange('formTask', newTask, event);
                                        }}
                                    />
                                    <label className="normal gray" htmlFor="personal">
                                        {t('form.label.personal')}
                                    </label>
                                </div>
                                <div className="input-group half">
                                    <input
                                        type="checkbox"
                                        id="business"
                                        value="business"
                                        checked={formTask.employerType === 'business'}
                                        onClick={(event) => {
                                            const newTask = { ...formTask };
                                            newTask.employerType = event.target.checked ? 'business' : 'personal';
                                            onChange('formTask', newTask, event);
                                        }}
                                    />
                                    <label className="normal gray" htmlFor="business">
                                        {t('form.label.business')}
                                    </label>
                                </div>
                            </>
                        </div>
                    )}
                    {(formTask.employerType === 'business' || platform.businessOnly) && (
                        <div className={`input-group ${platform.businessOnly ? 'no-margi-top' : ''}`}>
                            <Select
                                components={{
                                    LoadingMessage,
                                    Option: SelectOption,
                                    SingleValue: SelectValue,
                                }}
                                value={formTask.businessValue}
                                placeholder={t('form.label.selectBusiness')}
                                noOptionsMessage={() => t('businesses.noOptions')}
                                options={businesses.map((business) => ({
                                    label: business.name,
                                    value: business.id,
                                    avatar: business.logo ? business.logo.url : '/images/business.svg',
                                }))}
                                onChange={(event) => {
                                    const newTask = { ...formTask };
                                    newTask.business = event.value;
                                    newTask.businessValue = event;

                                    newTask.title = `${newTask.categoryValue ? newTask.categoryValue.label : ''} - ${
                                        formTask.employerType === 'business'
                                            ? newTask.businessValue
                                                ? newTask.businessValue.label || ''
                                                : ''
                                            : profile.name
                                    }`;
                                    onChange('formTask', newTask, event);
                                }}
                            />
                        </div>
                    )}
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <Select
                                placeholder={t(`form.label.selectCategory`)}
                                noOptionsMessage={() => t('categories.noOptions')}
                                options={categories.map((category) => ({
                                    label: t(`${category.name}`),
                                    category: category,
                                    value: category.id,
                                }))}
                                value={categories
                                    .map((category) => ({
                                        label: t(`${category.name}`),
                                        category: category,
                                        value: category.id,
                                    }))
                                    .filter(
                                        (category) =>
                                            category.value ===
                                            (formTask.category
                                                ? formTask.category.id
                                                    ? formTask.category.id
                                                    : formTask.category
                                                : '')
                                    )}
                                onChange={(event) => {
                                    const newTask = { ...formTask };
                                    newTask.category = event.value;
                                    newTask.categoryValue = event;

                                    newTask.descriptionParts = JSON.parse(
                                        JSON.stringify(event.category.descriptionParts)
                                    );
                                    newTask.keywords = JSON.parse(JSON.stringify(event.category.keywords));

                                    newTask.title = `${newTask.categoryValue ? newTask.categoryValue.label : ''} - ${
                                        formTask.employerType === 'business'
                                            ? newTask.businessValue
                                                ? newTask.businessValue.label || ''
                                                : ''
                                            : profile.name
                                    }`;
                                    onChange('formTask', newTask, event);
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <h1>{t('form.label.banner')}</h1>
                        <TaskBannerPicker {...this.props} ref={this.bannerRef} />
                    </div>
                    <div className="input-group">
                        <h1>{t('form.label.attachments')}</h1>
                        <Attachments
                            {...this.props}
                            onChange={(files) => {
                                const newTask = { ...formTask };
                                newTask.files = files.map((file) => file.externalReference.id);
                                onChange('formTask', newTask);
                            }}
                        />
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <textarea
                                value={formTask.description}
                                className="small"
                                onChange={(event) => {
                                    const newTask = { ...formTask };
                                    newTask.description = event.target.value;
                                    onChange('formTask', newTask, event);
                                }}
                            ></textarea>
                            <label>{t('form.label.generalShortDescription')}</label>
                        </div>
                    </div>
                    <div className="input-group more">
                        <TaskDescriptionsForm {...this.props} />
                    </div>
                    <div className="input-group more">
                        <TaskKeywordsForm {...this.props} />
                    </div>
                    <div className="input-group right more">
                        <input type="submit" onClick={(e) => this.handleNext(e)} value={t('next')} />
                    </div>
                </div>
            </form>
        );
    }

    _renderWhere(isActive) {
        const { onChange, t, formTask } = this.props;

        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)} key={formTask.id}>
                {this._renderStepper()}
                <div className="input-container">
                    <div className="input-group no-margin-top">
                        <div className="input-group eighty">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formTask.location.address.street}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        const location = { ...newTask.location };
                                        const address = { ...location.address };
                                        address.street = event.target.value;
                                        location.address = address;
                                        newTask.location = location;
                                        onChange('formTask', newTask, event);
                                    }}
                                />
                                <label>{t('form.label.street')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.street'),
                                formTask.location.address.street,
                                'required'
                            )}
                        </div>
                        <div className="input-group fifth">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formTask.location.address.houseNumber}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        const location = { ...newTask.location };
                                        const address = { ...location.address };
                                        address.houseNumber = event.target.value;
                                        location.address = address;
                                        newTask.location = location;
                                        onChange('formTask', newTask, event);
                                    }}
                                />
                                <label>{t('form.label.houseNumber')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.houseNumber'),
                                formTask.location.address.houseNumber,
                                'required'
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formTask.location.address.zipCode}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        const location = { ...newTask.location };
                                        const address = { ...location.address };
                                        address.zipCode = event.target.value;
                                        location.address = address;
                                        newTask.location = location;
                                        onChange('formTask', newTask, event);
                                    }}
                                />
                                <label>{t('form.label.zipCode')}*</label>
                            </div>
                            {this.validator.message(
                                t('form.label.zipCode'),
                                formTask.location.address.zipCode,
                                'required'
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formTask.location.address.city}
                                    onChange={(event) => {
                                        const newTask = { ...formTask };
                                        const location = { ...newTask.location };
                                        const address = { ...location.address };
                                        address.city = event.target.value;
                                        location.address = address;
                                        newTask.location = location;
                                        onChange('formTask', newTask, event);
                                    }}
                                />
                                <label>{t('form.label.city')}*</label>
                            </div>
                            {this.validator.message(t('form.label.city'), formTask.location.address.city, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <CountrySelector
                                    placeholder={`${t('form.label.selectCountry')}*`}
                                    value={formTask.location.address.countryCode}
                                    onChange={(value) => {
                                        const newTask = { ...formTask };
                                        const location = { ...newTask.location };
                                        const address = { ...location.address };
                                        address.country = value.label;
                                        address.countryCode = value.value;
                                        location.address = address;
                                        newTask.location = location;
                                        onChange('formTask', newTask);
                                    }}
                                />
                            </div>
                            {this.validator.message(
                                t('form.label.country'),
                                formTask.location.address.country,
                                'required'
                            )}
                        </div>
                    </div>
                    <div className="input-group more right">
                        <button type="button" className="back" onClick={(e) => this.handlePrevious(e)}>
                            {t('back')}
                        </button>
                        <input type="submit" onClick={(e) => this.handleNext(e)} value={t('next')} />
                    </div>
                </div>
            </form>
        );
    }

    _renderShifts(isActive) {
        const { t } = this.props;

        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                {this._renderStepper()}
                <TaskShiftsForm {...this.props} />
                <div className="input-group more right">
                    <button type="button" className="back" onClick={(e) => this.handlePrevious(e)}>
                        {t('back')}
                    </button>
                    <input
                        type="submit"
                        onClick={(e) => this.handleNext(e)}
                        value={t('next')}
                        disabled={!this.validator.allValid()}
                    />
                </div>
            </form>
        );
    }

    _renderConclusion(isActive) {
        const { formTask, platform, t, user } = this.props;
        const { address } = formTask.location;

        return (
            <form className={isActive ? 'form active' : 'form'} onSubmit={(e) => this.handleNext(e)}>
                {this._renderStepper()}
                <div className="input-container task-summary">
                    <h1>{formTask.title}</h1>
                    <h2>{`${address.street} ${address.houseNumber || ''}${address.houseNumberSuffix || ''}, ${
                        address.zipCode
                    } ${address.city || ''} `}</h2>{' '}
                    <p>{formTask.description}</p>
                    <div className="description-parts">
                        {formTask.descriptionParts.map((part) => (
                            <div>
                                <h2>{part.header}</h2>
                                <p>{part.body}</p>
                            </div>
                        ))}
                    </div>
                    <div className="keywords">
                        {formTask.keywords
                            .sort((a, b) => a.header - b.header)
                            .map((keyword) => (
                                <div className="keyword">
                                    <h2>{keyword.header}</h2>
                                    <div className="tags">
                                        {keyword.tags.map((tag) => (
                                            <div className="tag">{tag.name}</div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="shifts">
                        <h2>{t('task.shifts.header')}</h2>
                        {formTask.shifts.map((shift) => (
                            <div className="shift">
                                <div>{shift.amount}x</div>
                                <div className="date">
                                    <div className="">
                                        {moment(`${shift.date} ${shift.startTime}`, 'DD/MM/YYYY HH:mm').format(
                                            'dddd DD MMM'
                                        )}
                                    </div>
                                    <div className="">
                                        {moment(`${shift.date} ${shift.startTime}`, 'DD/MM/YYYY HH:mm').format('HH:mm')}
                                        -{moment(`${shift.date} ${shift.endTime}`, 'DD/MM/YYYY HH:mm').format('HH:mm')}
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="">{`${shift.payout.money.sign || '€'}${intToDouble(
                                        shift.payout.money.amount,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )} ${t('p/h')}`}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr />
                    <div className="flex-container justify-between">
                        <div>{t('task.summary.averageShiftCost')}</div>
                        <div>{`${formTask.shifts[0].payout.money.sign || '€'}${intToDouble(
                            formTask.shifts
                                .map(
                                    (shift) =>
                                        (shift.payout.money.amount / 60) *
                                        moment
                                            .duration(
                                                moment(`${shift.date} ${shift.endTime}`, 'DD/MM/YYYY HH:mm').diff(
                                                    moment(`${shift.date} ${shift.startTime}`, 'DD/MM/YYYY HH:mm')
                                                )
                                            )
                                            .asMinutes()
                                )
                                .reduce((a, b) => a + b, 0) / formTask.shifts.length,
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}`}</div>
                    </div>
                    <div className="flex-container justify-between">
                        <div>{t('task.summary.subTotal')}</div>
                        <div>{`${formTask.shifts[0].payout.money.sign || '€'}${intToDouble(
                            formTask.shifts
                                .map(
                                    (shift) =>
                                        ((shift.payout.money.amount * shift.amount) / 60) *
                                        moment
                                            .duration(
                                                moment(`${shift.date} ${shift.endTime}`, 'DD/MM/YYYY HH:mm').diff(
                                                    moment(`${shift.date} ${shift.startTime}`, 'DD/MM/YYYY HH:mm')
                                                )
                                            )
                                            .asMinutes()
                                )
                                .reduce((a, b) => a + b, 0),
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}`}</div>
                    </div>
                    <div className="flex-container justify-between">
                        <div>{t('task.summary.platformFee', { platformName: platform.name })}</div>
                        <div>{`${formTask.shifts[0].payout.money.sign || '€'}${intToDouble(
                            Math.round(
                                formTask.shifts
                                    .map(
                                        (shift) =>
                                            ((shift.payout.money.amount * shift.amount) / 60) *
                                            moment
                                                .duration(
                                                    moment(`${shift.date} ${shift.endTime}`, 'DD/MM/YYYY HH:mm').diff(
                                                        moment(`${shift.date} ${shift.startTime}`, 'DD/MM/YYYY HH:mm')
                                                    )
                                                )
                                                .asMinutes()
                                    )
                                    .reduce((a, b) => a + b, 0) *
                                    (parseFloat(platform.percentageFee) / 100)
                            ),
                            2,
                            user.language.decimalDelimiter,
                            user.language.thousandDelimiter
                        )}`}</div>
                    </div>
                    <div className="flex-container justify-between">
                        <div>
                            <b>{t('task.summary.total')}</b>
                        </div>
                        <div>
                            <b>{`${formTask.shifts[0].payout.money.sign || '€'}${intToDouble(
                                Math.round(
                                    formTask.shifts
                                        .map(
                                            (shift) =>
                                                ((shift.payout.money.amount * shift.amount) / 60) *
                                                moment
                                                    .duration(
                                                        moment(
                                                            `${shift.date} ${shift.endTime}`,
                                                            'DD/MM/YYYY HH:mm'
                                                        ).diff(
                                                            moment(
                                                                `${shift.date} ${shift.startTime}`,
                                                                'DD/MM/YYYY HH:mm'
                                                            )
                                                        )
                                                    )
                                                    .asMinutes()
                                        )
                                        .reduce((a, b) => a + b, 0) *
                                        (parseFloat(platform.percentageFee + 100) / 100)
                                ),
                                2,
                                user.language.decimalDelimiter,
                                user.language.thousandDelimiter
                            )}`}</b>
                        </div>
                    </div>
                    <div className="input-group more right">
                        <button type="button" className="back" onClick={(e) => this.handlePrevious(e)}>
                            {t('back')}
                        </button>
                        <input
                            type="submit"
                            disabled={!this.validator.allValid()}
                            onClick={(e) => this.handleNext(e)}
                            value={t('form.post')}
                        />
                    </div>
                </div>
            </form>
        );
    }

    _renderStepper() {
        const { t } = this.props;

        return (
            <Steps
                step={this.state.step}
                steps={[
                    { title: t('task.form.step1') },
                    { title: t('task.form.step2') },
                    { title: t('task.form.step3') },
                    { title: t('task.form.step4') },
                ]}
                onStepChange={(step) => this.setState({ step: step })}
            />
        );
    }

    render() {
        const { step } = this.state;
        return (
            <div className="step-form">
                {this._renderWhat(step === 0)}
                {this._renderWhere(step === 1)}
                {this._renderShifts(step === 2)}
                {this._renderConclusion(step === 3)}
            </div>
        );
    }
}
