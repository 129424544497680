
export default class Address {

    id;
    nonce; 

    street;
    houseNumber;
    zipCode;
    city;
    country; 
    
    constructor(){
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }
}