import api from "../api";

export const get = (id = "") => {
    return api.get(`/profile/${id}`);
};

export const getFromUser = (id = "") => {
    return api.get(`profile/user/${id}`);
};

export const updateCV = (cv) => {
    return api.put(`/profile/cv`, cv);
};

export const createProfile = (profile) => {
    return api.post(`/profile`, profile);
};

export const updateProfile = (profile) => {
    return api.put(`/profile`, profile);
};

export const updateProfileAvatar = (avatar) => {
    return api.put(`/profile/avatar`, { avatar: avatar });
};

export const updateProfileBanner = (banner) => {
    return api.put(`/profile/banner`, { banner: banner });
};

export const searchProfiles = (searchParameters = {}, from = 0, amount = 20) => {
    return api.post(`/profiles/${from}/${amount}`, searchParameters);
};

// Education CRUD
export const createEducation = (education) => {
    return api.post(`/profile/cv/education`, education);
};

export const updateEducation = (education) => {
    return api.put(`/profile/cv/education`, education);
};

export const deleteEducation = (id) => {
    return api.delete(`/profile/cv/education/${id}`);
};

// Experience CRUD
export const createExperience = (experience) => {
    return api.post(`/profile/cv/experience`, experience);
};

export const updateExperience = (experience) => {
    return api.put(`/profile/cv/experience`, experience);
};

export const deleteExperience = (id) => {
    return api.delete(`/profile/cv/experience/${id}`);
};
