import api from '../api';

export const get = (id = '') => {
    return api.get(`/article/${id}`);
};

export const create = (article) => {
    return api.post(`/article`, article);
};

export const update = (article) => {
    return api.put(`/article`, article);
};

export const latest = (from = 0, amount = 20) => {
    return api.get(`/articles/${from}/${amount}`);
};

export const userLatest = (user, from = 0, amount = 20) => {
    return api.get(`/articles/${user.id}/${from}/${amount}`);
};
export const search = (searchParameters = [], from = 0, amount = 20) => {
    return api.post(`/articles/search/${from}/${amount}`, searchParameters);
};
