import React, { Component } from 'react';
import moment from 'moment';
import ReactSVG from 'react-svg';

import { intToDouble } from '../../utils/moneyUtils';

import NoData from '../ui/components/NoData';

export default class TaskShifts extends Component {
    state = {
        selected: [],
    };

    handleScroll = () => {
        const { shiftsTotalResults, shiftsLoaded, onScrolledToBottom } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (shiftsTotalResults > shiftsLoaded) {
                onScrolledToBottom && onScrolledToBottom()
            }
        }
    };

    componentDidMount() {
        const { task, defaultSelected } = this.props;
        this.setState({
            selected: task.isOwner ? [] : defaultSelected || [],
        });

        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        const { task, defaultSelected } = this.props;
        const { selected } = this.state;
        if (!task.isOwner && defaultSelected && defaultSelected !== selected) {
            this.setState({
                selected: task.isOwner ? [] : defaultSelected || [],
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { t, task, onChange, defaultSelected, user, userProfile } = this.props;
        const { selected } = this.state;

        const shifts = task.shifts
            .filter(
                (shift) =>
                    !shift.applications
                        .filter((a) => a.taskApplicationStatus !== 'denied' && a.taskApplicationStatus !== 'cancelled')
                        .map((a) => a.applicant.id)
                        .includes(userProfile.id) || task.isOwner
            )
            .filter((shift) => moment(shift.startsAt).isAfter(moment()) || task.isOwner);

        if (shifts.length < 1) {
            return (
                <div className="not-found-action-box">
                    <div className="icon">
                        <ReactSVG src="/icons/space.svg" />
                    </div>
                    <NoData>{t('task.shifts.notFound')}</NoData>
                </div>
            );
        }
        return (
            <div>
                {shifts
                    .sort((a, b) => moment(a.startsAt).diff(moment(b.startsAt)))
                    .map((shift) => (
                        <div key={shift.id}>
                            <div
                                className={`shift ${
                                    selected.map((selectedShift) => selectedShift.id).includes(shift.id)
                                        ? 'selected'
                                        : ''
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();

                                    let selectedShifts = [...selected];
                                    if (selectedShifts.map((shift) => shift.id).includes(shift.id)) {
                                        selectedShifts = selectedShifts.filter(
                                            (selectedShift) => selectedShift.id !== shift.id
                                        );
                                    } else selectedShifts.push(shift);

                                    if (!defaultSelected) {
                                        this.setState({
                                            selected: selectedShifts,
                                        });
                                    }

                                    onChange && onChange(selectedShifts);
                                }}
                            >
                                {task.isOwner ? null : (
                                    <div className="select-box">
                                        <ReactSVG src="/icons/checkmark.svg" />
                                    </div>
                                )}
                                <div className="info">
                                    <div className="date">
                                        <div className="">{moment(shift.startsAt).format('dddd DD MMM')}</div>
                                        <div className="">
                                            {moment(shift.startsAt).format('HH:mm')}-
                                            {moment(shift.endsAt).format('HH:mm')}
                                        </div>
                                    </div>
                                    <div className="price">{`${shift.payout.money.currency.sign}${intToDouble(
                                        shift.payout.money.amount,
                                        2,
                                        user.language.decimalDelimiter,
                                        user.language.thousandDelimiter
                                    )} ${t('p/h')}`}</div>
                                    <div className="positions">
                                        <div className="">{t('task.shift.openPosition', { count: shift.amount })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
