import React, { Component } from 'react';

import Loader from '../ui/components/Loader';
import { intToDouble } from '../../utils/moneyUtils';

export default class WalletView extends Component {
    componentDidMount() {
        const { getUserWallet } = this.props;
        getUserWallet();
    }

    render() {
        const { wallet, isFetchingWallet, user } = this.props;

        if (!isFetchingWallet && wallet) {
            const { balance } = wallet;
            const { amount, currency } = balance;

            return (
                <div>
                    <div className="value">
                        {currency.sign}
                        {intToDouble(amount, 2, user.language.decimalDelimiter, user.language.thousandDelimiter)}
                    </div>
                    {currency.name}
                </div>
            );
        }
        return <Loader />;
    }
}
