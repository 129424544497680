import Money from '../wallet/Money';

export default class TaskPayout {
    
    id;
    nonce;

    money;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);

        this.money = new Money();
    }
}