import { chatConstants } from "../constants";
import * as chatApi from "../../api/chatApi";

/**
 * Change chat values
 */
export const changeChatValue = (name, value) => ({
    type: chatConstants.CHAT_CHANGE_VALUE,
    name,
    value,
});

/**
 * Creating chat
 */
export const startCreateChat = () => ({
    type: chatConstants.CREATE_CHAT_STARTED,
});

export const createChatSuccess = () => ({
    type: chatConstants.CREATE_CHAT_SUCCESS,
});

export const createChatFailure = () => ({
    type: chatConstants.CREATE_CHAT_FAILURE,
});

export const createChat = (chat, message) => {
    return (dispatch) => {
        dispatch(startCreateChat());

        const promise = chatApi.create(chat, message);

        promise
            .then((result) => {
                dispatch(createChatSuccess(result));
            })
            .catch((error) => {
                dispatch(createChatFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching chat
 */
export const startGetChat = () => ({
    type: chatConstants.GET_CHAT_STARTED,
});

export const getChatSuccess = (result) => ({
    type: chatConstants.GET_CHAT_SUCCESS,
    ...result,
});

export const getChatFailure = (error) => ({
    type: chatConstants.GET_CHAT_FAILURE,
    ...error,
});

export const getChat = (id) => {
    return (dispatch) => {
        dispatch(startGetChat());

        const promise = chatApi.get(id);

        promise
            .then((result) => {
                dispatch(getChatSuccess(result));
            })
            .catch((error) => {
                dispatch(getChatFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching chats
 */
export const startGetChats = () => ({
    type: chatConstants.GET_CHATS_STARTED,
});

export const getChatsSuccess = (result, from) => ({
    type: chatConstants.GET_CHATS_SUCCESS,
    ...result,
    from,
});

export const getChatsFailure = (error) => ({
    type: chatConstants.GET_CHATS_FAILURE,
    ...error,
});

export const getChats = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChats());

        const promise = chatApi.getChats(from, amount);

        promise
            .then((result) => {
                dispatch(getChatsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChatsFailure(error));
            });

        return promise;
    };
};

export const searchChats = (searchParameters, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetChats());

        const promise = chatApi.searchChats(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getChatsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getChatsFailure(error));
            });

        return promise;
    };
};

/**
 * Creating message
 */
export const startMessageCreate = (message) => ({
    type: chatConstants.CREATE_MESSAGE_STARTED,
    message,
});

export const createMessageSuccess = (result) => ({
    type: chatConstants.CREATE_MESSAGE_SUCCESS,
    ...result,
});

export const createMessageFailure = (error) => ({
    type: chatConstants.CREATE_MESSAGE_FAILURE,
    ...error,
});

export const createMessage = (chat, message) => {
    return (dispatch) => {
        dispatch(startMessageCreate(message));

        const promise = chatApi.createMessage(chat, message);

        promise
            .then((result) => {
                dispatch(createMessageSuccess(result));
            })
            .catch((error) => {
                dispatch(createMessageFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching messages
 */
export const startGetMessages = () => ({
    type: chatConstants.GET_MESSAGES_STARTED,
});

export const getMessagesSuccess = (result, from = 0) => ({
    type: chatConstants.GET_MESSAGES_SUCCESS,
    ...result,
    from,
});

export const getMessagesFailure = (error) => ({
    type: chatConstants.GET_MESSAGES_FAILURE,
    ...error,
});

export const getMessages = (id, from, amount) => {
    return (dispatch) => {
        dispatch(startGetMessages());

        const promise = chatApi.getMessages(id, from, amount);

        promise
            .then((result) => {
                dispatch(getMessagesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getMessagesFailure(error));
            });

        return promise;
    };
};

/**
    Reading messages
*/
export const startReadingMessages = () => ({
    type: chatConstants.READ_MESSAGES_STARTED,
});

export const readingMessagesSuccess = (result) => ({
    type: chatConstants.READ_MESSAGES_SUCCESS,
    ...result,
});

export const readingMessagesFailure = (error) => ({
    type: chatConstants.READ_MESSAGES_FAILURE,
    ...error,
});

export const readMessages = (messages) => {
    return (dispatch) => {
        dispatch(startReadingMessages());

        const promise = chatApi.readMessages(messages);

        promise
            .then((result) => {
                dispatch(readingMessagesSuccess(result));
            })
            .catch((error) => {
                dispatch(readingMessagesFailure(error));
            });

        return promise;
    };
};
