import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import { activateInputs } from "../../../utils/formUtils";

export default class CreateCheckDisputePage extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount = () => {
        activateInputs();
    };

    componentDidUpdate = () => {
        activateInputs();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { createCheckDispute, formCheckDispute } = this.props;

        createCheckDispute && createCheckDispute(formCheckDispute);
    };

    render() {
        const { t, onChange, isFetchingCheck, formCheckDispute } = this.props;

        if (!formCheckDispute || isFetchingCheck) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.check.dispute.new")}</h1>
                <div className="step-form">
                    <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="input-container">
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <textarea
                                        type="text"
                                        value={formCheckDispute.denyReason}
                                        onChange={(event) => {
                                            const newCheckDispute = { ...formCheckDispute };
                                            newCheckDispute.disputeReason = event.target.value;

                                            onChange("formCheckDispute", newCheckDispute, event);
                                        }}
                                    ></textarea>
                                    <label>{t("form.label.disputeReason")}*</label>
                                </div>
                                {this.validator.message(
                                    t("form.label.dispute"),
                                    formCheckDispute.disputeReason,
                                    "required"
                                )}
                            </div>
                            <div className="input-group more right">
                                <input
                                    type="submit"
                                    disabled={!this.validator.allValid()}
                                    onClick={(e) => e.stopPropagation()}
                                    value={t("form.send")}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </AsideView>
        );
    }
}
