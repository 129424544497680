import React, { Component } from "react";

export default class PersonInformation extends Component {
    render() {
        const { profile, subject } = this.props;
        const { person } = profile;
        const { address } = person;

        return (
            <>
                <div className="name">{`${profile.person.firstName} ${profile.person.lastName}`}</div>
                <div className="address-top">{`${address.street || ""} ${address.houseNumber || ""}${
                    address.houseNumberAddition || ""
                }`}</div>
                <div className="address-bottom">{`${address.zipCode || ""},  ${address.city || ""}, ${
                    address.country || ""
                }`}</div>
                {subject && profile.user.email ? <div className="email">{profile.user.email}</div> : null}
                {subject && profile.person.phoneNumber ? (
                    <div className="phoneNumber">{profile.person.phoneNumber}</div>
                ) : null}
                {subject ? <div className="iban"></div> : null}
            </>
        );
    }
}
