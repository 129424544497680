import React, { Component } from 'react';

export default class Avatar extends Component {
    getInitials(name) {
        const parts = name.split(' ');
        if (parts.length > 1) {
            if (parts[0].length > 0 && parts[1].length > 0) {
                return `${parts[0][0]}${parts[1][0]}`;
            }
        }

        if (parts[0].length > 1) {
            return `${parts[0][0]}${parts[0][1]}`;
        }

        if (parts[0].length > 0) {
            return `${parts[0][0]}`;
        }
        return '';
    }

    render() {
        const { imageUrl, name } = this.props;

        return <div className="avatar" alt={this.getInitials(name) || ""}>{imageUrl ? <img src={`${imageUrl}`} alt={this.getInitials(name) || ""}/> : this.getInitials(name)}</div>;
    }
}
