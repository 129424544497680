import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import TaskShiftView from '../../components/task/TaskShiftView';

import * as taskActions from '../../state/actions/taskActions';
import * as navigationActions from '../../state/actions/navigationActions';

class TaskShiftViewContainer extends Component {
    componentDidMount() {
        const { changeValue, targetShift } = this.props;

        if (targetShift) {
            changeValue('shift', targetShift);
        }
    }
    render() {
        const { getShift, getUserProfile, acceptApplication, ...props } = this.props;
        const { popStack } = this.props;

        return (
            <TaskShiftView
                {...props}
                getShift={(id) => getShift(id)}
                acceptApplication={(shift, application) => {
                    const newApplication = { ...application };
                    newApplication.task = shift.task.id;
                    newApplication.shift = shift.id;

                    acceptApplication(newApplication);
                    popStack();
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        platform: state.platform.platform,
        ...state.task,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(taskActions.changeCreateTask(name, value)),
        getShift: (id) => dispatch(taskActions.getShift(id)),
              checkIn: (check) => dispatch(taskActions.checkIn(check)),
        checkOut: (check) => dispatch(taskActions.checkOut(check)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),

        acceptApplication: (application) => dispatch(taskActions.acceptApplication(application)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TaskShiftViewContainer));
