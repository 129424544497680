import React, { Component } from "react";
import moment from "moment";

export default class ArticleCard extends Component {

    render() {
        const { article } = this.props;
        
        return (
            <div className="article">
                
                <div className="article__title">{article.title}</div>
                <div className="article__createdAt">{moment(article.createdAt).format("HH:mm DD/MM/YYYY")}</div>
                <div className="article__body">{article.body}</div>
            </div>
        )
    }
}