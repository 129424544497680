import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Footer from "../../components/footer/Footer.js";

class FooterContainer extends Component {
    render() {
        const { ...props } = this.props;

        return <Footer {...props} />;
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        platform: state.platform.platform,
        languages: state.platform.languages
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(FooterContainer));