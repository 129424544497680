import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../../api/fileApi";

import UpdateBusinessPage from "../../../components/business/crud/UpdateBusinessPage";

import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class UpdateBusinessContainer extends Component {

    componentDidMount() {
        const { changeValue, overrideBusiness } = this.props;
        changeValue("formBusiness", overrideBusiness)
    }
    render() {
        const { changeValue, updateBusiness, popStack, ...props } = this.props;

        return (
            <UpdateBusinessPage
                {...props}
                popStack={popStack}
                onChange={changeValue}
                updateBusiness={(business) => updateBusiness(business)}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        platform: state.platform.platform,
        industries: state.platform.industries,
        ...state.business
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(businessActions.changeUpdateBusiness(name, value)),
        updateBusiness: (business) => dispatch(businessActions.updateBusiness(business)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateBusinessContainer));
