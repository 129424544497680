import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../../../utils/formUtils";

export default class ReviewForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, rating, formReview } = this.props;

        onSubmit && onSubmit(rating, formReview);
    }

    updateReview = (newReview) => {
        const { onChange } = this.props;

        onChange("formReview", newReview);
    };

    render() {
        const { t, question, formReview } = this.props;

        return (
            <div className="step-form">
                <form className="form active no-padding" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        {question && <h2 className="center">{question}</h2>}
                        <div className="input-group no-margin-top">
                            <div className="input-group no-margin-top">
                                <div className="rating">
                                    <input
                                        type="radio"
                                        id="star5"
                                        name="rating"
                                        value="5"
                                        checked={formReview.rate === 5}
                                        onChange={(e) => {
                                            const newReview = { ...formReview };
                                            newReview.rate = 5;
                                            this.updateReview(newReview);
                                        }}
                                    />
                                    <label class="full" htmlFor="star5"></label>
                                    <input
                                        type="radio"
                                        id="star4"
                                        name="rating"
                                        value="4"
                                        checked={formReview.rate === 4}
                                        onChange={(e) => {
                                            const newReview = { ...formReview };
                                            newReview.rate = 4;
                                            this.updateReview(newReview);
                                        }}
                                    />
                                    <label class="full" htmlFor="star4"></label>
                                    <input
                                        type="radio"
                                        id="star3"
                                        name="rating"
                                        value="3"
                                        checked={formReview.rate === 3}
                                        onChange={(e) => {
                                            const newReview = { ...formReview };
                                            newReview.rate = 3;
                                            this.updateReview(newReview);
                                        }}
                                    />
                                    <label class="full" htmlFor="star3"></label>
                                    <input
                                        type="radio"
                                        id="star2"
                                        name="rating"
                                        value="2"
                                        checked={formReview.rate === 2}
                                        onChange={(e) => {
                                            const newReview = { ...formReview };
                                            newReview.rate = 2;
                                            this.updateReview(newReview);
                                        }}
                                    />
                                    <label class="full" htmlFor="star2"></label>
                                    <input
                                        type="radio"
                                        id="star1"
                                        name="rating"
                                        value="1"
                                        checked={formReview.rate === 1}
                                        onChange={(e) => {
                                            const newReview = { ...formReview };
                                            newReview.rate = 1;
                                            this.updateReview(newReview);
                                        }}
                                    />
                                    <label class="full" htmlFor="star1"></label>
                                </div>
                                {this.validator.message(t("form.label.rate"), formReview.rate, "required")}
                            </div>
                        </div>
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <textarea
                                    className="small"
                                    value={formReview.description}
                                    onChange={(event) => {
                                        const newReview = { ...formReview };
                                        newReview.description = event.target.value;
                                        this.updateReview(newReview);
                                    }}
                                ></textarea>
                                <label>{t("form.label.describeExperience")}</label>
                            </div>
                            {this.validator.message(
                                t("form.label.describeExperience"),
                                formReview.description,
                                "string"
                            )}
                        </div>

                        <div className="input-group more right">
                            <input
                                type="submit"
                                disabled={!this.validator.allValid()}
                                onClick={(e) => e.stopPropagation()}
                                value={t("form.save")}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
