import React, { Component } from "react";
import Loader from "../ui/components/Loader";

export default class ConfirmEmailPage extends Component {
    componentDidMount() {
        const { onLoad } = this.props;
        onLoad();
    }

    render() {
        return (
            <div className="fullpage">
                <div className="absolute-center">
                    <Loader />
                </div>
            </div>
        );
    }
}
