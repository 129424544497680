import React, { Component } from 'react';
import moment from 'moment';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import Loader from '../ui/components/Loader';
import NoData from '../ui/components/NoData';
import FilePreview from '../ui/components/FilePreview';
import FileViewer from '../ui/components/FileViewer';

import SupportTicketRequestsContainer from '../../containers/support/SupportTicketRequestsContainer';
import SupportTicketErrorsContainer from '../../containers/support/SupportTicketErrorsContainer';

import CreateSupportTicketActionContainer from '../../containers/support/crud/CreateSupportTicketActionContainer';
import SupportTicketActions from './SupportTicketActions';

export default class SupportTicketPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeRequest: null,
            activeError: null,
        };

        this.searchTimeout = null;
    }

    componentDidMount() {
        const { match, fetchTicket } = this.props;
        console.log(this)
        fetchTicket(match.params.id || '', match.params.token || '');
    }

    render() {
        const {
            t,
            user,
            isFetchingSupportTicket,
            supportTicket,
            addToStack,
            match,
            onChange,
            updateTicket,
            searchUsers,
        } = this.props;
        const { filePreviewOpen, files, focussedFile } = this.state;

        if (!supportTicket || (isFetchingSupportTicket && supportTicket.id !== match.params.id)) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        const convertMinsToHrsMins = (minutes) => {
            var h = Math.floor(minutes / 60);
            var m = minutes % 60;
            h = h < 10 ? '0' + h : h;
            m = m < 10 ? '0' + m : m;
            return h + ':' + m;
        };

        const priorityOptions = [
            { value: 0, valueLabel: 'LOW', label: t('support.ticket.priority.low') },
            {
                value: 1,
                valueLabel: 'NORMAL',
                label: t('support.ticket.priority.normal'),
            },
            {
                value: 2,
                valueLabel: 'HIGH',
                label: t('support.ticket.priority.high'),
            },
            {
                value: 3,
                valueLabel: 'URGENT',
                label: t('support.ticket.priority.urgent'),
            },
        ];

        const statusOptions = [
            {
                value: 0,
                valueLabel: 'OPEN',
                label: t('support.ticket.status.open'),
            },
            {
                value: 1,
                valueLabel: 'IN_PROGRESS',
                label: t('support.ticket.status.inProgress'),
            },
            {
                value: 2,
                valueLabel: 'WAITING',
                label: t('support.ticket.status.waiting'),
            },
            {
                value: 3,
                valueLabel: 'CLOSED',
                label: t('support.ticket.status.closed'),
            },
        ];

        const supportAdmin = user && user.role.level >= 800;

        return (
            <div className="wrapper top">
                <FileViewer
                    t={t}
                    isActive={filePreviewOpen}
                    files={files}
                    focussedFile={focussedFile}
                    onClose={() => {
                        this.setState({
                            files: [],
                            focussedFile: null,
                            filePreviewOpen: false,
                        });
                    }}
                />
                <div className="container support-ticket flex-container">
                    {supportAdmin && (
                        <div className="one content-block">
                            <h2>{t('support.ticket.userInformation')}</h2>
                            <div className="info-block">
                                <div className="name">{t('support.ticket.user.name')}</div>
                                <div className="value">
                                    {supportTicket.firstName} {supportTicket.lastName}
                                </div>
                            </div>
                            {supportTicket.user && (
                                <div className="info-block">
                                    <div className="name">{t('support.ticket.user.username')}</div>
                                    <div className="value">{supportTicket.user.username}</div>
                                </div>
                            )}
                            <div className="info-block">
                                <div className="name">{t('support.ticket.user.email')}</div>
                                <div className="value">
                                    <a href={`mailto:${supportTicket.email}`}>{supportTicket.email}</a>
                                </div>
                            </div>
                            <div className="info-block">
                                <div className="name">{t('support.ticket.user.phone')}</div>
                                <div className="value">
                                    <a href={`tel:${supportTicket.phoneNumber}`}>{supportTicket.phoneNumber}</a>
                                </div>
                            </div>
                            {supportTicket.user && (
                                <div className="info-block">
                                    <div className="name">{t('support.ticket.user.role')}</div>
                                    <div className="value">{supportTicket.user.role.name}</div>
                                </div>
                            )}
                            <hr />
                            <h2>{t('support.ticket.ticketInformation')}</h2>
                            <div className="info-block">
                                <div className="name">{t('support.ticket.spentTime')}</div>
                                <div className="value">
                                    {convertMinsToHrsMins(
                                        supportTicket.actions.length > 0
                                            ? supportTicket.actions
                                                  .map((action) => action.timeSpent)
                                                  .reduce((a, b) => a + b)
                                            : 0
                                    )}{' '}
                                    {t('support.ticket.hoursSpent')}
                                </div>
                            </div>
                            <div className="info-block">
                                <div className="name">{t('support.ticket.ticketOpenTime')}</div>
                                <div className="value">{moment().diff(moment(supportTicket.createdAt), 'days')}</div>
                            </div>
                            <hr />
                            <h2>{t('support.ticket.platformInformation')}</h2>
                            <div className="info-block">
                                <div className="name">{t('support.ticket.platformNames')}</div>
                                <div className="value">
                                    {supportTicket.platforms
                                        ? supportTicket.platforms.map((platform) => platform.name).join(', ')
                                        : t('support.ticket.noPlatformNames')}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="three main">
                        <div className="content-block flex-container column noWrap middle">
                            <div className="title">
                                <div className="flex-container justify-between">
                                    <h1>{supportTicket.subject}</h1>
                                    <h3>{supportTicket.priority}</h3>
                                </div>
                                <h6>#{supportTicket.id}</h6>
                            </div>
                            <div className="flex-container">
                                <div className="one">
                                    <div className="subtitle">
                                        <b>{t('support.ticket.createdAt')}</b>{' '}
                                        {moment(supportTicket.createdAt).format('DD/MM/YY HH:mm')}
                                    </div>
                                    <div className="subtitle">
                                        <b>{t('support.ticket.updatedAt')}</b>{' '}
                                        {moment(supportTicket.updatedAt).format('DD/MM/YY HH:mm')}
                                    </div>
                                    <div className="subtitle">
                                        <b>{t('support.ticket.plannedAt')}</b>{' '}
                                        {supportAdmin ? (
                                            <div className="form no-padding input-group no-margin-top">
                                                <DayPickerInput
                                                    format="DD/MM/YYYY"
                                                    value={moment(supportTicket.planned).format('DD/MM/YYYY')}
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    dayPickerProps={{ disabledDays: { before: new Date() } }}
                                                    onDayChange={(day) => {
                                                        const newSupportTicket = { ...supportTicket };
                                                        newSupportTicket.planned = moment(day).toISOString();
                                                        onChange('supportTicket', newSupportTicket);
                                                        updateTicket(newSupportTicket);
                                                    }}
                                                    component={(props) => (
                                                        <div>
                                                            <input
                                                                {...props}
                                                                className={
                                                                    props.isActive || (props.value && 'has-value')
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        ) : supportTicket.planned ? (
                                            moment(supportTicket.planned).format('DD/MM/YYYY')
                                        ) : (
                                            t('support.ticket.notPlanned')
                                        )}
                                    </div>
                                </div>
                                <div className="one">
                                    <div className="subtitle">
                                        <b>{t('support.ticket.assignedTo')}</b>{' '}
                                        {supportAdmin ? (
                                            <div className="form no-padding input-group no-margin-top">
                                                <AsyncSelect
                                                    isMulti={false}
                                                    noOptionsMessage={() => t('users.notFound')}
                                                    placeholder={t('form.label.selectAssignee')}
                                                    loadingMessage={() => t('loading')}
                                                    value={
                                                        supportTicket.assignedTo
                                                            ? {
                                                                  value: supportTicket.assignedTo.id,
                                                                  label: supportTicket.assignedTo.username,
                                                              }
                                                            : null
                                                    }
                                                    loadOptions={(inputValue, callback) => {
                                                        if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                                        this.searchTimeout = setTimeout(() => {
                                                            searchUsers(
                                                                {
                                                                    query: inputValue,
                                                                    role: 800,
                                                                },
                                                                0,
                                                                20
                                                            ).then((response) => {
                                                                callback(
                                                                    response.users.map((user) => {
                                                                        return {
                                                                            value: user.id,
                                                                            label: user.username,
                                                                        };
                                                                    })
                                                                );
                                                            });
                                                        }, 800);
                                                    }}
                                                    onChange={(e) => {
                                                        const newSupportTicket = { ...supportTicket };
                                                        newSupportTicket.assignedTo = e.value;
                                                        onChange('supportTicket', newSupportTicket, e);
                                                        updateTicket(newSupportTicket);
                                                    }}
                                                />
                                            </div>
                                        ) : supportTicket.assignedTo ? (
                                            supportTicket.assignedTo.username
                                        ) : (
                                            t('support.ticket.nobody')
                                        )}
                                    </div>
                                    <div className="subtitle">
                                        <b>{t('support.ticket.priority')}: </b>
                                        {supportAdmin ? (
                                            <div className="form no-padding input-group no-margin-top">
                                                <Select
                                                    placeholder={`${t('form.label.selectSupportPriority')}*`}
                                                    value={priorityOptions.find(
                                                        (option) => option.valueLabel === supportTicket.priority
                                                    )}
                                                    options={priorityOptions}
                                                    onChange={(e) => {
                                                        const newSupportTicket = { ...supportTicket };
                                                        newSupportTicket.priority = e.valueLabel;
                                                        onChange('supportTicket', newSupportTicket, e);
                                                        updateTicket(newSupportTicket);
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            supportTicket.priority
                                        )}
                                    </div>
                                    <div className="subtitle">
                                        <b>{t('support.ticket.status')}: </b>
                                        {supportAdmin ? (
                                            <div className="form no-padding input-group no-margin-top">
                                                <Select
                                                    placeholder={`${t('form.label.selectSupportPriority')}*`}
                                                    value={statusOptions.find(
                                                        (option) => option.valueLabel === supportTicket.status
                                                    )}
                                                    options={statusOptions}
                                                    onChange={(e) => {
                                                        const newSupportTicket = { ...supportTicket };
                                                        newSupportTicket.status = e.valueLabel;
                                                        onChange('supportTicket', newSupportTicket, e);
                                                        updateTicket(newSupportTicket);
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            supportTicket.status
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2>{t('support.ticket.description')}</h2>
                                <div className="message scrollable">{supportTicket.message}</div>
                            </div>
                            <div>
                                <h2>{t('support.ticket.attachments')}</h2>
                                <div className="attachments">
                                    {supportTicket.attachments.length > 0 ? (
                                        supportTicket.attachments.map((attachment) => (
                                            <div
                                                className="attachment"
                                                onClick={() => {
                                                    this.setState({
                                                        filePreviewOpen: true,
                                                        files: supportTicket.attachments,
                                                        focussedFile: attachment,
                                                    });
                                                }}
                                            >
                                                <FilePreview file={attachment} />
                                            </div>
                                        ))
                                    ) : (
                                        <NoData className="left">{t('support.ticket.attachments.notFound')}</NoData>
                                    )}
                                </div>
                            </div>
                            <div>
                                <h2>{t('support.actions.header')}</h2>
                                <SupportTicketActions actions={supportTicket.actions} />
                            </div>
                        </div>
                        <button
                            className="new-support-ticket"
                            onClick={() => {
                                addToStack({
                                    name: t('support.action.new.header'),
                                    component: <CreateSupportTicketActionContainer history={this.props.history} match={match} />,
                                });
                            }}
                        >
                            {t('support.action.new')}
                        </button>
                    </div>
                    {supportAdmin && (
                        <div className="one content-block request-info">
                            <div className="one">
                                <h2>{t('support.requests.header')}</h2>
                                <SupportTicketRequestsContainer />
                            </div>
                            <div className="one">
                                <h2>{t('support.errors.header')}</h2>
                                <SupportTicketErrorsContainer />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
