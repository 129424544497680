import React, { Component } from "react";
import moment from "moment";

import { activateInputs } from "../../../utils/formUtils";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import ShiftForm from "./ShiftForm";

export default class UpdateShiftPage extends Component {
    componentDidMount() {
        const { onChange, targetShift } = this.props;

        onChange("formShift", targetShift);
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const { t, onChange, formShift, shiftIsFetching, updateShift } = this.props;

        if (!formShift || shiftIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.shift.update")}</h1>
                <h2>
                    {t("task.shift.update.description", {
                        taskName: formShift.task ? formShift.task.title : "",
                    })}
                </h2>
                <div className="step-form">
                    <form
                        className={"form active"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateShift(formShift);
                        }}
                    >
                        <ShiftForm
                            {...this.props}
                            shift={formShift}
                            onChange={(formShift) => {
                                const shift = { ...formShift };
                                shift.startsAt = moment(
                                    `${shift.date} ${shift.startTime}`,
                                    "DD/MM/YYYY HH:mm"
                                );
                                shift.endsAt = moment(`${shift.date} ${shift.endTime}`, "DD/MM/YYYY HH:mm");
                                onChange("formShift", shift);
                            }}
                        />
                        <div className="input-group more right">
                            <input
                                type="submit"
                                value={t("form.save")}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </form>
                </div>
            </AsideView>
        );
    }
}
