import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from '../ui/components/Loader';
import FilePreview from '../ui/components/FilePreview';
import FileViewer from '../ui/components/FileViewer';
import NoData from '../ui/components/NoData';

export default class Messages extends Component {
    constructor(props) {
        super(props);

        this.messageListRef = null;
        this.scrollListener = React.createRef();

        this.state = {
            isAtBottom: true,
            filePreviewOpen: false,
            fileList: [],
            fileFocus: 0,
        };
    }

    getChatUser = (id) => {
        const { chat } = this.props;
        return chat.users.find((user) => user.id === id);
    };

    onScroll = (e) => {
        const scrollHeight = this.messageListRef.scrollHeight;
        const height = this.messageListRef.clientHeight;
        const maxScrollTop = scrollHeight - height;
        const { getMessages, messagesLoaded, messagesIsFetching, messagesTotalResults } = this.props;

        if (e.target.scrollTop < 10 && !messagesIsFetching && messagesLoaded < messagesTotalResults) {
            getMessages(messagesLoaded, messagesLoaded + 20);
        }
        if (e.target.scrollTop !== maxScrollTop && this.state.isAtBottom) {
            this.setState({ isAtBottom: false });
        }
    };

    componentDidMount() {
        const { getMessages } = this.props;
        getMessages(0, 20);
    }

    componentDidUpdate() {
        const { readMessages } = this.props;
        readMessages();
    }

    _renderMessageGroup(group, previousGroup) {
        const { t, user, messagesLoaded, messagesTotalResults } = this.props;
        const message = group[0];

        const chatUser = this.getChatUser(message.user);
        if (!chatUser) return null;
        const isMe = user.id === chatUser.user.id;

        return (
            <div className={`msg-container${isMe ? ' me' : ''}`} key={message.id}>
                {previousGroup &&
                moment(previousGroup[0].updatedAt).format('DD/MM/YYY') !==
                    moment(message.updatedAt).format('DD/MM/YYY') ? (
                    <div>
                        <div className="day-seperator">
                            <div className="date">{moment(message.updatedAt).format('DD MMM')}</div>
                        </div>
                    </div>
                ) : !previousGroup && messagesLoaded >= messagesTotalResults ? (
                    <div>
                        <div className="day-seperator">
                            <div className="date">
                                {t('chat.startedAt')} {moment(message.updatedAt).format('DD MMM')}
                            </div>
                        </div>
                    </div>
                ) : !previousGroup ? (
                    <Loader />
                ) : null}
                <div className="flex-container msg">
                    <Link to={`/profile/${chatUser.user.id}`}>
                        <img
                            src={
                                chatUser.profile && chatUser.profile.avatar
                                    ? chatUser.profile.avatar.url
                                    : '/images/user.svg'
                            }
                            alt={`avatar ${chatUser.profile.name}`}
                        />
                    </Link>
                    <div className="">
                        <div className="name">
                            <Link to={`/profile/${chatUser.user.id}`}>{chatUser.profile.name}</Link>
                            <span>{moment(message.updatedAt).format('HH:mm')}</span>
                        </div>
                        {group.map((gMessage) => (
                            <div key={gMessage.id}>
                                <div
                                    className={`message ${gMessage.isOffline && 'offline'}`}
                                    dangerouslySetInnerHTML={{ __html: gMessage.message }}
                                ></div>
                                <div className="files">
                                    {gMessage.files.map((file) => (
                                        <div
                                            className="file"
                                            onClick={() => {
                                                this.setState({
                                                    filePreviewOpen: true,
                                                    files: gMessage.files,
                                                    focussedFile: file,
                                                });
                                            }}
                                        >
                                            <FilePreview file={file} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { t, chat, messagesIsFetching, messages } = this.props;
        const { filePreviewOpen, files, focussedFile } = this.state;

        if (messagesIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <div
                className="messages scrollable"
                ref={(div) => (this.messageListRef = div)}
                onScroll={(e) => this.onScroll(e)}
            >
                <FileViewer
                    t={t}
                    isActive={filePreviewOpen}
                    files={files}
                    focussedFile={focussedFile}
                    onClose={() => {
                        this.setState({
                            files: [],
                            focussedFile: null,
                            filePreviewOpen: false,
                        });
                    }}
                />

                {messages.length > 0 ? (
                    messages.map((groupedMessage, index) =>
                        this._renderMessageGroup(groupedMessage, messages[index + 1])
                    )
                ) : (
                    <div className="msg-container">
                        <div className="day-seperator">
                            <div className="date">{moment(chat.createdAt).format('DD MMM YYYY')}</div>
                        </div>
                        <div>
                            <NoData>{t("chat.noMessages")}</NoData>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
