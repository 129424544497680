import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateCategoryPage from "../../../components/category/crud/CreateCategoryPage.js";

import * as categoryActions from "../../../state/actions/categoryActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreateCategoryContainer extends Component {
    render() {
        const { changeValue, createCategory, popStack, ...props } = this.props;

        return (
            <CreateCategoryPage
                {...props}
                onChange={changeValue}
                createCategory={(category) =>
                    createCategory(category).then(() => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        
        user: state.auth.user,
        profile: state.auth.profile,
        ...state.category,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createCategory: (category) => dispatch(categoryActions.createCategory(category)),
        changeValue: (name, value) => dispatch(categoryActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateCategoryContainer));
