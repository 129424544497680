export const supportConstants = {
    SUPPORT_TICKET_CHANGE_VALUE: "SUPPORT_TICKET_CHANGE_VALUE",

    GET_SUPPORT_TICKETS_STARTED: "GET_SUPPORT_TICKETS_STARTED",
    GET_SUPPORT_TICKETS_SUCCESS: "GET_SUPPORT_TICKETS_SUCCESS",
    GET_SUPPORT_TICKETS_FAILURE: "GET_SUPPORT_TICKETS_FAILURE",

    GET_SUPPORT_TICKET_STARTED: "GET_SUPPORT_TICKET_STARTED",
    GET_SUPPORT_TICKET_SUCCESS: "GET_SUPPORT_TICKET_SUCCESS",
    GET_SUPPORT_TICKET_FAILURE: "GET_SUPPORT_TICKET_FAILURE",

    CREATE_SUPPORT_TICKET_STARTED: "CREATE_SUPPORT_TICKET_STARTED",
    CREATE_SUPPORT_TICKET_SUCCESS: "CREATE_SUPPORT_TICKET_SUCCESS",
    CREATE_SUPPORT_TICKET_FAILURE: "CREATE_SUPPORT_TICKET_FAILURE",

    UPDATE_SUPPORT_TICKET_STARTED: "UPDATE_SUPPORT_TICKET_STARTED",
    UPDATE_SUPPORT_TICKET_SUCCESS: "UPDATE_SUPPORT_TICKET_SUCCESS",
    UPDATE_SUPPORT_TICKET_FAILURE: "UPDATE_SUPPORT_TICKET_FAILURE",

    GET_SUPPORT_TICKET_REQUESTS_STARTED: "GET_SUPPORT_TICKET_REQUESTS_STARTED",
    GET_SUPPORT_TICKET_REQUESTS_SUCCESS: "GET_SUPPORT_TICKET_REQUESTS_SUCCESS",
    GET_SUPPORT_TICKET_REQUESTS_FAILURE: "GET_SUPPORT_TICKET_REQUESTS_FAILURE",

    GET_SUPPORT_TICKET_ERRORS_STARTED: "GET_SUPPORT_TICKET_ERRORS_STARTED",
    GET_SUPPORT_TICKET_ERRORS_SUCCESS: "GET_SUPPORT_TICKET_ERRORS_SUCCESS",
    GET_SUPPORT_TICKET_ERRORS_FAILURE: "GET_SUPPORT_TICKET_ERRORS_FAILURE",

    CREATE_SUPPORT_TICKET_ACTION_STARTED: "CREATE_SUPPORT_TICKET_ACTION_STARTED",
    CREATE_SUPPORT_TICKET_ACTION_SUCCESS: "CREATE_SUPPORT_TICKET_ACTION_SUCCESS",
    CREATE_SUPPORT_TICKET_ACTION_FAILURE: "CREATE_SUPPORT_TICKET_ACTION_FAILURE",

    UPDATE_SUPPORT_TICKET_ACTION_STARTED: "UPDATE_SUPPORT_TICKET_ACTION_STARTED",
    UPDATE_SUPPORT_TICKET_ACTION_SUCCESS: "UPDATE_SUPPORT_TICKET_ACTION_SUCCESS",
    UPDATE_SUPPORT_TICKET_ACTION_FAILURE: "UPDATE_SUPPORT_TICKET_ACTION_FAILURE",
};
