import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import EducationForm from "./EducationForm";

export default class UpdateEducationPage extends Component {

    render() {
        const { t, formEducation, isFetchingEducation, updateEducation, popStack } = this.props;

        if (!formEducation || isFetchingEducation) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("cv.education.update.header")}</h1>
                <EducationForm
                    {...this.props}
                    onSubmit={(education) =>
                        updateEducation(education).then(() => {
                            popStack();
                        })
                    }
                />
            </AsideView>
        );
    }
}
