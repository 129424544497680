import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import BusinessForm from "./BusinessForm";

export default class UpdateBusinessPage extends Component {

    render() {
        const { t, formBusiness, businessIsFetching, updateBusiness, popStack } = this.props;

        if (!formBusiness || businessIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("business.update")}</h1>
                <BusinessForm
                    {...this.props}
                    onSubmit={(business) => updateBusiness(business).then(() => popStack())}
                />
            </AsideView>
        );
    }
}
