import * as ratingApi from "../../api/ratingApi";
import { ratingConstants } from "../constants";

export const changeValue = (name, value) => ({
    type: ratingConstants.RATING_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching review
 */
export const startGetReview = () => ({
    type: ratingConstants.GET_REVIEW_STARTED,
});

export const getReviewSuccess = (result) => ({
    type: ratingConstants.GET_REVIEW_SUCCESS,
    ...result,
});

export const getReviewFailure = (error) => ({
    type: ratingConstants.GET_REVIEW_FAILURE,
    ...error,
});

export const getReview = (id) => {
    return (dispatch) => {
        dispatch(startGetReview());

        const promise = ratingApi.getReview(id);

        promise
            .then((result) => {
                dispatch(getReviewSuccess(result));
            })
            .catch((error) => {
                dispatch(getReviewFailure(error));
            });

        return promise;
    };
};

/**
 * Creating review
 */
export const startCreateReview = () => ({
    type: ratingConstants.CREATING_REVIEW_STARTED,
});

export const createReviewSuccess = (result) => ({
    type: ratingConstants.CREATING_REVIEW_SUCCESS,
    ...result,
});

export const createReviewFailure = (error) => ({
    type: ratingConstants.CREATING_REVIEW_FAILURE,
    ...error,
});

export const createReview = (rating, review) => {
    return (dispatch) => {
        dispatch(startCreateReview());

        const promise = ratingApi.createReview(rating, review);

        promise
            .then((result) => {
                dispatch(createReviewSuccess(result));
            })
            .catch((error) => {
                dispatch(createReviewFailure(error));
            });

        return promise;
    };
};

/**
 * Updating review
 */
export const startUpdateReview = () => ({
    type: ratingConstants.UPDATING_REVIEW_STARTED,
});

export const updateReviewSuccess = (result) => ({
    type: ratingConstants.UPDATING_REVIEW_SUCCESS,
    ...result,
});

export const updateReviewFailure = (error) => ({
    type: ratingConstants.UPDATING_REVIEW_FAILURE,
    ...error,
});

export const updateReview = (review) => {
    return (dispatch) => {
        dispatch(startUpdateReview());

        const promise = ratingApi.updateReview(review);

        promise
            .then((result) => {
                dispatch(updateReviewSuccess(result));
            })
            .catch((error) => {
                dispatch(updateReviewFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching reviews
 */
export const startGetReviews = () => ({
    type: ratingConstants.GET_REVIEWS_STARTED,
});

export const getReviewsSuccess = (result) => ({
    type: ratingConstants.GET_REVIEWS_SUCCESS,
    ...result,
});

export const getReviewsFailure = (error) => ({
    type: ratingConstants.GET_REVIEWS_FAILURE,
    ...error,
});

export const getReviews = (rating, from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetReviews());

        const promise = ratingApi.latestReviews(rating, from, amount);

        promise
            .then((result) => {
                dispatch(getReviewsSuccess(result));
            })
            .catch((error) => {
                dispatch(getReviewsFailure(error));
            });

        return promise;
    };
};

export const searchReviews = (rating, searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetReviews());

        const promise = ratingApi.searchReviews(rating, searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getReviewsSuccess(result));
            })
            .catch((error) => {
                dispatch(getReviewsFailure(error));
            });

        return promise;
    };
};
