import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateContractPage from "../../../components/contract/crud/CreateContractPage.js";

import * as contractActions from "../../../state/actions/contractActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreateContractContainer extends Component {
    render() {
        const {
            changeValue,
            createContract,
            searchVehicles,
            popStack,
            callback,
            ...props
        } = this.props;
        return (
            <CreateContractPage
                {...props}
                onChange={changeValue}
                createContract={(contract) =>
                    createContract(contract).then((response) => {
                        popStack && popStack();
                        callback && callback(response);
                    })
                }
                searchVehicles={(searchParameters, from, amount) =>
                    searchVehicles(searchParameters, from, amount)
                }
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        languages: state.platform.languages,
        ...state.contract,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createContract: (contract) =>
            dispatch(contractActions.createContract(contract)),
        changeValue: (name, value) => dispatch(contractActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateContractContainer));
