import React, { Component } from "react";
import moment from "moment";

import Loader from "../ui/components/Loader";
import NoData from "../ui/components/NoData";

export default class SupportTicketRequests extends Component {
    componentDidMount() {
        const { fetchRequests } = this.props;
        fetchRequests();
    }

    render() {
        const { t, requests, isFetchingRequests } = this.props;

        if (isFetchingRequests) return <Loader />;
        if (requests.length < 1) return <NoData className="left">{t("support.requests.notFound")}</NoData>;

        return (
            <div className="support-ticket-requests scrollable">
                {requests.map((request) => (
                    <div className="support-ticket-request" key={request.id}>
                        <div className="flex-container justify-between">
                            <div className={`responseCode ${request.responseCode}`}>
                                {request.responseCode}
                            </div>
                            <div className="responseTime">{request.responseTime}ms</div>
                        </div>
                        <div className={`flex-container no-wrap request ${request.requestMethod}`}>
                            <div className="requestMethod">{request.requestMethod}</div>
                            <div className="url">{request.url}</div>
                        </div>
                        <div className="date">{moment(request.createdAt).format("DD/MM/YYYY HH:mm:ss")}</div>
                        <div className="ip">{request.ip}</div>
                        <div className="agent">{request.userAgent}</div>
                    </div>
                ))}
            </div>
        );
    }
}
