import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import DashboardPage from "../../components/dashboard/DashboardPage";

import * as navigationActions from "../../state/actions/navigationActions";
import * as taskActions from "../../state/actions/taskActions";
import * as categoryActions from "../../state/actions/categoryActions";
import * as profileActions from "../../state/actions/profileActions";
import * as walletActions from "../../state/actions/walletActions";
import * as businessActions from "../../state/actions/businessActions";

class DashboardContainer extends Component {
    render() {
        const {
            getUserProfile,
            getUserWallet,
            ...props
        } = this.props;

        const { user } = this.props;

        return (
            <DashboardPage
                {...props}
                getUserProfile={() => getUserProfile(user.id)}
                getUserWallet={() => getUserWallet(user.id)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,

        user: state.auth.user,

        profileIsFetching: state.profile.profileIsFetching,
        profile: state.auth.profile,

        walletIsFetching: state.wallet.isFetching,
        wallet: state.wallet.wallet,
        personalBusinesses: state.business.personalBusinesses,
        
        isFetchingCategories: state.category.isFetchingCategories,
        categories: state.category.categories,
        ...state.task,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        getShifts: (from, amount) => dispatch(taskActions.getShifts(from, amount)),
        getPersonalShifts: (from, amount) => dispatch(taskActions.getPersonalShifts(from, amount)),
        getPersonalShiftsByDate: (start, from, amount) =>
            dispatch(taskActions.getPersonalShiftsByDate(start, from, amount)),
        searchShifts: (searchParameters, fromDate, from, to) =>
            dispatch(taskActions.searchShifts(searchParameters, fromDate, from, to)),

        getShiftTasksByDate: (start, end) => dispatch(taskActions.getShiftTasksByDate(start, end)),

        getPersonalTasks: (from, amount) => dispatch(taskActions.getPersonalTasks(from, amount)),
        getPersonalTasksByDate: (fromDate, from, amount) =>
            dispatch(taskActions.getPersonalTasksByDate(fromDate, from, amount)),

        getUserProfile: (id) => dispatch(profileActions.getUserProfile(id)),
        getUserWallet: (id) => dispatch(walletActions.getUserWallet(id)),
        getPersonalBusinesses: () => dispatch(businessActions.getPersonalBusinesses()),

        getCategories: () => dispatch(categoryActions.getCategories()),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(DashboardContainer));
