import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import Home from "../../components/pages/Home";

class HomeContainer extends Component {
    render() {
        const { ...props } = this.props;

        return <Home {...props} />;
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
    };
}

function mapDispatchToProps() {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(HomeContainer));
