export default class PlatformHostName {
    id;
    nonce;

    hostName;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
    }
}