import TaskShift from './TaskShift';
import TaskLocation from './TaskLocation';

export default class Task {
    
    id;
    nonce; 
    
    title;
    description;
    banners;
    keywords;
    location;
    descriptionParts;
    type;

    shifts;

    createdAt;
    updatedAt;

    constructor() {
        this.id = Math.random().toString(36).substring(7);
        this.nonce = Math.random().toString(36).substring(7);
        
        this.employerType = "personal"
        this.keywords = [];
        
        this.descriptionParts = [];

        this.location = new TaskLocation();

        this.shifts = [];
        this.shifts.push(new TaskShift());

        this.banners = [];
    }
}