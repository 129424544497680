import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import moment from 'moment';

import AccountView from './AccountView';
import WalletView from './WalletView';
import SearchShifts from '../task/SearchShifts';
import ShiftPlanner from '../task/ShiftPlanner';
import TaskFilterView from '../task/TaskFilterView';

import TransactionsContainer from '../../containers/wallet/transaction/TransactionsContainer';
import ChecksContainer from '../../containers/task/ChecksContainer';
import PersonalShiftsContainer from '../../containers/task/PersonalShiftsContainer';
import CreatePayoutContainer from '../../containers/wallet/payout/crud/CreatePayoutContainer';
import AddCreditContainer from '../../containers/wallet/crud/AddCreditContainer';

export default class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 'myWork',
            subTab: 'future',
            filtersActive: false,
            currentDate: new Date(),
            searchParams: null,
        };
    }

    handleScroll = () => {
        const {
            match,
            shiftsTotalResults,
            shiftsLoaded,
            isFetchingShifts,
            isFetchingTasks,
            tasksTotalResults,
            tasksLoaded,
        } = this.props;
        const tab = match.params.tab ? match.params.tab : this.state.tab;

        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50) {
            if (tab === 'myWork' && !isFetchingShifts && shiftsTotalResults > shiftsLoaded) {
                this.fetch(shiftsLoaded);
            }
            if (tab === 'schedule' && !isFetchingTasks && tasksTotalResults > tasksLoaded) {
                this.fetch(tasksLoaded);
            }
        }
    };

    fetch = (offset = 0, amount = 20) => {
        const { match, getPersonalTasks, getPersonalShiftsByDate } = this.props;

        const tab = match.params.tab ? match.params.tab : this.state.tab;
        switch (tab) {
            case 'myWork':
            default:
                getPersonalShiftsByDate(moment().startOf('day'), null, offset, amount);
                break;
            case 'schedule':
                getPersonalTasks(offset, amount);
                break;
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.scrollTo(0, 0);

        const { match } = this.props;

        this.fetch();

        if (match.params.tab) {
            this.setState({
                tab: match.params.tab,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { t, platform, getPersonalTasks, getPersonalShiftsByDate, addToStack } = this.props;
        const { filtersActive, searchParams, tab, subTab } = this.state;

        return (
            <div className="container main dashboard">
                <div className="wrapper top minimal">
                    <div className="left-bar">
                        {filtersActive ? (
                            <TaskFilterView
                                {...this.props}
                                filters={searchParams}
                                onChange={(e) => {
                                    console.log('has changed');
                                    this.setState({
                                        searchParams: e,
                                    });
                                }}
                            />
                        ) : (
                            <div className="white-container">
                                <AccountView {...this.props} />
                                <div style={{ padding: '0px 10px 10px' }}>
                                    <div className="balance">
                                        <div className="part">
                                            <h1>{t('wallet.header')}</h1>
                                            <Link to="/wallet">
                                                <div className="details">{t('details.header')}</div>
                                            </Link>
                                        </div>
                                        <div className="bottom">
                                            <div className="available">
                                                <WalletView {...this.props} />
                                            </div>
                                            <div className="options">
                                                <h1>{t('options.header')}</h1>
                                                <div
                                                    className="as-link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addToStack({
                                                            name: t('wallet.addCredit.header'),
                                                            component: <AddCreditContainer />,
                                                        });
                                                    }}
                                                >
                                                    {t('wallet.addCredit')}
                                                </div>
                                                <div
                                                    className="as-link"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addToStack({
                                                            name: t('wallet.payout.header'),
                                                            component: <CreatePayoutContainer />,
                                                        });
                                                    }}
                                                >
                                                    {t('wallet.withdrawMoney')}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="part">
                                                <h1>{t('wallet.transactions.header')}</h1>
                                            </div>
                                            <TransactionsContainer minimal max={5} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="find">
                        <div className="tabs full-white">
                            <ul>
                                <li
                                    className={'tab ' + (tab === 'myWork' ? 'active' : '')}
                                    onClick={() => {
                                        this.setState({ tab: 'myWork', filtersActive: false });
                                        getPersonalShiftsByDate(
                                            subTab === 'future' ? moment().startOf('day') : null,
                                            subTab === 'past' ? moment().endOf('day') : null,
                                            0,
                                            20
                                        );
                                        window.history.replaceState({}, platform.name, '/dashboard/myWork');
                                    }}
                                >
                                    {t('dashboard.myWork')}
                                </li>
                                <li
                                    className={'tab ' + (tab === 'search' ? 'active' : '')}
                                    onClick={() => {
                                        this.setState({ tab: 'search', filtersActive: false });
                                        window.history.replaceState({}, platform.name, '/dashboard/search');
                                    }}
                                >
                                    {t('dashboard.searchTasks')}
                                </li>
                                <li
                                    className={'tab ' + (tab === 'schedule' ? 'active' : '')}
                                    onClick={() => {
                                        this.setState({ tab: 'schedule', filtersActive: false });
                                        getPersonalTasks(0, 20);
                                        window.history.replaceState({}, platform.name, '/dashboard/schedule');
                                    }}
                                >
                                    {t('dashboard.planning')}
                                </li>
                                <li
                                    className={'tab ' + (tab === 'checkInOut' ? 'active' : '')}
                                    onClick={() => {
                                        this.setState({ tab: 'checkInOut', filtersActive: false });
                                        window.history.replaceState({}, platform.name, '/dashboard/checkInOut');
                                    }}
                                >
                                    {t('dashboard.checkInOut')}
                                </li>
                            </ul>
                        </div>
                        <section className={'activity ' + (tab === 'myWork' ? 'active' : '')}>
                            <div className="tabs small" style={{ marginTop: 20 }}>
                                <ul>
                                    <li
                                        className={'tab ' + (subTab === 'future' ? 'active' : '')}
                                        onClick={() => {
                                            this.setState({ subTab: 'future' });
                                            getPersonalShiftsByDate(moment().startOf('day'), null, 0, 20);
                                        }}
                                    >
                                        {t('dashboard.shifts.future')}
                                    </li>
                                    <li
                                        className={'tab ' + (subTab === 'past' ? 'active' : '')}
                                        onClick={() => {
                                            this.setState({ subTab: 'past' });
                                            getPersonalShiftsByDate(null, moment().endOf('day'), 0, 20);
                                        }}
                                    >
                                        {t('dashboard.shifts.past')}
                                    </li>
                                </ul>
                            </div>
                            {tab === 'myWork' && (
                                <>
                                    <section className={`activity${subTab === 'future' ? ' active' : ''}`}>
                                        <PersonalShiftsContainer {...this.props} />
                                    </section>
                                    <section className={`activity${subTab === 'past' ? ' active' : ''}`}>
                                        <PersonalShiftsContainer {...this.props} />
                                    </section>
                                </>
                            )}
                        </section>
                        <section className={'activity ' + (tab === 'search' ? 'active' : '')}>
                            <div className="search-box">
                                <div className="filters">
                                    <div
                                        className={`filter-button${filtersActive ? ' active' : ''}`}
                                        onClick={(e) => this.setState({ filtersActive: !filtersActive })}
                                    >
                                        <ReactSVG src="/icons/filter.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="results">
                                {tab === 'search' && <SearchShifts {...this.props} searchParams={searchParams} />}
                            </div>
                        </section>

                        <section
                            className={'activity ' + (tab === 'schedule' ? 'active' : '')}
                            style={{ marginTop: 20 }}
                        >
                            {tab === 'schedule' && <ShiftPlanner {...this.props} />}
                        </section>
                        <section
                            className={'activity ' + (tab === 'checkInOut' ? 'active' : '')}
                            style={{ marginTop: 20 }}
                        >
                            {tab === 'checkInOut' && <ChecksContainer {...this.props} />}
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
