import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import TransactionsView from "../../../components/wallet/transaction/TransactionsView";

import * as walletActions from "../../../state/actions/walletActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class TransactionsContainer extends Component {
    render() {
        const { max, transactions, getTransactions, ...props } = this.props;

        return (
            <TransactionsView
                {...props}
                transactions={max ? transactions.slice(0, max) : transactions}
                getTransactions={(from = 0, to = 20) => getTransactions(from, to)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        
        user: state.auth.user,
        ...state.wallet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTransactions: (from, to) => dispatch(walletActions.getTransactions(from, to)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(TransactionsContainer));
