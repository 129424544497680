import React, { Component } from "react";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import Cleave from "cleave.js/react";
import moment from "moment";

import { activateInputs, enableSwitchCheckboxes } from "../../../utils/formUtils";

import CountrySelector from "../../ui/components/CountrySelector";
import Loader from "../../ui/components/Loader";

export default class UpdateProfilePage extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({ autoForceUpdate: true });

        this.state = {};
    }

    handleSubmit(e) {
        e.preventDefault();
        const { updateProfile } = this.props;

        updateProfile();
    }

    componentDidMount() {
        activateInputs();
        enableSwitchCheckboxes();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const { t, onChange, fields, formProfile, profileIsFetching } = this.props;

        if (profileIsFetching || !formProfile) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <form className={"form active no-padding-top"} onSubmit={(e) => this.handleSubmit(e)}>
                <h1 className="center">{t("profile.update")}</h1>
                <div className="input-container">
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={formProfile.name}
                                onChange={(event) => {
                                    const profile = { ...formProfile };
                                    profile.name = event.target.value;
                                    onChange("formProfile", profile, event);
                                }}
                            />
                            <label>{t("form.label.name")}</label>
                        </div>
                        {this.validator.message(t("form.label.name"), formProfile.name, "string|max:255")}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <textarea
                                type="text"
                                className="small"
                                value={formProfile.headline}
                                onChange={(event) => {
                                    const profile = { ...formProfile };
                                    profile.headline = event.target.value;
                                    onChange("formProfile", profile, event);
                                }}
                            ></textarea>
                            <label>{t("form.label.headline")}</label>
                        </div>
                        {this.validator.message(
                            t("form.label.firstName"),
                            formProfile.headline,
                            "string|max:255"
                        )}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <Select
                                placeholder={t("form.label.selectField")}
                                noOptionsMessage={() => t("noOptions")}
                                options={fields.map((field) => ({
                                    value: field.id,
                                    label: t(`field.${field.name}`),
                                }))}
                                value={formProfile.fieldValue}
                                onChange={(event) => {
                                    const profile = { ...formProfile };
                                    profile.field = event.value;
                                    profile.fieldValue = event;
                                    onChange("formProfile", profile);
                                }}
                            />
                        </div>
                        {this.validator.message(t("form.label.field"), formProfile.field, "required")}
                    </div>
                    <div className="input-group more">
                        <h1>{t("form.label.fullName")}</h1>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.firstName}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.firstName = event.target.value;
                                        profile.person = person;
                                        onChange("formProfile", profile, event);
                                    }}
                                />
                                <label>{t("form.label.firstName")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.firstName"),
                                formProfile.person.firstName,
                                "required|string|max:255"
                            )}
                        </div>
                        <div className="input-group half">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formProfile.person.lastName}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.lastName = event.target.value;
                                        profile.person = person;
                                        onChange("formProfile", profile, event);
                                    }}
                                />
                                <label>{t("form.label.lastName")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.lastName"),
                                formProfile.person.lastName,
                                "required|string|max:255"
                            )}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <Cleave
                                    value={formProfile.person.phoneNumber}
                                    options={{ phone: true, phoneRegionCode: "NL" }}
                                    onChange={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.phoneNumber = event.target.value;
                                        profile.person = person;
                                        onChange("formProfile", profile, event);
                                    }}
                                />
                                <label>{t("form.label.phoneNumber")}*</label>
                            </div>
                            {this.validator.message(
                                t("form.label.phoneNumber"),
                                formProfile.person.phoneNumber,
                                "required"
                            )}
                        </div>
                    </div>
                    <div className="input-group more">
                        <div className="input-group half">
                            <h1>{t("form.label.gender")}</h1>
                            <div className="input-group half">
                                <input
                                    type="checkbox"
                                    name="gender"
                                    id="MALE"
                                    value="MALE"
                                    checked={
                                        formProfile.person.gender === "MALE" || !formProfile.person.gender
                                    }
                                    onClick={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.gender = event.target.checked ? "MALE" : "FEMALE";
                                        profile.person = person;
                                        onChange("formProfile", profile, event);
                                    }}
                                />
                                <label className="normal gray" htmlFor="MALE">
                                    {t("form.label.male")}
                                </label>
                            </div>
                            <div className="input-group half">
                                <input
                                    type="checkbox"
                                    name="gender"
                                    id="FEMALE"
                                    value="FEMALE"
                                    checked={formProfile.person.gender === "FEMALE"}
                                    onClick={(event) => {
                                        const profile = { ...formProfile };
                                        const person = { ...profile.person };
                                        person.gender = event.target.checked ? "FEMALE" : "MALE";
                                        profile.person = person;
                                        onChange("formProfile", profile, event);
                                    }}
                                />
                                <label className="normal gray" htmlFor="FEMALE">
                                    {t("form.label.female")}
                                </label>
                            </div>
                        </div>
                        <div className="input-group half">
                            <h1>{t("form.label.dateOfBirth")}*</h1>
                            <div className="input-group">
                                <Cleave
                                    placeholder="dd/mm/yyyy"
                                    value={this.props.dateOfBirthValue}
                                    options={{ date: true, datePattern: ["d", "m", "Y"] }}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange("dateOfBirthValue", value);

                                        const dateOfBirth = moment(value, "DD/MM/YYYY");
                                        if (dateOfBirth.isValid) {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            person.birthday = dateOfBirth;
                                            profile.person = person;
                                            onChange("formProfile", profile, event);
                                        }
                                    }}
                                />
                            </div>
                            {this.validator.message(
                                "Geboorte datum",
                                formProfile.person.dateOfBirth,
                                "required"
                            )}
                        </div>
                        <div className="input-group more">
                            <h1>{t("form.label.address")}</h1>
                            <div className="input-group eighty">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formProfile.person.address.street}
                                        onChange={(event) => {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            const address = { ...person.address };
                                            address.street = event.target.value;
                                            person.address = address;
                                            profile.person = person;
                                            onChange("formProfile", profile, event);
                                        }}
                                    />
                                    <label>{t("form.label.street")}*</label>
                                </div>
                                {this.validator.message(
                                    t("form.label.street"),
                                    formProfile.person.address.street,
                                    "required"
                                )}
                            </div>
                            <div className="input-group fifth">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formProfile.person.address.houseNumber}
                                        onChange={(event) => {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            const address = { ...person.address };
                                            address.houseNumber = event.target.value;
                                            person.address = address;
                                            profile.person = person;
                                            onChange("formProfile", profile, event);
                                        }}
                                    />
                                    <label>{t("form.label.houseNumber")}*</label>
                                </div>
                                {this.validator.message(
                                    t("form.label.houseNumber"),
                                    formProfile.person.address.houseNumber,
                                    "required"
                                )}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formProfile.person.address.zipCode}
                                        onChange={(event) => {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            const address = { ...person.address };
                                            address.zipCode = event.target.value;
                                            person.address = address;
                                            profile.person = person;
                                            onChange("formProfile", profile, event);
                                        }}
                                    />
                                    <label>{t("form.label.zipCode")}*</label>
                                </div>
                                {this.validator.message(
                                    t("form.label.zipCode"),
                                    formProfile.person.address.zipCode,
                                    "required"
                                )}
                            </div>
                            <div className="input-group half">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formProfile.person.address.city}
                                        onChange={(event) => {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            const address = { ...person.address };
                                            address.city = event.target.value;
                                            person.address = address;
                                            profile.person = person;
                                            onChange("formProfile", profile, event);
                                        }}
                                    />
                                    <label>{t("form.label.city")}*</label>
                                </div>
                                {this.validator.message(
                                    t("form.label.city"),
                                    formProfile.person.address.city,
                                    "required"
                                )}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <CountrySelector
                                        placeholder={t("form.label.selectCountry")}
                                        value={formProfile.person.address.countryCode}
                                        onChange={(value) => {
                                            const profile = { ...formProfile };
                                            const person = { ...profile.person };
                                            const address = { ...person.address };
                                            address.country = value.label;
                                            address.countryCode = value.value;
                                            person.address = address;
                                            profile.person = person;
                                            onChange("formProfile", profile);
                                        }}
                                    />
                                </div>
                                {this.validator.message(
                                    t("form.label.country"),
                                    formProfile.person.address.country,
                                    "required"
                                )}
                            </div>
                        </div>
                        <div className="input-group right more">
                            <input
                                type="submit"
                                value={t("form.update")}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}
