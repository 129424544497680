import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../../api/fileApi";

import CreateBusinessPage from "../../../components/business/crud/CreateBusinessPage";
import * as businessActions from "../../../state/actions/businessActions";
import * as navigationActions from "../../../state/actions/navigationActions";

class CreateBusinessContainer extends Component {
    render() {
        const { changeValue, createBusiness, popStack, ...props } = this.props;

        return (
            <CreateBusinessPage
                {...props}
                popStack={popStack}
                onChange={changeValue}
                createBusiness={(business) => createBusiness(business)}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        platform: state.platform.platform,
        industries: state.platform.industries,
        ...state.business,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(businessActions.changeCreateBusiness(name, value)),
        createBusiness: (business) => dispatch(businessActions.createBusiness(business)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateBusinessContainer));
