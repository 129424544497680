import React, { Component } from 'react';

import ProfileTopView from './ProfileTopView';
import Loader from '../ui/components/Loader';

import CurriculumVitaeContainer from '../../containers/cv/CurriculumVitaeContainer';
import ArticlesContainer from '../../containers/article/ArticlesContainer';

export default class ProfilePage extends Component {
    state = {
        tab: 'cv',
    };
    componentDidMount() {
        const { profileIsFetching, user, getUserProfile, match, profileId } = this.props;
        if (profileId) {
        } else {
            if (match.params.id) {
                if (!profileIsFetching) getUserProfile(match.params.id);
            } else {
                if (!profileIsFetching) getUserProfile(user.id);
            }
        }

        if (match.params.tab) {
            switch (match.params.tab) {
                case 'articles':
                    this.setState({ tab: 'articles' });
                    break;
                default: 
                    break
            }
        }
    }

    render() {
        const { tab } = this.state;
        const { profileIsFetching } = this.props;
        // const { t, platform, profile } = this.props;

        if (profileIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }
        return (
            <div className="container profile">
                <div className="wrapper top minimal">
                    <ProfileTopView {...this.props} />
                    {/* <div className="tabs full-white">
                        <ul>
                            <li
                                className={'tab ' + (tab === 'cv' ? 'active' : '')}
                                onClick={() => {
                                    this.setState({ tab: 'cv' });
                                    window.history.replaceState({}, platform.name, `/profile/${profile.user.id}/cv`);
                                }}
                            >
                                {t('profile.tabs.cv')}
                            </li>
                            <li
                                className={'tab ' + (tab === 'articles' ? 'active' : '')}
                                onClick={() => {
                                    this.setState({ tab: 'articles' });
                                    window.history.replaceState({}, platform.name, `/profile/${profile.user.id}/articles`);
                                }}
                            >
                                {t('profile.tabs.articles')}
                            </li>
                        </ul>
                    </div> */}
                    <section className={'activity ' + (tab === 'cv' ? 'active' : '')}>
                        <CurriculumVitaeContainer />
                    </section>
                    <section className={'activity ' + (tab === 'articles' ? 'active' : '')}>
                        <ArticlesContainer />
                    </section>
                </div>
            </div>
        );
    }
}
