import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as fileApi from "../../api/fileApi";

import ProfilePage from "../../components/profile/ProfilePage";

import * as profileActions from "../../state/actions/profileActions";
import * as navigationActions from "../../state/actions/navigationActions";

class ProfileContainer extends Component {
    render() {
        const { getUserProfile, updateProfileAvatar, updateProfileBanner, ...props } = this.props;

        

        return (
            <ProfilePage
                {...props}
                getUserProfile={(id) => getUserProfile(id)}
                uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
                updateProfileAvatar={(avatar) => updateProfileAvatar(avatar)}
                updateProfileBanner={(banner) => updateProfileBanner(banner)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        platform: state.platform.platform,
        user: state.auth.user,
        ...state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getUserProfile: (id) => dispatch(profileActions.getUserProfile(id)),
        updateProfileAvatar: (avatar) => dispatch(profileActions.updateProfileAvatar(avatar)),
        updateProfileBanner: (banner) => dispatch(profileActions.updateProfileBanner(banner)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(ProfileContainer));
