import React, { Component } from "react";

import Loader from "../../ui/components/Loader";
import AsideView from "../../ui/components/AsideView";

import TaskApplication from "../../../models/task/TaskApplication";

import ApplicationForm from "./ApplicationForm";

export default class CreateApplicationPage extends Component {
    componentDidMount() {
        const { onChange, shifts, task } = this.props;

        const application = new TaskApplication();
        application.task = task.id;
        application.shifts = shifts;
        onChange("formApplication", application);
    }

    render() {
        const {
            t,
            isFetchingCurrencies,
            formApplication,
            isFetchingApplication,
            createApplication,
            shifts,
        } = this.props;

        if (isFetchingCurrencies || !formApplication || isFetchingApplication) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("task.application.new", { count: shifts.length })}</h1>
                <ApplicationForm
                    {...this.props}
                    onSubmit={(formApplication) => createApplication(formApplication)}
                />
            </AsideView>
        );
    }
}
